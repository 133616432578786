import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import profile01 from '../../Assets/profile01.jpg';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "./Appointment.scss";



import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { firebaseAuth } from '../../firebaseConfig';
import { getClinicData, getClinicsData, getPatientExper, getPatientsExper, getProviderList, getProviderLists, getSlot } from '../../utils/httpServices/httpService';

const steps = ['Information', 'Insurance', 'Confirm'];

const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(firebaseAuth, provider);
        const { displayName, email, idToken, photoUrl } = result._tokenResponse;
        //   setUser({ displayName, email, idToken, photoUrl });
        console.log("signIn", result, email);
    } catch (error) {
        console.log(error);
    }
};




// ----Photos----


// const stepContent = [
//     {
//         label: 'Select campaign settings',
//         content: (
//             <div className='first-step'>
//                 <Typography variant="body1" gutterBottom>
//                     Please fill out the following information:
//                 </Typography>
//                 <TextField label="Email" variant="outlined" fullWidth margin="normal" />
//                 <TextField label="Number" variant="outlined" fullWidth margin="normal" />
//                 <Typography className='text-center line mb-2'>
//                     or
//                 </Typography>
//                 <button className='btn-white rounded mb-4 w-100'>
//                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" role="img" class="icon ">
//                         <path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="#4285F4"></path>
//                         <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path>
//                         <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" fill="#FBBC05"></path>
//                         <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path>
//                     </svg>
//                     <span className='ms-2' onClick={handleGoogleSignIn}> Sign in with Google</span>

//                 </button>
//                 <Typography className='text-center line mb-2'>
//                     Already have an account?  <Link className='ms-2' to='/login'> Log in</Link>
//                 </Typography>
//                 <button className="theme_btn w-100">
//                     Continue booking
//                 </button>
//             </div>
//         ),
//     },
//     {
//         label: 'Create an ad group',
//         content: (

//             <div className='second_step'>
//                 <div className='mb-3'>
//                     <h5 className=''>
//                         Insurance Information
//                     </h5>
//                     <Typography variant="body1" gutterBottom>
//                         This information should be sent to the practice so they can check your coverage.
//                     </Typography>
//                 </div>


//                 <div className='form-group mb-3'>
//                     <label htmlFor="" className='form-label'>Insurance Carrier</label>
//                     <select class="form-select" aria-label="Default select example">
//                         <option selected>Cigna</option>
//                         <option value="1">One</option>
//                         <option value="2">Two</option>
//                         <option value="3">Three</option>
//                     </select>
//                 </div>
//                 <div className='form-group'>
//                     <label htmlFor="" className='form-label'>Insurance Plan</label>

//                     <select class="form-select" aria-label="Default select example">

//                         <option selected>Cigna Dental Pro</option>
//                         <option value="1">One</option>
//                         <option value="2">Two</option>
//                         <option value="3">Three</option>
//                     </select>
//                 </div>
//             </div>

//         )

//     },
//     {
//         label: 'Create an ad',
//         content: (
//             <div className='third-step'>
//                 <div className="card success-card mb-0">
//                     <div className="card-body">
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Name: </Typography>
//                             <Typography className='body2'>John Sons </Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Email: </Typography>
//                             <Typography className='body1'>JohnSons@gmail.com </Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Contact: </Typography>
//                             <Typography className='body2'>+44 484711554 </Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Insurance Carrier: </Typography>
//                             <Typography className='body1'>Cigna </Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Insurance Plan: </Typography>
//                             <Typography className='body1'>Cigna Dental Pro</Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Membar ID: </Typography>
//                             <Typography className='body1'>ABC 123456789</Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Date: </Typography>
//                             <Typography className='body1'>25 Nov 2023</Typography>
//                         </div>
//                         <div className='basic-dtl '>
//                             <Typography className='body2'>Time: </Typography>
//                             <Typography className='body1'>9:00 AM</Typography>
//                         </div>
//                         <div className='basic-dtl align-items-start justify-content-start flex-column'>
//                             <Typography className='body2'>Location: </Typography>
//                             <Typography className='body1'>1700 Westwood Boulevard, Los Angeles CA, 90024</Typography>
//                         </div>
//                         {/* <div className="success-cont">
//                             <i className="fas fa-check" />
//                             <h3>Appointment booked Successfully!</h3>
//                             <p>
//                                 Appointment booked with <strong>Dr. Shahram Sayedna</strong>
//                                 <br /> on <strong>12 Nov 2023 5:00PM to 6:00PM</strong>
//                             </p>
//                         </div> */}
//                     </div>
//                 </div>


//             </div>
//         )
//     },
// ];
const { RangePicker } = DatePicker;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function Appointment() {

    const { id } = useParams();
    const [data, setData] = useState({});
    const [ind, setInd] = useState(0);
    const [length, setLength] = useState(0);
    const [presentDate, setPresentDate] = useState('');
    const [open1, setOpen1] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [Day1, setDay1] = useState('')
    const [patients1, setPatients1] = useState([]);
    const [limit, setLimit] = useState(2)
    const [count, setCount] = useState(0)
    const [lat, setLat] = useState()
    const [lng, setLng] = useState()
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [providerList, setProvderList] = useState([]);
    const [datePick, setDatePick] = useState('')
    const [providerId, setProviderId] = useState("");
    const map1 = new Map();

    map1.set('Monday', 'Closed');
    map1.set('Tuesday', 'Closed');
    map1.set('Wednesday', 'Closed');
    map1.set('Thursday', 'Closed');
    map1.set('Friday', 'Closed');
    map1.set('Saturday', 'Closed');
    map1.set('Sunday', 'Closed');

    const formik = useFormik({
        initialValues: {
            Sunday: 'Sun',
            Monday: 'Mon',
            Tuesday: 'Tue',
            Wednesday: 'Wed',
            Thursday: 'Thu',
            Friday: 'Fri',
            Saturday: 'Sat',

        }
    })

    const formik1 = useFormik({
        initialValues: {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,

        }
    })

    const formik2 = useFormik({ //For collecting the slots from api
        initialValues: {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: []
        }
    })

    const formik3 = useFormik({ // To collect the slots onClick
        initialValues: {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: []
        }
    })

    const stepContent = [
        {
            label: 'Select campaign settings',
            content: (
                <div className='first-step'>
                    <Typography variant="body1" gutterBottom>
                        Please fill out the following information:
                    </Typography>
                    <TextField label="Email" variant="outlined" fullWidth margin="normal" />
                    <TextField label="Number" variant="outlined" fullWidth margin="normal" />
                    <Typography className='text-center line mb-2'>
                        or
                    </Typography>
                    <button className='btn-white rounded mb-4 w-100'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" role="img" class="icon ">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="#4285F4"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" fill="#FBBC05"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path>
                        </svg>
                        <span className='ms-2' onClick={handleGoogleSignIn}> Sign in with Google</span>

                    </button>
                    <Typography className='text-center line mb-2'>
                        Already have an account?  <Link className='ms-2' to='/login'> Log in</Link>
                    </Typography>
                    <button className="theme_btn w-100">
                        Continue booking
                    </button>
                </div>
            ),
        },
        {
            label: 'Create an ad group',
            content: (

                <div className='second_step'>
                    <div className='mb-3'>
                        <h5 className=''>
                            Insurance Information
                        </h5>
                        <Typography variant="body1" gutterBottom>
                            This information should be sent to the practice so they can check your coverage.
                        </Typography>
                    </div>


                    <div className='form-group mb-3'>
                        <label htmlFor="" className='form-label'>Insurance Carrier</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Cigna</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="" className='form-label'>Insurance Plan</label>

                        <select class="form-select" aria-label="Default select example">

                            <option selected>Cigna Dental Pro</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>

            )

        },
        {
            label: 'Create an ad',
            content: (
                <div className='third-step'>
                    <div className="card success-card mb-0">
                        <div className="card-body">
                            <div className='basic-dtl '>
                                <Typography className='body2'>Name: </Typography>
                                <Typography className='body2'>John Sons </Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Email: </Typography>
                                <Typography className='body1'>JohnSons@gmail.com </Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Contact: </Typography>
                                <Typography className='body2'>+44 484711554 </Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Insurance Carrier: </Typography>
                                <Typography className='body1'>Cigna </Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Insurance Plan: </Typography>
                                <Typography className='body1'>Cigna Dental Pro</Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Membar ID: </Typography>
                                <Typography className='body1'>ABC 123456789</Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Date: </Typography>
                                <Typography className='body1'>25 Nov 2023</Typography>
                            </div>
                            <div className='basic-dtl '>
                                <Typography className='body2'>Time: </Typography>
                                <Typography className='body1'>9:00 AM</Typography>
                            </div>
                            <div className='basic-dtl align-items-start justify-content-start flex-column'>
                                <Typography className='body2'>Location: </Typography>
                                <Typography className='body1'>1700 Westwood Boulevard, Los Angeles CA, 90024</Typography>
                            </div>
                            {/* <div className="success-cont">
                                <i className="fas fa-check" />
                                <h3>Appointment booked Successfully!</h3>
                                <p>
                                    Appointment booked with <strong>Dr. Shahram Sayedna</strong>
                                    <br /> on <strong>12 Nov 2023 5:00PM to 6:00PM</strong>
                                </p>
                            </div> */}
                        </div>
                    </div>


                </div>
            )
        },
    ];

    const [test, setTest] = useState(map1);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []);

    useEffect(() => {
        if (id) {
            getPatients();
        }
    }, [limit]);

    useEffect(() => {
     console.log(providerId)
        if (providerId) {
            getSlo();
        }
    }, [providerId]);

    const googleMapsUrl = `https://www.google.com/maps/embed/v1/view?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&center=${lat},${lng}&zoom=15`;

    const checkAMorPM = (time) => {
        // Assuming the time is in HH:mm:ss format
        const hour = parseInt(time.split(':')[0], 10);

        if (hour < 12) {
            return 'AM';
        } else {
            return 'PM';
        }
    }; 

   
    const localCon = (start, end, presenTime, presentDay, day) => {
        console.log('start-------->', start)
        const start_time = new Date(start)?.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
        });
        console.log('start_time---------->', start_time)
        if (presentDay === day) {
            const dateObj1 = new Date(`2000-01-01T${presenTime}`);// We just need compare the time
            const dateObj2 = new Date(`2000-01-01T${start_time}`);// We just need compare the time

            // Compare the Date objects
            if (dateObj1 > dateObj2) {
                setShowTime(false)
            } else if (dateObj1 < dateObj2) {
                setShowTime(true)
            } else {
                setShowTime(true)
            }
        }
        const end_time = new Date(end)?.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
        });

        let t1 = '';
        let t2 = '';

        const st = checkAMorPM(start_time);
        if (st === 'AM') {
            const hour = start_time.split(':');
            t1 = `${hour[0]}:${hour[1]} AM`;
        } else {
            const hour = parseInt(start_time.split(':')[0], 10);
            const h = hour > 12 ? hour - 12 : hour;
            t1 = `${h}:${start_time.split(':')[1]} PM`;
        }

        const en = checkAMorPM(end_time);
        if (en === 'AM') {
            const hour = end_time.split(':');
            t2 = `${hour[0]}:${hour[1]} AM`;
        } else {
            const hour = parseInt(end_time.split(':')[0], 10);
            const h = hour > 12 ? hour - 12 : hour;
            t2 = `${h}:${end_time.split(':')[1]} PM`;
        }

        const formatTime = `${t1}-${t2}`;
        // console.log(formatTime);
        return formatTime;
    };

    const localCon1 = (start, end) => {
        const start_time = new Date(start)?.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
        });

        const end_time = new Date(end)?.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
        });
        let t1 = '';
        let t2 = '';

        const st = checkAMorPM(start_time);
        if (st === 'AM') {
            const hour = start_time.split(':');
            t1 = `${hour[0]}:${hour[1]} AM`;
        } else {
            const hour = parseInt(start_time.split(':')[0], 10);
            const h = hour > 12 ? hour - 12 : hour;
            t1 = `${h}:${start_time.split(':')[1]} PM`;
        }

        const en = checkAMorPM(end_time);
        if (en === 'AM') {
            const hour = end_time.split(':');
            t2 = `${hour[0]}:${hour[1]} AM`;
        } else {
            const hour = parseInt(end_time.split(':')[0], 10);
            const h = hour > 12 ? hour - 12 : hour;
            t2 = `${h}:${end_time.split(':')[1]} PM`;
        }

        const formatTime = `${t1}-${t2}`;
        // console.log(formatTime);
        return formatTime;
    }

    const getData = async () => {
        try {

            let resp = {};
            let resp1 = []
            const token = localStorage.getItem('token');
            const user_data = localStorage.getItem('user_details');

            if (token != null && user_data != null) {
                resp = await getClinicsData(id);
                resp1 = await getProviderLists(id);
            } else {
                resp = await getClinicData(id);
                resp1 = await getProviderList(id);
            }

            setData(resp?.data[0])

            const doc_length = resp?.data[0]?.clinic_documents;

            if (doc_length.length > 6) {
                setInd(5);
            }

            setLength(doc_length.length);

            const date = new Date();
            const dateString = date.toString();
            const time = dateString.split(' ');

            setPresentDate(`${time[2]} ${time[1]} ${time[3]}`)
            const dayOfWeek = date.getDay(); // Returns a number (0-6) representing the day of the week (Sunday is 0, Monday is 1, and so on)

            // Convert dayOfWeek to a string representation
            const dayName = daysOfWeek[dayOfWeek];

            resp?.data[0]?.working_hours?.forEach((val) => {
                const v = val?.enabled ? localCon(val?.start_time, val?.end_time, time[4], dayName, val?.day) : 'Closed';

                if (val?.day === dayName) {
                    v === 'Closed' ? setOpen1(false) : setOpen1(true)
                }

                setDay1(dayName)
                map1.set(val?.day, v);
            })

            setTest(map1);
            setLat(resp?.data[0].location?.coordinates[0]);
            setLng(resp?.data[0].location?.coordinates[1]);


            setProvderList(resp1?.data)

        } catch (error) {
            console.log(error)
        }
    }

    const getPatients = async () => {
        try {
            const params = {
                branch_id: id,
                limit: limit
            }
            let resp = {};
            const token = localStorage.getItem('token');
            const user_data = localStorage.getItem('user_details');

            if (token != null && user_data != null) {
                resp = await getPatientsExper(params);
            } else {
                resp = await getPatientExper(params);
            }

            setCount(resp?.count)
            setPatients1(resp?.data);
            console.log('resp1-------->', resp)
        } catch (error) {
            console.log(error)
        }
    }

    const getSlo = async () => {
        try {
            const resp = await getSlot(providerId);
            console.log(resp?.data)

            resp?.data?.forEach((val) => {
                const formattedSlots = val?.slot?.map((val1) => {
                  return localCon1(val1.start, val1.end);
                });
              
                // Concatenate the formatted slots for the current day with the existing values
                const updatedValues = [...formik2?.values[val?.day], ...formattedSlots];
              
                // Set the concatenated values for the current day
                formik2.setFieldValue(val?.day, updatedValues);
              });
        } catch (error) {
            console.log(error)
        }
    } 

    // console.log(formik2.values)
    // ---Handle-Image-Popup---

    const handleImagePopup = (value) => {
        let modal = document.querySelector('.modal');

        if (value == 'close') {
            modal.style.display = 'none';
        }
        else if (value == 'open') {
            modal.style.display = 'block';
        }

    }

    // --replace-header-with-appointment-page

    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        // let clinicHeader = document.getElementById('clinicHeader');
        // let bookingCard = document.getElementById('appointment-card');
        setIsVisible((val) => !val);
    };

    //---Image-viewer--end

    // --DatePicker--
    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    // --Stepper--
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    // ---Modal---

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const filledStars = (ratingStar) => {
        return Array.from({ length: 5 }, (_, index) => (
            <i
                key={index}
                className={`fas fa-star ${index < Math.floor(ratingStar) ? 'filled' : ''}`}
            ></i>
        ));
    }

    const leftHandler = () => {
        if (ind !== 0) {
            setInd(ind - 1);
            console.log(ind, data?.clinic_documents[ind])
        } else {
            console.log('no left')
            return;
        }
    }

    const rightHandler = () => {
        if (ind !== length - 1) {
            setInd(ind + 1);
            console.log(ind, data?.clinic_documents[ind])
        } else {
            console.log('no right')

            return;
        }
    }

    const dateHandler = (val) => {
        const dateObject = new Date(val);

        const options = { day: '2-digit', month: 'long' };
        const formattedDate = dateObject.toLocaleDateString('en-US', options);
        return formattedDate;
    }

    function formatCustomDate(date) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayOfWeek}${dayOfMonth} ${month} ${year}`;
    }

    const dateHandler1 = (e) => {
        console.log(e.target.value)
        const originalDate = new Date(e.target.value);
        const dayOfWeek1 = originalDate.getDay();
        const dayName = daysOfWeek[dayOfWeek1];
        const behindDays = formik1.values[dayName] - 1;
        const beyondDays = 7 - parseInt(formik1.values[dayName]);
        const startingDate = new Date(originalDate);
        startingDate.setDate(originalDate.getDate() - behindDays);


        const endDate = new Date(originalDate);
        endDate.setDate(originalDate.getDate() + beyondDays);

        let currentDate = new Date(startingDate);
        let i = 0;
        while (currentDate <= endDate) {
            formik.setFieldValue(daysOfWeek[currentDate.getDay()], formatCustomDate(currentDate))
            console.log(formatCustomDate(currentDate))
            currentDate.setDate(currentDate.getDate() + 1); // Increment to the next day
        }

    }

    const slotsCollector = (day, slot) => {

        // console.log(formik?.values[day])
        let conditionMet = false;
        const updatedValues = formik3?.values[day]?.map((val) => {
            if (val?.Date === formik?.values[day]) {
                //   console.log(val?.Date);
                conditionMet = true;
                return { Date: formik?.values[day], slot: slot, selected: true };
            }
            return val;
        });

        if (conditionMet) {
            formik3.setFieldValue(day, updatedValues);
            return; // This exits the slotsCollector function
        }
        formik3.setFieldValue(day, [...formik3?.values[day], { Date: formik?.values[day], slot: slot }])
    }

    const sloHandler = (id)=>{
        formik2.setValues({
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: []
        });
        setProviderId(id);
    }

    return (
        <>
            <Header />
            <div className='page-wrap'>
                <section className='appointment'>
                    <div className="container">
                        <div className="row">
                            {/* --clinic--Profile--- */}
                            <div className='sigma_post-details-inner' style={{ display: isVisible == true ? 'block' : 'none' }} id='clinicHeader'>

                                <div className="sigma_team style-17 mb-0">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <div className="sigma_team-thumb">
                                                {/* <img
                                                    src={logo} className='img-fluid'
                                                    alt="team"
                                                /> */}
                                                <img src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${data?.profile_image}`} className='img-fluid' alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="sigma_team-body ">
                                                <h5>
                                                    <Link href="#">{data?.practice_name}</Link>
                                                </h5>
                                                <h6>{`${data?.address}${data?.distance !== null ? `, ${data?.distance}` : ''}`}</h6>
                                                <div className="sigma_rating">
                                                    {filledStars(data?.rating)}
                                                    <span className="ms-3">{`(${data?.total_count})`}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div class="card__doc-info-right">
                                                <div class="card__clini-infos">
                                                    <ul>
                                                        {/* <li><i class="far fa-thumbs-up"></i> 96%</li> */}
                                                        <li><i class="far fa-comment"></i> {data?.reviews_count? data?.reviews_count:0} feedback</li>
                                                        {/* <li><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="login" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 01520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 01270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 010 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path></svg> in Network</li> */}
                                                        <li><i class="fa-solid fa-arrow-trend-up"></i> Booked by {data?.appointmnet_count? data?.appointmnet_count:0} people in the last 30 days</li>
                                                    </ul>
                                                </div>
                                                <div class="card__clinic-booking">
                                                    <Link class="apt-btn theme_btn rounded-1" onClick={toggleVisibility} to="">Book Appointment</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* --clinic--Profile---End */}
                            {/* ---Schedule Appointment-- */}
                            <div className='appoitment_Card' style={{ display: isVisible == true ? 'none' : 'block' }} id='appointment-card'>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-4">
                                        <h4 className="mb-1">{presentDate}</h4>
                                        <p className="text-muted">{Day1}</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-8 col-xl-8 ">
                                        <div className="d-flex gap-5 w-100 align-items-center">
                                            <select class="form-select provider_select" aria-label="Default select example" onChange={(e) => sloHandler(e.target.value)}>
                                                <option value="" label='Select Provider' disabled={true} selected />
                                                {providerList.map((val) => (<option value={val?._id} label={val?.full_name} />))}
                                                {/* <option value="Any" label='Any' /> */}

                                            </select>
                                            {/* <RangePicker
                                                value={dates || value}
                                                disabledDate={disabledDate}
                                                onCalendarChange={(val) => {
                                                    setDates(val);
                                                }}
                                                onChange={(val) => {
                                                    setValue(val);
                                                }}
                                                onOpenChange={onOpenChange}
                                                changeOnBlur
                                            /> */}
                                            <input type='date' onChange={dateHandler1} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow border-0 booking-schedule schedule-widget mb-0">
                                    <div className="schedule-header">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="day-slot">
                                                    <ul>
                                                        <li className="left-arrow">
                                                            <Link to="">
                                                                <i className="fa fa-chevron-left"></i>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <span>{formik?.values?.Monday}</span>
                                                        </li>
                                                        <li>
                                                            <span>{formik?.values?.Tuesday}</span>

                                                        </li>
                                                        <li>
                                                            <span>{formik.values.Wednesday}</span>
                                                            {/* <span className="slot-date">13 Nov <small className="slot-year">2023</small></span> */}
                                                        </li>
                                                        <li>
                                                            <span>{formik.values.Thursday}</span>
                                                            {/* <span className="slot-date">14 Nov <small className="slot-year">2023</small></span> */}
                                                        </li>
                                                        <li>
                                                            <span>{formik.values.Friday}</span>
                                                            {/* <span className="slot-date">15 Nov <small className="slot-year">2023</small></span> */}
                                                        </li>
                                                        <li>
                                                            <span>{formik.values.Saturday}</span>
                                                            {/* <span className="slot-date">16 Nov <small className="slot-year">2023</small></span> */}
                                                        </li>
                                                        <li>
                                                            <span>{formik.values.Sunday}</span>
                                                            {/* <span className="slot-date">17 Nov <small className="slot-year">2023</small></span> */}
                                                        </li>
                                                        <li className="right-arrow">
                                                            <Link >
                                                                <i className="fa fa-chevron-right"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-cont">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="time-slot">
                                                    <ul className="clearfix">
                                                        <li>
                                                            {formik2?.values?.Monday?.map((val) => (<Link className={`timing available ${formik3.values.Tuesday.some((item) => item.Date === formik?.values.Tuesday && item.slot === val && item.selected === true) ? 'selected' : ''}`} onClick={() => slotsCollector('Monday', val)}>
                                                                <span >{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing" >
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing">
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Tuesday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Tuesday', val)}>
                                                                <span >{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing available" >
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing" >
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Wednesday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Wednesday', val)}>
                                                                <span>{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing" >
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing" >
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Thursday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Thursday', val)}>
                                                                <span>{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing available">
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing">
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Friday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Friday', val)}>
                                                                <span>{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing ">
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing">
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Saturday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Saturday', val)}>
                                                                <span>{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing  selected" >
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing" >
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                        <li>
                                                            {formik2?.values?.Sunday?.map((val) => (<Link className="timing " onClick={() => slotsCollector('Sunday', val)}>
                                                                <span>{val}</span>
                                                                {/* <span>AM</span> */}
                                                            </Link>))}
                                                            {/* <Link className="timing" >
                                                                <span>10:00</span> <span>AM</span>
                                                            </Link>
                                                            <Link className="timing">
                                                                <span>11:00</span> <span>AM</span>
                                                            </Link> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='d-flex gap-2 justify-content-center '>
                                                <button className='theme-outline-btn rounded' onClick={handleClickOpen}>
                                                    Request Booking
                                                </button>
                                                <button className='theme_btn rounded' onClick={toggleVisibility}> Back </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Schedule Appointment End  */}
                        </div>
                    </div>
                </section>
                <section className='appointment pt-0'>
                    <div className="container">
                        {/* --Clinic Overview--- */}
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="sigma_post-details-inner">
                                    <div className="entry-content">

                                        <ul className="detail-menu-list mb-3">

                                            <li className="menu nav-item ">
                                                <Link href="#overview" className="nav-link active p-0">
                                                    Overview
                                                </Link>
                                            </li>


                                            <li className="menu nav-item">
                                                <Link href="#services" className="nav-link p-0">
                                                    Services
                                                </Link>
                                            </li>


                                            <li className="menu nav-item border-0">
                                                <Link href="#reviews" className="nav-link p-0">
                                                    Reviews
                                                </Link>
                                            </li>

                                        </ul>


                                        <div id="overview" className='profile-container'>

                                            <div className="about-profile">

                                                <div className="col-md-12">

                                                    <div className="widget photos-widget">
                                                        <h4 className=''>Photos</h4>
                                                        {/* <p className=''>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum eveniet at voluptate quibusdam asperiores non eos velit vel omnis .</p> */}
                                                        <div className='about-photos'>
                                                            {length <= 6 ? data?.clinic_documents?.map((val, index) => (<div className='images' key={index}>
                                                                <img src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${val}`} className='img-fluid' alt="" />
                                                            </div>)) : data?.clinic_documents?.slice(0, 5).map((val, index) => (<div className='images' key={index}>
                                                                <img src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${val}`} className='img-fluid' alt="" />
                                                            </div>))}

                                                            {length > 6 ? <div className='images'>
                                                                <img id="myImg" src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${data?.clinic_documents[ind]}`} className='img-fluid' alt="clinic" style={{ width: '100%', maxWidth: '200px' }} />
                                                                <div className='modal'>
                                                                    <span onClick={() => handleImagePopup('close')} className="close">&times;</span>
                                                                    <span className='btn prev-btn' onClick={leftHandler}><i class="fa-solid fa-circle-chevron-left"></i></span>
                                                                    <div className='image-modal'>
                                                                        <img src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${data?.clinic_documents[ind]}`} className='modal-content' alt="doctor" />
                                                                    </div>

                                                                    <span className='btn next-btn' onClick={rightHandler}><i class="fa-solid fa-circle-chevron-right"></i></span>
                                                                </div>
                                                                <div id='image-view' className='view-all'>
                                                                    <Link onClick={() => handleImagePopup('open')} className='view'>View All </Link>
                                                                </div>
                                                            </div> : null}
                                                        </div>
                                                    </div>

                                                    <div className="widget about-widget">
                                                        <h4 className=''>About Me</h4>
                                                        <p className=''>{data?.bio}</p>
                                                    </div>


                                                    {/* <div class="widget awards-widget">
                                                        <h4 class="widget-title">Awards</h4>
                                                        <div class="experience-box">
                                                            <ul class="experience-list">
                                                                <li>
                                                                    <div class="experience-user">
                                                                        <div class="before-circle"></div>
                                                                    </div>
                                                                    <div class="experience-content">
                                                                        <div class="timeline-content">
                                                                            <p class="exp-year">July 2023</p>
                                                                            <h5 class="exp-title">Humanitarian Award</h5>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="experience-user">
                                                                        <div class="before-circle"></div>
                                                                    </div>
                                                                    <div class="experience-content">
                                                                        <div class="timeline-content">
                                                                            <p class="exp-year">March 2011</p>
                                                                            <h5 class="exp-title">Certificate for International Volunteer Service</h5>
                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                                                    <div className="widget highligts">
                                                        {/* <h4 className="my-3">Preferences & Highlights</h4>
                                                        <p className="mb-2">
                                                            Lorem Ipsum is simply dummy text of the printing and
                                                            typesetting industry. Lorem Ipsum has been the industry's
                                                            standard.
                                                        </p> */}

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="sigma_general-list style-3">
                                                                    <ul>
                                                                        <li >
                                                                            {data?.features_and_benefits?.map((val) => (<div className=''>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>{val}</span>
                                                                            </div>))}

                                                                        </li>
                                                                        {/* <li>
                                                                            <div className=''>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>Dental anxiety friendly</span>
                                                                            </div>

                                                                        </li>
                                                                        <li>
                                                                            <div className=''>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>Reliable Responder</span>I
                                                                            </div>

                                                                        </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-lg-6">
                                                                <div className="sigma_general-list style-3">
                                                                    <ul>
                                                                        <li>
                                                                            <div className=''>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>Patient's Choice</span>
                                                                            </div>

                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>Combine Fitness and Lifestyle</span>
                                                                            </div>

                                                                        </li>
                                                                        <li>
                                                                            <div>
                                                                                <i class="fa-solid fa-check"></i>
                                                                                <span>Achieve a Specific Goal</span>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                    </div>


                                                    <div className='Business_hours mb-4'>
                                                        <h4 className=''>Business Hours</h4>
                                                        <div class="widget business-widget">
                                                            <div class="widget-content">
                                                                <div class="listing-hours">
                                                                    <div class="listing-day current">
                                                                        <div class="day">Today <span>{presentDate}</span></div>

                                                                        <div class="time-items">
                                                                            <span class="open-status">{open1 ? <span class="badge bg-success">Open Now</span> : <span class="badge bg-danger">Closed</span>}</span>
                                                                            <span class="time">{showTime ? test.get(Day1) : ''}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Monday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Monday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Monday')}</span>
                                                                            )}</div>
                                                                        {/* {console.log("map1.get('Monday')", map1?.get('Monday'))} */}
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Tuesday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Tuesday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Tuesday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Wednesday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Wednesday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Wednesday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Thursday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Thursday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Thursday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Friday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Friday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Friday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day">
                                                                        <div class="day">Saturday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Saturday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Saturday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div class="listing-day closed">
                                                                        <div class="day">Sunday</div>
                                                                        <div class="time-items">
                                                                            {test?.get('Sunday') === 'Closed' ? (
                                                                                <span class="time">
                                                                                    <span class="badge bg-danger">Closed</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span class="time">{test?.get('Sunday')}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className='locations'>
                                                        <h4 className=''>Location</h4>
                                                        <div class="location-list">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="clinic-content">
                                                                        <h4 class="clinic-name"><Link >Delta Medical College & Hospital</Link></h4>
                                                                        <p class="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                                        <div class="rating">
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star"></i>
                                                                            <span class="d-inline-block average-rating">(4)</span>
                                                                        </div>
                                                                        <div class="clinic-details mb-0">
                                                                            <h5 class="clinic-direction"> <i class="fas fa-map-marker-alt"></i> 2286 Sundown Lane, Austin, Texas 78749, USA <br />  <Link to="" >Get Directions</Link></h5>
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="assets/img/features/feature-01.jpg" data-fancybox="gallery2">
                                                                                        <img src={profile02} alt="Feature " />
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="assets/img/features/feature-02.jpg" data-fancybox="gallery2">
                                                                                        <img src={profile01} alt="Feature " />
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="assets/img/features/feature-03.jpg" data-fancybox="gallery2">
                                                                                        <img src={profile03} alt="Feature" />
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="assets/img/features/feature-04.jpg" data-fancybox="gallery2">
                                                                                        <img src={profile04} alt="Feature " />
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <div class="clinic-timing">
                                                                        <div>
                                                                            <p class="timings-days">
                                                                                <span> Mon - Sat </span>
                                                                            </p>
                                                                            <p class="timings-times">
                                                                                <span>10:00 AM - 2:00 PM</span>
                                                                                <span>4:00 PM - 9:00 PM</span>
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p class="timings-days">
                                                                                <span>Sun</span>
                                                                            </p>
                                                                            <p class="timings-times">
                                                                                <span>10:00 AM - 2:00 PM</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> */}

                                                </div>

                                            </div>

                                            <div className="spacer"></div>


                                        </div>
                                        <div className="spacer"></div>
                                        <div id="services" >
                                            <div className='ourservice '>
                                                <div class="service-list">
                                                    <h4>Our Services</h4>
                                                    <ul class="clearfix">
                                                        {data?.services?.map((val, index) => (<li key={index}>{val}</li>))}
                                                        {/* <li>Root Canal Therapy</li>
                                                        <li>Implants</li>
                                                        <li>Composite Bonding</li>
                                                        <li>Fissure Sealants</li>
                                                        <li>Surgical Extractions</li> */}
                                                    </ul>
                                                </div>
                                                {/* <div class="service-list">
                                                    <h4>Specializations</h4>
                                                    <ul class="clearfix">
                                                        <li>Children Care</li>
                                                        <li>Dental Care</li>
                                                        <li>Oral and Maxillofacial Surgery </li>
                                                        <li>Orthodontist</li>
                                                        <li>Periodontist</li>
                                                        <li>Prosthodontics</li>
                                                    </ul>
                                                </div> */}
                                            </div>

                                            {/* <div className='d-flex justify-content-between align-items-center '>

                                                <h4 className="my-3">Your Selected Services</h4>
                                                <p>
                                                    <button class="theme_btn " type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                        View Your Selected Services
                                                    </button>
                                                </p>
                                            </div>

                                            <div class="collapse" id="collapseExample">
                                                <div class="card p-4">
                                                    <p className="mb-2">
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry. Lorem Ipsum has been the industry's
                                                        standard.
                                                    </p>
                                                    <div className="card-body p-0">
                                                        <div className="service-categories style-3">
                                                            <ul className='tags'>
                                                                <li>
                                                                    <Link className='tag'>Bonding</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Bridges / Dentures</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Braces / Invisalign</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Checkup & Cleaning</Link>
                                                                </li>

                                                                <li>
                                                                    <Link className='tag'>Crown / Cap</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Tooth Extraction</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Urgent Issue</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Wisdom Teeth Removal</Link>
                                                                </li>
                                                                <li>
                                                                    <Link className='tag'>Whitening</Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                        <div className="spacer"></div>
                                        <div id="reviews">
                                            <h4>Patient Experience</h4>
                                            {patients1.map((val) => (<div className="sigma_testimonial style-14">
                                                <div className="sigma_testimonial-thumb">
                                                    <img
                                                        src={val?.user?.profile_image ? `${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.user?.profile_image}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRBwCx_dBh49_bJvA_g0arR_UJAJ3NYOWRr3LQrEEfqw&s"}
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="sigma_testimonial-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <div className="sigma_author-block">
                                                                <h5 className='mb-0'>{val?.user?.full_name}</h5>
                                                            </div>
                                                            <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                                                {filledStars(val?.rating)}
                                                                <span className="ms-3">{`(${Math.floor(val?.rating)}/5)`}</span>
                                                            </div>
                                                        </div>
                                                        <span className="sigma_testimonial-date">{dateHandler(val?.createdAt)}</span>
                                                    </div>
                                                    <p>{val?.reviews}</p>
                                                </div>

                                            </div>))}
                                            {/* <div className="sigma_testimonial style-14">
                                                <div className="sigma_testimonial-thumb">
                                                    <img
                                                        src={user01}
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="sigma_testimonial-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <div className="sigma_author-block">
                                                                <h5 className='mb-0'>Jose Bradley</h5>
                                                            </div>
                                                            <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="far fa-star" />
                                                                <span className="ms-3">(4/5)</span>
                                                            </div>
                                                        </div>
                                                        <span className="sigma_testimonial-date">07 March</span>
                                                    </div>
                                                    <p>
                                                        "It is a long established fact that a reader will be
                                                        distracted by the readable content of a page when looking
                                                        at its layout."
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sigma_testimonial style-14">
                                                <div className="sigma_testimonial-thumb">
                                                    <img
                                                        src={user03}
                                                        alt="testimonial"
                                                    />
                                                </div>
                                                <div className="sigma_testimonial-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-block d-sm-flex align-items-center">
                                                            <div className="sigma_author-block">
                                                                <h5 className='mb-0'>Vincent Reyes</h5>
                                                            </div>
                                                            <div className="sigma_rating ms-sm-4 ms-0 mt-2 mt-sm-0">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="far fa-star" />
                                                                <span className="ms-3">(4/5)</span>
                                                            </div>
                                                        </div>
                                                        <span className="sigma_testimonial-date">07 March</span>
                                                    </div>
                                                    <p>
                                                        "It is a long established fact that a reader will be
                                                        distracted by the readable content of a page when looking
                                                        at its layout."
                                                    </p>
                                                </div>
                                            </div> */}
                                            {limit === 2 ? <button className="theme_btn text-white " onClick={() => setLimit(count)}>
                                                See More   <i class="fa-solid fa-arrow-right"></i>
                                            </button> :
                                                <button className="theme_btn text-white " onClick={() => setLimit(1)}>
                                                    See Less   <i class="fa-solid fa-arrow-right"></i>
                                                </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Sidebar Start */}
                            <div className="col-lg-4">
                                <div className="sidebar style-10 mt-5 mt-lg-0">

                                    <div className="sigma_contact-map mb-3">
                                        <iframe
                                            src={lat && lng ? googleMapsUrl : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin"}
                                            style={{ height: "600px", width: "100%" }}
                                            allowFullScreen="100"
                                        ></iframe>
                                    </div>
                                    {/* Author Widget */}


                                    {/* Contact Widget */}
                                    <div className="widget">
                                        <h5 className="widget-title">Contact</h5>
                                        <div className="widget-inner">
                                            <div className="sigma_info style-24 shadow-none">
                                                <div className="sigma_info-title">
                                                    <span className="sigma_info-icon bg-primary-1 text-white">
                                                        <i class="fa-solid fa-phone-flip"></i>
                                                    </span>
                                                </div>
                                                <div className="sigma_info-description">
                                                    <h5>Our Phone</h5>
                                                    {/* <p className='mb-0'>Telephone: 0029129102320</p> */}
                                                    <p>Mobile: {data?.phone_number}</p>
                                                </div>
                                            </div>
                                            <div className="sigma_info style-24 shadow-none">
                                                <div className="sigma_info-title">
                                                    <span className="sigma_info-icon bg-primary-1 text-white">
                                                        <i className="fal fa-envelope-open-text" />
                                                    </span>
                                                </div>
                                                <div className="sigma_info-description">
                                                    <h5>Our Email</h5>
                                                    <p className='mb-0' >Main Email: {data?.email}</p>
                                                    <p>Inquiries: info@orex.com</p>
                                                </div>
                                            </div>
                                            <div className="sigma_info style-24  shadow-none">
                                                <div className="sigma_info-title">
                                                    <span className="sigma_info-icon bg-primary-1 text-white">
                                                        <i className="fal fa-map-marker-alt" />
                                                    </span>
                                                </div>
                                                <div className="sigma_info-description">
                                                    <h5>Our Address</h5>
                                                    <p>{data?.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Sidebar End */}
                        </div>

                        {/* ----Clinic Overview End--- */}
                    </div>



                    {/* ---Dialogue Box For Booking---- */}



                    <div className="submit-section proceed-btn text-center" id='mui-dialogue'>
                        {/* <Link href="checkout.html" className="theme_btn submit-btn">Booked Now</Link> */}
                        <div>

                            <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}

                            >
                                {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                                            Modal title
                                                        </DialogTitle> */}
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent style={{ padding: '40px' }}>

                                    <div className='booking'>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="dentsit-profile">
                                                    <img src={profile01} className='img-fluid' alt="" />
                                                    <h5 className='text-center pt-3'>Dr. Shahram Sayedna</h5>
                                                    <p className='text-center'>1700 Westwood Boulevard, Los Angeles CA, 90024</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className='booking__information'>
                                                    <Box sx={{ width: '100%' }}>

                                                        <Stepper nonLinear activeStep={activeStep}>
                                                            {steps.map((label, index) => (
                                                                <Step key={index} completed={completed[index]}>
                                                                    <StepLabel color="inherit" onClick={handleStep(index)}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>

                                                        <div>
                                                            {allStepsCompleted() ? (
                                                                <React.Fragment>
                                                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                                                        All steps completed - you&apos;re finished
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                                        <Box sx={{ flex: '1 1 auto' }} />
                                                                        <Button onClick={handleReset}>Reset</Button>
                                                                    </Box>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                                                        {stepContent[activeStep].content}
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                                        <Button
                                                                            color="inherit"
                                                                            disabled={activeStep === 0}
                                                                            onClick={handleBack}
                                                                            sx={{ mr: 1 }}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                        <Box sx={{ flex: '1 1 auto' }} />
                                                                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                                                                            Next
                                                                        </Button>
                                                                        {/* {activeStep !== steps.length &&
                                                                                (completed[activeStep] ? (
                                                                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                                                        Step {activeStep + 1} already completed
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <Button onClick={handleComplete}>
                                                                                        {completedSteps() === totalSteps() - 1
                                                                                            ? 'Finish'
                                                                                            : 'Complete Step'}
                                                                                    </Button>
                                                                                ))} */}
                                                                    </Box>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </Box>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </DialogContent>
                                {/* <DialogActions>
                                            <Button autoFocus onClick={handleClose}>
                                                Save changes
                                            </Button>
                                        </DialogActions> */}
                            </BootstrapDialog>
                        </div>
                    </div>

                </section>
                <Footer />
            </div>
        </>

    )
}
