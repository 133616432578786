import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./Calender.scss";

import { useSelector } from "react-redux";

import interactionPlugin from "@fullcalendar/interaction";
import {
  getBookedAppointmentsByDentist,
  getDentistListingService,
  makeHttpRequest,
} from "../../../utils/httpServices/httpService";
import { Popover } from "antd";

// import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function Calender() {
  const [events, setEvents] = useState([]);
  console.log("🚀 ~ Calender ~ events:", events);

  const [selectedAppointment, setSelectedAppoinment] = useState({});
  console.log("🚀 ~ Calender ~ selectedAppointment:", selectedAppointment);

  const calendarRef = React.createRef();

  const state = useSelector((state) => state);
  const [branch_id, setBranch_id] = useState(null);
  const [initialState, setPageState] = useState(
    localStorage.getItem("selected_Branch")
  );

  useEffect(() => {
    if (state.getClinicBrances?.data?.data) {
      setBranch_id(state.getClinicBrances?.data?.data[initialState]._id);
      getProviderListById(state.getClinicBrances?.data?.data[initialState]._id);
    }
  }, [branch_id, initialState, state]);

  const updatePageState = (index) => {
    setPageState(index);
    localStorage.setItem("selected_Branch", index);
  };

  const [selectedDentistVar, setSelectedDentistVar] = useState("");
  console.log("selectedDentist", selectedDentistVar);

  function selectedDentist(e) {
    console.log("selectedDentist", selectedDentistVar);
    console.log("event target value", e.target.value);
    setSelectedDentistVar(e.target.value);
  }

  ////////////////////// PROVIERS LIST ////////////////////

  const [providerList, setProviderList] = useState([]);

  function setDates(date) {
    if (date !== undefined) {
      let date = structuredClone(date);
      let date2 = structuredClone(date);
      let start_date = new Date(date).setDate(1);
      console.log("start_date", new Date(start_date));
      let end_date = new Date(date2);
      end_date.setMonth(end_date.getMonth() + 1, 0);
      console.log(
        "start_date final",
        new Date(start_date),
        "end_date",
        new Date(end_date)
      );
      getBookedAppointmentsByDentistfun(
        new Date(start_date).toISOString(),
        new Date(end_date).toISOString()
      );
    } else {
      let date = new Date();
      let date2 = new Date();
      let start_date = new Date(date).setDate(1);
      console.log("start_date", new Date(start_date));
      let end_date = new Date(date2);
      end_date.setMonth(end_date.getMonth() + 1, 0);
      console.log(
        "start_date final",
        new Date(start_date),
        "end_date",
        new Date(end_date)
      );
      getBookedAppointmentsByDentistfun(
        new Date(start_date).toISOString(),
        new Date(end_date).toISOString()
      );
    }
  }

  async function getProviderListById(id) {
    console.log("id@@@@@", id);
    let obj = {
      name: "",
      branch_id: id,
      sort: -1,
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
    };
    try {
      const resp = await getDentistListingService(obj);
      console.log("resp for dentist list !!!!!!!!!!!!!!!!!!!!!!!!!", resp);
      if (resp?.code === 200) {
        setProviderList(resp.data);
        setSelectedDentistVar(resp.data[0]?._id);
      }
    } catch (err) {
      console.log("dentist list error", err);
    }
  }

  // const eventsDemo = [
  //   {

  //     start: '2024-03-22',
  //     end: '2024-03-22',
  //     tax:""

  //   },

  //   {
  //     title: "Appointment",
  //     start: '2024-03-22',
  //     end: '2024-03-22',
  //   },

  //   // Add more events as needed
  // ];

  useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    getBookedAppointmentsByDentistfun(
      startDate.toISOString(),
      endDate.toISOString()
    );
  }, []);

  async function getBookedAppointmentsByDentistfun(start_date, end_date) {
    console.log("props", start_date, end_date);
    let obj = {
      start_date: start_date,
      end_date: end_date,
      branch_id : branch_id,
    };
    if (selectedDentistVar !== undefined && selectedDentistVar !== "") {
      obj.dentist_id = selectedDentistVar;
    }
    console.log("obj", obj);
    try {
      // let resp = await getBookedAppointmentsByDentist(obj);
      let resp = await makeHttpRequest(
        "get",
        "users/appointmentForCalender",
        null,
        obj
      );

      if (resp?.code === 200) {
        console.log("resp", resp);
        if (resp?.data) {
          const eventArray = resp?.data?.map((obj) => ({
            ...obj,
            start: obj.start_time,
            end: obj.end_time,
          }));
          setEvents(eventArray);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    setDates(undefined);
  }, [selectedDentistVar , branch_id]);

  const handleDateChange = (changeType) => {
    console.log("clicked", changeType);
    let calendarApi = calendarRef.current.getApi();
    switch (changeType) {
      case "prev":
        calendarApi.prev();
        break;
      case "next":
        calendarApi.next();
        break;
      case "today":
        calendarApi.today();
        break;
      default:
        break;
    }
    let currentDate = calendarApi.getDate();
    let startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    let endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    console.log("start_date final", startDate, "end_date", endDate);
    getBookedAppointmentsByDentistfun(
      startDate.toISOString(),
      endDate.toISOString()
    );
  };

  const getSlotString = (start, end) => {
    console.log({ start, end });
    const startTime = new Date(start).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const endTime = new Date(end).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${startTime} - ${endTime}`;
  };

  const getDateString = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const content = useMemo(()=>{
    return (
      <div>
        <p>Patient : {selectedAppointment?.user_name} </p>
        <p>Dentist : {selectedAppointment?.dentist} </p>
        <p>Booking Date : {selectedAppointment?.booking_date}</p>
      </div>
    )
  },[selectedAppointment])

  // const content = (
  //   <div>
  //     <p>User Name : {selectedAppointment?.user_name} </p>
  //     <p>Dentist : {selectedAppointment?.dentist} </p>
  //     <p>Booking Date : {selectedAppointment?.booking_date}</p>
  //   </div>
  // );

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    console.log("🚀 ~ renderEventContent ~ event:", event);

    const { start, end } = event;
    const { createdAt, user_details, dentist_details } = event.extendedProps;

    return (
      <Popover content={content} title="Booking Detail">
        <span
          className="info"
          onMouseEnter={() =>
            setSelectedAppoinment({
              booking_date: getDateString(createdAt),
              user_name: user_details?.full_name || "",
              dentist: dentist_details?.full_name || "Not Specified",
            })
          }
        >
          {getSlotString(start, end)}
        </span>
      </Popover>
    );
  };

  return (
    <>
      <ProviderNav />
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar
            initialState={initialState}
            triggerParentUpdate={updatePageState}
          />
        </div>
        <div className="fullWidhtWrap">
          <div className="doctorWrap">
            <section className="hero-container pb-0">
              <div className="container">
                <NavigationBar />

                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <BreadCrumb Navitem={"Appointment Calendar"} />
                  <div className="form-group">
                    <label htmlFor="dentistSelect" className="mb-2">
                      Filter by Dentists
                    </label>
                    <select
                      name="dentistSelect"
                      value={selectedDentistVar}
                      // value={providerList[0]?._id}
                      onChange={(e) => selectedDentist(e)}
                      className="form-select"
                    >
                      <option value="">All</option>
                      {providerList.map((provider) => (
                        <option key={provider._id} value={provider._id}>
                          {provider.full_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="events_calender mb-5 mt-4">
                  <FullCalendar
                    ref={calendarRef}
                    defaultView="dayGridMonth"
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      listPlugin,
                      interactionPlugin,
                    ]}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={events}
                    eventContent={renderEventContent}
                    customButtons={{
                      prev: {
                        text: "Prev",
                        click: () => handleDateChange("prev"),
                      },
                      next: {
                        text: "Next",
                        click: () => handleDateChange("next"),
                      },
                      today: {
                        text: "Today",
                        click: () => handleDateChange("today"),
                      },
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
          <ProviderFooter />
        </div>
      </div>
    </>
  );
}
