import React from 'react';
import Slider from 'react-slick';

const ProviderImages = ({ images = [] }) => {
    // Default to an empty array if images is undefined or null
    const imageList = images || [];

    const providerImagesSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // Check the number of images
    const imageCount = imageList.length;

    // Render slider if there are more than 3 images, otherwise render images without slider
    return (
        <>
            {imageCount > 3 ? (
                <Slider {...providerImagesSettings}>
                    {imageList.map((el, index) => (
                        <div className='single-image' key={index}>
                            <img src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${el}`} alt={`Image ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="image-container">
                    {imageList.map((el, index) => (
                        <div key={index} className="single-image imageWrapper">
                            <img src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${el}`} alt={`Image ${index + 1}`} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ProviderImages;
