import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
    progress: 0,
  },
  reducers: {
    showLoader(state) {
      state.loading = true;
    },
    hideLoader(state) {
      state.loading = false;
    },
    setProgress(state, action) {
        state.progress = action.payload;
      },
  },
});

export const { showLoader, hideLoader,setProgress } = loaderSlice.actions;
export default loaderSlice.reducer;
