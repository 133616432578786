import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignUp.scss";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import {
  emailValidator,
  passwordValidator,
  emptyStringValidator,
} from "../../utils/validators/validatorServices";
import {
  signupService,
  successNotification,
  socialSignUpService,
  loginService,
  generateImageFromName,
} from "../../utils/httpServices/httpService";
import { useNavigate } from "react-router-dom";

// import { GoogleLogin } from "@react-oauth/google";

export default function SignUp() {
  const navigate = useNavigate();

  const [addForm, setAddForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    user_type: "user",
  });

  const [addFormValidator, setAddFormValidator] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
  });

  const settingValues = (event) => {
    setAddForm({
      ...addForm,
      [event.target.name]: event.target.value,
    });

    console.log("updated values", addForm);
  };

  function settingValidators(name) {
    switch (name) {
      case "first_name":
        setAddFormValidator(() => ({
          ...addFormValidator,
          first_name: emptyStringValidator(addForm[name]),
        }));
        console.log("add", addFormValidator);
        break;
      case "last_name":
        setAddFormValidator(() => ({
          ...addFormValidator,
          last_name: emptyStringValidator(addForm[name]),
        }));
        console.log("add", addFormValidator);
        break;
      case "email":
        setAddFormValidator(() => ({
          ...addFormValidator,
          email: emailValidator(addForm[name]),
        }));
        console.log("add", addFormValidator);
        break;
      case "password":
        setAddFormValidator(() => ({
          ...addFormValidator,
          password: passwordValidator(addForm[name]),
        }));
        console.log("add", addFormValidator);
        break;

      default:
    }
  }

  function finalVlidationCheck() {
    let tempValidator = addFormValidator;
    let validatorKeys = Object.keys(addFormValidator);
    validatorKeys.forEach((ele) => {
      if (ele === "first_name" || ele === "last_name")
        tempValidator[ele] = emptyStringValidator(addForm[ele]);
      else if (ele === "email")
        tempValidator[ele] = emailValidator(addForm[ele]);
      else tempValidator[ele] = passwordValidator(addForm[ele]);
    });
    setAddFormValidator(() => ({
      ...tempValidator,
    }));
  }

  
  // async function submit() {
  //   finalVlidationCheck();

  //   const validatorStatus = Object.values(addFormValidator);
  //   if (!validatorStatus.some((ele) => ele === true)) {
  //     const data = {
  //       ...addForm,
  //     };
  //     console.log("data", data);
     
      
  //     try {
  //       const resp = await signupService(data);
  //       console.log(" login response data=> ", resp);
  //       if (resp?.status === 201) {
  //         successNotification("Account created Successfully");
  //       }else{
  //         return;
  //       }

  //       const body = {
  //         email: addForm?.email,
  //         password: addForm?.password,
  //       };

  //       const resp1 = await loginService(body);
  //       localStorage.setItem("token", resp1?.data?.token);
  //       let v = resp1?.data?.user;
  //       localStorage.setItem("user_details", JSON.stringify(v));
  //       navigate("/");
  //       // navigate("/");
  //     } catch (err) {
  //       // alert(err);
  //       console.log("error")
        
  //     }
  //   } else {
  //     console.log("validation failed");
  //   }
  // }

  async function submit() {
    finalVlidationCheck();
  
    const validatorStatus = Object.values(addFormValidator);
    if (validatorStatus.some((ele) => ele === true)) {
      console.log("Validation failed");
      return;
    }
  
    const data = { ...addForm };
    console.log("Data:", data);
  
    try {
      const resp = await signupService(data);
      console.log("Signup response data:", resp);
  
      if (resp?.status === 201) {
        successNotification("Account created Successfully");
  
        const loginData = {
          email: addForm?.email,
          password: addForm?.password,
        };
  
        try {
          const resp1 = await loginService(loginData);
          localStorage.setItem("token", resp1?.data?.token);
          localStorage.setItem("user_details", JSON.stringify(resp1?.data?.user));
          navigate("/");
        } catch (loginErr) {
          console.error("Login error:", loginErr);
          // Handle login error
        }
      }
    } catch (err) {
      console.error("Signup error:", err);
      // Handle signup error
    }
  }
  

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const signUpHandler = async (response) => {
    // Access user information from the response object
    const result = jwtDecode(response?.credential);
    // console.log(a);
    const body = {
      email: result?.email,
      first_name: result?.given_name,
      last_name: result?.family_name,
      full_name: result?.name,
      social_type: "google",
      social_id: result?.sub,
    };

    const resp = await socialSignUpService(body);
    console.log(resp);
    localStorage.setItem("token", resp?.data?.token);
    let v = resp?.data?.user;
    localStorage.setItem("user_details", JSON.stringify(v));
    navigate("/");
  };

  const onErrorHandler = (error) => {
    console.error("Error:", error);
  };
  return (
    <div className="pt-3 position-relative overflow-x-hidden">
      <div className="container-fluid">
        <div className="login Signup">
          <div className="row">
            <div className="col-xl-12 p-0">
              <div className="login__text-form ">
                <h4 className="login__title">Sign Up to Dentist Finder</h4>
                <div className="login__google_auth">
                  {/* <button className='theme_btn w-100'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" role="img" class="icon ">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="#4285F4"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" fill="#FBBC05"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path>
                                        </svg>
                                        Sign up with Google
                                    </button> */}
                  {/* <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      console.log(credentialResponse);
                    }}
                    onError={errorMessage}
                  /> */}
                  {/* {console.log(process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID)} */}
                  <div className="socialLoginWrap">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        //   console.log(credentialResponse);
                        signUpHandler(credentialResponse);
                      }}
                    />
                  </div>
                  {/* <GoogleLogin
                    onSuccess={onSuccessHandler}
                    onError={onErrorHandler}
                    clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
                    buttonText="Login with Google"
                  /> */}
                  <hr class="login__divider sign-in"></hr>
                  <div className="login__auth-form">
                    {/* <form className='mb-4 row'> */}
                    <div className="mb-4 row">
                      <div class="mb-3 col-md-6">
                        <label for="exampleInputEmail1 " class="form-label">
                          First Name
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          name="first_name"
                          value={addForm.first_name}
                          onChange={settingValues}
                          onBlur={(e) => settingValidators(e.target.name)}
                          aria-describedby="emailHelp"
                        />
                        {addFormValidator.first_name ? (
                          <div className="validation_message">
                            *First name required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="exampleInputEmail1 " class="form-label">
                          Last Name
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          name="last_name"
                          value={addForm.last_name}
                          onChange={settingValues}
                          onBlur={(e) => settingValidators(e.target.name)}
                          aria-describedby="emailHelp"
                        />
                        {addFormValidator.last_name ? (
                          <div className="validation_message">
                            *Last name required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="exampleInputEmail1 " class="form-label">
                          Email 
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          value={addForm.email}
                          onChange={settingValues}
                          onBlur={(e) => settingValidators(e.target.name)}
                          aria-describedby="emailHelp"
                        />
                        {addFormValidator.email ? (
                          <div className="validation_message">
                            *Valid email address require{" "}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div class="mb-4 col-md-6">
                        <label for="password" class="form-label d-flex">
                          Password{" "}
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          name="password"
                          value={addForm.password}
                          onChange={settingValues}
                          onBlur={(e) => settingValidators(e.target.name)}
                          aria-describedby="password"
                        />
                        {addFormValidator.password ? (
                          <div className="validation_message">
                            *Password must be 8 or greater in length{" "}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* <div class="mb-4 col-12">
                                                <label for="password" class="form-label d-flex">Password </label>
                                                <input type="password" class="form-control" id="password" aria-describedby="password" />
                                            </div> */}
                      <div className="col-12">
                        <button className="w-100 btn-white" onClick={submit}>
                          Create Account
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                    <p class="auth-link text-center">
                      Already have an account?{" "}
                      <Link to="/Login" class="underline">
                        Log In
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="blobs1">
                  <svg
                    width="216"
                    height="216"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FF6BB5"
                      d="M68,-38.7C81.5,-16,81.1,15.3,67.5,39C53.8,62.7,26.9,78.8,0.7,78.5C-25.6,78.1,-51.1,61.2,-65.2,37.2C-79.3,13.3,-81.9,-17.8,-69.1,-40.1C-56.3,-62.4,-28.2,-75.9,-0.4,-75.7C27.3,-75.4,54.6,-61.3,68,-38.7Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
                <div className="blobs2">
                  <svg
                    width="216"
                    height="216"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FF6BB5"
                      d="M68,-38.7C81.5,-16,81.1,15.3,67.5,39C53.8,62.7,26.9,78.8,0.7,78.5C-25.6,78.1,-51.1,61.2,-65.2,37.2C-79.3,13.3,-81.9,-17.8,-69.1,-40.1C-56.3,-62.4,-28.2,-75.9,-0.4,-75.7C27.3,-75.4,54.6,-61.3,68,-38.7Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
