import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./ConatctForm.scss";

import { useFormik } from "formik";
import * as Yup from "yup";
import { submitContactUs } from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";

export default function ContactForm({ show, handleClose, handleSuccess , isDisableText=false }) {


  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      // phone_number: Yup.string().required("Phone Number is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmitForm =async (values)=> {
    console.log(values)
    try {
      const resp = await submitContactUs(values)
      toast.success("Successfully submitted")
      formik.resetForm();
      handleClose()
      handleSuccess()
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: '',
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });



  return (
    <>
      <Modal className="contact-us" show={show} onHide={handleClose}>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header className="chatbot__header">
            <Modal.Title>Contact Us</Modal.Title>
            <span onClick={handleClose}>
              <i class="fa-solid fa-xmark"></i>
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="contact-form">
            { !isDisableText && 
              <p>
                Regrettably, our services are not yet available in your area.
                Feel free to contact us by filling out the form below.
              </p>
            }
              
              <div className="row">
                <div className="col-md-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      {...formik.getFieldProps("first_name")}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="form-error">{formik.errors.first_name}</div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="form-error">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="form-error">{formik.errors.email}</div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      {...formik.getFieldProps("phone_number")}
                    />
                    {/* {formik.touched.phone_number && formik.errors.phone_number ? (
                      <div className="form-error">{formik.errors.phone_number}</div>
                    ) : null} */}
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Leave us a message ...."
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="form-error">{formik.errors.description}</div>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button class="btn-white" onClick={handleClose}>
              Close
            </button>
            <div class="sign_up">
              <button type="submit" class="theme_btn ">
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
