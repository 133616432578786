import React, { useEffect, useState } from 'react'
import companyLogo from '../../Assets/Logos/logo1.png'
import { Link } from 'react-router-dom'
import "./UserHeader.scss"


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';

import NotificationsIcon from '@mui/icons-material/Notifications';

import avatar from "../../Assets/avtar_img.jpeg"

import image01 from "../../Assets/client-01.jpg"
import image02 from "../../Assets/client-02.jpg"

import { useNavigate } from 'react-router-dom';
import { getNotifications, seenNotifications, unSeenNotifications } from '../../utils/httpServices/httpService';
import ProfilePic from '../ProfilePic/ProfilePic';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../redux/slices/auth';




export default function UserHeader(props) {

    const [notificationData, setNotificationData] = useState([]);
    const [notificationCount, setNotificationCount] = useState();

    // console.log("props", props)
    const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;
    let user_profile_image = null;
    if (props?.data) {
        user_profile_image = profile_image_base_url + props?.data;
    } else {
        // user_profile_image = process.env.REACT_APP_DEFAULT_IMAGE_URL
    }
    const navigate = useNavigate();
    // ---User--profile---

    const userDetails = JSON.parse(localStorage.getItem('user_details'))

    const full_name = userDetails?.full_name || "";

    const [notifEl, setnotifEl] = React.useState(null)
    const open1 = Boolean(notifEl);

    const handleClick2 = async (event1) => {
        // console.log(event1)
        setnotifEl(event1.currentTarget);
        try {
            const resp = await seenNotifications();
            getUnSeenCount();
            // getData()
        } catch (error) {
            console.log(error)
        }
    }

    const handleClose1 = () => {
        setnotifEl(null);
    };

    function displayNavbarUser() {
        const navbar = document.getElementById("navbarSupportNav");
        if (navbar.classList.contains("show")) {
            navbar.classList.remove("show");
            navbar.classList.add("hide");
        } else {
            navbar.classList.remove("hide");
            navbar.classList.add("show");
        }
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        // Add a scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        // Check the scroll position and update the 'scrolled' state
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const dispatch = useDispatch()

    function logout() {
        localStorage.clear();
        dispatch(setAuthenticated(false))

        navigate("/");
    }

    async function getData() {
        try {

            const param = {
                limit: 2
            }
            const resp1 = await getNotifications(param);
            console.log(resp1)
            setNotificationData(resp1?.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function getUnSeenCount() {
        try {
            const resp = await unSeenNotifications();
            setNotificationCount(resp?.data)
            console.log(resp)
        } catch (error) {

        }
    }

    useEffect(() => {
        getUnSeenCount();
        getData();
    }, [])


    const checkAMorPM = (time) => {
        // Assuming the time is in HH:mm:ss format
        const hour = parseInt(time.split(':')[0], 10);

        if (hour < 12) {
            return 'AM';
        } else {
            return 'PM';
        }
    };

    const localCon1 = (date) => {

        let t1 = '';

        const st = checkAMorPM(date);
        if (st === 'AM') {
            const hour = date.split(':');
            t1 = `${hour[0]}:${hour[1]} AM`;
        } else {
            const hour = parseInt(date.split(':')[0], 10);
            const h = hour > 12 ? hour - 12 : hour;
            t1 = `${h}:${date.split(':')[1]} PM`;
        }



        const formatTime = { formatTime: `${t1}` };

        return formatTime;
    }

    function getDate(date) {

        let v = new Date(date)?.toLocaleString();
        let res = localCon1(v)
        return res?.formatTime;
    }
    return (
        <>
            <nav className='navbar patient-navbar navbar-expand-lg shadow bg-black fixed-top'>
                <div className="container">
                    <Link className="navbar-brand flex-grow-1" to="/">
                        <img src={companyLogo} alt="Logo" className="d-inline-block align-text-top" />
                    </Link>
                    <button className="navbar-toggler" onClick={displayNavbarUser}>
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                         
                            <li className="nav-item">
                                <Link className='nav-link text-white' to="/about">About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className='nav-link text-white' to={`${process.env.REACT_APP_DENTIST_BLOG_LINK}`}>Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link className='nav-link text-white' to="/support">Support</Link>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/find-dentist">For Dentists</Link>
                            </li>
                        </ul>


                        <div className='profile__info d-flex justify-content-center'>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                <MenuItem>
                                    <IconButton
                                        size="large"
                                        aria-label="show 17 new notifications"
                                        color="#fff"
                                        padding="0"
                                        onClick={handleClick2}
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'notification' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Badge badgeContent={notificationCount} color="error">
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                    <p className='notific-text m-0 p-0' variant="h4">Notifications</p>
                                </MenuItem>

                                <Menu
                                    notifEl={notifEl}
                                    id="notification"
                                    open={open1}
                                    onClose={handleClose1}
                                    onClick={handleClose1}

                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}

                                    anchorReference="anchorPosition"
                                    anchorPosition={{ top: 70, left: 650 }}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}


                                >
                                    <div className="noti-content">
                                        <ul className="notification-list pb-0">
                                            {notificationData?.map((val,index) => (<li className='notification__list-item' key={index}>
                                                <Link to=''
                                                // {val?.type === 'appointment'
                                                //     ? {
                                                //         pathname: '/user-profile/tab6',
                                                //         search: `?appointmentId=${val?._id}`,
                                                //     }
                                                //     : ''}
                                                >
                                                    <div className="notify-block d-flex">
                                                        <span className="avatar">
                                                            {val?.sender_details?.profile_image ?
                                                                <img className="avatar-img" alt="" src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.sender_details?.profile_image}`} /> :
                                                                <img className="avatar-img" alt="" src={process.env.REACT_APP_DEFAULT_IMAGE_URL} />

                                                            }
                                                        </span>
                                                        <div className="media-body">
                                                            <h6>
                                                                {val?.title}{" "}
                                                                <span className="notification-time">{getDate(val?.createdAt)}</span>
                                                            </h6>
                                                            <p className="noti-details">
                                                                {val?.body}{" "}
                                                                <span className="noti-title">{val?.sender_details?.full_name}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>))}
                                            {/* <li className="notification-message">
                                                <Link to="" >
                                                    <div className="notify-block d-flex">
                                                        <span className="avatar">
                                                            <img className="avatar-img" alt="Hendry Watt" src={image02} />
                                                        </span>
                                                        <div className="media-body">
                                                            <h6>
                                                                Travis Tremble{" "}
                                                                <span className="notification-time">12 Min Ago</span>
                                                            </h6>
                                                            <p className="noti-details">
                                                                {" "}
                                                                has booked her appointment to{" "}
                                                                <span className="noti-title">Dr. Hendry Watt</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li> */}

                                        </ul>
                                        <div className="button">
                                            <Link to="/user-profile/tab2" className="theme_btn d-block rounded-0 w-100">View all</Link>
                                        </div>
                                    </div>

                                </Menu>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >

                                        <Avatar sx={{ width: 32, height: 32 }}>
                                        {
                                            user_profile_image ?

                                            <img src={user_profile_image} alt="" />

                                            :
                                            <ProfilePic name={full_name} fontSize ={15}/>
                                        }
                                        </Avatar>

                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                            >
                                <MenuItem onClick={handleClose}>
                                    <Link className='text-black' to="/user-profile/tab4">My Profile</Link>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClose}>
                                    {/* <Avatar /> */}
                                    <Link className='text-black' to="/user-profile/tab6"> My Appointments</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    {/* <Avatar /> */}
                                    <Link className='text-black' to="/user-profile/tab1">Favorites </Link>
                                </MenuItem>
                                {/* <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Add another account
                                </MenuItem> */}
                                {/* <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem> */}
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    <button onClick={logout}>Logout</button>
                                </MenuItem>
                            </Menu>
                        </div>

                    </div>
                </div>
            </nav>

        </>
    )
}
