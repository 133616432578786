import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./ChatBox.scss";

import { io } from "socket.io-client";
import empty_img from "../../Assets/empty-chat.svg";
import file_icon_image from "../../Assets/file_icon_image.png";
import {
  declineAllSuggestedSlots,
  errorNotification,
  getChatData,
  imageUpload,
  makeHttpRequest,
  slotBookingForReshedule
} from "../../utils/httpServices/httpService";

import debounce from 'lodash/debounce';
import moment from "moment";
import { SignalWifiStatusbarNullOutlined } from "@mui/icons-material";
import { getisoDateForDateObj } from "../../utils/common/common";

export default function ChatBox() {
  const location = useLocation();

  // ---Toggle-Sidebar---

  const [isOpen, setIsOpen] = useState(false);
  // const [divWidth, setDivWidth] = useState(0);
  // const [divWidth2, setDivWidth2] = useState("100%");
  const [clinicsList, setClinicsList] = useState([]);
  const [singleClinicDetail, setSingleClinicDetail] = useState({});
  const [openChat, setOpenChat] = useState(false);
  const [chatData, setChatData] = useState([]);
  console.log("🚀 ~ chatData:", chatData)
  const [clinicsList1, setClinicsList1] = useState([]);
  const [sendMessage, setSendMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [userId, setUserId] = useState("");
  const [count, setCount] = useState(0);
  const [roomId, setRoomId] = useState("");
  const par = useParams();
  const chatContentRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [chatAttachmentData, setChatAttachmentData] = useState(null);

  const [isFirstRander, setIsFirstRander] = useState(true)

  const [appointmentDetail, setAppointmentDetail] = useState(null);
  console.log("🚀 ~ appointmentDetail:", appointmentDetail)


  // console.log(divWidth2, window.innerWidth);
  // console.log(divWidth);

  // useEffect(() => { }, [divWidth2, divWidth]);

  // const toggleDiv = () => {
  //   setIsOpen(!isOpen);
  //   setDivWidth(250);
  //   setDivWidth2(window.innerWidth - 500);

  //   console.log("sxjssxj");
  // };

  // const handleClose = () => {
  //   setDivWidth2(window.innerWidth - 250);
  //   setDivWidth(0);
  // };


  function openrightSidebar() {
    console.log("clicking ")
    var mainBody = document.querySelector("#mainBody");
    var x = document.querySelector("#rightSidebar");


    if (!x.classList.contains("right-sidebar_width") && !mainBody.classList.contains("content_widht")) {
      x.classList.add("right-sidebar_width");
      mainBody.classList.add("content_widht");
    }


  }

  function closerightSidebar() {
    var mainBody = document.querySelector("#mainBody");
    var x = document.querySelector("#rightSidebar");

    if (x.classList.contains("right-sidebar_width") && mainBody.classList.contains("content_widht")) {
      x.classList.remove("right-sidebar_width");
      mainBody.classList.remove("content_widht");
    }

  }


  function toggleSidebar() {
    let leftSidebar = document.querySelector("#leftSidebar");
    leftSidebar.classList.toggle('expanded');

  }


  const localCon1 = (date) => {
    let v = new Date(date)?.toLocaleString().split(", ")[1];
    let t1 = "";

    const st = checkAMorPM(v);
    if (st === "AM") {
      const hour = v.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(v.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${v.split(":")[1]} PM`;
    }

  

    const formatTime = { formatTime: `${t1}` };

    return formatTime;
  };

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time.split(":")[0], 10);
    console.log(hour);
    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  function getTime(date) {
    let res = localCon1(date);
   
    return res?.formatTime;
  }

  async function getDefaultClinics(socket) {
    try {
      // const resp = await getDefaultSideClinics(id);
      // console.log(resp)
      // setClinicsList(resp?.data)
      // setClinicsList1(resp?.data)
      socket.on("sidebar", (data) => {
        console.log(data);
        setClinicsList(data);
        setClinicsList1(data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    let user = localStorage.getItem("user_details");
    user = JSON.parse(user);
    console.log(user?._id);

    socket.on("connect", () => {
      console.log("connected");
    });
    socket.emit("sidebar join", user?._id);
    getDefaultClinics(socket);
    setSocket(socket);
    setUserId(user?._id);
    return () => {
      socket.emit("sidebar leave", user?._id);
      socket.on("disconnect", () => {
        console.log("disconnected");
      });
    };
  }, []);

  // useEffect(() => {
  //   let user = localStorage.getItem('user_details');
  //   user = JSON.parse(user);
  //   console.log(user?._id)

  //   if (user?.id !== null) {
  //     setUserId(user?._id)

  //     socket.emit('sidebar join', user?._id);
  //     getDefaultClinics();
  //     return ()=>{
  //       socket.emit('sidebar leave', user?._id);

  //     }
  //   }

  // }, [])

  function clinicDataHandler(val) {
    // setRoomId(val?.room_id)
    socket.emit("seen all message", {
      room_id: val?.last_chat?.room_id,
      user_id: userId,
    });
    setSingleClinicDetail(val);
    setOpenChat(true);
    leave_room(socket);

    getAppointmentDetails(val)
  }

  async function getPreviousChatMessagesFunc() {
    console.log("123", singleClinicDetail);

    console.log("par--------------->", par);

    try {
      const params = {
        room_id: singleClinicDetail.room_id,
        // limit: 10,
        // offset : count
      };
      const resp = await getChatData(params);
      console.log(resp?.data);
      // setCount(prev => prev + 10);
      // setChatData([...chatData, ...resp?.data])
      setChatData(resp?.data);
    } catch (error) {
      console.log(error);
    }
  }

  console.log("count", count);

  function join_roomFunc(socket) {
    socket.emit("room join", { room_id: singleClinicDetail?.room_id });
    console.log("join room");
    getPreviousChatMessagesFunc();
    getSocket_Messages()
  }

  function leave_room(socket) {
    socket.emit("leave room", { room_id: singleClinicDetail?.room_id });
    console.log("leave room !!");
    join_roomFunc(socket);
  }

  function getSocket_Messages() {
   socket.off("chat_message")

    socket.on("chat_message", (args) => {
      console.log("args ... ", args, args[0]);
      setChatData((prevData) => {
        let newData = structuredClone(prevData);
        newData.push(args[0]);
        return newData;
    });
    });
  }

  function sendHandler(_, slotMessage) {
    console.log(singleClinicDetail?.room_id);
    socket.emit("chat_message", {
      room_id: singleClinicDetail?.room_id,
      message: slotMessage || sendMessage,
      primary_room_id: singleClinicDetail?._id,
      sender_id: userId,
      receiver_id: singleClinicDetail?.receiver_details?._id,
    });
    // getSocket_Messages();
    setSendMessage("");
    console.log("message send test !!!");
  }

  function searchHandler(e) {
    let searchedValue = e.target.value;

    // const regex = new RegExp(searchedValue);


    // const filteredArray = clinicsList.filter((val) =>
    //   regex.test(val?.receiver_details?.practice_name)
    // );
    // setClinicsList1(filteredArray);

    debounceSearch(searchedValue)
  }

  const debounceSearch = debounce((value) => {

    const filteredArray = clinicsList.filter((clinic) =>
      clinic.receiver_details?.practice_name.toLowerCase().includes(value.toLowerCase())
    )
    setClinicsList1(filteredArray);
  }, 300)

  function checkingFileFormat_uploadFile(url) {
    let file_name_exten = url.split(".");
    let image_formats = ["jpeg", "png", "jpg", "gif"];
    if (image_formats.includes(file_name_exten[file_name_exten.length - 1])) {
      return true;
    } else return false;
  }

  function checkingFileFormat(ele) {
    console.log("checking file format", ele);
    if (ele?.attachments && ele?.attachments[0]?.url) {
      let image_formats = ["jpeg", "png", "jpg", "gif"];
      let file_name = ele?.attachments[0]?.url;
      let split_data = file_name.split(".");
      if (image_formats.includes(split_data[split_data.length - 1])) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }

  async function downloadtryImage(url, image) {
    const imgName = url;

    const file = await fetch(
      imgName + "?r=" + Math.floor(Math.random() * 100000)
    ).then(function (response) {
      return response.blob();
    });

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = blob;
    link.download = image;
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    setTimeout(() => {
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  // function dataAddHandler() {
  //   console.log(singleClinicDetail)
  //   try {
  //     const element = document.getElementById('content')
  //     // const windowHeight = element.offsetHeight;
  //     // console.log(element.scrollHeight)
  //     // console.log(element.scrollTop)
  //     // console.log(element.offsetHeight)
  //     const scrollPosition = element.scrollTop;

  //     if (scrollPosition === 0) {
  //       console.log("Reached the top");
  //       getPreviousChatMessagesFunc();
  //     }

  //     // if (windowHeight + element.scrollTop === windowHeight) {
  //     //   console.log(element.offsetHeight + element.scrollTop);
  //     //   getPreviousChatMessagesFunc()
  //     // }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   const element = document.getElementById('content')

  //   element.addEventListener('scroll', dataAddHandler);

  //   return () => {
  //     element.removeEventListener('scroll', dataAddHandler)
  //   }

  // }, [par, count])

  useEffect(() => {
    if (singleClinicDetail !== null && socket !== null) {
      join_roomFunc(socket);
    }
  }, [singleClinicDetail, socket]);

  // async function mediaHandler(e) {
  //   const file = e.target.files[0];
  //   try {
  //     if (file) {
  //       let filename = file.name;
  //       console.log("upload file name", filename);

  //       if (file.size <= 5000000) {
  //         const data = new FormData();
  //         data.append("path", "chatAttachment");
  //         data.append("media", file);
  //         try {
  //           console.log(data);
  //           const resp = await imageUpload(data);
  //           console.log("upload image response", resp);
  //           if (resp?.code === 200) {
  //             console.log({
  //               attachment_name: filename,
  //               attachment_url: resp?.path,
  //               room_id: singleClinicDetail?.room_id,
  //               primary_room_id: singleClinicDetail?._id,
  //               sender_id: userId,
  //               receiver_id: singleClinicDetail?.receiver_details?._id,
  //             });

  //             socket.emit("media message", {
  //               attachment_name: filename,
  //               attachment: resp?.path,
  //               room_id: singleClinicDetail?.room_id,
  //               primary_room_id: singleClinicDetail?._id,
  //               sender_id: userId,
  //               receiver_id: singleClinicDetail?.receiver_details?._id,
  //             });

  //             socket.on("media message", (data) => {
  //               let newData = structuredClone(chatData);
  //               newData.push(data[0]);
  //               setChatData(newData);
  //             });
  //           }
  //         } catch (error) {
  //           console.log("upload image error", error);
  //         }
  //       } else {
  //         errorNotification("Image size is greater than 2MB");
  //       }
  //     }
  //   } catch (error) {}
  // }

  const imageUploadFunc = async (event) => {
    console.log("upload image data", event);
    const file = event.target.files[0];
    if (file) {
      let filename = file.name;
      console.log("upload file name", filename);
      // if ((file.type.split('/').includes('gif') ||
      //     file.type.split('/').includes('jpg') ||
      //     file.type.split('/').includes('jpeg') ||
      //     file.type.split('/').includes('png'))) {
      if (file.size <= 5000000) {
        const data = new FormData();
        data.append("path", "chatAttachment");
        data.append("media", file);
        try {
          const resp = await imageUpload(data);
          console.log("upload image response", resp);
          if (resp?.code === 200) {
            // successNotification("Image uplaoded Successfully");
            setChatAttachmentData({
              name: filename,
              url: resp.path,
            });
            // console.log("provider profile profile data", addProviderProfile)
          }
        } catch (error) {
          console.log("upload image error", error);
        }
      } else {
        errorNotification("Image size is greater than 2MB");
      }
      // }
      // else {
      //     errorNotification("Please upload image in supported format (PNG,JPG,GIF)")
      // }
    }
  };

  function getSocket_mediaMessages() {
    socket.on("media message", (args) => {
      console.log("args ... media message ", args[0], args[0]);
      let newData = structuredClone(chatData);
      newData.push(args[0]);
      console.log("chat list data media message", newData);
      setChatData(newData);
    });
  }
  const sendImage = () => {
    // console.log("message value", message, socket)
    // console.log("data to be send as message", {
    //   attachment_name: chatAttachmentData.name,
    //   attachment: chatAttachmentData.url,
    //   room_id: roomName?.room_id,
    //   primary_room_id: roomName?._id,
    //   sender_id: selectedBranch?._id,
    //   receiver_id: roomName?.receiver_details?._id,
    // })
    // if (socket && message) {
    socket.emit("media message", {
      attachment_name: chatAttachmentData.name,
      attachment: chatAttachmentData.url,
      room_id: singleClinicDetail?.room_id,
      primary_room_id: singleClinicDetail?._id,
      sender_id: userId,
      receiver_id: singleClinicDetail?.receiver_details?._id,
      // message : message
    });
    getSocket_mediaMessages();
    setChatAttachmentData(null);
    console.log("attachment workinhg !!!!! media message");
  };

  useEffect(() => {
    // Scroll to the bottom when chatData changes
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [chatData]);

  useEffect(() => {
    if (clinicsList1.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      const clinicId = queryParams.get('clinicId');


      if (clinicId && isFirstRander) {
        const defaultJoinedRoom = clinicsList1.find(room => room?.receiver_details?._id === clinicId)
        if (defaultJoinedRoom) {
          setSingleClinicDetail(defaultJoinedRoom);
          setOpenChat(true);
        }
      }
      setIsFirstRander(false)
    }

  }, [clinicsList1])



  const [selectedOption, setSelectedOption] = useState('');

  const [ selectedSlot , setSlectedSlot] = useState(null);

  const [ newDenstist , setNewDentist] = useState(null);

  const options = [
    { id: 1, text: 'None of these times', count: '0' },
    { id: 2, text: 'June 21st at 04:25 AM', count: '1' },
  ];

  const handleOptionClick = (optionText, slot, messageDetail) => {
    setSelectedOption(optionText);
    if(slot){

      setSlectedSlot(slot)
    }
    if(messageDetail?.dentist_id){
      setNewDentist(messageDetail?.dentist_id)
    }
  };

  const handleCancelSelectedSlot = ()=>{
    setSelectedOption('');
    setSlectedSlot(null)
  }

  const getAppointmentDetails = async (roomdetail) => {
    const clinicId = roomdetail?.receiver_details?._id;

    if (!clinicId) {
      console.log("user id not available");
      return;
    }

    try {
      const resp = await makeHttpRequest(
        "get",
        `users/getLatestAppointmentForUser/${clinicId}`
      );
      setAppointmentDetail(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptionConfirm= ()=>{
    if(selectedOption === "None of the below"){
      handleCancelRequest()
    }else{
      handleConfirmSlot()
    }
  }

  const  handleCancelRequest = async ()=>{

    const body = {
      appointment_id : appointmentDetail?._id ,
    }
    try {
      sendHandler(null,selectedOption)
      const resp = await declineAllSuggestedSlots(body);
      getAppointmentDetails(singleClinicDetail)
    } catch (error) {
      
    }
  }

  const handleConfirmSlot = async ()=>{
     if(!selectedSlot){
       console.log("selected slot is not selected")
       return;
      }
      console.log("🚀 ~ handleConfirmSlot ~ selectedSlot:", selectedSlot)
     const localStartDate = new Date(selectedSlot?.start);
     const weekdayNumber = localStartDate.getDay();
     const weekdays = [
       "Sunday",
       "Monday",
       "Tuesday",
       "Wednesday",
       "Thursday",
       "Friday",
       "Saturday",
     ];
 
     const dayOfWeek = weekdays[weekdayNumber];
 
    //  const DateStringUtC = new Date(
    //    localStartDate.setHours(0, 0, 0, 0)
    //  ).toISOString();
 
     const DateStringUtC = getisoDateForDateObj(selectedSlot?.start)
 
     const startDate = localStartDate
       .toLocaleDateString("zh-Hans-CN", {
         year: "numeric",
         month: "2-digit",
         day: "2-digit",
       })
       .replace(/\//g, "-");
 
     console.log(
       "changs for new payload :",
       selectedSlot,
       new Date(selectedSlot?.start)
     );
 
     const body = {
       branch_id: appointmentDetail?.branch_id,
       dentist_id: newDenstist || "",
       start_time: selectedSlot?.start,
       end_time: selectedSlot?.end,
       reason: appointmentDetail?.reason,
       services: appointmentDetail?.service,
       user_id: appointmentDetail?.user_id,
       day: dayOfWeek,
       date: startDate,
       start: DateStringUtC,
       appointment_id : appointmentDetail?._id
     };

     try {
      sendHandler(null,selectedOption)
      const resp = await slotBookingForReshedule(body);
      getAppointmentDetails(singleClinicDetail)
      
     } catch (error) {
      console.log(error)
     }

  }



  return (
    <div className="page-wrap p-0">
      <div className="chat-box slack-user">
        <header className="slack__header">
          <nav className="nav">
            <div className="form-group">
              <div className="search-bar">
                <i className="fas fa-search"></i>
                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Search Clinics"
                  className="form-control"
                  onChange={searchHandler}
                />
              </div>
            </div>
            <span className="d-block d-md-none openbtn" onClick={toggleSidebar}>
              <i class="fa-solid fa-bars"></i>
            </span>
          </nav>
        </header>

        <main className="slack__main">
          <section className="section d-none d-md-block p-0">
            {/* <article className='section-1 px-2'>
              <div className="box box-1">A</div>
              <div className="box box-2"></div>
              <div className="box box-3">+</div>
            </article> */}
            <article className="section-2">
              {/* <div className="sidebar-user">
                <div className="sidebar-user-info">
                  <div className="dropdown">
                    <h5 className="dropdown-toggle mb-0 text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Anwar Alii
                    </h5>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><Link className="dropdown-item" href="#">Action</Link></li>
                      <li><Link className="dropdown-item" href="#">Another action</Link></li>
                      <li><Link className="dropdown-item" href="#">Something else here</Link></li>
                    </ul>
                  </div>
                </div>
                <span className="user-edit-icon">
                  <i className="text-white fas fa-edit" />
                </span>
              </div> */}
              <hr className="border" />
              <div className="bottom-list pt-3 px-2">
                {/* <div className="draft ">
                  <h6 className="mb-0 header">
                    <span className="draft-icons me-2">
                      <i className="fa-regular fa-paper-plane"></i>
                    </span>
                    Draft & sent
                  </h6>
                  <span className="user-edit-icon text-white">
                    <i className="fa-solid fa-pencil"></i> 1
                  </span>

                </div> */}
                <div className="channels">
                  <h6 className="text-white">Direct Messages</h6>
                  <ul className="open-list">
                    {clinicsList1?.map((val) => (
                      <li>
                        <Link
                          className=""
                          to={``}
                          onClick={() => clinicDataHandler(val)}
                          key={val?._id}
                        >
                          <i className="fas fa-hashtag" />
                          {val?.receiver_details?.practice_name}
                          {/* {val?.chats?.count} */}
                          {val?.chats?.count ? (
                            // <span style={{ 'color': 'red' }}> {ele?.chats?.count}</span>
                            <span className='blue-dot'></span>
                          ) : <></>}

                        </Link>
                      </li>
                    ))}
                    {/* /chat-box/${val?.room_id} */}
                    {/* <li>
                      <Link className="" to="">
                        <i className="fas fa-hashtag" />
                        design-crit
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="direct-messages">
                  <div className="dropdown">
                    <h6 className="dropdown-toggle header" role='button' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Direct Messages
                    </h6>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><a className="dropdown-item" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>

                  <ul className='open-list'>
                    <li>
                      <Link to="">
                        <span className='box'>A <span className='active-dot'></span></span>
                        Anwar Ali (You)
                      </Link>
                    </li>
                  </ul>

                </div> */}
              </div>
            </article>
          </section>
          <section className="section mobile_Section p-0" id="leftSidebar">

            {/* <span className="d-block d-md-none closebtn" onClick={closeSidebarOne}>
              x
            </span> */}

            <article className="section-2">

              <hr className="border" />
              <div className="bottom-list pt-4 px-4">

                <div className="channels">
                  <h6 className="text-white">Direct Messages</h6>
                  <ul className="open-list">
                    {clinicsList1?.map((val) => (
                      <li>
                        <Link
                          className=""
                          to={``}
                          onClick={() => clinicDataHandler(val)}
                          key={val?._id}
                        >
                          <i className="fas fa-hashtag" />
                          {val?.receiver_details?.practice_name}
                          {/* {val?.chats?.count} */}
                          {val?.chats?.count ? (
                            // <span style={{ 'color': 'red' }}> {ele?.chats?.count}</span>
                            <span className='blue-dot'></span>
                          ) : <></>}

                        </Link>
                      </li>
                    ))}
                    {/* /chat-box/${val?.room_id} */}
                    {/* <li>
                      <Link className="" to="">
                        <i className="fas fa-hashtag" />
                        design-crit
                      </Link>
                    </li> */}
                  </ul>
                </div>

              </div>
            </article>
          </section>
          <section
            className="body p-0"
          // style={{ width: `${divWidth2}px`, transition: "width 0.6s ease" }}
          >
            {openChat ? (
              <div className="content" id="mainBody">
                <div className="content-header">
                  <article className="channel-content-header-details">
                    <h4 className="channel-content-header-name">
                      #{singleClinicDetail?.receiver_details?.practice_name}
                      {/* <i className="fas fa-star" /> */}
                    </h4>
                  </article>
                  <button
                    className="border-none ms-auto"
                    id="info"
                    type="button"
                    disabled=""
                    onClick={openrightSidebar}
                  >
                    <i className="fas fa-info-circle" />
                  </button>
                </div>

                <div className="feeds" id="content" ref={chatContentRef}>
                  {chatData?.map((val) => (
                    <article className="feed">
                    {

                      val?.message_type !== "alert" &&
                      <div className="feeds-user-avatar">
                        <img
                          src={`${val?.sender_details?.profile_image
                            ? process.env.REACT_APP_USER_PROFILE_IMAGE +
                            val?.sender_details?.profile_image
                            : process.env.REACT_APP_DEFAULT_IMAGE_URL
                            }`}
                          alt="User 1"
                          width={40}
                        />
                        {/* <span>Z</span> */}
                      </div>
                    }
                      
                      <div className={`feed-content ${val?.message_type === "alert" ? "alertMessage" : ""}`}>
                      {
                        val?.message_type !== "alert" &&
                        <div className="feed-user-info">
                          <h4>
                            {val?.sender_details?.practice_name || val?.sender_details?.full_name}{" "}
                            <strong className="time-stamp">
                              {getTime(val?.createdAt)}
                            </strong>
                          </h4>
                        </div>
                      }
                      

                        {val?.message_type === "text" ? (
                          <p className="feed-text">{val?.message}</p>
                        ) : 
                        val?.message_type === "slot" &&  appointmentDetail?._id === val?.appointment_id &&  appointmentDetail.status === "rescheduled" 
                        ?
                        (<div className="chatbot-container">
                          <div className="card chatbot-card">
                            <div className="card-body">
                              <div className="chatbot-message mb-3">
                                <i className="bi bi-robot text-primary"></i>
                                <span className="ms-2">{val?.message}</span>
                              </div>
                                <h3 className="feed-text">Dentist : {val?.dentist_details?.full_name || "Not Specified"}</h3>
                              <div className="options">
                              <button
                                   
                                   className={`btn btn-outline-primary mb-2 w-100 option-button ${"None of the below"  === selectedOption ? 'active' :''}  `}

                                    onClick={() => handleOptionClick("None of the below")}
                                  >
                                    <i className={0}></i>
                                    <span className="ms-2">None of the below</span>
                                  </button>
                                { val?.slots.map((slot, i) => (
                                  <button
                                    key={i}
                                    className={`btn btn-outline-primary mb-2 w-100 option-button ${moment(new Date(slot?.start)).format('DD-MM-YYYY, hh:mm A') === selectedOption ? 'active' :''}  `}
                                    onClick={() => handleOptionClick(moment(new Date(slot?.start)).format('DD-MM-YYYY, hh:mm A'), slot, val)}
                                  >
                                    <i className={i}></i>
                                    <span className="ms-2">{moment(new Date(slot?.start)).format('DD-MM-YYYY, hh:mm A')}</span>
                                  </button>
                                ))}
                              </div>
                              <div class="collapse" id="collapseExample">
                                <div class="card card-body">
                                  Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                </div>
                              </div>
                              {selectedOption && (
                                <div className="alert alert-info mt-3">
                                  <p className="fw-semi-bold">You selected: <strong>{selectedOption}</strong></p>
                                  <div class="btn-group" role="group" aria-label="Basic outlined example">
                                    <button type="button" class="btn btn-outline-primary option-button" onClick={handleOptionConfirm}>  Confirm</button>
                                    <button type="button" class="btn btn-outline-primary option-button" onClick={handleCancelSelectedSlot}>Cancel</button>
                                  </div>

                                </div>
                              )}
                            </div>
                          </div>

                        </div>)
                        :
                        val?.message_type === "slot" 
                        ?
                        <div className="card shadow mt-2">
                                  <div className="card-body">
                                    <p className="feed-text">{val?.message}</p>
                                    <h3 className="feed-text">Dentist : {val?.dentist_details?.full_name || "Not Specified"}</h3>
                                    <ol>
                                    <li>None of the Below</li>
                                    {val?.slots?.map((slot)=>(

                                      <li>{moment(new Date(slot?.start)).format('DD-MM-YYYY, hh:mm A')}</li>
                                    ))
                                    }
                                    </ol>
                                  </div>
                         </div>
                        :
                         val?.message_type === "alert" ? 
                         (
                         <p className={`feed-text alert ${val?.color !== "grey" ? "alert-info" : "alert-secondary"}`}>{val?.message}</p>
) :
                         checkingFileFormat(val) ? (
                          <>
                            <a
                              className="imgPreviewBtn"
                              data-bs-toggle="modal"
                              data-bs-target="#previewImgModal"
                              onClick={() =>
                                setPreviewImage(val?.attachments[0])
                              }
                            >
                              <img
                                src={
                                  val?.attachments && val?.attachments[0]?.url
                                    ? process.env.REACT_APP_CHAT_DOC +
                                    val?.attachments[0]?.url
                                    : ""
                                }
                                alt="Image data"
                                width={200}
                              />
                            </a>
                            <button
                              type="button"
                              onClick={() =>
                                downloadtryImage(
                                  process.env.REACT_APP_CHAT_DOC +
                                  val?.attachments[0]?.url,
                                  val?.attachments[0]?.name
                                )
                              }
                            >
                              {" "}
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </button>
                            <p>
                              {val?.attachments &&
                                val?.attachments[0]?.name &&
                                val?.attachments[0]?.name !== ""
                                ? val?.attachments[0]?.name
                                : "--"}
                            </p>{" "}
                          </>
                        ) : val?.attachments ? (
                          <>
                            <img
                              src={
                                val?.attachments && val?.attachments[0]?.url
                                  ? file_icon_image
                                  : ""
                              }
                              alt="file doc"
                              width={200}
                            />
                            <a
                              href={
                                process.env.REACT_APP_CHAT_DOC +
                                val?.attachments[0]?.url
                              }
                              download={val?.attachments[0]?.name}
                            >
                              {" "}
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </a>
                            <p>
                              {val?.attachments &&
                                val?.attachments[0]?.name &&
                                val?.attachments[0]?.name !== ""
                                ? val?.attachments[0]?.name
                                : "--"}
                            </p>
                            {/* <button type="button"><i class="fa fa-download" onClick={() => saveFile()} aria-hidden="true"></i></button> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </article>
                    
                   

                  ))}






                  {/* <article className="feed">
                  <div className="feeds-user-avatar">
                    <img src={chatUser02} alt="User 1" width={40} />
                  </div>
                  <div className="feed-content">
                    <div className="feed-user-info">
                      <h4>
                        Acme Team <span className="tag">app</span>
                        <span className="time-stamp"> 12:45pm</span>
                      </h4>
                    </div>
                    <p className="feed-text">Events starting in 15 minutes:</p>

                  </div>
                </article>
                <article class="feed">
                  <div class="feeds-user-avatar">
                    <img src={chatUser01} alt="User 1" width="40" />
                  </div>
                  <div class="feed-content">
                    <div class="feed-user-info">
                      <h4>Hary Boone <span class="time-stamp">12:58pm</span></h4>
                    </div>
                    <div>
                      <p class="feed-text">
                        Quick note: today <span class="mention">@lisa</span> will
                        join our team sync to provide updates on the launch, if
                        you have questions, bring 'em. See you all later...er, in
                        2 minutes.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="feed">
                  <div className="feeds-user-avatar">
                    <img src={chatUser02} alt="User 1" width={40} />
                  </div>
                  <div className="feed-content">
                    <div className="feed-user-info">
                      <h4>
                        Acme Team <span className="tag">app</span>
                        <span className="time-stamp"> 12:45pm</span>
                      </h4>
                    </div>
                    <p className="feed-text">Events starting in 15 minutes:</p>

                  </div>
                </article>
                <article className="feed">
                  <div className="feeds-user-avatar">
                    <img src={chatUser01} alt="User 1" width={40} />
                    <span>Z</span>
                  </div>
                  <div className="feed-content">
                    <div className="feed-user-info">
                      <h4>
                        Zeo Maxwell <span className="time-stamp"> 9:56am</span>
                      </h4>
                    </div>
                    <div>
                      <p className="feed-text">
                        No! It was my pleasure! Great to see the enthusiasm out there.
                      </p>
                    </div>
                  </div>
                </article> */}
                </div>
                {/* <form action="." className="form" name="feedForm">
                <div
                  className="input"
                  id="textBox"
                  contentEditable="true"
                  data-gramm="false"
                  wt-ignore-input="true"
                >
                  <p>Message #social-media</p>
                </div>
                <div className="input-toolbar-icons">
                  <div className="move-right">
                    <i className="fas fa-bolt" />
                    <i className="fas fa-bold" data-command="b" />
                    <i className="fas fa-italic" data-command="i" />
                    <i className="fas fa-strikethrough" />
                    <i className="fas fa-code" data-command="code" />
                    <i className="fas fa-link" data-command="a" />
                    <i className="fas fa-list-ol" data-command="ol" />
                    <i className="fas fa-list-ul" data-command="ul" />
                    <i className="fas fa-quote-left" data-command="blockquote" />
                    <i className="far fa-file-code" data-command="pre" />
                    <i className="fas fa-print" onclick="printDoc();" />
                  </div>
                  <div className="move-left">
                    <i className="fas fa-at" />
                    <i className="far fa-smile" />
                    <button type="submit" className="btn-borderless button">
                      <i className="fas fa-angle-double-right" />
                    </button>
                  </div>
                </div>
                <div className="input-toolbar-selection" />
              </form> */}

                {/* <form action="." className="form" name="feedForm">
                <div className="chat-footer">
                  <div className="input-group">
                    <div className="btn-file btn">
                      <i className="fa fa-paperclip"></i>
                      <input type="file" onChange={mediaHandler} />
                    </div>
                    <input
                      type="text"
                      value={sendMessage}
                      onChange={(e) => setSendMessage(e.target.value)}
                      class="input-msg-send form-control rounded"
                      placeholder="Type something"
                    />
                    <button
                      type="button"
                      disabled={sendMessage === ""}
                      class="btn msg-send-btn rounded-pill ms-2"
                      onClick={sendHandler}
                    >
                      <i class="fab fa-telegram-plane"></i>
                    </button>
                  </div>
                </div>
              </form> */}

                <div
                  className="form chat-footer-form attachment-image"
                  name="feedForm"
                >
                  {/* <div class="card__clinic-booking">
                  <Link class="apt-btn theme_btn rounded-1" onClick={handleClickOpenDialog}>Appointment</Link>
                </div> */}

                  {/* {roomName !== null ? ( */}
                  <div className="chat-footer">
                    <div className="input-group attachment-image">
                      {chatAttachmentData?.url ? (
                        <>
                          <div className="img-attach">
                            {checkingFileFormat_uploadFile(
                              chatAttachmentData?.url
                            ) ? (
                              <img
                                // style={{ height: "100px", width: "200px" }}
                                src={
                                  process.env.REACT_APP_CHAT_DOC +
                                  chatAttachmentData?.url
                                }
                              />
                            ) : (
                              <img
                                style={{ height: "140px", width: "120px" }}
                                src={file_icon_image}
                              />
                            )}
                            <button
                              type="button"
                              onClick={() => setChatAttachmentData(null)}
                            >
                              <i className="fa fa-remove"></i>
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={() => sendImage()}
                            class="btn msg-send-btn rounded-pill ms-2"
                          >
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      {chatAttachmentData === null ? (
                        <>
                          <div className="btn-file btn">
                            <i className="fa fa-paperclip"></i>
                            <input type="file" onChange={imageUploadFunc} />
                          </div>
                          <input
                            type="text"
                            value={sendMessage}
                            onChange={(e) => setSendMessage(e.target.value)}
                            class="input-msg-send form-control rounded"
                            placeholder="Type something"
                          />
                          <button
                            type="button"
                            disabled={sendMessage === ""}
                            onClick={sendHandler}
                            class="btn msg-send-btn rounded-pill ms-2"
                          >
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* ) : <></>} */}
                </div>
              </div>
            ) : (
              <div className="empty-chat">
                <img src={empty_img} />

                <h4>Start Chat</h4>
              </div>
            )}
            <div
              className="right-sidebar shadow" id="rightSidebar"
            //  style={{ width: `${divWidth}px`, transition: "width 0.6s ease" }}
            >
              <div className="right-sidebar-header">
                <article className="right-sidebar-header-details">
                  <h4 className="right-sidebar-header-name">Details</h4>
                  <p>#{singleClinicDetail?.receiver_details?.practice_name}</p>
                </article>

                <span onClick={closerightSidebar} >
                  <i className="fas fa-times" />
                </span>
              </div>
              <div className="right-sidebar-about">
                <article className="about-header">
                  <h4 className="text-center">About</h4>
                </article>
                <article className="about-details">
                  <div className="profile">
                    <img
                      src={`${singleClinicDetail?.receiver_details?.profile_image
                        ? process.env.REACT_APP_USER_PROFILE_IMAGE +
                        singleClinicDetail?.receiver_details?.profile_image
                        : process.env.REACT_APP_DEFAULT_IMAGE_URL
                        }`}
                      alt="img"
                    />
                  </div>

                  <h5 className="text-center">
                    {singleClinicDetail?.receiver_details?.practice_name}
                  </h5>
                  <p className="text-center">
                    {singleClinicDetail?.receiver_details?.bio}
                  </p>
                  {/* <ul className="sigma_sm justify-content-center">
                    <li>
                      <Link to="" >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="fab fa-youtube" />
                      </Link>
                    </li>
                  </ul> */}
                </article>
              </div>
            </div>

            <div
              class="modal fade"
              id="previewImgModal"
              tabindex="-1"
              aria-labelledby="previewImgModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="previewImgModalLabel">
                      Preview Image
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setPreviewImage(null)}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="previewImgWrap">
                      <img
                        src={process.env.REACT_APP_CHAT_DOC + previewImage?.url}
                        alt=""
                        className="img-fluid"
                        srcset=""
                      />
                    </div>
                  </div>
                  {/* <div class="modal-footer">
              <button type="button" class="theme-outline-btn" data-bs-dismiss="modal">Close</button>
              <a href="../../../Assets/Blue-Cross.svg" download>
              <button type="button" class="theme_btn">Save changes</button> 
              </a>
            </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
