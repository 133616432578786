import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ResultNavbar.scss";
import companyLogo from "../../Assets/Logos/logo1.png";
import logo1 from "../../Assets/Logos/logo1.png";

export default function ResultNavabr() {
  const [scrolled, setScrolled] = useState(false);
  let account_type = null;
  const token = localStorage.getItem("token");
  const user_data = localStorage.getItem("user_details");
  if (user_data !== null) {
    const parsedData = JSON.parse(user_data);
    account_type = parsedData.user_type;
  }

  function displayNavUser() {
    const navbar = document.getElementById("navbarUserSupport");
    if (navbar.classList.contains("show")) {
      navbar.classList.remove("show");
      navbar.classList.add("hide");
    } else {
      navbar.classList.remove("hide");
      navbar.classList.add("show");
    }
  }

  return (
    <>
      <nav
        id="navbardefault-eight"
        className="navbar patient-navbar-three navbar-expand-lg shadow bg-black fixed-top"
      >
        <div className="container">
          <Link className="navbar-brand flex-grow-1" to="/">
            <img
              src={companyLogo}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            // type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#navbarSupportedContent3"
            // aria-controls="navbarSupportedContent3"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
            onClick={displayNavUser}
          >
            <i class="fa-solid fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarUserSupport">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white"
                  to={`${process.env.REACT_APP_DENTIST_BLOG_LINK}`}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/support">
                  Support
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/find-dentist">
                  For Dentists
                </Link>
              </li>
            </ul>

            {token === null ? (
              <form className="d-flex" role="search">
                <Link
                  to="/login"
                  class="theme_btn text-decoration-none"
                  // type="button"
                >
                  Log in
                </Link>
              </form>
            ) : token !== null && account_type === "user" ? (
              <Link
                to="/user-profile/tab1"
                class="theme_btn text-decoration-none"
                // type="button"
              >
                Go to User Profile
              </Link>
            ) : (
              <Link
                to="/dentistProfile"
                class="theme_btn text-decoration-none"
                // type="button"
              >
                Go to Dentist Profile
              </Link>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
