import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadCrumb(props) {
    return (
        <div>

            <div className="breadcrum">
                <div className="d-flex align-items-center  pb-3 pt-3">

                    <span className='arrow'>
                        <svg
                            className="mx-1 icon-18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            height={25}
                            width={25}
                        >
                            <path
                                d="M8.5 5L15.5 12L8.5 19"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>

                    <h4 className="mb-0  text-dark fw-500">{props.Navitem}</h4>
                </div>

            </div>

        </div>
    )
}
