import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField, Typography, Tab, Tabs } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import {
  loginService,
  signupService,
  socialSignUpService,
  successNotification,
} from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";
const AuthComponent = ({ show, handleClose, setUserData, setValidUser, onLoginDone }) => {
  const [tabName, setTabName] = useState("signin");
  const [signIn, setSignIn] = useState(true);

  const handleGoogleSignIn = async (response) => {
    // const provider = new GoogleAuthProvider();
    try {
      const result = jwtDecode(response?.credential);
      // console.log(a);
      const body = {
        email: result?.email,
        first_name: result?.given_name,
        last_name: result?.family_name,
        full_name: result?.name,
        social_type: "google",
        social_id: result?.sub,
      };

      const resp = await socialSignUpService(body);
      console.log("resp--------------------------");
      localStorage.setItem("token", resp?.data?.token);
      let v = resp?.data?.user;
      localStorage.setItem("user_details", JSON.stringify(v));
      if(setUserData){
     setUserData(resp?.data?.user);

      }
      if(setValidUser){

        setValidUser(true);
      }
      if(onLoginDone){

        onLoginDone()
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().when("signIn", {
      is: false,
      then: Yup.string().required("Full Name is required"),
    }),
    last_name: Yup.string().when("signIn", {
      is: false,
      then: Yup.string().required("Last Name is required"),
    }),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (signIn) {
        let payload = {
          email: values.email,
          password: values.password,
        };
        handleSignIn(payload);
      } else {
        let payload = {
          email: values.email,
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          user_type:"user"
        };
        const response = await signupService(payload);
        console.log(response,"======================respo");
        if (response.status === 201) {
          toast.success("Account has been created successfully.")
          handleSignIn({ email: values.email, password: values.password });
        }
      }
    },
  });

  const handleSignIn = async (payload) => {
    try {
      const resp = await loginService(payload);
      console.log(resp, "respoonse");
      if (resp?.code === 200) {
        toast.success("Login Successfully");
        localStorage.setItem("user_details", JSON.stringify(resp.data.user));
        localStorage.setItem("token", resp.data.token);
        toast.success("Login Successfully");
        handleClose();
        if(onLoginDone){

          onLoginDone()
        }
        window.location.reload();
      } else {
        successNotification(resp?.message);
      }
    } catch (err) {
      console.log("login error data", err);
    }
  };
  const handleTabChange = (event, newValue) => {
    setSignIn(newValue === 0);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{signIn ? "Sign In" : "Sign Up"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs value={signIn ? 0 : 1} onChange={handleTabChange} centered>
          <Tab label="Sign In" />
          <Tab label="Sign Up" />
        </Tabs>

        <div>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="form-error">{formik.errors.email}</p>
          ) : null}
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <p className="form-error">{formik.errors.password}</p>
          ) : null}
          {!signIn && (
            <>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <p className="form-error">{formik.errors.first_name}</p>
              ) : null}
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <p className="form-error">{formik.errors.last_name}</p>
              ) : null}
            </>
          )}
        </div>

        <Typography className="text-center line mb-2">or</Typography>

        {/* Google authentication component */}
        <div className="socialLoginWrap">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleGoogleSignIn(credentialResponse);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {signIn ? "Sign In" : "Sign Up"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthComponent;
