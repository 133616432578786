import React, { useState, useEffect } from "react";
import "./AppointmentRequest.scss";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import Sidebar from "../../../components/Sidebar/Sidebar";
import chatUser01 from "../../../Assets/chatUser2.jpg";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import doctor01 from "../../../Assets/profile01.jpg";
import Tables from "../../../components/Tables/Tables";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";

import { getDentistAppointsByIDService } from "../../../utils/httpServices/httpService";

import { useSelector } from "react-redux";
import RequestAppointment from "./request_appointment/RequestAppointment";
import ScheduleAppointment from "./schedule_appointment/ScheduleRequest";
import CompletedAppointment from "./completed_appointment/CompletedRequest";
import HistoryAppointment from "./history_appointment/HistoryRequest";

const customContentStyle = {
  width: "30%",
  height: "200px",
  maxWidth: "none",
};

export default function AppointmentRequest() {
  // ---Reject-dialogue-open---

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpenDialog1 = () => {
    setOpen1(true);
  };

  const handleCloseDialog1 = () => {
    setOpen1(false);
  };

  // ---accept-dialogue-open---

  const [open, setOpen] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // --rightSidebar--

  const [isOpen, setIsOpen] = useState(false);
  const [divWidth, setDivWidth] = useState(0);

  const handleClickOpen = (id) => {
    console.log("appointment id", id);
    setIsOpen(!isOpen);
    setDivWidth(30);

    console.log(setIsOpen);
  };

  const handleClose = () => {
    setDivWidth(0);
  };

  // --handletabs--

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "tab1") setScreenName("requests");
    else if (tab === "tab2") setScreenName("scheduled");
    else if (tab === "tab3") setScreenName("completed");
    else setScreenName("history");
  };

  /////////////////////////////// API Handeling ////////////////////////

  const state = useSelector((state) => state);
  const [branch_id, setBranch_id] = useState(null);
  const [initialState, setPageState] = useState(
    localStorage.getItem("selected_Branch")
  );
  const [screenName, setScreenName] = useState("requests");

  // const [scheduledAppointmentList,setScheduledAppointmentList] = useState([]);
  // const [completedAppointmentList,setCompletedAppointmentList] = useState([]);
  // const [historyAppointmentList,setHistoryAppointmentList] = useState([]);
  // const [appointmentList,setAppointmentList] = useState([]);

  // const [requestPagination, setRequestPagination] = useState({
  //     count:0,
  //     limit:5,
  //     offset:0,
  // })

  // const [schedulePagination, setSchedulePagination] = useState({
  //     count:0,
  //     limit:5,
  //     offset:0,

  // })

  // const [completePagination, setCompletePagination] = useState({
  //     count:0,
  //     limit:5,
  //     offset:0,
  // })

  // const [historyPagination, setHistoryPagination] = useState({
  //     count:0,
  //     limit:5,
  //     offset:0,
  // })

  // async function getDentistAppointsByID(){
  //     try {
  //            let obj = {
  //             branch_id:branch_id,
  //             screen:screenName,
  //            }

  //            if(screenName === 'requests'){
  //             obj['limit'] = requestPagination.limit
  //             obj['offset'] = requestPagination.offset
  //             obj["start_date"]= filter_start_date.start_date
  //             obj["end_date"]=filter_start_date.end_date

  //            }
  //            else if(screenName === 'scheduled'){
  //             obj['limit'] = schedulePagination.limit
  //             obj['offset'] = schedulePagination.offset
  //             obj["start_date"]= scheduled_filter_start_date.start_date
  //             obj["end_date"]=scheduled_filter_start_date.end_date

  //            }
  //            else if(screenName === 'completed'){
  //             obj['limit'] = completePagination.limit
  //             obj['offset'] = completePagination.offset
  //             obj["start_date"]= completed_filter_start_date.start_date
  //             obj["end_date"]=completed_filter_start_date.end_date

  //            }
  //            else{
  //             obj['limit'] = historyPagination.limit
  //             obj['offset'] = historyPagination.offset
  //             obj["start_date"]= history_filter_start_date.start_date
  //             obj["end_date"]=history_filter_start_date.end_date
  //            }
  //         let resp = await getDentistAppointsByIDService(obj);
  //         console.log("response", resp);
  //         if(resp.code === 200) {
  //             if(screenName === 'requests')
  //             {
  //             setAppointmentList(resp.data);
  //             setRequestPagination({...requestPagination,count:resp?.count});
  //             }
  //             else if(screenName === 'scheduled'){
  //             setScheduledAppointmentList(resp.data);
  //             setSchedulePagination({...schedulePagination,count:resp?.count});
  //             }
  //             else if (screenName === 'completed'){
  //             setCompletedAppointmentList(resp.data);
  //             setCompletePagination({...completePagination,count:resp?.count});
  //             }
  //             else{
  //              setHistoryAppointmentList(resp.data);
  //              setHistoryPagination({...historyPagination,count:resp?.count});
  //             }
  //         }
  //     }
  //     catch (error){
  //         console.log(error)
  //     }
  // }

  const [selectedBranchName, setSelectedBranchName] = useState(null);

  useEffect(() => {
    if (state.getClinicBrances?.data?.data) {
      setBranch_id(state.getClinicBrances?.data?.data[initialState]._id);
      setSelectedBranchName(state.getClinicBrances?.data?.data[initialState]);
    }
  }, [branch_id, initialState, state]);
  // useEffect(() => {
  //     if(branch_id !== null)
  //     getDentistAppointsByID();
  // },[branch_id,screenName]);

  const updatePageState = (index) => {
    setPageState(index);
    localStorage.setItem("selected_Branch", index);
  };

  //     const [filter_start_date, setFilter_end_date] = useState({
  //         start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(1).end_time
  //     });

  //     const [scheduled_filter_start_date, setScheduledFilter_end_date] = useState({
  //         start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(1).end_time
  //     });

  //     const [completed_filter_start_date, setCompletedFilter_end_date] = useState({
  //         start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(1).end_time
  //     });

  //     const [history_filter_start_date, setHistoryFilter_end_date] = useState({
  //         start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(1).end_time
  //     });

  //     function timeConversionForFilter(day){
  //         const startInputTime = new Date();
  //         let todayEndtime = new Date();
  //         const endInputTime = new Date(todayEndtime.setDate(todayEndtime.getDate() + day));

  //         startInputTime.setHours(0, 0, 0, 0);
  //         endInputTime.setHours(0, 0, 0, 0);

  //         console.log("new time", startInputTime,endInputTime)
  //         console.log("new time to ISO string " , startInputTime.toISOString(), endInputTime.toISOString())

  //         return {start_time:startInputTime.toISOString() ,end_time:endInputTime.toISOString()}
  //     }

  //    ///////////////////////////////////////////////////////////////////////////////

  //     function filterFunction(event){
  //         console.log("event",event.target.value)
  //          const e = event.target.value;
  //         if(e === 'today')
  //         setFilter_end_date({start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(0).end_time})
  //         else if(e === 'last1week')
  //         setFilter_end_date({start_date:timeConversionForFilter(-7).start_time,
  //             end_date:timeConversionForFilter(-7).end_time})
  //         else
  //         setFilter_end_date({start_date:timeConversionForFilter(-1).start_time,
  //             end_date:timeConversionForFilter(-1).end_time})
  //     }

  //     function schedule_filterFunction(event){
  //         console.log("event",event.target.value)
  //          const e = event.target.value;
  //         if(e === 'today')
  //         setScheduledFilter_end_date({start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(0).end_time})
  //         else if(e === 'last1week')
  //         setScheduledFilter_end_date({start_date:timeConversionForFilter(-7).start_time,
  //             end_date:timeConversionForFilter(-7).end_time})
  //         else
  //         setScheduledFilter_end_date({start_date:timeConversionForFilter(-1).start_time,
  //             end_date:timeConversionForFilter(-1).end_time})
  //     }

  //     function completed_filterFunction(event){
  //         console.log("event",event.target.value)
  //          const e = event.target.value;
  //         if(e === 'today')
  //         setCompletedFilter_end_date({start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(0).end_time})
  //         else if(e === 'last1week')
  //         setCompletedFilter_end_date({start_date:timeConversionForFilter(-7).start_time,
  //             end_date:timeConversionForFilter(-7).end_time})
  //         else
  //         setCompletedFilter_end_date({start_date:timeConversionForFilter(-1).start_time,
  //             end_date:timeConversionForFilter(-1).end_time})
  //     }

  //     function history_filterFunction(event){
  //         console.log("event",event.target.value)
  //          const e = event.target.value;
  //         if(e === 'today')
  //         setHistoryFilter_end_date({start_date:timeConversionForFilter(1).start_time,
  //         end_date:timeConversionForFilter(0).end_time})
  //         else if(e === 'last1week')
  //         setHistoryFilter_end_date({start_date:timeConversionForFilter(-7).start_time,
  //             end_date:timeConversionForFilter(-7).end_time})
  //         else
  //         setHistoryFilter_end_date({start_date:timeConversionForFilter(-1).start_time,
  //             end_date:timeConversionForFilter(-1).end_time})
  //     }

  //     ////////////////////////////////////////////////////

  //     useEffect(() => {
  //         getDentistAppointsByID()
  //     },[filter_start_date,scheduled_filter_start_date,completed_filter_start_date,
  //         history_filter_start_date])

  return (
    <>
      <ProviderNav />
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar
            initialState={initialState}
            triggerParentUpdate={updatePageState}
          />
        </div>
        <div className="fullWidhtWrap">
          <div className="container">
            <section className="doctorWrap">
              <div className="navigationBarWrapper">
                <NavigationBar />
              </div>
              <BreadCrumb Navitem={"Appointment List"} />
              <div className="row gap-md-0 gap-2">
                <div className="col-md-3 col-sm-12 ">
                  <div className="card shadow h-100 border-0">
                    <div className="sidebar">
                      <article className="SideNav">
                        <div className="sidebar-header">
                          {/* <h5 className="mb-0">
                            Branch Name{" "}
                            {selectedBranchName !== null ? (
                              <span style={{ color: "red" }}>
                                {selectedBranchName?.full_name}
                              </span>
                            ) : (
                              ""
                            )}
                          </h5> */}
                        </div>
                        <div className="bottom-list ">
                          <ul className="open-list">
                            <li>
                              <Link
                                className={`${
                                  activeTab === "tab1" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("tab1")}
                              >
                                <span className="make-white">
                                  <i class="fa-solid me-2 fa-envelope"></i>
                                  Requests
                                </span>
                                {/* <span className="counter">7</span> */}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={`${
                                  activeTab === "tab2" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("tab2")}
                              >
                                <span className="make-white">
                                  <i class="fa-solid me-2 fa-calendar-days"></i>
                                  Scheduled
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={`${
                                  activeTab === "tab3" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("tab3")}
                              >
                                <span className="make-white">
                                  <i class="fa-solid me-2 fa-circle-check"></i>
                                  Completed
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                className={`${
                                  activeTab === "tab4" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("tab4")}
                              >
                                <i class="fa-solid me-2 fa-clock-rotate-left"></i>
                                History
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-sm-12">
                  {activeTab === "tab1" && (
                    <RequestAppointment screenName="requests" />
                  )}
                  {activeTab === "tab2" && (
                    <ScheduleAppointment screenName="scheduled" />
                  )}
                  {activeTab === "tab3" && (
                    <CompletedAppointment screenName="completed" />
                  )}
                  {activeTab === "tab4" && (
                    <HistoryAppointment screenName="history" />
                  )}
                  
                </div>
              </div>

              {/* <div
                className="right-sidebar shadow"
                style={{
                  width: `${divWidth}rem`,
                  transition: "width 0.6s ease",
                }}
              >
                <RightSidebar handleClose={handleClose} />
              </div> */}
            </section>
          </div>

          <ProviderFooter />
        </div>

        {/* ---Accept-Request-Modal- */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog"
          PaperProps={{
            style: customContentStyle, // Apply custom styles using PaperProps
          }}
        >
          <DialogContent className="d-flex justify-content-center align-items-center">
            <DialogContentText id="alert-dialog">
              <Typography variant="h5" className="text-center" component="h4">
                Are you sure you want to Accept the Request
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>No</Button>
            <Button onClick={handleCloseDialog} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* ---Reject-Request-Modal- */}
        <Dialog
          open={open1}
          onClose={handleClose}
          aria-describedby="alert-dialog"
          PaperProps={{
            style: customContentStyle, // Apply custom styles using PaperProps
          }}
        >
          <DialogContent className="d-flex justify-content-center align-items-center">
            <DialogContentText id="alert-dialog">
              <Typography variant="h5" className="text-center" component="h4">
                Are you sure you want to Reject the Request
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog1}>No</Button>
            <Button onClick={handleCloseDialog1} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
