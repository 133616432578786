import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NavigationBar from "../../components/NavigationCont/NaviagtionBar";
import ProviderFooter from "../../components/ProviderFooter/ProviderFooter";
import ProviderNav from "../../components/ProviderNav/ProviderNav";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./ProviderHomepage.scss";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

// --chips--

import { styled } from "@mui/material/styles";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";

import { useSelector } from "react-redux";
import {
  addDentistService,
  deleteDentistProfile,
  editDentistProfile,
  errorNotification,
  getDentistListingService,
  imageUpload,
  makeHttpRequest,
  successNotification,
} from "../../utils/httpServices/httpService";

import { useFormik } from "formik";
import * as Yup from "yup";
import EditHours from "../../components/EditHours/EditHours";
import EditProfile from "../../components/EditProfile/EditProfile";
import EditServices from "../../components/EditServices/EditServices";

import { toast } from "react-toastify";
import noUserImage from "../../Assets/noUserImage.png";
import Services from "../../components/Services/Services";
import ChildModal from "../../components/ChildComp/ChildModal";
import { convertDateToTimeString } from "../../utils/common/common";

const customContentStyle = {
  width: "30%",
  height: "200px",
  maxWidth: "none",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ProviderHomepage() {
  // ---steps---/

  const [currentStep, setCurrentStep] = useState(1);

  const [dentistSlots, setDentistSlots] = useState([]);
  console.log("🚀 ~ ProviderHomepage ~ dentistSlots:", dentistSlots);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  function handleNext() {
    setCurrentStep(currentStep + 1);
  }

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  // --modal for provider delete--

  const [open6, setOpen6] = React.useState(false);

  const [deleteProvide_id, setDeleteProvider_id] = React.useState(null);

  const handleClickOpenDialog = (id) => {
    setOpen6(true);
    console.log("delete id selected", id);
    setDeleteProvider_id(id);
  };

  const handleCloseDialog = () => {
    setOpen6(false);
    setDeleteProvider_id(null);
  };

  // --chips--

  const [chipData, setChipData] = React.useState([]);

  const [newChipLabel, setNewChipLabel] = React.useState("");

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    profile_step2.setFieldValue("services", (chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleAddChip = () => {
    if (newChipLabel.trim() !== "") {
      const newChip = {
        key: chipData.length,
        label: newChipLabel.trim(),
      };
      setChipData([...chipData, newChip]);
      profile_step2.setFieldValue("services", [...chipData, newChip]);
      setNewChipLabel("");
    }
  };

  // --Edit Profile modal--
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const [editProfile_id, setEditProfile_id] = useState(null);

  const handleOpen = (id) => {
    setOpen(true);
    setEditProfile_id(id);
  };
  const handleClose = () => {
    setOpen(false);
    setEditProfile_id(null);
  };

  // Edit-Service-

  const [open5, setOpen5] = React.useState(false);

  const [editProfileService_id, setEditProfileService_id] = useState(null);

  const handleOpen5 = (id, data) => {
    console.log("working hour editd data", data);
    setOpen5(true);
    setEditProfileService_id(id);
  };
  const handleClose5 = () => {
    setOpen5(false);
    setEditProfileService_id(null);
  };

  // Edit-Hours-

  const [selectedProviderSlots, setselectedProviderSlots] = useState([]);
  const [editProfileHours_id, seteditProfileHours_id] = useState(null);

  const [open7, setOpen7] = React.useState(false);
  const handleOpen7 = (id, data) => {
    console.log("hours edit id", id, "slots", data);
    setOpen7(true);
    setselectedProviderSlots(data);
    seteditProfileHours_id(id);
  };
  const handleClose7 = () => {
    setOpen7(false);
    seteditProfileHours_id(null);
  };

  // ---Add-modal--

  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {
    getDentistSlots();
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
    profile_step1.resetForm();
    profile_step2.resetForm();
    setCurrentStep(1);
    makeDefaultsSlotsForDentists(workingTimeSlotOfClinic);
  };

  //view-modal

  const [value3, setValue3] = React.useState(0);

  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  // --child-modal--

  const [open1, setOpen1] = React.useState(false);
  const [slotDay, setSlotDay] = React.useState("Monday");

  const handleOpen1 = (day) => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  function child_settingFunction() {}

  ///////////////////////////// Code ///////////////////////////////
  const [branchDetails, setBranchDetails] = useState();

  const state = useSelector((state) => state);
  const [branch_id, setBranch_id] = useState();
  const [workingTimeSlotOfClinic, setWorkingTimeSlotOfClinic] = useState([]);
  console.log(
    "🚀 ~ ProviderHomepage ~ workingTimeSlotOfClinic:",
    workingTimeSlotOfClinic
  );
  const [services, setServices] = React.useState([]);

  const [initialState, setPageState] = useState(
    localStorage.getItem("selected_Branch")
  );

  const [allSlots_addProvider, setAddSlots_addProvider] = useState([
    { day: "Sunday", slot: [] },
    { day: "Monday", slot: [] },
    { day: "Tuesday", slot: [] },
    { day: "Wednesday", slot: [] },
    { day: "Thursday", slot: [] },
    { day: "Friday", slot: [] },
    { day: "Saturday", slot: [] },
  ]);
  console.log(
    "🚀 ~ ProviderHomepage ~ allSlots_addProvider:",
    allSlots_addProvider
  );
  const [servicesList, setServicesList] = useState([]);
  useEffect(() => {
    if (state.getClinicBrances?.data?.data) {
      setBranchDetails(state?.getClinicBrances?.data?.data[initialState]);
      setBranch_id(state?.getClinicBrances?.data?.data[initialState]?._id);
      getProviderListById(
        state?.getClinicBrances?.data?.data[initialState]?._id
      );
      setWorkingTimeSlotOfClinic(
        state?.getClinicBrances?.data?.data[initialState]?.working_hours
      );
      setServicesList(
        state?.getClinicBrances?.data?.data[initialState]?.services
      );
      const clinicWorkingHours =
        state.getClinicBrances?.data?.data[initialState].working_hours;
      makeDefaultsSlotsForDentists(clinicWorkingHours);
      // let data = [];
      // let working_hours =
      //   state.getClinicBrances?.data?.data[initialState].working_hours;
      // data = allSlots_addProvider.filter((ele) =>
      //   working_hours.some((ele2) => ele2.day === ele.day)
      // );
      // console.log("data !!!!!!!!!", data);
      // setAddSlots_addProvider(data);
    }
  }, [initialState, state]);

  ///////////////////// Provide List (START) //////////////////////////////////

  const [providerList, setProviderList] = useState([]);

  async function getProviderListById(id) {
    console.log("id", id);
    if (id !== undefined && id !== "") {
      let obj = {
        name: "",
        branch_id: id,
        sort: -1,
        limit: pageSize,
        offset: offset,
      };
      try {
        const resp = await getDentistListingService(obj);
        console.log("resp for dentist list", resp);

        if (resp?.code === 200) {
          // const test = resp?.data?.slots?.map((el) => {
          //     return {
          //       ...el,
          //       start_time: new Date(el?.start_time)?.toLocaleString().split(", ")[1],

          //       end_time: new Date(el?.end_time)?.toLocaleString().split(", ")[1],

          //     };
          //   });

          setProviderList(resp.data); /////////// ////////////////////////////////////////////////////////////  CHANGE
          console.log("dentist listing", resp.data, "IOie", providerList);
          paginationWorking(resp.data, resp.count);
        }
      } catch (err) {
        console.log("dentist list error", err);
      }
    }
  }

  const [numberOfPages, setNumberOfPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  function paginationWorking(list, total) {
    console.log("pagination function", list, total);
    let count = total === undefined ? 0 : total;
    let numberOfPages = Math.ceil(count / pageSize);
    let arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i);
    }
    console.log("arr", arr);
    setNumberOfPages(arr);
  }

  function changingPage(i) {
    setOffset(i);
  }

  function nextPage() {
    let value = offset + 1;
    setOffset(value);
  }

  function previousPage() {
    let value = offset - 1;
    setOffset(value);
  }

  useEffect(() => {
    getProviderListById(branch_id);
  }, [offset]);

  //////////////////// Provider List (END) ////////////////////////

  /////////////////// Add Provider Profile (START) ////////////////////////

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    console.log(time);
    const hour = parseInt(time.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon1 = (start, end) => {
    console.log(start, end);

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start);
    if (st === "AM") {
      const hour = start.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start.split(":")[1]} PM`;
    }

    console.log(t1);
    const en = checkAMorPM(end);
    if (en === "AM") {
      const hour = end.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end.split(":")[1]} PM`;
    }

    const formatTime = { formatTime: `${t1}-${t2}` };
    console.log("formatTime", formatTime);
    return formatTime;
  };

  const [timeSlots_addProvider, setTimeSlots_addProvider] = React.useState([]);

  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;
  const [provider_tab_1, setProvider_tab_1] = React.useState(null);
  const [provider_tab_2, setProvider_tab_2] = React.useState(null);

  const profile_step1 = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      profile_image: "",
      experience: "",
      joining_date: "",
      designation: "",
      address: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      // email: Yup.string()
      //   .email("Please enter a valid email address")
      //   .required("Email is required."),
      profile_image: "",
      experience: Yup.string().required("Experience is required"),
      // joining_date: Yup.string().required("Joining date is required"),
      designation: Yup.string().required("Designation is required"),
      // address: Yup.string().required("Address is required"),
    }),
    onSubmit: (values) => {
      console.log("form values step 1", values);
      setProvider_tab_1(values);
      handleNext();
    },
  });

  const profile_step2 = useFormik({
    initialValues: {
      services: [],
    },
    onSubmit: (values) => {
      console.log("form values step 2", values);
      setProvider_tab_2(values);
      handleNext();
    },
  });

  const settingDay = (day) => {
    setSlotDay(day);
  };

  const providerProfilePicture = async (event) => {
    console.log("upload image data", event);
    const file = event.target.files[0];
    if (file) {
      if (
        file.type.split("/").includes("gif") ||
        file.type.split("/").includes("jpg") ||
        file.type.split("/").includes("jpeg") ||
        file.type.split("/").includes("png")
      ) {
        if (file.size <= 2000000) {
          const data = new FormData();
          data.append("path", "userProfile");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload provider profile image response", resp);
            if (resp?.code === 200) {
              successNotification("Image uplaoded Successfully");
              profile_step1.setFieldValue("profile_image", resp.path);
              // console.log("provider profile profile data", addProviderProfile)
            }
          } catch (error) {
            console.log("upload profile image error", error);
          }
        } else {
          errorNotification("Image size is greater than 2MB");
        }
      } else {
        errorNotification(
          "Please upload image in supported format (PNG,JPG,GIF)"
        );
      }
    }
  };

  function disabledWeekDay(day) {
    let working_days = structuredClone(workingTimeSlotOfClinic);

    if (working_days.filter((ele) => ele.day === day).length === 0) return true;
    else return false;
  }

  const removingSlot = (i) => {
    // e.preventDefault()
    try {
      let remain = {};
      let slots = allSlots_addProvider.filter((ele) => ele.day === slotDay)[0]
        ?.slot;
      remain = allSlots_addProvider.filter((ele) => ele.day !== slotDay)[0]
        ?.slot;
      slots.splice(i, 1);

      console.log("new slots after remove form 3", slotDay, slots);
      remain.push({
        day: slotDay,
        slot: slots,
      });
      console.log(remain);
      setAddSlots_addProvider(remain);
    } catch (e) {
      console.log("jhsdfjhsdfsdhbs", e);
    }
  };

  async function saveProviderProfile() {
    // let slots = structuredClone(allSlots_addProvider);
    // let services = structuredClone(provider_tab_2);
    // console.log("services values", services);

    // let services_values = [];
    // services.services.map((ele) => {
    //   services_values.push(ele.label);
    // });

    // let check = false;
    // console.log("slots", slots);
    // slots.forEach((slot) => {
    //   slot.slot.forEach((timeSlot) => {
    //     const { start, end } = timeSlot;

    //     if (!start || !end) {
    //       toast.error("Start and end times for slots are both required.");
    //       check = true;
    //       return;
    //     }

    //     const [startHour, startMinute] = start.split(":").map(Number);
    //     const [endHour, endMinute] = end.split(":").map(Number);

    //     const startTime = startHour * 60 + startMinute; // Convert start time to minutes
    //     const endTime = endHour * 60 + endMinute; // Convert end time to minutes

    //     if (startTime >= endTime) {
    //       toast.error(
    //         "Invalid practice hours. Start time must be before end time."
    //       );
    //       check = true;
    //       return;
    //     }

    //     // Convert the slot times to ISO strings
    //     const startInputTime = new Date("2023-01-01");
    //     const endInputTime = new Date("2023-01-01");

    //     startInputTime.setHours(startHour, startMinute, 0, 0);
    //     endInputTime.setHours(endHour, endMinute, 0, 0);

    //     timeSlot.start = startInputTime.toISOString();
    //     timeSlot.end = endInputTime.toISOString();
    //   });
    // });

    // if (check) return;

    const slots = dentistSlots

    const { first_name, ...rest } = provider_tab_1;
    const corrected_first_name = first_name.startsWith("Dr.")
      ? first_name
      : `Dr. ${first_name}`;

    const corrected_provider_tab_1 = {
      ...rest,
      first_name: corrected_first_name,
    };

    const data = {
      branch_id: branch_id,
      ...corrected_provider_tab_1,
      services: services,
      slots: [...slots],
    };
    console.log("provider profile add data", data);
    try {
      const resp = await addDentistService(data);
      console.log(" provider profile response", resp);
      if (resp?.code === 200) {
        successNotification("Profile Added Successfully");
        getProviderListById(branch_id);
        handleClose2();
      }
    } catch (err) {
      // alert(err);
    }
  }

  ////////////////// Add Provider Profile (END) //////////////////////////

  const updatePageState = (index) => {
    setPageState(index);
    localStorage.setItem("selected_Branch", index);
  };

  ///////////////// Edit Provider Profile (START ) ///////////////////////

  async function changeProviderStatus(event, id) {
    let status = event.target.checked;
    let obj = {
      status: status === true ? "active" : "deactive",
    };
    try {
      const resp = await editDentistProfile(obj, id);
      console.log(" provider profile response", resp);
      if (resp?.code === 200) {
        successNotification("Status Changed Successfully");
        getProviderListById(branch_id);
        handleCloseDialog();
      }
    } catch (err) {
      // alert(err);
    }
  }

  ///////////////// Edit Provider Profile (END) ////////////////////////

  ///////////////// DELETE Provider Profile (START) ////////////////////

  async function deleteProviderProfile() {
    try {
      const resp = await deleteDentistProfile(deleteProvide_id);
      console.log("provider profile delete response", resp);
      if (resp?.code === 200) {
        successNotification("Dentist Profile Deleted Successfully");
        getProviderListById(branch_id);
        handleCloseDialog()
      }
    } catch (err) {
      // alert(err);
    }
  }

  ///////////////// Delete Provder Profile (END) ///////////////////////
  ///////////////////////////// Code Finish ////////////////////////
  const [getServicesData, setGetServicesData] = useState([]);

  const [serData, setSerData] = useState("");
  const [val, setVal] = React.useState("");

  console.log("services", services);
  const getServices = (services) => {
    setServices(services);
  };

  const serviceHandler = (e) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    console.log("gdvjhadvashjhasdahdfh", val);
    setSerData(e.target.value);
    setVal(e.target.value);
  };

  function getClinicTimeByDay() {
    const clinicSlot = workingTimeSlotOfClinic.find(
      (slot) => slot.day === slotDay
    );

    if (clinicSlot) {
      const startTime = new Date(clinicSlot.start_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const endTime = new Date(clinicSlot.end_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      return `Clinic Time : ${startTime} - ${endTime}`;
    } else {
      return "Clinic is not open this day.";
    }
  }

  const getHourlySlotArray = useCallback((startTimeString, endTimeString) => {
    const slots = [];

    const startTime = new Date(startTimeString);
    const endTime = new Date(endTimeString);

    let currentTime = new Date(startTime);
    while (currentTime.getTime() + 3600000 <= endTime.getTime()) {
      // 3600000 milliseconds = 1 hour

      const hour = currentTime.getHours();
      const minute = currentTime.getMinutes();

      // Format hour and minute strings with leading zeros
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const formattedMinute = minute < 10 ? `0${minute}` : minute;

      const nextHour = hour + 1 < 10 ? `0${hour + 1}` : `${hour + 1}`;

      const slot = {
        start: `${formattedHour}:${formattedMinute}`,
        end: `${nextHour}:${formattedMinute}`,
      };

      slots.push(slot);

      // Move to the next hour
      currentTime.setHours(hour + 1);
    }

    return slots;
  }, []);

  const makeDefaultsSlotsForDentists = useCallback((clinicSlots) => {
    if (clinicSlots?.length === 0) {
      console.log("clinic has no working day");
      return;
    }
    let dentistSlots = [];
    clinicSlots?.map((workDay) => {
      const day = workDay.day;
      const slotsArray = getHourlySlotArray(
        workDay?.start_time,
        workDay?.end_time
      );

      const slotsOnWorkDat = {
        day: day,
        slot: slotsArray,
      };
      dentistSlots.push(slotsOnWorkDat);
    });

    setAddSlots_addProvider(dentistSlots);
  }, []);

  const existingSlotExistsForDay = (day) => {
    const filteredSlot = allSlots_addProvider?.find(
      (slots) => slots?.day === day
    );
    if (filteredSlot?.slot?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getDentistSlots = async () => {
    try {
      const resp = await makeHttpRequest(
        "get",
        `users/branch-slots/${branch_id}`
      );
      setDentistSlots(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSlot = (dayIndex, slotIndex) => {
    console.log({ dayIndex, slotIndex });
    setDentistSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[dayIndex].slot[slotIndex] = {
        ...updatedSlots[dayIndex].slot[slotIndex],
        enable: !updatedSlots[dayIndex].slot[slotIndex].enable,
      };
      return updatedSlots;
    });
  };

  return (
    <>
      <ProviderNav />
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar
            initialState={initialState}
            triggerParentUpdate={updatePageState}
          />
        </div>
        <div className="fullWidhtWrap">
          <div className="doctorWrap">
            <section className="">
              <div className="container">
                <div className="navigationBarWrapper">
                  <NavigationBar />
                </div>
                <div
                  className="content-inner pb-0 px-0 container-fluid"
                  id="providers-list"
                >
                  <BreadCrumb Navitem={"Dentist List"} />
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card border-0 shadow">
                        <div className="card-header">
                          <div className="row align-items-center gy-3">
                            <div className="col-md-4 col-lg-6 text-md-start">
                              <h5 className="txt-heading mb-2">
                                Dentists thats works at{" "}
                                <span className="bName">
                                  {branchDetails?.full_name &&
                                  branchDetails?.full_name !== "" ? (
                                    branchDetails?.full_name
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </h5>
                              <h6 className="mb-0">
                                {providerList.length} Dentists
                              </h6>
                            </div>
                            <div className="col-md-8 col-lg-6 text-md-end">
                              <div className="">
                                {/* Nav tabs */}
                                <ul
                                  id="appointment-table-tab"
                                  className="nav nav-tabs d-inline-flex align-items-center gap-3 flex-wrap mb-0 px-0"
                                  role="tablist"
                                >
                                  <li>
                                    <button
                                      onClick={handleOpen2}
                                      className="theme_btn rounded "
                                    >
                                      <span className="btn-inner">
                                        <span className=" d-inline-block align-middle">
                                          Add Dentists
                                        </span>
                                        <span className="icon d-inline-block align-middle ms-1 ps-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={10}
                                            height={10}
                                            viewBox="0 0 8 8"
                                            fill="#fff"
                                          >
                                            <path
                                              d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </span>
                                      </span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-4">
                          {/* Nav tabs content */}
                          <div className="table-responsive DentistTabel">
                            <table className="table border-end border-start align-middle rounded">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">No.</th>
                                  <th scope="col">Profile Image</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Appointment Request</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {providerList.map((ele, i) => (
                                  <tr data-item="list">
                                    <td>{i + 1}</td>
                                    <td>
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={
                                            ele?.profile_image &&
                                            ele?.profile_image !== ""
                                              ? profile_image_base_url +
                                                ele?.profile_image
                                              : noUserImage
                                          }
                                          className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                          alt="icon"
                                        />
                                        {/* <h6 className="mb-0">
                                          {ele?.full_name
                                            ? ele?.full_name
                                            : "--"}
                                        </h6> */}
                                      </div>
                                    </td>

                                    <td>
                                      {" "}
                                      <h6 className="mb-0">
                                        {ele?.full_name ? ele?.full_name : "--"}
                                      </h6>
                                    </td>

                                    <td>
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          checked={
                                            ele?.status === "active"
                                              ? true
                                              : false
                                          }
                                          type="checkbox"
                                          id="flexSwitchCheckChecked"
                                          onClick={(e) =>
                                            changeProviderStatus(e, ele?._id)
                                          }
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexSwitchCheckChecked"
                                        >
                                          {ele?.status === "active"
                                            ? "Active"
                                            : "Deactive"}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        className="d-inline-block theme-outline-sm  me-2"
                                        onClick={() => handleOpen(ele?._id)}
                                      >
                                        Edit Profile
                                      </Link>
                                      <Link
                                        className="d-inline-block theme-outline-sm me-2"
                                        onClick={() =>
                                          handleOpen7(ele?._id, ele?.slots)
                                        }
                                      >
                                        Edit Hours
                                      </Link>
                                      <Link
                                        className="d-inline-block theme-outline-sm me-2 "
                                        onClick={() => handleOpen5(ele?._id)}
                                      >
                                        Edit Services
                                      </Link>
                                      <Link
                                        onClick={() =>
                                          handleClickOpenDialog(ele?._id)
                                        }
                                        className="d-inline-block  btn btn-sm btn-danger ps-2 delete-btn"
                                      >
                                        Delete
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {providerList.length === 0 ? (
                              <div className="text-center font-weight-bold">
                                *No Data to be found
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="card-footer pt-2">
                          <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
                            <ul className="page-number m-0 p-0 list-unstyled d-flex gap-2">
                              {/* <li className="text-center theme_btn px-3 py-2 text-white rounded">1</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">2</li>
                                                            <li className="text-center text-dark rounded">...</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">6</li> */}
                              {numberOfPages.map((ele, i) => (
                                <li
                                  className={
                                    offset === i
                                      ? "text-center theme_btn px-3 py-2 text-white rounded"
                                      : "text-center bg-soft-primary text-dark rounded"
                                  }
                                  onClick={() => changingPage(i)}
                                >
                                  {i + 1}
                                </li>
                              ))}
                            </ul>
                            <div>
                              {offset !== 0 ? (
                                <button
                                  onClick={() => previousPage()}
                                  className="theme-outline-btn rounded me-3"
                                  type="button"
                                >
                                  <span className="btn-inner">
                                    <span className="text d-inline-block align-middle">
                                      Previous
                                    </span>
                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={8}
                                        height={8}
                                        viewBox="0 0 8 8"
                                        fill="#fff"
                                      >
                                        <path
                                          d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                          fill="#fff"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {offset < numberOfPages.length ? (
                                <button
                                  onClick={() => nextPage()}
                                  className="theme_btn rounded me-3"
                                  type="button"
                                >
                                  <span className="btn-inner">
                                    <span className="text d-inline-block align-middle">
                                      Next
                                    </span>
                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={8}
                                        height={8}
                                        viewBox="0 0 8 8"
                                        fill="#fff"
                                      >
                                        <path
                                          d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                          fill="#fff"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ProviderFooter />
        </div>

        {/* Edit ---modal-- */}

        <EditProfile
          open={open}
          handleClose={handleClose}
          editProfile_id={editProfile_id}
          getProviderListById={getProviderListById}
          branch_id={branch_id}
        />
        <EditServices
          open5={open5}
          handleClose5={handleClose5}
          editProfile_id={editProfileService_id}
          servicesList={servicesList}
          key={editProfileService_id}
        />
        <EditHours
          open7={open7}
          handleClose7={handleClose7}
          editProfile_id={editProfileHours_id}
          selectedProviderSlots={selectedProviderSlots}
          clinicWorkingHoursSlots={workingTimeSlotOfClinic}
        />

        {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>

                        <Box className="tabpanel" >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Edit Profile" />
                                <Tab label="Edit Hours" />
                                <Tab label="Edit Services" />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <div className='provider_main'>
                                    <div className="Profile_cntainer">
                                        <div className='profile-header'>
                                            <div className="profile-img">
                                                <img src={doctor01} alt="doctor" />
                                            </div>
                                            <label htmlFor="uploadImage" className='btn btn-sm btn-primary rounded-pill'>
                                                <i class="fa-solid fa-pencil"></i></label>
                                            <input type="file" name="" id="uploadImage" />
                                        </div>
                                        <div className="profile-body mt-4">
                                                <div class="row">
                                                    <div class="col-6 mb-2">
                                                        <input type="text" class="form-control" placeholder="First name" aria-label="First name" 
                                                        name='first_name'
                                                        value={addProviderProfile.first_name}
                                                        onChange={settingAddProviderProfileValues}
                                                        onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                      {addProviderProfileValidation.first_name ? (<div className="validation_message">*First name required</div>):(<></>)}
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <input type="text" class="form-control" placeholder="Last name" aria-label="Last name" 
                                                         name='last_name'
                                                         value={addProviderProfile.last_name}
                                                         onChange={settingAddProviderProfileValues}
                                                         onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                       {addProviderProfileValidation.last_name ? (<div className="validation_message">*Last name required</div>):(<></>)}
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <input type="text" class="form-control" placeholder="Email " aria-label="Email" 
                                                         name='email'
                                                         value={addProviderProfile.email}
                                                         onChange={settingAddProviderProfileValues}
                                                         onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                       {addProviderProfileValidation.email ? (<div className="validation_message">*Enter valid email address</div>):(<></>)}
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <input type="text" class="form-control" placeholder="Experience" aria-label="Experience" />
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <input type="text" class="form-control" placeholder="Designation" aria-label="Designation"  
                                                         name='designation'
                                                         value={addProviderProfile.designation}
                                                         onChange={settingAddProviderProfileValues}
                                                         onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                       {addProviderProfileValidation.designation ? (<div className="validation_message">*Designation required</div>):(<></>)}
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <input type="date" class="form-control" placeholder="select date" aria-label="Designation"
                                                         name='joining_date'
                                                         value={addProviderProfile.joining_date}
                                                         onChange={settingAddProviderProfileValues}
                                                         onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                       {addProviderProfileValidation.joining_date ? (<div className="validation_message">*Joining date required</div>):(<></>)}
                                                  
                                                    </div>
                                                    <div class="col-12 mb-2">
                                                        <input type="text" class="form-control" placeholder="Location" aria-label="location" 
                                                         name='address'
                                                         value={addProviderProfile.address}
                                                         onChange={settingAddProviderProfileValues}
                                                         onBlur={(e) => addProviderProfileValidatorCheck(e.target.name)}/>
                                                       {addProviderProfileValidation.address ? (<div className="validation_message">*Address required</div>):(<></>)}
                                                  
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="card schedule-widget mb-0">
                                    <div className="schedule-header">
                                        <div className="schedule-nav">
                                            <ul className="nav nav-tabs nav-justified gap-2" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_sunday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Sunday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link active"
                                                        data-bs-toggle="tab"
                                                        href="#slot_monday"
                                                        aria-selected="true"
                                                        role="tab"
                                                    >
                                                        Monday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_tuesday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Tuesday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_wednesday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Wednesday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_thursday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Thursday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_friday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Friday
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        href="#slot_saturday"
                                                        aria-selected="false"
                                                        tabIndex={-1}
                                                        role="tab"
                                                    >
                                                        Saturday
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-content schedule-cont">
                                        <div id="slot_sunday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between align-items-center">
                                                <span>Time Slots</span>
                                                <Link className="edit-link" >
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </Link>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                        <div id="slot_monday" className="tab-pane fade show active" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between align-items-center">
                                                <span>Time Slots</span>
                                                <button className="theme-outline-btn" onClick={handleOpen1} >
                                                    <i className="fa fa-edit me-1" />
                                                    Edit
                                                </button>
                                            </h4>
                                            <div className="doc-times">
                                                <div className="doc-slot-list" style={{ display: "none" }}>
                                                    8:00 pm - 11:30 pm
                                                    <Link className="delete_schedule">
                                                        <i className="fa fa-times" />
                                                    </Link>
                                                </div>
                                                <div className="doc-slot-list">
                                                    11:30 pm - 1:30 pm
                                                    <Link className="delete_schedule">
                                                        <i className="fa fa-times" />
                                                    </Link>
                                                </div>
                                                <div className="doc-slot-list">
                                                    3:00 pm - 5:00 pm
                                                    <Link className="delete_schedule">
                                                        <i className="fa fa-times" />
                                                    </Link>
                                                </div>
                                                <div className="doc-slot-list">
                                                    6:00 pm - 11:00 pm
                                                    <Link className="delete_schedule">
                                                        <i className="fa fa-times" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="slot_tuesday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between">
                                                <span>Time Slots</span>
                                                <a className="edit-link" data-bs-toggle="modal" href="#add_time_slot">
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </a>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                        <div id="slot_wednesday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between">
                                                <span>Time Slots</span>
                                                <a className="edit-link" data-bs-toggle="modal" href="#add_time_slot">
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </a>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                        <div id="slot_thursday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between">
                                                <span>Time Slots</span>
                                                <a className="edit-link" data-bs-toggle="modal" href="#add_time_slot">
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </a>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                        <div id="slot_friday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between">
                                                <span>Time Slots</span>
                                                <a className="edit-link" data-bs-toggle="modal" href="#add_time_slot">
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </a>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                        <div id="slot_saturday" className="tab-pane fade" role="tabpanel">
                                            <h4 className="card-title d-flex justify-content-between">
                                                <span>Time Slots</span>
                                                <a className="edit-link" data-bs-toggle="modal" href="#add_time_slot">
                                                    <i className="fa fa-plus-circle" /> Add Slot
                                                </a>
                                            </h4>
                                            <p className="text-muted mb-0">Not Available</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            p: 2,
                                            m: 0,
                                        }}
                                        component="ul"
                                    >
                                        {chipData.map((data) => {
                                            let icon;

                                            if (data.label === 'React') {
                                                icon = <TagFacesIcon />;
                                            }

                                            return (
                                                <ListItem key={data.key}>
                                                    <Chip

                                                        icon={icon}
                                                        label={data.label}
                                                        onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </Paper>
                                    <div className='mt-3 d-flex justify-content-center align-items-center'>
                                        <TextField
                                            label="Add New Service"
                                            variant="outlined"
                                            value={newChipLabel}
                                            onChange={(e) => setNewChipLabel(e.target.value)}
                                            sx={{ marginRight: 2 }}
                                        />
                                        <button className='btn btn-secondary px-3 rounded py-3' onClick={handleAddChip}>
                                            Add
                                        </button>
                                    </div>

                                </div>
                            </TabPanel>

                        </Box>
                        <ChildModal open1={open1} handleClose1={handleClose1} />
                        <div className='text-center '>
                            <button onClose={handleClose} onSubmit={() => saveProviderProfile()} className='theme_btn rounded'>Save</button>
                        </div>
                    </Box>


                </Modal> */}

        {/* --Ask for Delete Modal-- */}

        {/* ---Accept-Request-Modal- */}
        <Dialog
          open={open6}
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog"
          PaperProps={{
            style: customContentStyle, // Apply custom styles using PaperProps
          }}
        >
          <DialogContent className="d-flex justify-content-center align-items-center">
            <DialogContentText id="alert-dialog">
              <Typography variant="h5" className="text-center" component="h4">
                Are you sure you want to Delete this Dentist
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDialog}>
              No
            </Button>
            <Button variant="outlined" onClick={() => deleteProviderProfile()}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add ---modal-- */}

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <Box>
            <Box className="tabpanel modal-content">
              {currentStep === 1 && (
                <div className="provider_main">
                  <div className="Profile_cntainer">
                    <div className="profile-header">
                      <h5 className="fw-bold mb-2">Add Profile</h5>
                      <div className="profile-img">
                        <img
                          src={
                            profile_image_base_url +
                            profile_step1.values.profile_image
                          }
                          alt=""
                        />
                      </div>
                      <label
                        htmlFor="uploadImage"
                        className="btn btn-sm btn-primary rounded-pill"
                      >
                        <i class="fa-solid fa-cloud-arrow-up"></i>
                      </label>
                      <input
                        type="file"
                        name=""
                        id="uploadImage"
                        onChange={providerProfilePicture}
                      />
                    </div>
                    <div className="profile-body mt-4">
                      <form onSubmit={profile_step1.handleSubmit}>
                        <div class="row">
                          <div class="col-6 mb-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First name"
                              aria-label="First name"
                              id="first_name"
                              name="first_name"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.first_name}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.first_name &&
                            profile_step1.errors.first_name ? (
                              <p className="validation_message">
                                {profile_step1.errors.first_name}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="col-6 mb-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Last name"
                              aria-label="Last name"
                              id="last_name"
                              name="last_name"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.last_name}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.last_name &&
                            profile_step1.errors.last_name ? (
                              <p className="validation_message">
                                {profile_step1.errors.last_name}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="col-6 mb-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Email "
                              aria-label="Email"
                              id="email"
                              name="email"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.email}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.email &&
                            profile_step1.errors.email ? (
                              <p className="validation_message">
                                {profile_step1.errors.email}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="col-6 mb-2">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Experience in years"
                              aria-label="Experience"
                              id="experience"
                              name="experience"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.experience}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.experience &&
                            profile_step1.errors.experience ? (
                              <p className="validation_message">
                                {profile_step1.errors.experience}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="col-6 mb-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Designation"
                              aria-label="Designation"
                              id="designation"
                              name="designation"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.designation}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.designation &&
                            profile_step1.errors.designation ? (
                              <p className="validation_message">
                                {profile_step1.errors.designation}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div class="col-6 mb-2">
                            <input
                              type="date"
                              class="form-control"
                              placeholder="joining date"
                              aria-label="Designation"
                              id="joining_date"
                              name="joining_date"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.joining_date}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.joining_date &&
                            profile_step1.errors.joining_date ? (
                              <p className="validation_message">
                                {profile_step1.errors.joining_date}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          {/* <div class="col-12 mb-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Location"
                              aria-label="location"
                              id="address"
                              name="address"
                              onChange={profile_step1.handleChange}
                              value={profile_step1.values.address}
                              onBlur={profile_step1.handleBlur}
                            />
                            {profile_step1.touched.address &&
                            profile_step1.errors.address ? (
                              <p className="validation_message">
                                {profile_step1.errors.address}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div> */}
                        </div>
                        <button type="submit" className="theme_btn rounded">
                          Next
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="add-service-cntner">
                  <div className="service_input">
                    <Services
                      setServicesToParent={getServices}
                      serData={serData}
                      getServicesData={getServicesData}
                    />
                  </div>

                  <div className="select_cntner">
                    <Select
                      onChange={serviceHandler}
                      value={val}
                      variant="outlined"
                      displayEmpty
                      style={{ marginTop: "10px", width: "100%" }}
                    >
                      <MenuItem value="" disabled>
                        Select Services
                      </MenuItem>
                      {servicesList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  {/* <h5 className="fw-bold mb-4">Add Services</h5>
                  <Paper
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 2,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {chipData.map((data) => {
                      let icon;

                      if (data.label === "React") {
                        icon = <TagFacesIcon />;
                      }

                      return (
                        <ListItem key={data.key}>
                          <Chip
                            icon={icon}
                            label={data.label}
                            onDelete={
                              data.label === "React"
                                ? undefined
                                : handleDelete(data)
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <TextField
                      label="Add New Service"
                      variant="outlined"
                      value={newChipLabel}
                      onChange={(e) => setNewChipLabel(e.target.value)}
                      sx={{ marginRight: 2 }}
                    />
                    <button
                      className="btn btn-secondary px-3 rounded py-3"
                      onClick={handleAddChip}
                    >
                      Add
                    </button>
                  </div> */}
                  <div className="d-flex justify-content-end align-items-end mt-4">
                    <button
                      className="theme_btn rounded me-2"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="theme_btn rounded"
                      onClick={() => {
                        profile_step2.handleSubmit();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="select_time">
                  <h5 className="fw-bold mb-2">Add Work Hours</h5>
                  <div className="card schedule-widget mb-0">
                    <div className="schedule-header">
                      <div className="schedule-nav">
                        <ul
                          className="nav nav-tabs nav-justified gap-2"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#slot_Monday"
                              aria-selected="true"
                              role="tab"
                              onClick={() => settingDay("Monday")}
                            >
                              Monday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Tuesday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Tuesday")}
                            >
                              Tuesday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Wednesday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Wednesday")}
                            >
                              Wednesday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Thursday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Thursday")}
                            >
                              Thursday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Friday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Friday")}
                            >
                              Friday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Saturday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Saturday")}
                            >
                              Saturday
                            </a>
                          </li>

                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#slot_Sunday"
                              aria-selected="false"
                              tabIndex={-1}
                              role="tab"
                              onClick={() => settingDay("Sunday")}
                            >
                              Sunday
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="tab-content schedule-cont">
                      {daysOfWeek.map((day, dayIndex) => {
                        const obj = dentistSlots.find(
                          (slot) => slot.day === day
                        );
                        const objIndex = dentistSlots.findIndex((slot) => slot.day === day);

                        return (
                          <div
                            className={`slotsOverflow tab-pane fade show ${
                              day === "Monday" ? "active" : ""
                            }`}
                            role="tabpanel"
                            id={`slot_${day}`}
                          >
                            <div className="slotTimeContainer">
                              {obj ? (
                                obj.slot.map((slot, slotIndex) => (
                                  <div className="time-slots" key={slotIndex}>
                                    <input type="checkbox"  checked={slot.enable}
                                      id={`time-slot-${dayIndex}-${slotIndex}`} 

                                      />
                                    <label
                                      htmlFor={`time-slot-${dayIndex}-${slotIndex}`}
                                      onClick={() =>
                                        toggleSlot(objIndex, slotIndex)
                                      }
                                    >
                                      {convertDateToTimeString(slot)}
                                    </label>
                                  </div>
                                ))
                              ) : (
                                <p>No slots available for {day}.</p>
                              )}

                              {/* <div className="time-slots">
                      <input type="checkbox" id="slot-time-2" />
                      <label htmlFor="slot-time-2">9:00 AM to 10:00 AM</label>
                    </div> */}
                            </div>
                          </div>
                        );
                      })}

                      {/* <div
                        id="slot_monday"
                        className="tab-pane fade show active"
                        role="tabpanel"
                      >
                        <h4 className="card-title d-flex justify-content-between align-items-center">
                          <span>Time Slots</span>
                          <button
                            onClick={() => handleOpen1("Monday")}
                            className="theme-outline-btn"
                            disabled={disabledWeekDay("Monday")}
                          >
                            <i className="fa me-2 fa-plus-circle" />
                            {existingSlotExistsForDay("Monday") ? "Edit Slot" : "Add Slot"}

                          </button>
                        </h4>
                        <div className="doc-times">
                          {allSlots_addProvider
                            .filter((ele) => ele.day === slotDay)[0]
                            ?.slot.map((ele, i) => (
                              <div className="time-slot-list-cntner">
                                <div className="doc-slot-list">

                                 
                                  {ele.start} - {ele.end}
                               
                                </div>
                              </div>
                            ))}
                          
                        </div>
                        {allSlots_addProvider.filter(
                          (ele) => ele.day === slotDay
                        )[0]?.slot.length === 0 ? (
                          <p className="text-muted mb-0">No Slot</p>
                        ) : (
                          <></>
                        )}
                       
                      </div> */}

                      {/* <p className="ps-2">
                        <strong>{getClinicTimeByDay()}</strong>
                      </p> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-end mt-4">
                    <button
                      className="theme_btn rounded me-2"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => saveProviderProfile()}
                      className="theme_btn rounded"
                    >
                      Finish
                    </button>
                  </div>
                </div>
              )}
            </Box>

            <ChildModal
              open1={open1}
              handleClose1={handleClose1}
              slotDay={slotDay}
              slots={allSlots_addProvider}
              setAddSlots_addProvider={setAddSlots_addProvider}
              setOpen1={setOpen1}
              workingTimeSlotOfClinic={workingTimeSlotOfClinic}
            />
            {/* <div className='d-flex justify-content-end align-items-end mt-4'>
                            {currentStep > 1 && (
                                <button className='theme_btn rounded me-2' onClick={handleBack}>
                                    Back
                                </button>
                            )}
                            {currentStep <= 2 ? (
                                <button type="button" className='theme_btn rounded' onClick={() => { ;  handleNext()}} >
                                    Next
                                </button>
                            ) : (
                                <button onClick={handleClose2} className='theme_btn rounded' >
                                    Finish
                                </button>
                            )}
                        </div> */}
          </Box>
        </Modal>

        {/* view-Modal  */}

        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="provider_profile">
              <div className="card mb-3 border-0" style={{ width: "100%" }}>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="doctorCard">
                      <img
                        src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
                        alt="Doctor Profile Image"
                      />
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Name of Doctor
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="Dr. Pankaj Tripathi"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Designation
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="Dentist"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Experience
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="5 Years"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Joining Date
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="23/05/2017"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            No. of Confirmed Appointments
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="200"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
