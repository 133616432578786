import React from "react";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import doctor from "../../../Assets/doctor.png"
import Sidebar from "../../../components/Sidebar/Sidebar";


export default function DoctorList() {
  return (
    <div className="layoutWrap">
      <div className="shadow sideWrap" id="myDIV">
        <Sidebar />
      </div>
      <div className="fullWidhtWrap">
        <ProviderNav />
        <div className="doctorWrap">
          <section className="">
            <div className="container">
              <NavigationBar />
              <h3>Doctor List</h3>
              <div className="docPageAdd">
                <Button class="theme_btn">Add Doctor</Button>
                <div className="form-group">
                  <label htmlFor="" className="mb-2">Filter by Exp</label>
                  <select name="" className="form-select" id="">
                    <option value="">1-2 Years</option>
                    <option value="">3-4 Years</option>
                    <option value="">5-6 Years</option>
                    <option value="">7-8 Years</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="doctorCard">
                    <EditIcon class="editIc" />
                    <div className="listSwitch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div className="docImg">
                      <img src={doctor} alt="Doctor Profile Image" />
                    </div>
                    <div className="docInfo">
                      <h3>Dr. Vikram Parihar</h3>
                      <h5 className="designation">Dentist</h5>
                      <span className="exp">4 Years</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ProviderFooter />
      </div>
    </div>
  );
}
