import { configureStore } from '@reduxjs/toolkit';
import getClinicBranchesReducer from './slices/getting_clinic_branches/index';
import selectedBranchReducer from './slices/selected_clinic_Branch/index';
import authReducer from './slices/auth/index';
import loaderReducer from './slices/loader/index'

export const store = configureStore({
    reducer: {
        getClinicBrances:getClinicBranchesReducer,
        selectedBranchIndex:selectedBranchReducer,
        auth: authReducer,
        loader: loaderReducer,
    },
  })