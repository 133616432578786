import React, { useEffect, useState } from 'react'
import companyLogo from '../../Assets/Logos/company-logo.png'
import { Link } from 'react-router-dom'
import "./Header.scss"






export default function Header({showbackToMatches=true}) {

    const [token, setToken] = useState(null);
    const [userDetails, setUserDetails] = useState({})
    // const [scrolled, setScrolled] = useState(false);

    // useEffect(() => {
    //     Add a scroll event listener
    //     window.addEventListener('scroll', handleScroll);

    //     Clean up the event listener on component unmount
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    // const handleScroll = () => {

    //     let navdefault = document.getElementById('defaultNavbar')
    //     let navabrOnscroll = document.getElementById('navOnscroll')

    //     // Check the scroll position and update the 'scrolled' state
    //     if (window.scrollY > 400) {
    //         setScrolled(true);
    //         navdefault.style.display = "none"
    //         navabrOnscroll.style.display = "block"
    //     } else {
    //         setScrolled(false);
    //         navdefault.style.display = "block"
    //         navabrOnscroll.style.display = "none"


    //     }
    // };

    useEffect(()=>{
        let token = localStorage.getItem('token')
        if(token !== null){
            let user = localStorage.getItem('user_details');
            if(user !== null){
                setToken(token);
                let userdetails = JSON.parse(user)
                console.log(userdetails)
                setUserDetails(userdetails)
            }
        }
    },[])

    return (
        <>
            <nav id="defaultNavbar7" className='navbar search-header shadow-sm  navbar-light bg-light fixed-top'>
                <div className="container ">
                    <Link className="navbar-brand text-black" to="/">
                        <img src={companyLogo} alt="" width="30" height="24" class="d-inline-block align-text-top" />
                    </Link>
                    {
                        showbackToMatches &&
                        <Link className='helpicon' to={`${token !== null && userDetails && userDetails?.user_type === 'user'?'/dentist-list':'/dentist-lists'}`}>
                        <span className='text-muted d-flex align-items-center '><i class="fa-solid fa-list"></i> <span className='d-sm-block d-none ms-3'> Back to Matches</span></span>
                    </Link>
                    }
                    
                </div>
            </nav>

        </>
    )
}
