// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNZH70Dvo7KCKFm-BNxPmXlhjY39Sffg8",
  authDomain: "dentistfinder-dev.firebaseapp.com",
  projectId: "dentistfinder-dev",
  storageBucket: "dentistfinder-dev.appspot.com",
  messagingSenderId: "665037390972",
  appId: "1:665037390972:web:7d885e1420a5ff227275f9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);