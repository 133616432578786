import React, { useState, useEffect, useCallback } from "react";
import FindDentist1 from "../../Assets/find-dentist.png";
import dottedImage from "../../Assets/dotted-image.png";
import Footer from "../../components/Footer/Footer";
import "./FindDentist.scss";
import Navbar from "../../components/Nav/Navbar";

import {
  getDentistContentService,
  sendQuery,
} from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";

export default function FindDentist() {
  const findDentist_base_url = process.env.REACT_APP_CMS;
  const [state, setState] = useState({
    practic_name: "",
    email: "",
    number: "",
  });
  const [dentistContent, setDentistContent] = useState({
    first_section: "",
    second_section: "",
    card_section: [],
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!state.practic_name.trim()) {
      newErrors.practic_name = "Practice name is required";
    }
    if (!state.number.trim()) {
      newErrors.number = "Phone number is required";
    }
    if (!state.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(state.email)) {
      newErrors.email = "Please enter a valid email";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const response=await sendQuery(state)
      if(response.status === 200) {
        toast.success("Form submitted successfully. We'll be in touch shortly.")
      }
      setState({
        email:"",
        practic_name:"",
        number:""
      })
   
    }
  };
  const [background_image_data, set_background_image_data] = useState(
    "./Assets/home_1_1_3.jpg"
  );

  async function getDentistContent() {
    try {
      const resp = await getDentistContentService();
      console.log("dentist content resp ", resp);
      if (resp?.code === 200) {
        console.log("dentist content data", resp.data);
        setDentistContent({
          first_section: resp.data.first_section,
          second_section: resp.data.second_section,
          card_section: resp.data.card_section,
        });
        set_background_image_data(
          findDentist_base_url +
            resp.data.first_section.background_image.replace(/ /g, "%20")
        );
        console.log(
          "background image",
          findDentist_base_url +
            resp.data.first_section.background_image.replace(/ /g, "%20")
        );
      }
    } catch (err) {
      console.log(err);
      // alert(err)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0); 
    getDentistContent();

  }, []);

  return (
    <>
      <Navbar />

      <div className="page-wrap">
        {/* ----Hero---Section---- */}
        {/* style={{'background-image': `url(${background_image_data})`}} */}
        <section
          className="find-dentist-container"
          style={{ "background-image": `url(${background_image_data})` }}
        >
          <div className="container">
            <div className="find-dentist">
              <div className="patient_form">
                {/* <h2 className='patient_form__title'>New patients sent directly to <br /> your email.</h2> */}
                <h2 className="patient_form__title">
                  {dentistContent.first_section.title
                    ? dentistContent.first_section.title
                    : ""}
                </h2>
                <div className="d-flex flex-wrap gap-2 mb-4">
                  {dentistContent.first_section.keyword &&
                  dentistContent.first_section.keyword.length > 0 ? (
                    dentistContent.first_section.keyword.map((ele) => (
                      <div className="checkbox_cntner">
                        <span className="patient_form__icon">
                          <span className="fa-solid fa-circle-check"></span>
                        </span>
                        <span className="patient_form__sub-title">
                          {ele.title ? ele.title : ""}
                        </span>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  {/* <div className='checkbox_cntner'>
                                        <span className='patient_form__icon'><span className='fa-solid fa-circle-check'></span></span>
                                        <span className='patient_form__sub-title'>No contract</span>
                                    </div>
                                    <div className='checkbox_cntner'>
                                        <span className='patient_form__icon'><span className='fa-solid fa-circle-check'></span></span>
                                        <span className='patient_form__sub-title'>Easy setup</span>
                                    </div> */}
                </div>

                <div className="form mx-0 mx-sm-3">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-3 col-sm-6 p-0">
                        <div className="input-group border-end border-1">
                          <span className="form__icons">
                            <i className="fa-solid fa-stethoscope"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Practice name"
                            className="form-control"
                            name="practic_name"
                            value={state.practic_name}
                            onChange={handleChange}
                          />
                          {errors.practic_name && (
                            <div className="error" style={{ color: "red" }}>
                              {errors.practic_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 p-0">
                        <div className="input-group">
                          <span className="form__icons">
                            <i className="fa-solid fa-phone-volume"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            className="form-control"
                            name="number"
                            value={state.number}
                            onChange={handleChange}
                          />
                          {errors.number && (
                            <div className="error" style={{ color: "red" }}>
                              {errors.number}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6  p-0">
                        <div className="input-group">
                          <span className="form__icons">
                            <i className="fa-regular fa-envelope"></i>
                          </span>
                          <input
                            type="email"
                            placeholder="Enter your email"
                            className="form-control"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="error" style={{ color: "red" }}>
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 p-0">
                        <button type="submit" className="btn-white btn-two">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ----Hero---Section---End */}

        {/* ---How-it-Work---Section--- */}
        <section className="howitworks-container">
          <div className="howitworks">
            <div className="container">
              <div className="howitworks__heading_text">
                <h2 className="howitworks__title text-center">
                  {dentistContent.second_section.title
                    ? dentistContent.second_section.title
                    : ""}
                </h2>
                <p className="howitworks__heading-desc text-center">
                  {dentistContent.second_section.description
                    ? dentistContent.second_section.description
                    : ""}
                </p>
              </div>
              <div className="howitworks__boxes">
                {dentistContent.card_section &&
                dentistContent.card_section.length > 0 ? (
                  dentistContent.card_section.map((ele) => (
                    <div className="card">
                      <div className="howitworks__box animate__animated animate__fadeInUp ">
                        <h4 className="howitworks__sub_heading">
                          {ele.title ? ele.title : ""}
                        </h4>
                        <p className="howitworks__desc">
                          {ele.description ? ele.description : ""}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}

                {/* <div className='howitworks__box  animate__animated animate__fadeInUp'>
                                    <h4 className='howitworks__sub_heading'>We locate the finest patients.</h4>
                                    <p className='howitworks__desc'>Using our marketing expertise, we attract patients from high-intent places</p>
                                </div>
                                <div className='howitworks__box  animate__animated animate__fadeInUp'>
                                    <h4 className='howitworks__sub_heading'>We connect patients with your services</h4>
                                    <p className='howitworks__desc'>Motivated patients are guided through choosing the best practice for their needs</p>
                                </div>
                                <div className='howitworks__box  animate__animated animate__fadeInUp '>
                                    <h4 className='howitworks__sub_heading'>Fix your schedule</h4>
                                    <p className='howitworks__desc'>Easily communicate with patients to schedule an appointment time through our portal</p>
                                </div>
                                <div className='howitworks__box  animate__animated animate__fadeInUp'>
                                    <h4 className='howitworks__sub_heading'>Settle the bill when they appear.</h4>
                                    <p className='howitworks__desc'>We believe in a win-win situations. That's why we only charge you when you see value </p>
                                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* ---How-it-Work---Section---End */}

        <Footer />
      </div>
    </>
  );
}
