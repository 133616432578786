import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./ProviderChatBox.scss";

import { Dialog, DialogContent } from "@mui/material";
import { DatePicker } from "antd";
import chatUser01 from "../../../Assets/chatUser2.jpg";
import file_icon_image from "../../../Assets/file_icon_image.png";
import empty_img from "../../../Assets/empty-chat.svg";
import RightSidebar from "../../../components/RightSidebar/RightSidebar";

import * as socketIo from "socket.io-client";
import {
  errorNotification,
  getBranchListing,
  getPreviousChatsByRoomId,
  imageUpload,
  makeHttpRequest,
} from "../../../utils/httpServices/httpService";

import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Button } from "bootstrap";
import imgPrev from "../../../Assets/Blue-Cross.svg";
import { ResheduleApp } from "../../../components/ResheduleApp/ResheduleApp";
import AppointmentStatus from "../../../components/AppointmentStatus/AppointmentStatus";

const customContentStyle = {
  width: "70%",
  height: "500px",
  maxWidth: "none",
};

export default function ProvdiderChatBox() {
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(false);
  };

  const chat_image_base = process.env.REACT_APP_CHAT_DOC;
  const userProfile = process.env.REACT_APP_USER_PROFILE_IMAGE;

  const { RangePicker } = DatePicker;
  // --DatePicker--
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const [openChat, setOpenChat] = useState(false);

  const [open, setOpen] = React.useState(false);

  // const handleClickOpenDialog = () => {
  //   setOpen(true);
  // };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // ---Toggle-Sidebar---

  function opneSidebarOne() {
    var leftSidebar = document.querySelector("#ClinicleftSidebar");
    leftSidebar.style.width = "250px";
  }

  function closeSidebarOne() {
    var leftSidebar = document.querySelector("#ClinicleftSidebar");
    leftSidebar.style.width = "0";
  }

  const [isFirstRander, setIsFirstRander] = useState(true);

  const [appointmentDetail, setAppointmentDetail] = useState(null);

  const [showResheduleModal, setShowResheduleModal] = useState(false);

  const handleOpenResheduleModal = () => setShowResheduleModal(true);

  const handleCloseResheduleModal = () => setShowResheduleModal(false);

  const [branchList, setBranchList] = useState([]);

  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  async function branchListFunc() {
    try {
      let res = await getBranchListing();
      if (res?.code === 200) {
        setBranchList(res?.data);
        if (res?.data.length > 0) setSelectedBranch(res?.data[0]);
      }
    } catch (error) {
      console.log("branch list error in chat box page", error);
    }
  }

  useEffect(() => {
    branchListFunc();
  }, []);

  const [selectedBranch, setSelectedBranch] = useState(null);
  console.log("🚀 ~ selectedBranch:", selectedBranch);

  const selectBranch = (ele) => {
    if (selectedBranch?._id === ele._id) {
      return;
    }
    setAllChat([]);
    setRoomList([]);
    setCompleteRoomList([]);
    setRoomName(null);
    sideBarLeave(socket, selectedBranch);
    setSelectedBranch(ele);
  };

  const [roomList, setRoomList] = useState([]);

  const [completeRoomList, setCompleteRoomList] = useState([]);

  // async function getRoomListFunc(id){
  //   if(id !== undefined && id !== null){
  //   try {
  //    let res = await getRoomListBasedOnBranchId(id);
  //    if(res?.code === 200){
  //     console.log("room lsit data",res)
  //     setRoomList(res.data);
  //     if(res.data.length > 0)
  //     setRoomName(res.data[0]);
  //    }
  //   }
  //   catch(error) {
  //     console.log("error on user list based on branch",error)
  //   }
  // }
  // }

  // useEffect(() => {
  //   getRoomListFunc(selectedBranch?._id);
  //   setRoomName(null);
  // })

  useEffect(() => {
    if (socket !== null) {
      sideBarJoin(socket);
      sideBarListen(socket);
    }
  }, [selectedBranch]);

  const [roomName, setRoomName] = useState(null);

  const selectedRoom = (info) => {
    setRoomName(info);
    readAllMessage(socket, info.room_id);
    leave_roomFunc(socket);
    getAppointmentDetails(info);
  };

  //////////////////////// SOCKET IO HANDELING STARTS  //////////////////////////

  const [message, setMessage] = useState("");

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const socketURL = process.env.REACT_APP_SOCKET_URL;

    const socketVar = socketIo.connect(socketURL, {
      query: {
        token: token,
      },
    });
    setSocket(socketVar);

    ///////////////   Listening the events emitted by server

    socketVar.on("connection", () => {
      console.log("connected", socket.connected);
    });

    //   return () => {
    //     socketVar.on("disconnect", () => {
    //     console.log("socket disconnect sucessfully"); // undefined
    //   });
    // }
  }, []);

  ////////////////// SIDE BAR WORKING  START /////////////////////////////////////

  function sideBarJoin(socketVar) {
    socketVar.emit("sidebar join", selectedBranch?._id);
    console.log("joining SideBar");
  }

  function sideBarListen(socketVar) {
    socketVar.on("sidebar", (args) => {
      console.log("sideBar listen data", args);
      setRoomList(args);
      setCompleteRoomList(args);
    });
    console.log("sideBar listening !!!!");
    join_roomFunc(socketVar);
  }

  function sideBarLeave(socketVar, selectedBranchID) {
    socketVar.emit("sidebar leave", selectedBranchID?._id);
    console.log("leaving SideBar !!!!");
  }

  function readAllMessage(socketVar, roomId) {
    console.log("read all message function", {
      room_id: roomId,
      user_id: selectedBranch?._id,
    });
    socketVar.emit("seen all message", {
      room_id: roomId,
      user_id: selectedBranch?._id,
    });
    console.log("read all message of User sideBar !!!!");
  }

  ///////////////// SIDE BAR WORKING END ///////////////////////////////////

  const [allChat, setAllChat] = useState([]);
  console.log("🚀 ~ allChat:", allChat);

  function join_roomFunc(socketVar) {
    socketVar.emit("room join", { room_id: roomName?.room_id });
    console.log("room joined !!");
    getPreviousChatMessagesFunc();
    getSocket_Messages();
  }

  function leave_roomFunc(socketVar) {
    socketVar.emit("leave room ", { room_id: roomName?.room_id });
    console.log("leave room !!");
    join_roomFunc(socketVar);
  }

  function getSocket_Messages() {
    socket.off("chat_message");

    socket.on("chat_message", (args) => {
      console.log("🚀 ~ socket.on ~ args:", args);
      setAllChat((prevAllChat) => {
        let newData = structuredClone(prevAllChat);
        newData.push(args[0]);
        console.log("chat list data text message", newData);
        return newData;
      });
    });
  }

  function getSocket_mediaMessages() {
    socket.on("media message", (args) => {
      console.log("args ... media message ", args[0], args[0]);
      let newData = structuredClone(allChat);
      newData.push(args[0]);
      console.log("chat list data media message", newData);
      setAllChat(newData);
    });
  }

  const sendMessage = () => {
    console.log("data to be send as message", {
      room_id: roomName?.room_id,
      message: message,
      primary_room_id: roomName?._id,
      sender_id: selectedBranch?._id,
      receiver_id: roomName?.receiver_details?._id,
    });

    // if (socket && message) {
    socket.emit("chat_message", {
      room_id: roomName?.room_id,
      message: message,
      primary_room_id: roomName?._id,
      sender_id: selectedBranch?._id,
      receiver_id: roomName?.receiver_details?._id,
    });
    // getSocket_Messages();
    setMessage("");
    // }
  };

  const sendImage = () => {
    console.log("data to be send as message", {
      attachment_name: chatAttachmentData.name,
      attachment: chatAttachmentData.url,
      room_id: roomName?.room_id,
      primary_room_id: roomName?._id,
      sender_id: selectedBranch?._id,
      receiver_id: roomName?.receiver_details?._id,
    });
    // if (socket && message) {
    socket.emit("media message", {
      attachment_name: chatAttachmentData.name,
      attachment: chatAttachmentData.url,
      room_id: roomName?.room_id,
      primary_room_id: roomName?._id,
      sender_id: selectedBranch?._id,
      receiver_id: roomName?.receiver_details?._id,
      // message : message
    });
    getSocket_mediaMessages();
    setChatAttachmentData(null);
  };

  async function getPreviousChatMessagesFunc() {
    let obj = {
      room_id: roomName?.room_id,
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
    };
    try {
      let res = await getPreviousChatsByRoomId(obj);
      if (res?.code === 200) {
        setAllChat(res?.data);
      }
    } catch (error) {
      console.log("error on user list based on branch", error);
    }
  }

  useEffect(() => {
    if (allChat.length !== 0) {
      var elem = document.getElementById("lastMessageOfChat");
      elem.scrollIntoView();
    }
  }, [allChat]);

  useEffect(() => {
    if (roomName !== null && socket !== null) {
      join_roomFunc(socket);
    }
  }, [roomName, socket]);

  const [chatAttachmentData, setChatAttachmentData] = useState(null);

  const imageUploadFunc = async (event) => {
    console.log("upload image data", event);
    const file = event.target.files[0];
    if (file) {
      let filename = file.name;
      console.log("upload file name", filename);
      // if ((file.type.split('/').includes('gif') ||
      //     file.type.split('/').includes('jpg') ||
      //     file.type.split('/').includes('jpeg') ||
      //     file.type.split('/').includes('png'))) {
      if (file.size <= 5000000) {
        const data = new FormData();
        data.append("path", "chatAttachment");
        data.append("media", file);
        try {
          const resp = await imageUpload(data);
          console.log("upload image response", resp);
          if (resp?.code === 200) {
            // successNotification("Image uplaoded Successfully");
            setChatAttachmentData({
              name: filename,
              url: resp.path,
            });
            // console.log("provider profile profile data", addProviderProfile)
          }
        } catch (error) {
          console.log("upload image error", error);
        }
      } else {
        errorNotification("Image size is greater than 2MB");
      }
      // }
      // else {
      //     errorNotification("Please upload image in supported format (PNG,JPG,GIF)")
      // }
    }
  };

  /////////////////////// SOCKET IO HANDELING ENDS ///////////////////////////////

  function checkingFileFormat(ele) {
    console.log("checking file format", ele);
    if (ele?.attachments && ele?.attachments[0]?.url) {
      let image_formats = ["jpeg", "png", "jpg", "gif"];
      let file_name = ele?.attachments[0]?.url;
      let split_data = file_name.split(".");
      if (image_formats.includes(split_data[split_data.length - 1])) {
        return true;
      } else {
        return false;
      }
    } else return false;
  }

  function checkingFileFormat_uploadFile(url) {
    let file_name_exten = url.split(".");
    let image_formats = ["jpeg", "png", "jpg", "gif"];
    if (image_formats.includes(file_name_exten[file_name_exten.length - 1])) {
      return true;
    } else return false;
  }

  const [previewImage, setPreviewImage] = useState(null);

  async function downloadtryImage(url, image) {
    const imgName = url;

    const file = await fetch(
      imgName + "?r=" + Math.floor(Math.random() * 100000)
    ).then(function (response) {
      return response.blob();
    });

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = blob;
    link.download = image;
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    setTimeout(() => {
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  function searchHandler(e) {
    let searchedValue = e.target.value.toLowerCase();

    const regex = new RegExp(searchedValue);

    const filteredArray = completeRoomList.filter((val) =>
      regex.test((val?.receiver_details?.full_name).toLowerCase())
    );
    setRoomList(filteredArray);
  }

  useEffect(() => {
    if (roomList.length > 0) {
      const queryParams = new URLSearchParams(location.search);
      const userId = queryParams.get("userId");

      if (userId && isFirstRander) {
        const defaultJoinedRoom = roomList.find(
          (room) => room?.receiver_details?._id === userId
        );
        if (defaultJoinedRoom) {
          selectedRoom(defaultJoinedRoom);
          setOpenChat(true);
        }
      }
      setIsFirstRander(false);
    }
  }, [roomList]);

  const [showShedule, setShowShedule] = useState(false);

  const handleCloseSchedule = () => setShowShedule(false);
  const handleShowSchedule = () => setShowShedule(true);

  const getAppointmentDetails = async (roomdetail) => {
    const userId = roomdetail?.receiver_details?._id;

    if (!userId) {
      console.log("user id not available");
      return;
    }

    try {
      const resp = await makeHttpRequest(
        "get",
        `users/getLatestAppointment/${userId}`
      );
      setAppointmentDetail(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmReshedule = () => {
    handleCloseResheduleModal();
    if (roomName) {
      getAppointmentDetails(roomName);
    }
  };

  const handleAppointmentStatusChange = () => {
    if (roomName) {
      getAppointmentDetails(roomName);
    }
  };

  return (
    <div className="page-wrap p-0">
      <div className="chat-box slack">
        <header className="slack__header">
          <nav className="nav">
            <div className="icons">
              <Link to="/dashboard">
                <i className="fas fa-arrow-left"></i>
              </Link>
            </div>
            <div className="form-group">
              <div action="." className="search-bar">
                <i className="fas fa-search"></i>
                <input
                  type="search"
                  name="search"
                  id="search"
                  onChange={searchHandler}
                  placeholder="Search user"
                  className="form-control"
                />
              </div>
              {roomName && (
                <div className="move-left">
                  <Link onClick={handleShowSchedule} to="">
                    {/* <i className="far fa-question-circle"></i> */}
                    <button
                      style={{
                        backgroundColor: "#ff6bb5",
                        color: "white",
                        fontSize: "21px",
                      }}
                      className="btn"
                    >
                      Appointment info
                    </button>
                  </Link>
                  <div className="user-img-wrapper" id="user">
                    <img src={chatUser01} alt="User 1" />
                  </div>
                </div>
              )}

              {/* <button className="btn btn-primary"  >offcanvas</button> */}
              <AppointmentStatus
                placement="end"
                show={showShedule}
                handleClose={handleCloseSchedule}
                handleShow={handleShowSchedule}
                appointmentDetail={appointmentDetail}
                handleChangeTime={handleOpenResheduleModal}
                updateparentState={handleAppointmentStatusChange}
              />
            </div>
            <span
              className="d-block d-md-none openbtn"
              onClick={opneSidebarOne}
            >
              <i class="fa-solid fa-bars"></i>
            </span>
          </nav>
        </header>

        <main className="slack__main">
          <section className="section lg_screen p-0">
            <article className="section-1 px-2">
              {/* <div className="box box-1">A</div>
              <div className="box box-2"></div>
              <div className="box box-3">+</div> */}
              {branchList.length > 0 ? (
                branchList.map((ele, i) => (
                  <div
                    className={`box box-${i}`}
                    onClick={() => selectBranch(ele)}
                  >
                    {ele?.full_name && ele?.full_name !== ""
                      ? ele?.full_name.charAt(0)
                      : "--"}
                  </div>
                ))
              ) : (
                <></>
              )}
            </article>
            <article className="section-2">
              <div className="sidebar-user">
                <div className="brnch_selcted">
                  <h5 className="mb-0 ">
                    {/* User in{" "} */}
                    {selectedBranch !== null &&
                    selectedBranch?.practice_name &&
                    selectedBranch?.practice_name !== ""
                      ? selectedBranch.practice_name
                      : "--"}
                  </h5>
                </div>
              </div>
              <hr className="border" />
              <div className="bottom-list pt-3 px-2">
                <div className="channels">
                  {/* <div className="dropdown">
                    <h6 className=" dropdown-toggle header" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Direct Messages
                    </h6>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><Link className="dropdown-item" href="#">Action</Link></li>
                      <li><Link className="dropdown-item" href="#">Another action</Link></li>
                      <li><Link className="dropdown-item" href="#">Something else here</Link></li>
                    </ul>
                  </div> */}
                  <ul className="open-list">
                    {/* <li>
                      <Link className="active" to="">
                        <i className="fas fa-hashtag" />
                        Zeo Maxwell
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span className="make-white">
                          <i className="fas fa-hashtag" />
                          Acme Team
                        </span>
                        <span className="counter">1</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span className="make-white">
                          <i className="fas fa-hashtag" />
                          Hubspot lorem
                        </span>
                      </Link>
                    </li> */}
                    {roomList.length > 0 ? (
                      roomList.map((ele, i) => (
                        <li>
                          <Link
                            className=""
                            to=""
                            onClick={() => {
                              selectedRoom(ele);
                              setOpenChat(true);
                            }}
                          >
                            <i className="fas fa-hashtag" />
                            {ele?.receiver_details &&
                            ele?.receiver_details?.full_name &&
                            ele?.receiver_details?.full_name
                              ? ele?.receiver_details?.full_name
                              : ""}
                            {ele?.chats?.count ? (
                              // <span style={{ 'color': 'red' }}> {ele?.chats?.count}</span>
                              <span className="blue-dot"></span>
                            ) : (
                              <></>
                            )}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                    {/* <li>
                      <Link to="">
                        <i className="fas fa-lock" />
                        team-chat
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="">
                        <span className='box'>+</span>
                        Add Channels
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="direct-messages">
                  <div className="dropdown">
                    <h6 className="dropdown-toggle header" role='button' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Direct Messages
                    </h6>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><a className="dropdown-item" href="#">Action</a></li>
                      <li><a className="dropdown-item" href="#">Another action</a></li>
                      <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </div>

                  <ul className='open-list'>
                    <li>
                      <Link to="">
                        <span className='box'>A <span className='active-dot'></span></span>
                        Anwar Ali (You)
                      </Link>
                    </li>
                  </ul>

                </div> */}
              </div>
            </article>
          </section>
          <section className="section mobile_menu p-0" id="ClinicleftSidebar">
            <div className="mobile_inner">
              <span
                className="d-block d-md-none closebtn"
                onClick={closeSidebarOne}
              >
                x
              </span>
              <article className="section-1 px-2">
                {branchList.length > 0 ? (
                  branchList.map((ele, i) => (
                    <div
                      className={`box box-${i}`}
                      onClick={() => selectBranch(ele)}
                    >
                      {ele?.practice_name && ele?.practice_name !== ""
                        ? ele?.practice_name.charAt(0)
                        : "--"}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </article>
              <article className="section-2">
                <div className="sidebar-user">
                  <div className="brnch_selcted">
                    <h5 className="mb-0 ">
                      {/* User in{" "} */}
                      {selectedBranch !== null &&
                      selectedBranch?.practice_name &&
                      selectedBranch?.practice_name !== ""
                        ? selectedBranch.practice_name
                        : "--"}
                    </h5>
                  </div>
                </div>
                <hr className="border" />
                <div className="bottom-list pt-3 px-2">
                  <div className="channels">
                    <ul className="open-list">
                      {roomList.length > 0 ? (
                        roomList.map((ele, i) => (
                          <li>
                            <Link
                              className=""
                              to=""
                              onClick={() => {
                                selectedRoom(ele);
                                setOpenChat(true);
                              }}
                            >
                              <i className="fas fa-hashtag" />
                              {ele?.receiver_details &&
                              ele?.receiver_details?.full_name &&
                              ele?.receiver_details?.full_name
                                ? ele?.receiver_details?.full_name
                                : ""}
                              {ele?.chats?.count ? (
                                // <span style={{ 'color': 'red' }}> {ele?.chats?.count}</span>
                                <span className="blue-dot"></span>
                              ) : (
                                <></>
                              )}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </section>
          {/* style={{ width: `${divWidth2}px`, transition: 'width 0.6s ease' }} */}
          <section className="body p-0">
            <div className="content">
              <div className="content-header custm-style">
                <h4 className="mb-0 channel-content-header-name">
                  {roomName !== null &&
                  roomName?.receiver_details &&
                  roomName?.receiver_details?.full_name &&
                  roomName?.receiver_details?.full_name
                    ? roomName?.receiver_details?.full_name
                    : ""}
                </h4>
                {/* <button
                  className="border-none ms-auto"
                  id="info"
                  type="button"
                  disabled=""
                  onClick={toggleDiv}
                >
                  <i className="fas fa-info-circle" />
                </button> */}
              </div>
              <div className="feeds">
                {/* <article className="feed">
                  <div className="feeds-user-avatar">
                    <img src={chatUser01} alt="User 1" width={40} />
                    <span>Z</span>
                  </div>
                  <div className="feed-content">
                    <div className="feed-user-info">
                      <h4>
                        Zeo Maxwell <span className="time-stamp"> 9:56am</span>
                      </h4>
                    </div>
                    <div>
                      <p className="feed-text">
                        No! It was my pleasure! Great to see the enthusiasm out there.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="feed">
                  <div className="feeds-user-avatar">
                    <img src={chatUser02} alt="User 1" width={40} />
                  </div>
                  <div className="feed-content">
                    <div className="feed-user-info">
                      <h4>
                        Acme Team <span className="tag">app</span>
                        <span className="time-stamp"> 12:45pm</span>
                      </h4>
                    </div>
                    <p className="feed-text">Events starting in 15 minutes:</p>

                  </div>
                </article>
                <article class="feed">
                  <div class="feeds-user-avatar">
                    <img src={chatUser01} alt="User 1" width="40" />
                  </div>
                  <div class="feed-content">
                    <div class="feed-user-info">
                      <h4>Hary Boone <span class="time-stamp">12:58pm</span></h4>
                    </div>
                    <div>
                      <p class="feed-text">
                        Quick note: today <span class="mention">@lisa</span> will
                        join our team sync to provide updates on the launch, if
                        you have questions, bring 'em. See you all later...er, in
                        2 minutes.
                      </p>
                    </div>
                  </div>
                </article> 
                 (allChat.length > 0) ?*/}
                {openChat ? (
                  allChat?.map((ele, i) => {
                    if (ele?.sender_id === selectedBranch?._id) {
                      return (
                        <>
                          <article className="feed">
                            {ele?.message_type !== "alert" && (
                              <div className="feeds-user-avatar">
                                <img
                                  src={
                                    userProfile +
                                    ele?.sender_details?.profile_image
                                  }
                                  alt="User 1"
                                  width={40}
                                />
                              </div>
                            )}

                            <div
                              className={`feed-content ${
                                ele?.message_type === "alert"
                                  ? "alertMessage"
                                  : ""
                              }`}
                              id={
                                i === allChat.length - 1
                                  ? "lastMessageOfChat"
                                  : ""
                              }
                            >
                              {ele?.message_type !== "alert" && (
                                <div className="feed-user-info">
                                  <h4>
                                    {ele?.sender_details?.practice_name ||
                                      ele?.sender_details?.full_name}{" "}
                                    {/* {ele?.sender_details?.full_name &&
                                    ele?.sender_details?.full_name !== ""
                                      ? ele?.sender_details?.full_name
                                      : "--"}{" "} */}
                                    <span className="tag"></span>
                                    <strong className="time-stamp">
                                      {" "}
                                      {ele?.updatedAt && ele?.updatedAt !== null
                                        ? moment(
                                            new Date(ele?.updatedAt)
                                          ).format("Do MMMM YYYY")
                                        : "--"}
                                    </strong>
                                  </h4>
                                </div>
                              )}

                              {ele?.message_type === "text" ? (
                                <p className="feed-text">{ele?.message}</p>
                              ) : ele?.message_type === "slot" ? (
                                <div className="card shadow mt-2">
                                  <div className="card-body">
                                    <p className="feed-text">{ele?.message}</p>
                                    <ol>
                                      <li>None of the below</li>

                                      {ele?.slots?.map((slot) => (
                                        <li>
                                          {moment(new Date(slot?.start)).format(
                                            "DD-MM-YYYY, hh:mm A"
                                          )}
                                        </li>
                                      ))}
                                    </ol>
                                  </div>
                                </div>
                              ) : ele?.message_type === "alert" ? (
                                <p
                                  className={`feed-text alert ${
                                    ele?.color !== "grey"
                                      ? "alert-info"
                                      : "alert-secondary"
                                  }`}
                                >
                                  {ele?.message}
                                </p>
                              ) : checkingFileFormat(ele) ? (
                                <>
                                  <a
                                    className="imgPreviewBtn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#previewImgModal"
                                    onClick={() =>
                                      setPreviewImage(ele?.attachments[0])
                                    }
                                  >
                                    <img
                                      src={
                                        ele?.attachments &&
                                        ele?.attachments[0]?.url
                                          ? chat_image_base +
                                            ele?.attachments[0]?.url
                                          : ""
                                      }
                                      alt="Image data"
                                      width={200}
                                    />
                                  </a>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadtryImage(
                                        chat_image_base +
                                          ele?.attachments[0]?.url,
                                        ele?.attachments[0]?.name
                                      )
                                    }
                                  >
                                    {" "}
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <p>
                                    {ele?.attachments &&
                                    ele?.attachments[0]?.name &&
                                    ele?.attachments[0]?.name !== ""
                                      ? ele?.attachments[0]?.name
                                      : "--"}
                                  </p>{" "}
                                </>
                              ) : ele?.attachments ? (
                                <>
                                  <img
                                    src={
                                      ele?.attachments &&
                                      ele?.attachments[0]?.url
                                        ? file_icon_image
                                        : ""
                                    }
                                    alt="file doc"
                                    width={200}
                                  />
                                  <a
                                    href={
                                      chat_image_base + ele?.attachments[0]?.url
                                    }
                                    download={ele?.attachments[0]?.name}
                                  >
                                    {" "}
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                  <p>
                                    {ele?.attachments &&
                                    ele?.attachments[0]?.name &&
                                    ele?.attachments[0]?.name !== ""
                                      ? ele?.attachments[0]?.name
                                      : "--"}
                                  </p>
                                  {/* <button type="button"><i class="fa fa-download" onClick={() => saveFile()} aria-hidden="true"></i></button> */}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </article>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <article className="feed">
                            <div className="feeds-user-avatar">
                              <img
                                src={
                                  userProfile +
                                  ele?.sender_details?.profile_image
                                }
                                alt="User 2"
                                width={40}
                              />
                            </div>
                            <div
                              className="feed-content"
                              id={
                                i === allChat.length - 1
                                  ? "lastMessageOfChat"
                                  : ""
                              }
                            >
                              <div className="feed-user-info">
                                <h4>
                                  {ele?.sender_details?.full_name &&
                                  ele?.sender_details?.full_name !== ""
                                    ? ele?.sender_details?.full_name
                                    : "--"}{" "}
                                  <span className="time-stamp">
                                    {ele?.updatedAt && ele?.updatedAt !== null
                                      ? moment(ele?.updatedAt).format(
                                          "do MMMM YYYY"
                                        )
                                      : "--"}
                                  </span>
                                </h4>
                              </div>
                              <div>
                                {ele?.message_type === "text" ? (
                                  <p className="feed-text">{ele?.message}</p>
                                ) : checkingFileFormat(ele) ? (
                                  <>
                                    <a
                                      className="imgPreviewBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#previewImgModal"
                                      onClick={() =>
                                        setPreviewImage(ele?.attachments[0])
                                      }
                                    >
                                      <img
                                        src={
                                          ele?.attachments &&
                                          ele?.attachments[0]?.url
                                            ? chat_image_base +
                                              ele?.attachments[0]?.url
                                            : ""
                                        }
                                        alt="Image data"
                                        width={200}
                                      />
                                    </a>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        downloadtryImage(
                                          chat_image_base +
                                            ele?.attachments[0]?.url,
                                          ele?.attachments[0]?.name
                                        )
                                      }
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    {/* <a href={chat_image_base + ele?.attachments[0]?.url} download={ele?.attachments[0]?.name}> <i class="fa fa-download" aria-hidden="true"></i></a> */}
                                    <p>
                                      {ele?.attachments &&
                                      ele?.attachments[0]?.name &&
                                      ele?.attachments[0]?.name !== ""
                                        ? ele?.attachments[0]?.name
                                        : "--"}
                                    </p>{" "}
                                  </>
                                ) : ele?.attachments ? (
                                  <>
                                    <img
                                      src={
                                        ele?.attachments &&
                                        ele?.attachments[0]?.url
                                          ? file_icon_image
                                          : ""
                                      }
                                      alt="file doc"
                                      width={200}
                                    />
                                    <a
                                      href={
                                        chat_image_base +
                                        ele?.attachments[0]?.url
                                      }
                                      download={ele?.attachments[0]?.name}
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    <p>
                                      {ele?.attachments &&
                                      ele?.attachments[0]?.name &&
                                      ele?.attachments[0]?.name !== ""
                                        ? ele?.attachments[0]?.name
                                        : "--"}
                                    </p>

                                    {/* <button type="button"><i class="fa fa-download" onClick={() => saveFile()} aria-hidden="true"></i></button> */}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </article>
                        </>
                      );
                    }
                  })
                ) : (
                  <div className="empty-chat">
                    <img src={empty_img} />

                    <h4>Start Chat</h4>
                  </div>
                )}
              </div>

              <div
                className="form chat-footer-form attachment-image"
                name="feedForm"
              >
                {/* <div class="card__clinic-booking">
                  <Link class="apt-btn theme_btn rounded-1" onClick={handleClickOpenDialog}>Appointment</Link>
                </div> */}

                {roomName !== null ? (
                  <div className="chat-footer">
                    {appointmentDetail?.status === "pending" && (
                      <h4 class="timeSlot" onClick={handleOpenResheduleModal}>
                        <span className="badge bg-secondary">
                          {" "}
                          <i class="fa-regular fa-clock"></i> Time
                        </span>
                      </h4>
                    )}
                    <div className="input-group attachment-image">
                      {chatAttachmentData?.url ? (
                        <>
                          <div className="img-attach">
                            {checkingFileFormat_uploadFile(
                              chatAttachmentData?.url
                            ) ? (
                              <img
                                src={chat_image_base + chatAttachmentData?.url}
                              />
                            ) : (
                              <img
                                style={{ height: "140px", width: "120px" }}
                                src={file_icon_image}
                              />
                            )}
                            <button
                              type="button"
                              onClick={() => setChatAttachmentData(null)}
                            >
                              <i className="fa fa-remove"></i>
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={() => sendImage()}
                            class="btn msg-send-btn rounded-pill ms-2"
                          >
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      {chatAttachmentData === null ? (
                        <>
                          <div className="btn-file btn">
                            <i className="fa fa-paperclip"></i>
                            <input type="file" onChange={imageUploadFunc} />
                          </div>
                          <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && message) {
                                sendMessage();
                              }
                            }}
                            class="input-msg-send form-control rounded"
                            placeholder="Type something"
                          />
                          <button
                            type="button"
                            disabled={message === ""}
                            onClick={() => sendMessage()}
                            class="btn msg-send-btn rounded-pill ms-2"
                          >
                            <i class="fab fa-telegram-plane"></i>
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          {/* style={{ width: `${divWidth}px`, transition: 'width 0.6s ease' }} */}
          <div className="right-sidebar provider-chat-box shadow">
            <RightSidebar />
            {/* handleClose={handleClose}  */}
          </div>
        </main>
      </div>

      {/* Image preview Modal start */}
      <div
        class="modal fade"
        id="previewImgModal"
        tabindex="-1"
        aria-labelledby="previewImgModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="previewImgModalLabel">
                Preview Image
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setPreviewImage(null)}
              ></button>
            </div>
            <div class="modal-body">
              <div className="previewImgWrap">
                <img
                  src={chat_image_base + previewImage?.url}
                  alt=""
                  className="img-fluid"
                  srcset=""
                />
              </div>
            </div>
            {/* <div class="modal-footer">
              <button type="button" class="theme-outline-btn" data-bs-dismiss="modal">Close</button>
              <a href="../../../Assets/Blue-Cross.svg" download>
              <button type="button" class="theme_btn">Save changes</button> 
              </a>
            </div> */}
          </div>
        </div>
      </div>
      {/* Modal end */}

      {/* modal start */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog"
        PaperProps={{
          style: customContentStyle, // Apply custom styles using PaperProps
        }}
      >
        <DialogContent className="appointment d-flex flex-column justify-content-center align-items-center">
          <div className="appoitment_Card" id="appointment-card">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-xl-4">
                <h4 className="mb-1">11 November 2023</h4>
                <p className="text-muted">Monday</p>
              </div>
              <div className="col-12 col-sm-6 col-md-8 col-xl-8 ">
                <div className="d-flex gap-5 w-100 align-items-center">
                  <select
                    class="form-select provider_select"
                    aria-label="Default select example"
                  >
                    <option selected>Select Provider</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <RangePicker
                    value={dates || value}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => {
                      setDates(val);
                    }}
                    onChange={(val) => {
                      setValue(val);
                    }}
                    onOpenChange={onOpenChange}
                    changeOnBlur
                  />
                </div>
              </div>
            </div>
            <div className="card shadow border-0 booking-schedule schedule-widget mb-0">
              <div className="schedule-header">
                <div className="row">
                  <div className="col-md-12">
                    <div className="day-slot">
                      <ul>
                        <li className="left-arrow">
                          <Link to="">
                            <i className="fa fa-chevron-left"></i>
                          </Link>
                        </li>
                        <li>
                          <span>Mon</span>
                          <span className="slot-date">
                            11 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Tue</span>
                          <span className="slot-date">
                            12 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Wed</span>
                          <span className="slot-date">
                            13 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Thu</span>
                          <span className="slot-date">
                            14 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Fri</span>
                          <span className="slot-date">
                            15 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Sat</span>
                          <span className="slot-date">
                            16 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li>
                          <span>Sun</span>
                          <span className="slot-date">
                            17 Nov <small className="slot-year">2023</small>
                          </span>
                        </li>
                        <li className="right-arrow">
                          <Link>
                            <i className="fa fa-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="schedule-cont">
                <div className="row">
                  <div className="col-md-12">
                    <div className="time-slot">
                      <ul className="clearfix">
                        <li>
                          <Link className="timing ">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing available">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing available">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing available">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing selected">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                        <li>
                          <Link className="timing available">
                            <span>9:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>10:00</span> <span>AM</span>
                          </Link>
                          <Link className="timing">
                            <span>11:00</span> <span>AM</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex gap-2 justify-content-center ">
                    <button
                      className="theme-outline-btn rounded"
                      onClick={handleCloseDialog}
                    >
                      Request Booking
                    </button>
                    <button
                      className="theme_btn rounded"
                      onClick={handleCloseDialog}
                    >
                      {" "}
                      Back{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions className='modal-foot'>
                </DialogActions> */}
      </Dialog>
      {/* modal end */}
      {showResheduleModal && (
        <ResheduleApp
          show={showResheduleModal}
          handleClose={handleCloseResheduleModal}
          appointmentDetail={appointmentDetail}
          handleConfirmInParent={handleConfirmReshedule}
        />
      )}
    </div>
  );
}
