import React, { useCallback, useState, Component, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ProviderProfile.scss";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Button,
  TextField,
  Modal,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import loader from "../../../Assets/lottieAnim/loader.json";
import verified from "../../../Assets/lottieAnim/sucessful.json";
import clinicimg from "../../../Assets/clinicImgs.jpg";
import Chips from "react-chips";
import _ from 'lodash';

import {
  getBranchByIdService,
  successNotification,
  errorNotification,
  updateClinicBrnachProfile,
  imageUpload,
} from "../../../utils/httpServices/httpService";
import { useSelector, useDispatch } from "react-redux";

import { fetchClinicBranches } from "../../../redux/slices/getting_clinic_branches/index";

import {
  emptyStringValidator,
  emailValidator,
  phoneValidator,
} from "../../../utils/validators/validatorServices";
import Select from "react-select";
import { Select as MuiSelect } from "@mui/material";
// import { colourOptions } from '../data';
import moment from "moment";

import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import CropModal from "../../../components/CropImageModal/CropModal";
import { generateHoursOptions } from "../../../utils/common/common";

export default function ProviderProfile() {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const [croppedImage, setCroppedImage] = useState(null);

  const [editEnable, setEditEnable] = useState(false);

  const [isWorkingHourChanged, setisWorkingHourChanged] = useState(false)


  const createCroppedImage = (crop) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions to match cropped area
    canvas.width = crop.width;
    canvas.height = crop.height;

    const originalImage = new Image();
    originalImage.src = clinicimg;

    // Draw the cropped portion of the original image onto the canvas
    ctx.drawImage(
      originalImage,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImageUrl = canvas.toDataURL("image/jpeg");
    console.log("🚀 ~ createCroppedImage ~ croppedImageUrl:", croppedImageUrl);

    setCroppedImage(croppedImageUrl);
  };

  const options = [
    { value: "Option A", label: "Option A" },
    { value: "Option B", label: "Option B" },
    { value: "Option C", label: "Option C" },
    { value: "Other", label: "Other" },
  ];

  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };
  const verifiedOptions = {
    loop: true,
    autoplay: true,
    animationData: verified,
  };
  const [timeValue, onValueChange] = useState("10:00");

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    step1: { firstName: "", lastName: "" },
    step2: { email: "", phone: "" },
    step3: { address: "", city: "", postalCode: "" },
  });

  const steps = ["Step 1", "Step 2", "Step 3"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (step, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: value,
      },
    }));
  };

  const isStepOptional = (step) => {
    return step === 1; // You can adjust this as needed
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const medical_records_doc_url = process.env.REACT_APP_CLINIC_DOCUMENTS;

  // Callback for when files are dropped
  const onDrop = useCallback(
    (acceptedFiles) => {
      const updatedFiles = selectedFiles.concat(
        acceptedFiles.slice(0, 6 - selectedFiles.length)
      );
      medicalRecordDocs(updatedFiles);

    },
    [selectedFiles]
  );

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    const docFilesName = clinicProfile.clinic_documents;
    updatedFiles.splice(index, 1);
    docFilesName.splice(index, 1);

    setClinicProfile({
      ...clinicProfile,
      clinic_documents: docFilesName,
    });
    setSelectedFiles(updatedFiles);
  };

  // react-dropzone hook configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: 6,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => { setisWorkingHourChanged(true); setOpen(true); }
  const handleClose = () => setOpen(false);

  const [chips, setChips] = useState([]);

  const onChange = (newChips) => {
    setChips(newChips);
    setClinicProfile({
      ...clinicProfile,
      features_and_benefits: newChips,
    });

  };

  // const state = useSelector((state) => state);

  // const dispatcher = useDispatch();

  const [branch_id, setBranch_id] = useState();

  const navigate = useNavigate();

  // const [initialState, setPageState] = useState(localStorage.getItem("selected_Branch"));

  //////////////////// Get Clinic Profile (START) /////////////////////

  const [clinicProfile, setClinicProfile] = useState({
    full_name: "",
    practice_name: "",
    email: "",
    profile_image: "",
    clinic_image: "",
    clinic_documents: [],
    bio: "",
    phone_number: "",
    address: "",
    working_hours: [],
    features_and_benefits: [],
    services: [],
    insurance_they_accept: [],
    website_link: "",
    location: {
      coordinates: [],
    },
  });
  console.log("🚀 ~ ProviderProfile ~ clinicProfile:", clinicProfile)

  const [clinicProfileValidator, setClinicProfileValidator] = useState({
    full_name: false,
    practice_name: false,
    email: false,
    profile_image: false,
    clinic_image: false,
    bio: false,
    address: false,
  });

  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  async function getClinicProfile(id) {
    try {
      const resp = await getBranchByIdService(id);
      console.log("resp for branch Detail", resp);
      if (resp?.code === 200) {
        console.log("clinic Details", resp.data);
        let service_data = [];
        let Insurance_data = [];
        const services = resp.data.services.forEach((ele) => {
          service_data.push({ value: ele, label: ele });
        });
        const insurance_they_accept = resp.data.insurance_they_accept.forEach(
          (ele) => {
            Insurance_data.push({ value: ele, label: ele });
          }
        );



        clinic_patchingValues(resp, service_data, Insurance_data);
      }
    } catch (err) {
      console.log("clinic details error", err);
    }
  }

  const [addtimeSlot, setTimeSlot] = useState({
    day: "",
    enabled: true,
    end_time: "",
    start_time: "",
  });

  const settingTime = (e, time_type) => {
    // let today_date = moment();
    // console.log("today_date", today_date);
    // let time = "2023-12-06T" + e.target.value + ":00.000Z";
    let time = e.target.value;
    console.log("time", time);
    setTimeSlot({
      ...addtimeSlot,
      [time_type]: time,
    });
    console.log("update time", addtimeSlot);
  };

  const slotSatus = (e) => {
    console.log("e", e);
    setTimeSlot({
      ...addtimeSlot,
      enabled: e.target.checked,
    });
  };

  const onSlotDayChange = (day) => {
    const dayExist = addedTimeSlots.find(dayObj => dayObj.day === day)
    console.log({ day, dayExist })
    if (dayExist) {
      toast.info("Selected day already exists")
      return
    }
    setTimeSlot({
      ...addtimeSlot,
      day: day,
    })
  }

  const isValidTimeFormatCustom = (time) => {
    const timeRegex = /^(?:2[0-3]|[0-1]?[0-9]):[0-5][0-9]:[0-5][0-9]$/;
    return timeRegex.test(time);
  }

  const isValidTimeRangeCustom = (startTime, endTime) => {
    if (!startTime || !endTime) {
      toast.error("Please provide a start and end time")
      return;
    }

    const startDate = new Date(`01/01/2000 ${startTime}`);
    const endDate = new Date(`01/01/2000 ${endTime}`);

    if (startDate.getTime() >= endDate.getTime()) {
      toast.error("Please provide a valid start and end time");
      return;
    }

    return true;
  };
  const apply_timeSlots = () => {
    console.log(addedTimeSlots, "added time slots");
    for (const item of addedTimeSlots) {
      if (!isValidTimeRangeCustom(item.start_time, item.end_time)) {
        return;
      }
    }
    setClinicProfile({
      ...clinicProfile,
      working_hours: _.cloneDeep(addedTimeSlots),
    });
    console.log("new time slots", clinicProfile);
    handleClose();
  };

  function timeConversion(time) {
    if (time !== "") {
      // console.log(new Date(time), time)
      let hours_min = time.split("T")[1].split(":");
      console.log("hours", hours_min);
      return hours_min[0] + ":" + hours_min[1];
    } else {
      return "";
    }
  }

  const [addedTimeSlots, setAddedTimeSlots] = useState([]);
  console.log("🚀 ~ ProviderProfile ~ addedTimeSlots:", addedTimeSlots)

  const isValidTime = (startTime, endTime) => {
    const isValidTimeFormat = (time) => {
      const timeRegex = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/;
      return timeRegex.test(time);
    };
    if (!startTime || !endTime) {
      toast.error("Please provide start time and end time");
      return;
    }

    if (!isValidTimeFormat(startTime) || !isValidTimeFormat(endTime)) {
      toast.error("Invalid time");
      return;
    }

    const startDate = new Date(`01/01/2000 ${startTime}`);
    const endDate = new Date(`01/01/2000 ${endTime}`);

    // Check if start_time is less than end_time
    if (startDate.getTime() >= endDate.getTime()) {
      toast.error("Invalid time range");
      return;
    }

    return true;
  };

  const addingTimeSlot = () => {
    const { start_time, end_time, day } = addtimeSlot;
    if (!day) {
      toast.error("Enter a valid day")
      return;
    }
    const isValid = isValidTime(start_time, end_time);
    if (isValid) {
      setAddedTimeSlots([...addedTimeSlots, addtimeSlot]);
      setTimeSlot({
        day: "",
        enabled: false,
        end_time: "",
        start_time: "",
      });
    }

    console.log("time slots list", addedTimeSlots);
  };

  const removeTimeSlot = (i) => {
    console.log("i", i);
    let slots = addedTimeSlots;
    slots.splice(i, 1);
    setAddedTimeSlots([...slots]);
  };

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon1 = (start, end) => {
    console.log(start, end);

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start);
    if (st === "AM") {
      const hour = start.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start.split(":")[1]} PM`;
    }

    const en = checkAMorPM(end);
    if (en === "AM") {
      const hour = end.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end.split(":")[1]} PM`;
    }

    const formatTime = { formatTime: `${t1}-${t2}` };
    console.log("formatTime", formatTime);
    return formatTime;
  };

  function clinic_patchingValues(resp, services, insurance_they_accept) {
    console.log("patching para data", resp, services, insurance_they_accept);

    const test = resp?.data?.working_hours?.map((el) => {
      return {
        ...el,
        start_time: new Date(el?.start_time)?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),

        // end_time: new Date(el?.end_time)?.toLocaleString().split(", ")[1],
        end_time: new Date(el?.end_time)?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),

      };
    });
    // console.log()

    setAddedTimeSlots(test);

    setClinicProfile({
      full_name: resp?.data?.full_name,
      practice_name: resp?.data?.practice_name,
      email: resp?.data?.email,
      profile_image: resp?.data?.profile_image,
      clinic_image: resp?.data?.clinic_image,
      clinic_documents: resp?.data?.clinic_documents,
      bio: resp?.data?.bio,
      phone_number: resp?.data?.phone_number,
      address: resp?.data?.address,
      working_hours: test, // resp?.data?.working_hours,
      features_and_benefits: resp?.data?.features_and_benefits,
      services: services,
      insurance_they_accept: insurance_they_accept,
      website_link: resp?.data?.website_link,
      location: resp?.data?.location,
    });

    if (resp.data.clinic_documents.length > 0) {
      let docs = [];
      resp.data.clinic_documents.forEach(async (ele, i) => {
        console.log("running complete url", medical_records_doc_url + ele);

        let response = await fetch(medical_records_doc_url + ele);
        let data = await response.blob();
        let metadata = {
          type: "image/jpeg",
        };
        let file = new File([data], ele, metadata);
        docs.push(file);
        console.log("url to file", file, "doc1111111", docs);
        if (resp.data.clinic_documents.length === i + 1) {
          console.log("docs console", docs);
          setSelectedFiles(docs);
          console.log("selectedFiles", selectedFiles);
        }
      });
    }
  }

  const settingOptions = (data, type) => {
    console.log("e", data, "type", type);
    setClinicProfile({
      ...clinicProfile,
      [type]: data,
    });
    console.log("clinic data after set", clinicProfile);
  };

  // useEffect(() => {
  //   console.log("state", state)
  //   if (state.getClinicBrances?.data !== null) {
  //     setBranch_id(state.getClinicBrances?.data?.data[initialState]._id);
  //     getClinicProfile(state.getClinicBrances?.data?.data[initialState]._id);
  //   }
  //   else {
  //     dispatcher(fetchClinicBranches());
  //   }
  // }, [state]);

  const route_params = useParams();

  useEffect(() => {
    console.log("route params", route_params);
    setBranch_id(route_params.id);
    getClinicProfile(route_params.id);
  }, []);

  ///////////////////  Get Clinic Profile  (END) ////////////////////////

  ///////////////////  Updating Clinic Profile  (START) /////////////////

  const settingClinicProfileValues = (event) => {
    console.log("event!!!", event.target, clinicProfile);
    setClinicProfile({
      ...clinicProfile,
      [event.target.name]: event.target.value,
    });

    console.log("updated values for clinic profile", clinicProfile);
  };

  const [addressPlace, setAddressPlace] = useState(null);

  const settingAddress = (place) => {
    // place.geometry.location.lat(e => console.log(e))
    setAddressPlace(place);
  };

  function updatingAddress() {
    console.log("map address data", addressPlace);
    setClinicProfile({
      ...clinicProfile,
      address: addressPlace?.formatted_address,
      location: {
        coordinates: [
          addressPlace?.geometry?.location.lat(),
          addressPlace?.geometry?.location?.lng(),
        ],
      },
    });

    console.log("clinic after address update", clinicProfile);
  }

  useEffect(() => {
    updatingAddress();
  }, [addressPlace]);

  function updateClinicProfileValidatorCheck(name) {
    switch (name) {
      case "full_name":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          full_name: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("clinic  profile validators", clinicProfileValidator);
        break;
      case "practice_name":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          practice_name: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;
      case "email":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          email: emailValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;

      case "profile_image":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          profile_image: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;

      case "clinic_image":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          clinic_image: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;

      case "bio":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          bio: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;

      case "phone_number":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          phone_number: phoneValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;
      case "address":
        setClinicProfileValidator(() => ({
          ...clinicProfileValidator,
          address: emptyStringValidator(clinicProfile[name]),
        }));
        console.log("user profile validators", clinicProfileValidator);
        break;

      default:
    }
  }

  const clinic_imageUpload = async (event, image_for) => {
    console.log("upload image data", event, image_for);
    const file = event.target.files[0];
    if (file) {
      if (
        file.type.split("/").includes("gif") ||
        file.type.split("/").includes("jpg") ||
        file.type.split("/").includes("jpeg") ||
        file.type.split("/").includes("png")
      ) {
        if (file.size <= 2000000) {
          const data = new FormData();
          data.append("path", "userProfile");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload image response", resp);
            if (resp?.code === 200) {
              if (image_for === "clinic_image") {
                setClinicProfile({
                  ...clinicProfile,
                  clinic_image: resp.path,
                });
                successNotification("Image uplaoded Successfully");
                handleCloseDialog();
                console.log("user profile data", clinicProfile);
              } else {
                setClinicProfile({
                  ...clinicProfile,
                  profile_image: resp.path,
                });
                successNotification("Image uplaoded Successfully");
                handleCloseDialogForProfile();
                console.log("user profile data", clinicProfile);
              }
            }
          } catch (error) {
            console.log("upload profile image error", error);
          }
        } else {
          errorNotification("Image size is greater than 2MB");
        }
      } else {
        errorNotification(
          "Please upload image in supported format (PNG,JPG,GIF)"
        );
      }
    }
  };

  function finalValidationCheck() {
    console.log("user check function", clinicProfile, clinicProfileValidator);
    let tempValidator = clinicProfileValidator;
    let validatorKeys = Object.keys(clinicProfileValidator);
    console.log("user keys", validatorKeys, tempValidator);
    validatorKeys.forEach((ele) => {
      if (
        ele === "full_name" ||
        ele === "practice_name" ||
        ele === "profile_image" ||
        ele === "clinic_image" ||
        ele === "bio" ||
        ele === "address"
      ) {
        tempValidator[ele] = emptyStringValidator(clinicProfile[ele]);
      } else if (ele === "email") {
        tempValidator[ele] = emailValidator(clinicProfile[ele]);
      } else if (ele === "phone_number") {
        tempValidator[ele] =
          clinicProfile[ele] !== ""
            ? phoneValidator(clinicProfile[ele])
            : false;
        console.log(tempValidator[ele], clinicProfile[ele]);
      }
    });
    setClinicProfileValidator(() => ({
      ...tempValidator,
    }));
  }

  const medicalRecordDocs = async (event) => {
    console.log("uploaded add medical data", event);
    const file = event[event.length - 1];
    console.log("files", file, "event", event);
    if (
      file.type.split("/").includes("gif") ||
      file.type.split("/").includes("jpg") ||
      file.type.split("/").includes("jpeg") ||
      file.type.split("/").includes("png")
    ) {
      if (file.size <= 2000000) {
        const data = new FormData();
        data.append("path", "clinicDocument");
        data.append("media", file);
        try {
          const resp = await imageUpload(data);
          console.log("medical doc response", resp);
          if (resp?.code === 200) {
            let doc = clinicProfile.clinic_documents;
            doc.push(resp.path);
            console.log("doc", doc, "medical records", clinicProfile);
            setClinicProfile({
              ...clinicProfile,
              clinic_documents: doc,
            });
            successNotification("Image uplaoded Successfully");
            console.log("user profile data", clinicProfile, "event", event);
            setSelectedFiles(event);
          }
        } catch (error) {
          console.log("upload profile image error", error);
          return setSelectedFiles(event.slice(0, event.length - 1));
        }
      } else {
        errorNotification("Image size is greater than 2MB");
      }
    } else {
      errorNotification(
        "Please upload image in supported format (PNG,JPG,GIF)"
      );
    }
  };

  // async function saveUpdateProfile() {
  //   finalValidationCheck();
  //   console.log("saving profile================================saving profile================================")
  //   console.log(clinicProfileValidator)
  //   const validatorStatus = Object.values(clinicProfileValidator);
  //   if (!validatorStatus.some((ele) => ele === true)) {
  //     let service = clinicProfile.services.map((ele) => ele.value);

  //     let insurance = clinicProfile.insurance_they_accept.map(
  //       (ele) => ele.value
  //     );
  //     const data = {
  //       ...clinicProfile,
  //       services: service,
  //       insurance_they_accept: insurance,
  //     };
  //     console.log(data);
  //     const body = JSON.parse(JSON.stringify(data));
  //     console.log(body);
  //     console.log("saving profile2================================saving profile2================================")
  //     for (let i = 0; i < body.working_hours.length; i++) {
  //       const startTime = body.working_hours[i].start_time;
  //       const endTime = body.working_hours[i].end_time;

  //       console.log(startTime, endTime);

  //       const [starhour, startminute] = startTime.split(":");
  //       const [endHour, endMinute] = endTime.split(":");
  //       let time = 0;
  //       starhour === "00" ? (time = 24) : (time = starhour);
  //       console.log(starhour);

  //       if (time > endHour) {
  //         if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
  //           toast.error(
  //             "Invalid practice hours. Start time must be before end time."
  //           );
  //           return;
  //         } else if (
  //           time >= 13 &&
  //           time <= 24 &&
  //           endHour >= 13 &&
  //           endHour <= 24
  //         ) {
  //           toast.error(
  //             "Invalid practice hours. Start time must be before end time."
  //           );
  //           return;
  //         }
  //       } else if (time === endHour && startminute >= endMinute) {
  //         toast.error(
  //           "Invalid practice hours. Start time must be before end time."
  //         );
  //         return;
  //       }
  //       console.log("saving profile3================================saving profile3================================")
  //       const startInputTime = new Date("2023-01-01");
  //       const endInputTime = new Date("2023-01-01");

  //       startInputTime.setHours(starhour, startminute, 0, 0); // Set the hours and minutes
  //       endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

  //       console.log(
  //         startInputTime,
  //         endInputTime,
  //         body.working_hours[i].start_time
  //       );

  //       body.working_hours[i].start_time = startInputTime.toISOString();
  //       body.working_hours[i].end_time = endInputTime.toISOString();
  //     }

  //     console.log("data=================================================data", data);
  //     try {
  //       const resp = await updateClinicBrnachProfile(body, branch_id);
  //       console.log(" clinic profile update", resp);
  //       if (resp?.code === 200) {
  //         successNotification("Clinic Profile Updated Successfully");
  //       }
  //     } catch (err) {
  //       // alert(err);
  //     }
  //   } else {
  //     console.log("validation failed", clinicProfileValidator);
  //   }
  // }
  async function saveUpdateProfile() {
    finalValidationCheck();

    const validatorStatus = Object.values(clinicProfileValidator);
    if (validatorStatus.some((ele) => ele === true)) {
      console.log("Validation failed", clinicProfileValidator);
      return;
    }

    const data = prepareDataForUpdate();


    if (!data) {
      return;
    }

    data.isHoursChanged = isWorkingHourChanged

    try {
      const resp = await updateClinicBrnachProfile(data, branch_id);
      console.log("Clinic profile update", resp);
      if (resp?.code === 200) {
        successNotification("Clinic Profile Updated Successfully");
        setisWorkingHourChanged(false)
      }
    } catch (err) {
      console.error("Error updating clinic profile", err);
      // Handle specific errors and provide appropriate messages
      if (err.response && err.response.status === 400) {
        // Bad request error
        toast.error("Invalid data. Please check your inputs.");
      } else {
        // Other errors
        toast.error("Failed to update clinic profile. Please try again later.");
      }
    }
  }

  function prepareDataForUpdate() {
    const service = clinicProfile.services.map((ele) => ele.value);
    const insurance = clinicProfile.insurance_they_accept.map(
      (ele) => ele.value
    );

    const data = {
      ...clinicProfile,
      services: service,
      insurance_they_accept: insurance,
    };

    for (let i = 0; i < data.working_hours.length; i++) {
      const { start_time, end_time } = data.working_hours[i];
      if (!isValidTimeRange(start_time, end_time)) {
        toast.error(
          "Invalid practice hours. Start time must be before end time."
        );
        return false; // Return null to indicate validation failure
      }

      data.working_hours[i].start_time = getTimeISOString(start_time);
      data.working_hours[i].end_time = getTimeISOString(end_time);
    }

    return data;
  }

  function isValidTimeRange(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":");
    const [endHour, endMinute] = endTime.split(":");
    return (
      parseInt(startHour) < parseInt(endHour) ||
      (parseInt(startHour) === parseInt(endHour) &&
        parseInt(startMinute) < parseInt(endMinute))
    );
  }

  function getTimeISOString(timeString) {

    const isDateString = !isNaN(Date.parse(timeString));

    if (isDateString) {
      // If it's already in date format, return the input string as it is
      return timeString;
    } else {

      const time = new Date();
      const [hours, minutes] = timeString.split(":");
      time.setHours(hours, minutes, 0, 0);
      return time.toISOString();
    }
  }

  ///////////////////  Updating Clinic Profile  (END) /////////////////

  const navigateCancel = () => {
    window.scroll(0, 0);
    navigate("/dashboard");
  };

  // --crop-image-modal--

  const [showCropModal, setShowCropModal] = useState(false);

  const handleCloseDialog = () => setShowCropModal(false);
  const handleShowCrop = () => setShowCropModal(true);

  const [showCropModalForProfile, setShowCropModalForProfile] = useState(false);

  const handleShowCropForProfile = () => setShowCropModalForProfile(true);

  const handleCloseDialogForProfile = () => setShowCropModalForProfile(false);

  return (
    <div>
      <ProviderNav />
      <div className="about_us page-wrap">
        <section className="">
          <div className="My_profile">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="clinic_logo_div mt-5">
                    <div className="text-center">
                      <label htmlFor="" className="mb-3">
                        Clinic Logo
                      </label>
                      <div className="position-relative">
                        <img
                          src={
                            profile_image_base_url + clinicProfile?.clinic_image
                          }
                          onError={(e) => (e.target.src = clinicimg)}
                          alt=""
                          className="clinic_logo"
                        />

                        {/* <Link className="edit_pencil upload_logo">
                          <i className="fa fa-pencil"></i>
                        </Link> */}
                        <label
                          for="handleCop"
                          onClick={handleShowCrop}
                          className="edit_pencil upload_logo"
                        >
                          <i
                            className="fa fa-pencil"
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                        {showCropModal && (
                          <CropModal
                            show={showCropModal}
                            uploadedImage={
                              clinicProfile?.clinic_image
                                ? profile_image_base_url +
                                clinicProfile?.clinic_image
                                : null
                            }
                            handleCloseDialog={handleCloseDialog}
                            handleUploadNew={clinic_imageUpload}
                            imageType="clinic_image"
                            setClinicProfile={setClinicProfile}
                          />
                        )}

                        <input
                          type="file"
                          id="upload2"
                          onChange={(e) =>
                            clinic_imageUpload(e, "clinic_image")
                          }
                          className="hideInput"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="clinic_logo_div mt-5">
                    <div className="text-center">
                      <label htmlFor="" className="mb-3">
                        Clinic Image
                      </label>
                      <div className="position-relative">
                        <img
                          src={
                            profile_image_base_url +
                            clinicProfile?.profile_image
                          }
                          onError={(e) => (e.target.src = clinicimg)}
                          alt=""
                          className="clinic_logo"
                        />
                        {/* <Link className="edit_pencil upload_logo">
                          <i className="fa fa-pencil"></i>
                        </Link> */}
                        <label
                          onClick={handleShowCropForProfile}
                          className="edit_pencil upload_logo"
                        >
                          <i
                            className="fa fa-pencil"
                            style={{ cursor: "pointer" }}
                          />
                        </label>

                        {/* *************************  FOR PROFILE IMAGE ********************** */}
                        {showCropModalForProfile && (
                          <CropModal
                            show={showCropModalForProfile}
                            uploadedImage={
                              clinicProfile?.profile_image
                                ? profile_image_base_url +
                                clinicProfile?.profile_image
                                : null
                            }
                            handleCloseDialog={handleCloseDialogForProfile}
                            handleUploadNew={clinic_imageUpload}
                            imageType="profile_image"
                            setClinicProfile={setClinicProfile}
                          />
                        )}
                        <input
                          type="file"
                          id="upload"
                          onChange={(e) =>
                            clinic_imageUpload(e, "profile_image")
                          }
                          className="hideInput"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-4">
                <div className="col-lg-6 mb-4">
                  <div className="form-group">
                    <label for="" class="form-label">
                      Clinic Name
                    </label>
                    <input
                      type="text"
                      class="form-control input_field"
                      id=""
                      placeholder="Enter Clinic Name"
                      name="practice_name"
                      value={clinicProfile.practice_name}
                      onChange={settingClinicProfileValues}
                      onBlur={(e) =>
                        updateClinicProfileValidatorCheck(e.target.name)
                      }
                    />
                    {clinicProfileValidator.practice_name ? (
                      <div className="validation_message">
                        *Clinic name required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="form-group">
                    <label for="" class="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      class="form-control input_field"
                      id=""
                      placeholder="Enter Your Name"
                      name="full_name"
                      value={clinicProfile.full_name}
                      onChange={settingClinicProfileValues}
                      onBlur={(e) =>
                        updateClinicProfileValidatorCheck(e.target.name)
                      }
                    />
                    {clinicProfileValidator.full_name ? (
                      <div className="validation_message">
                        *Full name required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="form-group">
                    <label htmlFor="" className="mb-2">
                      Enter the description about the clinic
                    </label>
                    <textarea
                      className="form-control"
                      id=""
                      rows="5"
                      placeholder="Enter description..."
                      name="bio"
                      value={clinicProfile.bio}
                      onChange={settingClinicProfileValues}
                      onBlur={(e) =>
                        updateClinicProfileValidatorCheck(e.target.name)
                      }
                    ></textarea>
                    {clinicProfileValidator.bio ? (
                      <div className="validation_message">
                        *Description required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div className="form-group position-relative">
                    <label for="" class="form-label">
                      Phone Number
                    </label>

                    <input
                      type="number"
                      class="form-control input_field "
                      id=""
                      placeholder="Enter Phone Number"
                      name="phone_number"
                      value={clinicProfile?.phone_number}
                      onChange={settingClinicProfileValues}
                    />
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div className="form-group position-relative">
                    <label for="" class="form-label">
                      Email Address
                    </label>
                    <input
                      type="text"
                      class="form-control input_field "
                      id=""
                      placeholder="Enter Email Address"
                      name="email"
                      value={clinicProfile.email}
                      onChange={settingClinicProfileValues}
                      onBlur={(e) =>
                        updateClinicProfileValidatorCheck(e.target.name)
                      }
                    />
                    {/* <a href="" className="verify_color_btn">
                      Verify
                    </a> */}
                    {clinicProfileValidator.email ? (
                      <div className="validation_message">*Email required</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div className="form-group">
                    <label for="" class="form-label">
                      Website
                    </label>
                    <input
                      type="text"
                      class="form-control input_field"
                      id=""
                      name="website_link"
                      placeholder="Enter Website"
                      value={clinicProfile.website_link}
                      onChange={settingClinicProfileValues}
                    />
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="form-group">
                    <label for="" class="form-label">
                      Address
                    </label>
                    {/* <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6754130498734!2d77.78201857438131!3d12.992600487324713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c15ce8aff9%3A0x10089da2c7bd9b37!2sMakita%20Power%20Tools%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1697106756759!5m2!1sen!2sin"
                      width="100%"
                      height="200"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe> */}
                    {/* <input
                      type="text"
                      class="form-control input_field"
                      id=""
                      placeholder="Enter Your Address"
                      name='address'
                      value={clinicProfile.address}
                      onChange={settingClinicProfileValues}
                      onBlur={(e) => updateClinicProfileValidatorCheck(e.target.name)}
                    /> */}
                    <Autocomplete
                      class="form-control input_field"
                      apiKey={googleMapKey}
                      defaultValue={clinicProfile.address}
                      onPlaceSelected={(place) => settingAddress(place)}
                    />
                    {clinicProfileValidator.address ? (
                      <div className="validation_message">
                        *Address required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/*------------- Practice Hours ----------------- */}
                <div className="col-lg-12 mb-4">
                  <div className="card profile_card">
                    <div className="card-header d-flex justify-content-between">
                      <h5 className="">Practice Hours</h5>
                      <Link className="edit_icon" onClick={handleOpen}>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box class="wHourModal">
                          <div className="d-flex justify-content-md-between flex-md-row flex-column align-items-center">
                            <Typography
                              id="modal-modal-title"
                              className="mb-3 modal_heading"
                              variant="h4"
                              component="h4"
                            >
                              Create Working Hours
                            </Typography>
                            <button
                              className="theme_btn rounded"
                              onClick={addingTimeSlot}
                            >
                              Add New
                            </button>
                          </div>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            <div className="weekWrapper">
                              <div className="dayWrap">
                                <div className="select-contner">
                                  <select
                                    value={addtimeSlot.day}
                                    onChange={(e) =>
                                      // setTimeSlot({
                                      //   ...addtimeSlot,
                                      //   day: e.target.value,
                                      // })
                                      onSlotDayChange(e.target.value)
                                    }
                                    className="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option value="">Select..</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                  </select>
                                </div>
                                <label className="switch">
                                  <input
                                    checked={addtimeSlot.enabled}
                                    type="checkbox"
                                    onChange={(e) => slotSatus(e)}
                                  />
                                  <span className="slider"></span>
                                </label>
                                <h6 className="d-none d-md-block">Open</h6>
                                <MuiSelect
                                  className="time_select"
                                  value={
                                    addtimeSlot.start_time !== ""
                                      ? addtimeSlot.start_time
                                      : ""
                                  }
                                  onChange={(e) => settingTime(e, "start_time")}
                                >
                                  {generateHoursOptions()}
                                </MuiSelect>
                                {/* <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                  value={
                                    addtimeSlot.start_time !== ""
                                      ? addtimeSlot.start_time
                                      : // timeConversion(addtimeSlot.start_time)
                                        ""
                                  }
                                  onChange={(e) => settingTime(e, "start_time")}
                                /> */}

                                <MuiSelect
                                  className="time_select"
                                  value={
                                    addtimeSlot.end_time !== ""
                                      ? addtimeSlot.end_time
                                      : ""
                                  }
                                  onChange={(e) => settingTime(e, "end_time")}
                                >
                                  {generateHoursOptions(addtimeSlot.start_time)}
                                </MuiSelect>

                                {/* <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                  value={
                                    addtimeSlot.start_time !== ""
                                      ? addtimeSlot.end_time
                                      : // timeConversion(addtimeSlot.end_time)
                                        ""
                                  }
                                  onChange={(e) => settingTime(e, "end_time")}
                                /> */}
                                {/* <a href="#" className="btn btn-danger">
                                  <i class="fa-solid fa-trash"></i>
                                </a> */}
                              </div>

                              <hr />

                              <div className="weekWrapperContainer">
                                {addedTimeSlots.length > 0 ? (
                                  addedTimeSlots.map((ele, i) => (
                                    <div className="dayWrap">
                                      <div className="select-contner">
                                        <select
                                          value={ele.day}
                                          disabled={true}
                                          onChange={(e) => {
                                            ele.day = e.target.value;
                                            setAddedTimeSlots([
                                              ...addedTimeSlots,
                                            ]);
                                          }}
                                          class="form-select"
                                          aria-label="Default select example"
                                        >
                                          <option selected value="Monday">
                                            Monday
                                          </option>
                                          <option value="Tuesday">Tuesday</option>
                                          <option value="Wednesday">
                                            Wednesday
                                          </option>
                                          <option value="Thursday">
                                            Thursday
                                          </option>
                                          <option value="Friday">Friday</option>
                                          <option value="Saturday">
                                            Saturday
                                          </option>
                                          <option value="Sunday">Sunday</option>
                                        </select>
                                      </div>
                                      <label class="switch">
                                        <input
                                          type="checkbox"
                                          checked={ele.enabled}
                                          onChange={(e) => {
                                            ele.enabled = e.target.checked;
                                            setAddedTimeSlots([
                                              ...addedTimeSlots,
                                            ]);
                                          }}
                                        />
                                        <span className="slider"></span>
                                      </label>
                                      <h6 className="d-none d-md-block">Close</h6>
                                      <MuiSelect
                                        className="time_select"
                                        value={
                                          ele.start_time !== ""
                                            ? ele.start_time
                                            : //  timeConversion(ele.start_time)
                                            ""
                                        }
                                        onChange={(e) => {
                                          ele.start_time = e.target.value;

                                          setAddedTimeSlots([...addedTimeSlots]);
                                        }}
                                      >
                                        {generateHoursOptions()}
                                      </MuiSelect>
                                      {/* <input
                                      type="time"
                                      className="time_input"
                                      id=""
                                      value={
                                        ele.start_time !== ""
                                          ? ele.start_time
                                          : //  timeConversion(ele.start_time)
                                            ""
                                      }
                                      onChange={(e) => {
                                        ele.start_time = e.target.value;
                                        // "2023-12-06T" +
                                        // e.target.value
                                        // ":00.000Z";
                                        setAddedTimeSlots([...addedTimeSlots]);
                                      }}
                                    /> */}
                                      <MuiSelect
                                        className="time_select"
                                        value={
                                          ele.end_time !== ""
                                            ? ele.end_time
                                            : //  timeConversion(ele.start_time)
                                            ""
                                        }
                                        onChange={(e) => {
                                          ele.end_time = e.target.value;

                                          setAddedTimeSlots([...addedTimeSlots]);
                                        }}
                                      >
                                        {generateHoursOptions(ele.start_time)}
                                      </MuiSelect>
                                      {/* <input
                                      type="time"
                                      className="time_input"
                                      id=""
                                      value={
                                        ele.end_time !== ""
                                          ? ele.end_time
                                          : //  timeConversion(ele.end_time)
                                            ""
                                      }
                                      onChange={(e) => {
                                        ele.end_time = e.target.value;
                                        // "2023-12-06T" +
                                        // e.target.value +
                                        // ":00.000Z";
                                        setAddedTimeSlots([...addedTimeSlots]);
                                      }}
                                    /> */}
                                      <a
                                        onClick={() => removeTimeSlot(i)}
                                        className="btn btn-danger"
                                      >
                                        <i class="fa-solid fa-trash"></i>
                                      </a>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}

                              </div>



                              {/* <div className="dayWrap">
                                <h4>Wednesday</h4>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                <h6>Close</h6>
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                              </div>

                              <div className="dayWrap">
                                <h4>Thursday</h4>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                <h6>Close</h6>
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                              </div>

                              <div className="dayWrap">
                                <h4>Friday</h4>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                <h6>Open</h6>
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                              </div>

                              <div className="dayWrap">
                                <h4>Saturday</h4>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                <h6>Open</h6>
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                              </div>

                              <div className="dayWrap">
                                <h4>Sunday</h4>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                <h6>Close</h6>
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                                <input
                                  type="time"
                                  className="time_input"
                                  name=""
                                  id=""
                                />
                              </div> */}
                            </div>
                          </Typography>
                          <Typography className="modal_btn">
                            <Button
                              color="inherit"
                              sx={{ mr: 1 }}
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button class="theme_btn" onClick={apply_timeSlots}>
                              Apply
                            </Button>
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                    <div className="card-body">
                      {addedTimeSlots.length ? (
                        addedTimeSlots.map((ele) => (
                          <div className="d-flex justify-content-between">
                            <p>{ele.day}</p>
                            {ele?.enabled ? (
                              <p>
                                {
                                  localCon1(ele.start_time, ele.end_time)
                                    .formatTime
                                }
                                {/* {moment(ele.start_time).format("h:mm a")} -{" "}
                                {moment(ele.end_time).format("h:mm a")} */}
                              </p>
                            ) : (
                              "Closed"
                            )}
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      <hr />
                      {/* <div className="d-flex justify-content-between">
                        <p>Tuesday</p>
                        <p>10:00 AM - 08:00 PM</p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Wednesday</p>
                        <p>10:00 AM - 08:00 PM</p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Thursday</p>
                        <p>10:00 AM - 08:00 PM</p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Friday</p>
                        <p>10:00 AM - 08:00 PM</p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Saturday</p>
                        <p>10:00 AM - 08:00 PM</p>
                      </div>
                      <hr /> */}
                      {/* <div className="d-flex justify-content-between">
                        <p>Sunday</p>
                        <p>Closed</p>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="card profile_card">
                    <div className="card-header">
                      <h5 className="">Practice Photos</h5>
                    </div>
                    <div className="card-body">
                      <div class="imgUploaderContainer">
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          <p>
                            Drag & drop images here, or click to select up to 6
                          </p>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="image-preview">
                            <div className="image-list">
                              {selectedFiles.map((file, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Preview ${index + 1}`}
                                  // style={{
                                  //   maxWidth: "100%",
                                  //   maxHeight: "150px",
                                  // }}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeImage(index)}
                                  >
                                    x
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div className="card profile_card">
                    <div className="card-header">
                      <h5 className="">Services</h5>
                    </div>
                    <div className="card-body">
                      {/* <div className="services_chips_class">
                        <Chips
                          class="chips_class"
                          placeholder="Enter Services"
                          value={chips}
                          onChange={onChange}
                          suggestions={["Your", "Data", "Here"]}
                          fromSuggestionsOnly={false}
                          createChipKeys={[13, 32]}
                        />
                      </div> */}
                      <div className="services_chips_class">
                        {/* Multiselect */}
                        <Select
                          options={options}
                          value={clinicProfile.services}
                          onChange={(e) => settingOptions(e, "services")}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mb-4">
                  <div className="card profile_card">
                    <div className="card-header">
                      <h5 className="">Features and Benefits</h5>
                    </div>
                    <div className="card-body">
                      <div className="services_chips_class">
                        <Chips
                          class="chips_class"
                          placeholder="Enter features and benefits"
                          value={clinicProfile.features_and_benefits}
                          onChange={onChange}
                          suggestions={["Wifi", "Car Parking", "Near Station"]}
                          fromSuggestionsOnly={false}
                          createChipKeys={[13, 32]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="card profile_card">
                    <div className="card-header">
                      <h5 className="">Insurance they accept</h5>
                    </div>
                    <div className="card-body">
                      {/* <div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          Progressive
                        </div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          GMAC Insurance
                        </div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          SBI General Insurance
                        </div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          National Insurance Company
                        </div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          United India Insurance
                        </div>
                        <div className="mb-2">
                          <input type="checkbox" className="check_box_class" />{" "}
                          Acko General Insurance
                        </div>
                      </div> */}

                      {/* <div className="services_chips_class">
                        <Chips
                          class="chips_class"
                          placeholder="Enter Insurance"
                          value={chips}
                          onChange={onChange}
                          suggestions={["Your", "Data", "Here"]}
                          fromSuggestionsOnly={false}
                          createChipKeys={[13, 32]}
                        />
                      </div> */}
                      <div className="services_chips_class">
                        {/* Multiselect */}
                        <Select
                          options={options}
                          value={clinicProfile.insurance_they_accept}
                          onChange={(e) =>
                            settingOptions(e, "insurance_they_accept")
                          }
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit_btn_class">
                  <button
                    type="button"
                    className=""
                    onClick={() => navigateCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="theme_btn"
                    onClick={saveUpdateProfile}
                  >
                    Save
                  </button>
                </div>

                {/* <div className="col-lg-12 mb-4">
                  <div className="card profile_card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h5 className="">Profile Questions</h5>
                        <a href="">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      
                      <div className="form-group mb-3">
                        <label htmlFor="" className="mb-2">Enter Your Answer</label>
                        <textarea
                          name=""
                          className="form-control"
                          id=""
                          rows="3"
                          placeholder="Start typing your answer..."
                        ></textarea>
                      </div>

                      <div className="form-group mt-4">
                        <label htmlFor="" className="mb-2">
                          What are you and your team proud of at your practice
                        </label>
                        <textarea
                          name=""
                          className="form-control"
                          id=""
                          rows="3"
                          placeholder="Start typing your answer..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <ProviderFooter />
    </div>
  );
}
