import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getBranchListing} from '../../../utils/httpServices/httpService';

//Fetching data form api

export const fetchClinicBranches = createAsyncThunk('getClinicBranches',async () => {
    const response = await getBranchListing();
    return response;
} )

const getClinicBranches = createSlice({
    name:'get_ClinicBranches',
    initialState: {
        isLoading: false,
        data:null,
        isError:false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClinicBranches.pending,(state,action) => {
            console.log("asyncthunk on pending",state,"action",action)
            state.isLoading = true;
        } )
        builder.addCase(fetchClinicBranches.fulfilled, (state,action) => {
            console.log("asyncthunk on success",action)
            state.isLoading = false;
            state.data = action.payload
        })
        builder.addCase(fetchClinicBranches.rejected, (state,action) => {
            console.log("asyncThunk error",action.payload);
            state.isError = true;
        })
    }


})


export default getClinicBranches.reducer;