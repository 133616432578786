import React, { useEffect, useState } from 'react'
import companyLogo from '../../Assets/Logos/company-logo2.png'
import { Link } from 'react-router-dom'
import "./DatePickerComponent.scss"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function DatePickerComponent() {

    const [selectedDate, setSelectedDate] = useState(null);
    const availableDates = ["2023-11-02", "2023-10-31", "2023-10-30"]; // Sample available booking dates

    const isDateAvailable = (date) => {
        const formattedDate = date.toISOString().split("T")[0];
        return availableDates.includes(formattedDate);
    };


    return (
        <>

            <div>
                
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    filterDate={isDateAvailable} 
                    minDate={new Date()} 
                    open
                    
                />
                {selectedDate && (
                    <p>Selected Date: {selectedDate.toISOString().split("T")[0]}</p>
                )}
            </div>


        </>
    )
}
