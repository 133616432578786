import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header/Header";
import "./Dentist-Lists.scss";

import GoogleMapReact from "google-map-react";
import DentistCard from "../../components/DentistCard/DentistCard";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../Assets/logo-dsf-en-US.svg";
import logo2 from "../../Assets/Blue-Cross.svg";
import logo3 from "../../Assets/sunlife.svg";
import logo4 from "../../Assets/manulife.png";

// ---mui--

import { GoogleMap, LoadScript, Marker, useJsApiLoader } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import HomeNav from "../../components/HomeNav/HomeNav";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserHeader from "../../components/UserHeader/UserHeader";
// import { MdLocationPin } from "react-icons/md";
import { LocationOn } from "@mui/icons-material";
import Navbar from "../../components/Nav/Navbar";
import ResultNavabr from "../../components/ResultNavbar/ResultNavbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const AnyReactComponent = () => {
//     <div>{<LocationOn/>}</div>;
// }
const AnyReactComponent = ({ lat, lng }) => (
  <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
    {console.log("Here is a, b", lat, lng)}
    <LocationOnIcon style={{ color: "red" }} fontSize="large" />
  </div>
);

const markerIcon = process.env.REACT_APP_CLINIC_MARKER;

const ClinicMarker = React.memo(({ clinic, index }) => (
  <Marker
    key={index}
    position={{
      lat: clinic?.location?.coordinates?.[1],
      lng: clinic?.location?.coordinates?.[0],
    }}
    icon={{
      url: markerIcon,
      scaledSize: new window.google.maps.Size(20, 40) 
    }}
  />
));

export default function DentistList() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [clinicList, setClinicList] = useState([]);
  const [lat, setLat] = useState(parseFloat(localStorage.getItem("lat")));
  const [lng, setLng] = useState(parseFloat(localStorage.getItem("lng")));

  const [isMapLoaded, setIsMapLoaded] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // --Dialogue--

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [defaultProps , setDefaultProps] = useState({
  //   center: { lat: lat, lng: lng },
  //   zoom: 11,
  // })

  const defaultProps = {
    center: {
      lat: parseFloat(localStorage.getItem("lat")),
      lng: parseFloat(localStorage.getItem("lng")),
    },
    zoom: 11,
  };
  console.log("🚀 ~ DentistList ~ defaultProps:", defaultProps);

  // const defaultProps = {
  //   center: { lat: lat, lng: lng },
  //   zoom: 11,
  // };

  const getClinicLists = () => {
    var list = JSON.parse(localStorage.getItem("clinic-lists"));
    setClinicList(list);
  };

  useEffect(() => {
    getClinicLists();
  }, []);

  useEffect(() => {
    // Check if Google Maps API is already loaded
    if (window.google && window.google.maps) {
      setIsMapLoaded(true);
    }
  }, []);

  const markers = useMemo(
    () =>
      clinicList?.map((clinic, index) => (
        <ClinicMarker key={index} clinic={clinic} index={index} />
      )),
    [clinicList]
  );
  const [zoom ,setZoom] = useState(6)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(defaultProps?.center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);


  useEffect(()=>{
  const timer =   setTimeout(()=>{
     setZoom(11)

   },3000)

   return ()=>{
   clearTimeout(timer)
   }
  },[isLoaded])

  return (
    <>
      {/* <UserHeader /> */}
      <ResultNavabr />
      <div className="page-wrap">
        <section className="dentist-list-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-7 col-lg-7 ps-md-5 ps-sm-3">
                <div className="header">
                  <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-md-6 col">
                      <h5 className="">
                        {clinicList?.length}{" "}
                        {clinicList?.length === 0
                          ? `Clinics`
                          : clinicList?.length < 2
                          ? `Clinic`
                          : "Clinics"}{" "}
                        Found
                      </h5>
                    </div>
                    <div className="col-md-6 ">
                      <div className="view-icons d-grid">
                        {/* <Link to="" variant="outlined" onClick={handleClickOpen} className='btn btn-outline-dark ms-auto'>Modify My Insurance Details</Link> */}
                        <div>
                          <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                          >
                            <DialogTitle
                              className="text-center mb-0"
                              sx={{ m: 0, p: 2 }}
                              id="customized-dialog-title"
                            >
                              Enter Insurance
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>

                            <p className="text-center px-4 pb-3  fw-bold">
                              To determine which dentist is in your network,
                              please verify your insurance below and the results
                              page will update with that information.
                            </p>

                            <DialogContent className="dentist-tabs m-auto">
                              <Box
                                sx={{
                                  maxWidth: { xs: 320, sm: 480 },
                                  bgcolor: "background.paper",
                                }}
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  aria-label="scrollable auto tabs example"
                                >
                                  <Tab label="Step One" />
                                  <Tab label="Step Two" />
                                </Tabs>
                                {value === 0 && (
                                  <Box p={3}>
                                    <p className="fs-4 fw-bold text-center">
                                      Who is your insurance Provider?
                                    </p>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <label className="">
                                              <span className="step-box-text">
                                                Aetna
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Cigna
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Delta Dental
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Guardian
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Medicaid
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              MetLife
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                )}
                                {value === 1 && (
                                  <Box p={3}>
                                    <p className="fs-4 fw-bold text-center">
                                      What is your insurance Plan?
                                    </p>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Delta Dental Pro
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Other Plan...
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                          <div className="select-form">
                                            <select
                                              className="form-select form-select-lg "
                                              aria-label="Default select example"
                                            >
                                              <option selected>
                                                Insurance Plan
                                              </option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                )}
                              </Box>
                            </DialogContent>
                            <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                Save changes
                              </Button>
                            </DialogActions>
                          </BootstrapDialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dentist-list">
                  {clinicList?.map((val) => (
                    <DentistCard
                      name={val?.practice_name}
                      address={val?.address}
                      rating={val?.rating}
                      // location={' Newyork, USA'}
                      profile={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.profile_image}`}
                      // likes={' 98%'}
                      feedback={`${val?.reviews_count} feedback`}
                      booking={`Booked by ${val?.appointmnet_count} people in the last 30 days`}
                      // network={' in Network'}
                      distance={`${
                        val?.distance !== null
                          ? `, ${val?.distance.toFixed(2)}`
                          : ""
                      }`}
                      documentImages={val?.clinic_documents}
                      services={val?.services}
                      id={val?._id}
                      total_count={val?.total_count}
                    />
                  ))}
                </div>
              </div>
              <div className="col-xl-5 col-lg-5">
                <div className="dentist-list__map">
                  <div className="map_ofList">
                    {/* <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                      }}
                      center={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent lat={defaultProps.center.lat} lng={defaultProps.center.lng}  />
                    </GoogleMapReact> */}

                     {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={{ height: "100%", width: "100%" }}
                      center={defaultProps.center}
                      zoom={zoom}
                      
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                     {clinicList?.map((clinic, index) => (
                            <ClinicMarker
                              key={index}
                              clinic={clinic}
                              index={index}
                            />
                          ))}
                      <></>
                    </GoogleMap>
                    ) : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
