import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Nav/Navbar';


import { getTermOfUserService } from "../../utils/httpServices/httpService"

export default function TermsOfUse() {
const [termOfUseTitle, setTermOfUseTitle] = useState('');
const [termOfUseDescription, setTermOfUseDescription] = useState('');

async function getTermAndCondition(){
try {
    const resp = await getTermOfUserService();
    console.log("term of use data", resp);
    if (resp?.code === 200) {
        setTermOfUseTitle(`<h2 className="breadcrumb-title">
           ${resp.data.title }</h2>`)

  const formattedDescription = resp?.data?.description.replace(/\n<[^>]+><\/[^>]+>/g, '<br>');
           setTermOfUseDescription(`
        <div className="terms-text">${formattedDescription}</div> `)
        // setTermOfUseDescription(resp.data.description)
    }
  } catch (err) {
    console.log(err)
    // alert(err)
  }
}

useEffect(() => {
    getTermAndCondition();
},[])

    return (
        <>
         <Navbar />
            <div className='page-wrap'>
                <div className="breadcrumb-bar-two">
                    <div className="container">
                        <div className="row align-items-center inner-banner">
                            <div className="col-md-12 col-12 text-center">
                                {/* <h2 className="breadcrumb-title">Terms &amp; Condition</h2> */}
                                <div dangerouslySetInnerHTML={
                                      { __html: termOfUseTitle }
                                   } />
                                  
                                <nav aria-label="breadcrumb" className="page-breadcrumb ">
                                    {/* <ol className="breadcrumb text-center">
                                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">Terms &amp; Condition</li>
                                </ol> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='term-condition mb-4'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                 <div className="terms-content">
                                 <div dangerouslySetInnerHTML={
                                      { __html: termOfUseDescription }
                                   } />
                                 
                                  {/*  <div className="terms-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                    <div class="terms-text terms-list">
                                        <p><i className="fas fa-circle-check"></i> At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et</p>
                                        <p><i className="fas fa-circle-check"></i> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,</p>
                                        <p><i className="fas fa-circle-check"></i> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                        <p><i className="fas fa-circle-check"></i> At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et</p>
                                        <p><i className="fas fa-circle-check"></i> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,</p>
                                        <p><i className="fas fa-circle-check"></i> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    </div>
                                    <div class="terms-text">
                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>*/}
                                </div>
                                {/* <div class="terms-btn">
                                    <Link className="btn btn-right-now">Not right now...</Link>
                                    <Link className="btn btn-primary prime-btn">I agree with terms</Link>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}
