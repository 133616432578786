import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header/Header";
import "./Dentist-List.scss";

import GoogleMapReact from "google-map-react";
import DentistCard from "../../components/DentistCard/DentistCard";

// ---mui--

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserHeader from "../../components/UserHeader/UserHeader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const AnyReactComponent = () => (
  <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
    <LocationOnIcon style={{ color: "red" }} fontSize="large" />
  </div>
);

// const markerIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
const markerIcon = process.env.REACT_APP_CLINIC_MARKER;

const ClinicMarker = React.memo(({ clinic, index }) => (
  <Marker
    key={index}
    position={{
      lat: clinic?.location?.coordinates?.[1],
      lng: clinic?.location?.coordinates?.[0],
    }}
    icon={{
      url: markerIcon,
      scaledSize: new window.google.maps.Size(20, 40),
    }}
  />
));


export default function DentistList() {
  const [value, setValue] = React.useState(0);
  const [clinicList, setClinicList] = useState([]);
  const [lat, setLat] = useState(parseFloat(localStorage.getItem("lat")));
  const [lng, setLng] = useState(parseFloat(localStorage.getItem("lng")));

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // --Dialogue--

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [defaultProps, setDefaultProps] = useState({
    center: { lat: lat, lng: lng },
    zoom: 11,
  });



  const [zoom ,setZoom] = useState(6)

  // const defaultProps = {
  //   center: { lat: lat, lng: lng },
  //   zoom: 11,
  // };
  const locationCoordinates = {
    lat: lat,
    lng: lng,
  };

  const getClinicLists = () => {
    var list = JSON.parse(localStorage.getItem("clinic-lists"));
    console.log(list);
    setClinicList(list);
  };

  useEffect(() => {
    getClinicLists();
    const lat = parseFloat(localStorage.getItem("lat"));
    const lng = parseFloat(localStorage.getItem("lng"));
    setLat(lat);
    setLng(lng);
  }, []);

  const markers = useMemo(
    () =>
      clinicList?.map((clinic, index) => (
        <ClinicMarker key={index} clinic={clinic} index={index} />
      )),
    [clinicList]
  );

  const handleLoad = () => {
    setIsMapLoaded(true);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(defaultProps?.center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);


  useEffect(()=>{
    const timer =   setTimeout(()=>{
       setZoom(11)
  
     },3000)
  
     return ()=>{
     clearTimeout(timer)
     }
    },[isLoaded])

  return (
    <>
      <UserHeader />
      <div className="page-wrap">
        <section className="dentist-list-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-7 col-lg-7  ">
                <div className="header">
                  <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-md-6 col">
                      <h5 className="">
                        {clinicList.length}{" "}
                        {clinicList.length === 0
                          ? `Clinics`
                          : clinicList.length < 2
                          ? `Clinic`
                          : "Clinics"}{" "}
                        Found
                      </h5>
                    </div>
                    <div className="col-md-6 ">
                      <div className="view-icons d-grid">
                        {/* <Link to="" variant="outlined" onClick={handleClickOpen} className='btn btn-outline-dark ms-auto'>Modify My Insurance Details</Link> */}
                        <div>
                          <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                          >
                            <DialogTitle
                              className="text-center mb-0"
                              sx={{ m: 0, p: 2 }}
                              id="customized-dialog-title"
                            >
                              Enter Insurance
                            </DialogTitle>
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon />
                            </IconButton>

                            <p className="text-center px-4 pb-3  fw-bold">
                              To determine which dentist is in your network,
                              please verify your insurance below and the results
                              page will update with that information.
                            </p>

                            <DialogContent className="dentist-tabs m-auto">
                              <Box
                                sx={{
                                  maxWidth: { xs: 320, sm: 480 },
                                  bgcolor: "background.paper",
                                }}
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  aria-label="scrollable auto tabs example"
                                >
                                  <Tab label="Step One" />
                                  <Tab label="Step Two" />
                                </Tabs>
                                {value === 0 && (
                                  <Box p={3}>
                                    <p className="fs-4 fw-bold text-center">
                                      Who is your insurance Provider?
                                    </p>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <label className="">
                                              <span className="step-box-text">
                                                Aetna
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Cigna
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Delta Dental
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Guardian
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Medicaid
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              MetLife
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                )}
                                {value === 1 && (
                                  <Box p={3}>
                                    <p className="fs-4 fw-bold text-center">
                                      What is your insurance Plan?
                                    </p>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Delta Dental Pro
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="step-box-content ">
                                            <span className="step-box-text">
                                              Other Plan...
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                          <div className="select-form">
                                            <select
                                              className="form-select form-select-lg "
                                              aria-label="Default select example"
                                            >
                                              <option selected>
                                                Insurance Plan
                                              </option>
                                              <option value="1">One</option>
                                              <option value="2">Two</option>
                                              <option value="3">Three</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                )}
                              </Box>
                            </DialogContent>
                            <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                Save changes
                              </Button>
                            </DialogActions>
                          </BootstrapDialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dentist-list">
                  {clinicList?.map((val, index) => (
                    <DentistCard
                      key={index}
                      name={val?.practice_name}
                      address={val?.address}
                      rating={val?.rating}
                      // location={' Newyork, USA'}
                      profile={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.profile_image}`}
                      // likes={' 98%'}
                      feedback={`${
                        val?.reviews_count === undefined
                          ? "0"
                          : val?.reviews_count
                      } feedback`}
                      booking={`Booked by ${val?.appointmnet_count} people in the last 30 days`}
                      // network={' in Network'}
                      distance={`${
                        val?.distance !== null ? `, ${val?.distance}` : ""
                      }`}
                      documentImages={val?.clinic_documents}
                      services={val?.services}
                      id={val?._id}
                      total_count={val?.total_count}
                    />
                  ))}

                  {/* <DentistCard
                                        name={'Fuller Smiles Huntington Park'}
                                        address={'2553 East Slauson Avenue Huntington Park, CA 90255'}
                                        rating={15}
                                        location={' Florida, USA'}
                                        profile={logo2}
                                        likes={' 100%'}
                                        feedback={' 15 feedback'}
                                        booking={'Booked by 30 people in the last 30 days'}
                                        network={' Out Network'}
                                        distance={'4.4ml'}

                                    />

                                    <DentistCard
                                        name={'Eaglerock Dental Clinic'}
                                        address={' 1739 Colorado Boulevard Los Angeles, CA 90041'}
                                        rating={25}
                                        location={' Georgia, USA'}
                                        profile={logo3}
                                        likes={' 96%'}
                                        feedback={' 25 feedback'}
                                        booking={'Booked by 22 people in the last 30 days'}
                                        network={' in Network'}
                                        distance={'4.9ml'}


                                    />
                                    

                                    <DentistCard
                                        name={'Monterey Hills Dental'}
                                        address={'5906 Monterey Road Los Angeles, CA 90042'}
                                        rating={25}
                                        location={' Florida, USA'}
                                        profile={logo4}
                                        likes={' 96%'}
                                        feedback={' 25 feedback'}
                                        booking={'Booked by 22 people in the last 30 days'}
                                        network={' in Network'}
                                        distance={'4.9ml'}


                                    /> */}
                </div>
              </div>
              <div className="col-xl-5 col-lg-5">
                <div className="dentist-list__map">
                  <div
                    style={{
                      height: "100vh",
                      width: "100%",
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    {/* <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent {...locationCoordinates} />
                    </GoogleMapReact> */}
                    {/* <LoadScript
                      googleMapsApiKey={
                        process.env.REACT_APP_GOOGLE_MAP_API_KEY
                      }
                      onLoad={handleLoad}
                    >
                      {isMapLoaded ? (
                        <GoogleMap
                          center={defaultProps.center}
                          zoom={defaultProps.zoom}
                          mapContainerStyle={{ height: "100%", width: "100%" }}
                        >
                          {clinicList?.map((clinic, index) => (
                            <ClinicMarker
                              key={index}
                              clinic={clinic}
                              index={index}
                            />
                          ))}
                        </GoogleMap>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </LoadScript> */}

                    {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={{ height: "100%", width: "100%" }}
                      center={defaultProps.center}
                      zoom={zoom}
                      
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                     {clinicList?.map((clinic, index) => (
                            <ClinicMarker
                              key={index}
                              clinic={clinic}
                              index={index}
                            />
                          ))}
                      <></>
                    </GoogleMap>
                    ) : <></>}

                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

// {isMapLoaded ? (
//   <GoogleMap
//     center={defaultProps.center}
//     zoom={defaultProps.zoom}
//     mapContainerStyle={{ height: "100%", width: "100%" }}
//   >
//     {/* <Marker key={1} position={defaultProps.center} /> */}
//     {clinicList?.map((clinic, index) => (
//     <ClinicMarker key={index} clinic={clinic} index={index} />
//       ))}
//   </GoogleMap>
// ) : (
//   <LoadScript
//     googleMapsApiKey={

//     }
//   >
//     <GoogleMap
//       center={defaultProps.center}
//       zoom={defaultProps.zoom}
//       mapContainerStyle={{ height: "100%", width: "100%" }}
//     >
//     {clinicList?.map((clinic, index) => (
//     <ClinicMarker key={index} clinic={clinic} index={index} />
//       ))}
//     </GoogleMap>
//   </LoadScript>

// )}
