import React, { useEffect, useState } from 'react'
import useContactUsForm from '../../../hooks/useContactUsForm';
import { getcontctdetails } from '../../../utils/httpServices/httpService';

const ContactUs = () => {

    const {formik} = useContactUsForm();
    const [contactDetails , setContactDetails] = useState()

    const getContactDetails =async ()=> {
      try {
        const resp = await getcontctdetails();
              if (resp?.code === 200) {
            setContactDetails(resp.data);
        }
      } catch (error) {
        //handle for error 
      }
    }

    useEffect(()=> {
       getContactDetails()
    },[])

  return (
   
    <div className="card">
    <form  onSubmit={formik.handleSubmit}> 
      <div class="contact-1 py-4 py-md-5">
        <div class="container">
          <div class="row">
            <div class="col-xl-10 mx-auto ">
              <div class="col-xl-9 mb-4">
                <h1 class="h3 mb-4">We would love to hear from you</h1>
                <p class="h5 mb-2">
                  Please submit your information and we will get back to
                  you.
                </p>
                <p>
                  Call us at
                  <a
                  //  href="tel:{contactDetails?.phone}"
                    class="text-primary border-bottom border-transparent hover:border-primary"
                  >
                 {" "}{contactDetails?.phone}
                  </a>
                </p>
              </div>

              <div class="row mt-4 mt-md-5">
                <div class="col-md-8">
                  <div class="contact-form">
                    <div class="row gx-3">
                      <div class="col-sm-6 mb-3">
                        <input
                          type="text"
                          placeholder="First Name"
                          class="form-control"
                          {...formik.getFieldProps("first_name")}
                     />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="form-error">{formik.errors.first_name}</div>
                    ) : null}
                      </div>
                      <div class="col-sm-6 mb-3">
                        <input
                          type="text"
                          placeholder="Last Name"
                          class="form-control"
                          {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="form-error">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                      </div>
                      <div class="col-sm-6 mb-3">
                        <input
                          type="text"
                          placeholder="Email address"
                          class="form-control"
                          {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="form-error">{formik.errors.email}</div>
                    ) : null}
                      </div>
                      <div class="col-sm-6 mb-3">
                        <input
                          type="number"
                          placeholder="Phone Number"
                          class="form-control"
                          {...formik.getFieldProps("phone_number")}
                    />
                    {/* {formik.touched.phone_number && formik.errors.phone_number ? (
                      <div className="form-error">{formik.errors.phone_number}</div>
                    ) : null} */}
                      </div>
                      <div class="col-12">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="4"
                          placeholder="Leave us a message..."
                          class="form-control"
                        {...formik.getFieldProps("description")}
                        ></textarea>
                  
                    {formik.touched.description && formik.errors.description ? (
                      <div className="form-error">{formik.errors.description}</div>
                    ) : null}
                      </div>
                    </div>
                    <div class="text-end mt-4 mt-md-5">
                      <button type="submit" class=" theme-outline-btn">
                        Send a Message
                        <i class="fas fa-chevron-right ms-2"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 px-4 mt-4 mt-md-0">
                  <div class="bg-light rounded py-4 px-3">
                    <h5 class="h5 mb-3">Help</h5>
                    <p class="text-muted mb-4">
                      Need help or have any query? Don't hesitate, you can
                      directly shoot us an 
                      <a
                        href={`mailto:${contactDetails?.email}`}
                        target='_blank'
                        class="text-primary px-2 border-bottom border-transparent hover:border-primary"
                      >
                        email
                      </a>
                         or call us at 
                      <a
                        // href="tel:{contactDetails?.phone}"
                        
                        class="text-primary border-bottom border-transparent hover:border-primary"
                      >
                      {" "}{contactDetails?.phone}
                      </a>
                    </p>
                    {/* <p class="text-muted">
                      You can move to
                      <a
                        href="#"
                        class="text-primary px-2 border-bottom border-transparent hover:border-primary"
                      >
                        FAQs
                      </a>
                      or
                      <a
                        href="#"
                        class="text-primary px-2 border-bottom border-transparent hover:border-primary"
                      >
                        Support
                      </a>
                      page to get more information about our site.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </form>
    </div>
 
  )
}

export default ContactUs