import React, { useState, useEffect } from "react";
import "./HistoryRequest.scss";
import Tables from "../../../../components/Tables/Tables";
import RightSidebar from "../../../../components/RightSidebar/RightSidebar";

import { getDentistAppointsByIDService } from "../../../../utils/httpServices/httpService";

import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import dayjs from 'dayjs';

const customContentStyle = {
  width: "30%",
  height: "200px",
  maxWidth: "none",
};

export default function HistoryAppointment({ screenName }) {
  const [open1, setOpen1] = React.useState(false);

  const { RangePicker } = DatePicker;

  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');

  const [dateVal, setDateVal] = useState([startOfMonth, endOfMonth]);
  const [buttonVal, setButtonVal] = useState("");

  const dateHandler = (value) => {
    setDateVal(value);
    setButtonVal("");
    console.log(value);
  };

  const buttonHandler = () => {
    setDateVal([]);
    setButtonVal("today");
  };

  function filterByDate() {
    if (dateVal && dateVal.length === 2) {
      const firstDate = dateVal[0].$d;
      const secondDate = dateVal[1].$d;
      let start = new Date(firstDate);
      let end = new Date(secondDate);

      let added_start_date = start.getDate();
      let added_end_date = end.getDate() + 1;
      start.setDate(added_start_date); // Modify the cloned date
      end.setDate(added_end_date); // Modify the cloned date

      start.setHours(0, 0, 0, 0); // Set time to 00:00:00
      end.setHours(0, 0, 0, 0); // Set time to 00:00:00

      // console.log("new time to ISO string: ", clonedEndtime.toISOString());

      return { start_time: start.toISOString(), end_time: end.toISOString() };
    }
  }

  const handleClickOpenDialog1 = () => {
    setOpen1(true);
  };

  const handleCloseDialog1 = () => {
    setOpen1(false);
  };

  // ---accept-dialogue-open---

  const [open, setOpen] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // --rightSidebar--

  const [isOpen, setIsOpen] = useState(false);
  // const [divWidth, setDivWidth] = useState(0);

  const [id, setId] = useState(null);

  const handleClickOpen = (id) => {
    console.log("appointment id", id);
    setId(id);
    setIsOpen(true);
    // setDivWidth(30);

    console.log(setIsOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const state = useSelector((state) => state);
  const [branch_id, setBranch_id] = useState(null);

  const [appointmentList, setAppointmentList] = useState([]);

  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    offset: 0,
  });

  async function getDentistAppointsByID() {
    try {
      let start_date = "";
      let end_date = "";

      if (buttonVal !== "") {
        start_date = timeConversionForFilter(0).end_time;
        end_date = timeConversionForFilter(1).end_time;
      } else if (dateVal != []) {
        let getDate = filterByDate();
        start_date = getDate?.start_time;
        end_date = getDate?.end_time;
      }
      let obj = {
        branch_id: branch_id,
        screen: screenName,
        limit: pageSize,
        offset:  offset*pageSize,
        start_date: start_date,
        end_date: end_date,
      };
      let resp = await getDentistAppointsByIDService(obj);
      console.log("response", resp);
      if (resp.code === 200) {
        setAppointmentList(resp.data);
        paginationWorking(resp.data, resp.count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log("state in request component", state);
    if (state?.getClinicBrances?.data?.data) {
      setBranch_id(
        state?.getClinicBrances?.data?.data[state?.selectedBranchIndex]._id
      );
    }
  }, [branch_id, state]);

  // useEffect(() => {
  //   if (branch_id !== null) getDentistAppointsByID();
  // }, [branch_id]);

  const [numberOfPages, setNumberOfPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [filter_start_date, setFilter_end_date] = useState({
    start_date: timeConversionForFilter(0).end_time,
    end_date: timeConversionForFilter(1).end_time,
  });

  // useEffect(() => {
  //   getDentistAppointsByID();
  // }, [dateVal, buttonVal]);

  function nextPage() {
    let value = offset + 1;
    setOffset(value);
  }

  function previousPage() {
    let value = offset - 1;
    setOffset(value);
  }

  function changingPage(i) {
    setOffset(i);
  }

  const [totalCount, setTotalCount] = useState(0);

  function paginationWorking(list, total) {
    setTotalCount(total);
    console.log("pagination function", list, total);
    let count = total === undefined ? 0 : total;
    let numberOfPages = Math.ceil(count / pageSize);
    let arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i);
    }
    console.log("arr", arr);
    setNumberOfPages(arr);
  }

  function timeConversionForFilter(day) {
    let todayEndtime = new Date(); // Current date and time
    let clonedEndtime = new Date(todayEndtime); // Create a copy of current date and time
    let added_date = clonedEndtime.getDate() + day;
    clonedEndtime.setDate(added_date); // Modify the cloned date

    clonedEndtime.setHours(0, 0, 0, 0); // Set time to 00:00:00

    console.log("new time to ISO string: ", clonedEndtime.toISOString());

    return { end_time: clonedEndtime.toISOString() };

    // const startInputTime = new Date();
    // let todayEndtime = new Date();
    // const endInputTime = new Date(todayEndtime.setDate(todayEndtime.getDate() + day));

    // startInputTime.setHours(0, 0, 0, 0);
    // endInputTime.setHours(0, 0, 0, 0);

    // console.log("new time", startInputTime,endInputTime)
    // console.log("new time to ISO string " , startInputTime.toISOString(), endInputTime.toISOString())

    // return {start_time:startInputTime.toISOString() ,end_time:endInputTime.toISOString()}
  }

  ///////////////////////////////////////////////////////////////////////////////

  const [filterName, setFilterName] = useState("today");

  function filterFunction(event) {
    console.log("event", event.target.value);
    const e = event.target.value;
    if (e === "today") {
      setFilterName("today");
      setFilter_end_date({
        start_date: timeConversionForFilter(0).end_time,
        end_date: timeConversionForFilter(1).end_time,
      });
    } else if (e === "last1week") {
      setFilterName("last1week");
      setFilter_end_date({
        start_date: timeConversionForFilter(-7).end_time,
        end_date: timeConversionForFilter(1).end_time,
      });
    } else {
      setFilterName("yesterday");
      setFilter_end_date({
        start_date: timeConversionForFilter(-1).end_time,
        end_date: timeConversionForFilter(0).end_time,
      });
    }
  }

  ////////////////////////////////////////////////////

  useEffect(() => {
  if(branch_id){

    getDentistAppointsByID();
  }
  }, [dateVal, buttonVal, offset, branch_id]);

  return (
    <>
      <div className="patient_data">
        <div className="card mb-0 shadow border-0">
          <div class="card-header">
            <div class="row align-items-center gy-3">
              <div class="col-md-4 col-lg-6 text-md-start">
                <h4>Appointments History</h4>
                <p class="mb-0">
                  {totalCount} appointment {filterName}
                </p>
              </div>
              <div class="col-md-8 col-lg-6 text-md-end">
                <div className="form-group">
                  <label htmlFor="">Filter By Date</label>
                  {/* <select
                    class="form-select"
                    onChange={(e) => filterFunction(e)}
                    aria-label="Default select example"
                  >
                    <option selected value="today">
                      Today
                    </option>
                    <option value="yesterday">Yesterday</option>
                    <option value="last1week">Last One Week</option>
                  </select> */}
                  <div className="filter-data">
                    <button
                      className={`btn btn-sm ${
                        buttonVal === "today" ? "btn-active" : ""
                      } me-2`}
                      onClick={buttonHandler}
                    >
                      Today
                    </button>
                    <Space direction="vertical" size={12}>
                      <RangePicker value={dateVal} onChange={dateHandler} />
                      {/* <RangePicker showTime />
                    <RangePicker picker="week" />
                    <RangePicker picker="month" />
                    <RangePicker picker="quarter" />
                    <RangePicker
                      picker="year"
                      id={{
                        start: "startInput",
                        end: "endInput",
                      }}
                      onFocus={(_, info) => {
                        console.log("Focus:", info.range);
                      }}
                      onBlur={(_, info) => {
                        console.log("Blur:", info.range);
                      }}
                    /> */}
                    </Space>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <Tables
              handleClickOpen={handleClickOpen}
              data={appointmentList}
              offset={offset}
              limit={pageSize}
            />
          </div>
          <div className="card-footer pt-2">
            <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
              <ul className="page-number m-0 p-0 list-unstyled d-flex gap-2">
                {/* <li className="text-center theme_btn px-3 py-2 text-white rounded">1</li>
                                                        <li className="text-center bg-soft-primary text-dark rounded">2</li>
                                                        <li className="text-center text-dark rounded">...</li>
                                                        <li className="text-center bg-soft-primary text-dark rounded">6</li> */}
                {numberOfPages.map((ele, i) => (
                  <li
                    className={
                      offset === i
                        ? "text-center theme_btn px-3 py-2 text-white rounded"
                        : "text-center bg-soft-primary text-dark rounded"
                    }
                    onClick={() => changingPage(i)}
                  >
                    {i + 1}
                  </li>
                ))}
              </ul>
              {/* <div>
                                                        <button className="theme-outline-btn rounded me-3" type="button">
                                                            <span className="btn-inner">
                                                                <span className="text d-inline-block align-middle">Previous</span>
                                                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={8}
                                                                        height={8}
                                                                        viewBox="0 0 8 8"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </button>
                                                        <button className="theme_btn rounded me-3" type="button">
                                                            <span className="btn-inner">
                                                                <span className="text d-inline-block align-middle">Next</span>
                                                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={8}
                                                                        height={8}
                                                                        viewBox="0 0 8 8"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div> */}
              <div>
                {offset !== 0 ? (
                  <button
                    onClick={() => previousPage()}
                    className="theme-outline-btn rounded me-3"
                    type="button"
                  >
                    <span className="btn-inner">
                      <span className="text d-inline-block align-middle">
                        Previous
                      </span>
                      <span className="icon d-inline-block align-middle ms-1 ps-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={8}
                          height={8}
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                ) : (
                  <></>
                )}
                {offset < numberOfPages.length - 1 ? (
                  <button
                    onClick={() => nextPage()}
                    className="theme_btn rounded me-3"
                    type="button"
                  >
                    <span className="btn-inner">
                      <span className="text d-inline-block align-middle">
                        Next
                      </span>
                      <span className="icon d-inline-block align-middle ms-1 ps-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={8}
                          height={8}
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                ) : (
                  <></>
                )}
                {/* <button className="theme-outline-btn rounded me-3" type="button">
                                                            <span className="btn-inner">
                                                                <span className="text d-inline-block align-middle">Previous</span>
                                                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={8}
                                                                        height={8}
                                                                        viewBox="0 0 8 8"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </button>
                                                        <button className="theme_btn rounded me-3" type="button">
                                                            <span className="btn-inner">
                                                                <span className="text d-inline-block align-middle">Next</span>
                                                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={8}
                                                                        height={8}
                                                                        viewBox="0 0 8 8"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          className={`right-sidebar shadow ${isOpen ? "open" : "closed"}`}
        // style={{ width: `${divWidth}rem`, transition: "width 0.6s ease" }}
      >
        <RightSidebar handleClose={handleClose} id={id} type="History" />
      </div>
    </>
  );
}
