import React, { useState } from "react";
import doctor01 from "../../Assets/profile01.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
// import chatUser01 from "../../Assets/chatUser2.jpg";
import chatUser01 from "../../Assets/noUserImage.png";
import "./RightSidebar.scss";
import {
  getAppointmentDetailById,
  changeAppointmentstatus_schedule,
  getDashboard_approveOrRejectAppontment,
} from "../../utils/httpServices/httpService";
import moment from "moment";
import { ResheduleApp } from "../ResheduleApp/ResheduleApp";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function RightSidebar({ handleClose, id, type, listUpdate,appointmentDetail }) {
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  console.log("id", id);
  const [details, setDetails] = React.useState(null);

  const [appointment_date, setAppointment_date] = React.useState("");

  const navigate = useNavigate()

  const [openCancelModal , setOpenCancelModal] = useState(false)

  const handleCloseCancelModal = ()=>{
    setOpenCancelModal(false)
  }

  const handleOpenCancelModal = ()=>{
    setCancelReason('')
    setOpenCancelModal(true)
    
  }

  const [cancelReason , setCancelReason ] = useState('')

  async function getAppointmentDetailByIdFunction() {
    if (id) {
      try {
        let resp = await getAppointmentDetailById(id);
        if (resp?.code === 200) {
          setDetails(resp?.data);
          let date = resp?.data?.start_time;
          let converted_date = new Date(date);
          console.log("date", date, new Date(date));
          setAppointment_date(converted_date);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  async function changeAppointmentstatus_scheduleFunc(status) {
    if (id) {
      try {
        let obj = {
          appointment_id: id,
          is_visited: status,
        };
        let resp = await changeAppointmentstatus_schedule(obj);
        if (resp?.code === 200) {
          console.log("change appointment for schedule", resp);
          handleClose();
          listUpdate();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  async function approveOrRejectAppointmentFunc(status, showToast = false) {

    if(status === 'rejected' && !cancelReason){
      toast.info('Please enter the reason for cancellation');
      return;
    }

    let obj = {
      appointment_id: id,
      status: status,
    };

    if(status === 'rejected'){
      obj.cancel_reason = cancelReason
    }
    try {
      const resp = await getDashboard_approveOrRejectAppontment(obj);
      console.log("approve and reject", resp);
      setCancelReason('')
      handleCloseCancelModal()
      if(status === "accepted"){
        toast.success("Appointment accepted successfully")
      }else if(status === "rejected"){
        toast.success("Appointment rejected successfully")
      }
      if (resp?.code === 200) {
        // if(showToast){

        //   toast.success(resp?.message)
        // }
        //   getDashboard_appointmentRequestFunc(selectedProvider_id)
        handleClose();
        listUpdate();
      }
    } catch (err) {
      console.log("approve reject error", err);
      
    }
  }

  React.useEffect(() => {
    getAppointmentDetailByIdFunction();
  }, [id]);

  console.log("details", details);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmReshedule = ()=>{
    handleClose();
    listUpdate();
  }

  const navigateToChat = ()=>{
    navigate(`/provider-chat-box?userId=${details?.user_id}`);
  }

  return (
    <>
      <div className="right-sidebar-header">
        <article className="right-sidebar-header-details">
          <h4 className="right-sidebar-header-name">{type} Details</h4>
          {/* <p>Branch</p> */}
        </article>
        <span className="crossIcon" onClick={handleClose}>
          <i className="fas fa-times" />
        </span>
        {type === "Request" && details?.status === "pending"  ? 
        
        (
          <div className="d-flex gap-2">
            <button
              type="button"
              onClick={() => approveOrRejectAppointmentFunc("accepted", "show toast")}
              className="badge bg-success fw-500 px-3 py-2 border-0 me-2"
            >
              Accept
            </button>
            {
              details?.user_details?.user_type === "guest"  ?
              <button
              type="button"
              // onClick={() => approveOrRejectAppointmentFunc("rejected", "show toast")}
              onClick={handleOpenCancelModal}
              className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
            >
              Cancel
            </button> 
            :
            <>
            <button className="badge bg-danger fw-500 px-3 py-2 border-0 me-2" onClick={handleOpenModal}>
            Reschedule
            </button>
            <button
              type="button"
              // onClick={() => approveOrRejectAppointmentFunc("rejected", "show toast")}
              onClick={handleOpenCancelModal}

              className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
            >
              Cancel
            </button> 
            </>
            

            }
           
           

            {/* ModalComponent usage */}
            {
              showModal &&
            <ResheduleApp show={showModal} handleClose={handleCloseModal} appointmentDetail ={appointmentDetail}

               handleConfirmInParent = {handleConfirmReshedule}
            />
            }

          </div>
        ) : (
          <></>
        )}

        {type === "Request" && details?.status === "rescheduled"  ? 
        
        (
          <div className="d-flex gap-2">
          <button className="badge bg-danger fw-500 px-3 py-2 border-0 me-2" disabled>
            Rescheduled
            </button>

          </div>
        ) : (
          <></>
        )}

        {type === "Schedule" ? (
          <div className="d-flex">
            <button
              type="button"
              onClick={() => changeAppointmentstatus_scheduleFunc("visited")}
              className="badge bg-success fw-500 px-3 py-2 border-0 me-2"
            >
              Visited
            </button>
            <button
              type="button"
              onClick={() =>
                changeAppointmentstatus_scheduleFunc("not visited")
              }
              className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
            >
              Not Visited
            </button>
          </div>
        ) : (
          <></>
        )}

        {type === "History" ? (
          <div className="d-flex">
          Status:  <p className="statusText mb-0 ms-2"> {details?.status.toUpperCase()}</p>
          </div>
          
        ) : (
          <></>
        )}
      </div>
      <div className="right-sidebar-body">
        <article className="about-details px-3">
          <div class="card shadow border-0 mb-3">
            <div class="row g-0">
              <div class="col-md-4">
                <img
                  src={
                    details?.user_details?.profile_image &&
                    details?.user_details?.profile_image !== ""
                      ? profile_image_base_url +
                        details?.user_details?.profile_image
                      : chatUser01
                  }
                  alt="User "
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <div className="profile-det-info">
                  <div className="d-flex gap-1  align-items-baseline"
                   onClick={navigateToChat}>
                  <h3 className="card-title cursor-pointer" >
                      {" "}
                      {details?.user_details?.full_name
                        ? details?.user_details?.full_name
                        : ""}{" "}
                    </h3>
                    {
                      details  &&

                    <button ><i class="fa-solid fa-message"></i></button>
                    }
                  </div>
                   
                    <div className="patient-details">
                      <h5 className="card-text">
                        <i className="far fa-clock" />
                        {/* 14 Nov 2023, 10.00 AM */}
                        {/* {details?.start_time && details?.start_time !== "" ? 
                                                appointment_date : ""} */}
                        {appointment_date !== ""
                          ? moment(appointment_date).format("DD-MM-YYYY") +
                            " " +
                            moment(appointment_date).format("hh:mm A")
                          : ""}
                      </h5>
                      <h5 className="card-text">
                        <i class="fa-solid fa-tooth"></i> Insurance Information:
                      </h5>
                      <h5 className="card-text ">
                        <span className="ps-3 text-success">
                          {details?.questionires &&
                          details?.questionires?.insurance
                            ? details?.questionires?.insurance
                            : ""}
                        </span>
                      </h5>
                      <h5 className="card-text mb-0">
                        <i className="fas fa-phone" />
                        {details?.user_details?.phone_number &&
                        details?.user_details?.phone_number !== ""
                          ? profile_image_base_url +
                            details?.user_details?.phone_number
                          : ""}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="first-step">
              <p className="text-center line mb-0">Selected Dentist</p>
            </div>
            <div class="row g-0">
              <div class="col-md-8">
                <div class="card-body">
                  <div className="profile-det-info">
                    <h3 className="card-title">
                      {" "}
                      {details?.dentist_details?.full_name
                        ? details?.dentist_details?.full_name
                        : ""}{" "}
                    </h3>
                    {
                      !(details?.booked_on_dentist) && 
                    <p>The patient made this appointment without a dentist's preference</p>
                    }
                    <div className="patient-details">
                      <h5 className="card-text">
                        <i className="far fa-clock" />
                        {/* 14 Nov 2023, 10.00 AM */}
                        {appointment_date !== ""
                          ? moment(appointment_date).format("DD-MM-YYYY") +
                            " " +
                            moment(appointment_date).format("hh:mm A")
                          : ""}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <img
                  src={
                    details?.dentist_details?.profile_image &&
                    details?.dentist_details?.profile_image !== ""
                      ? profile_image_base_url +
                        details?.dentist_details?.profile_image
                      : doctor01
                  }
                  alt="User "
                />
              </div>
            </div>
          </div>

          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>What is the main purpose of your visit?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires && details?.questionires?.main_purpose
                    ? details?.questionires?.main_purpose
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  What specific procedure are you looking to have?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires && details?.questionires?.service
                    ? details?.questionires?.service.join()
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>What I'm looking for in a dentist?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires &&
                  details?.questionires?.looking_in_dentist
                    ? details?.questionires?.looking_in_dentist.join()
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  When was the last time you saw a dentist?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires && details?.questionires?.last_time
                    ? details?.questionires?.last_time
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  What time for your visit works best for you?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires &&
                  details?.questionires?.best_visit_time
                    ? details?.questionires?.best_visit_time
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  How soon would you like to receive care?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires && details?.questionires?.receive_care
                    ? details?.questionires?.receive_care
                    : ""}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography> Do you have dental insurance?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires &&
                  details?.questionires?.have_insurance
                    ? "Yes I have Insurance"
                    : "No, I don't have Insurance"}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {details?.questionires?.have_insurance && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    Who is your dental insurance provider?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {details?.questionires && details?.questionires?.insurance
                      ? details?.questionires?.insurance
                      : ""}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  If we found you a dentist that matches all of your criteria
                  are you ready to book now?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {details?.questionires &&
                  details?.questionires?.ready_to_book === true
                    ? "Yes"
                    : "No"}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {details?.questionires?.ready_to_book === false && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What's holding you back?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {details?.questionires &&
                    details?.questionires?.holding_back
                      ? details?.questionires?.holding_back
                      : "Nothing"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </article>
      </div>

      <Modal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Reason For Cancel The Request
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* <p className="text-bold">
                      Note<span className="text-danger">*</span>{" "}
                      <span className="text-muted">
                        The Request will be manually evaluated
                      </span>
                    </p> */}
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label for="floatingTextarea2">Reason</label>
                    </div>
                    {/* <div className="">
                      <div class="my-3">
                        <label for="formFile" class="form-label">
                          Add File (optional)
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          id="formFile"
                          // onChange={providerProfilePicture}
                        />
                      </div>
                    </div> */}
                  </Typography>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    <Button variant="outlined" onClick={handleCloseCancelModal}>
                      Close
                    </Button>
                    <Button variant="outlined" onClick={()=>approveOrRejectAppointmentFunc("rejected", "show toast")}>
                      Confirm
                    </Button>
                  </div>
                </Box>
              </Modal>


    </>
  );
}
