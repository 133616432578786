import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import "./ProviderNav.scss"
import companyLogo from "../../Assets/Logos/logo1.png"
import logo1 from "../../Assets/Logos/logo1.png"
import user1 from "../../Assets/avtar_img.jpeg";
import MenuIcon from '@mui/icons-material/Menu';

import appoinment_icon from "../../../src/Assets/appoinment_icon.png";

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar/Sidebar';
import { setAuthenticated } from '../../redux/slices/auth';

export default function     ProviderNav() {

    const navigate = useNavigate();

    const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

    function displayFunc() {
        var x = document.querySelector("#myDIV");
        var mainDiv = document.querySelector(".fullWidhtWrap");
         if(!x){
            return;
         }
        if (x?.classList?.contains("sideWrap-rspnsv") && mainDiv.classList.contains("fullWidhtWrap-rspnsv" )) {
            x?.classList?.remove("sideWrap-rspnsv");
            mainDiv?.classList.remove("fullWidhtWrap-rspnsv");
        }

        else if (!x.classList.contains("sideWrap-rspnsv") && !mainDiv.classList.contains("fullWidhtWrap-rspnsv" )) {
            x.classList.add("sideWrap-rspnsv");
            mainDiv.classList.add("fullWidhtWrap-rspnsv");
        }

        // if (x.style.width === "16rem") {
        //     x.style.width = "0rem";
        //     mainDiv.style.marginLeft = "0rem";
        //     mainDiv.style.width = "100%"
        // } else {
        //     x.style.width = "16rem";
        //     mainDiv.style.marginLeft = "16rem";
        //     mainDiv.style.width = "calc(100% - 16rem)";

        // }

        // console.log("sxmsxssi")
    }

    const [scrolled, setScrolled] = useState(false);
    const [clinicDetail, setClinicDetail] = useState();

    const state = useSelector((state) => state?.getClinicBrances?.data?.data[0]);

    useEffect(() => {
        // Add a scroll event listener
        // setClinicDetail(JSON.parse(localStorage.getItem("user_details")));

        window.addEventListener('scroll', handleScroll);

        if (state) {
            setClinicDetail(state);
        }

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state]);

    const handleScroll = () => {
        // Check the scroll position and update the 'scrolled' state
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const dispatch = useDispatch()

    function logout() {
        localStorage.clear();
        dispatch(setAuthenticated(false))

        navigate("/");
    }

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };



    return (
        <>
            <header className={``}>
                <nav className={`navbar navbar-expand-lg shadow bg-black cstmNav ${scrolled ? 'navbar-scrolled' : ''}`}>
                    <div className="container-fluid">
                        <Link class="toggleMenu d-none d-lg-block" onClick={displayFunc}><MenuIcon /></Link>
                        <Link className="navbar-brand flex-grow-1" to="/dashboard">
                            <img src={companyLogo} alt="Logo" className="d-inline-block align-text-top" /> </Link>
                        <button className="navbar-toggler" onClick={toggleSidebar} >
                            <i class="fa-solid fa-bars"></i>
                        </button>
                        <div className="navbar-collapse justify-content-end d-lg-block d-none">
                            <div className="dropdown show">
                                <button className="btn dropdown-toggle" style={{ border: "transparent" }}
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={profile_image_base_url + clinicDetail?.profile_image} onError={(e) => ((e.target.src = user1))}
                                        alt="" className="user_profile_img" />
                                </button>

                                <div className="dropdown-menu ">
                                    <li><button onClick={logout} className="dropdown-item logout_drop">Logout</button></li>
                                </div>
                            </div>
                        </div>
                        <div className={`navbar-collapse justify-content-end sidepanel ${sidebarOpen ? 'show' : 'hide'}`} id="navbarSupportedContent4">
                             <span onClick={toggleSidebar} className='closeIcon' ><i class="fa-solid fa-xmark"></i></span>

                            <div className="d-flex d-lg-none d-block h-100 w-100 px-2" role="search">
                                <Sidebar />

                            </div>
                            <div className="dropdown show d-none d-md-none mt-auto mt-sm-0">
                                <button className="btn dropdown-toggle" style={{ border: "transparent" }}
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={profile_image_base_url + clinicDetail?.profile_image} onError={(e) => ((e.target.src = user1))}
                                        alt="" className="user_profile_img" />
                                </button>

                                <div className="dropdown-menu ">
                                    {/* <li><a className="dropdown-item" href="/provider-profile">My Profile</a></li>
                                    <li><a className="dropdown-item" href="/myDashboard">Notification</a></li> */}
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li><button onClick={logout} className="dropdown-item logout_drop">Logout</button></li>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* <div className={`navbar navbar-expand-lg shadow bg-white subheader_fixed ${scrolled ? 'subheader-scrolled' : ''}`}>
                    <div className="container">
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav provide_nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" to="/provider-home"><i className='fa fa-home me-1'></i> Dashboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/provider-appointment"><i className='fa fa-user me-1'></i> Appointments</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/Provider-Calender"><i className='fa fa-calendar me-1'></i> Calender</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/provider-home"><i className='fa fa-line-chart me-1'></i> Performance</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/provider-home"><i className='fa fa-user me-1'></i> Providers</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/provider-profile"><i className='fa fa-id-card me-1'></i> Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to="/provider-home"><i className='fa fa-gear me-1'></i> Settings</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div> */}
            </header>
        </>
    )
}