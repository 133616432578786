import React, { useEffect, useMemo, useState } from "react";

import "./CustomSelect.scss";
import PlaceholderImage from "../../Assets/userplace.webp";

const CustomSelect = ({ data, handleClick, dentistList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const allDentistOptions = useMemo(() => {
    const imgsArray = dentistList?.map((dentist) => dentist.profile_image) || [];
    
    return {
        _id: "",
        full_name: "Any provider",
        imgs: imgsArray,
    };
}, [dentistList]);

  console.log("🚀 ~ allDentistOptions ~ allDentistOptions:", allDentistOptions)

  const [selectedDentist, setSelectedDentist] = useState({});

  useEffect(()=> {
    setSelectedDentist(allDentistOptions)
  },[allDentistOptions])

  const toggleDropdown = () => {
    console.log("Toggle dropdown");
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-select">
      <div className="select-selected " onClick={toggleDropdown}>
        {" "}
        {selectedDentist?.imgs ?
          (
          selectedDentist?.imgs?.map((dentistImage) => (
            <img
              className="headerImage"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
              }}
              src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${dentistImage}`}
              alt="Selected Option"
            />
          ))
        )
        :
        (
          <img
            className="headerImage"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
            }}
            src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${selectedDentist?.profile_image}`}
            alt="Selected Option"
          />
        )
         
        }
        <span>{selectedDentist?.full_name}</span>
        {/* <p className="mb-0 ms-auto">All dentist Availability</p> */}
        <i
          className={`ms-auto fa-solid ${
            isOpen ? "fa-angle-up" : "fa-angle-down"
          }`}
        ></i>
      </div>
      {isOpen && (
        <div className="select-options">
          <div
            data-value={allDentistOptions._id}
            onClick={() => {
              handleClick(allDentistOptions._id);
              setIsOpen(!isOpen);
              setSelectedDentist(allDentistOptions)
            }}
            className={selectedDentist?._id === allDentistOptions._id ? "selected-dent" : ''}
          >
            {allDentistOptions?.imgs?.map((dentistImage) => (
              <img
                className="headerImage"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
                }}
                src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${dentistImage}`}
                alt="Selected Option"
              />
            ))}
            <span>{allDentistOptions.full_name}</span>
          </div>
          {data?.map((option) => (
            <div
              key={option._id}
              data-value={option._id}
              onClick={() => {
                handleClick(option._id);
                setIsOpen(!isOpen);
                setSelectedDentist(option)
              }}
              className={selectedDentist?._id === option._id ? "selected-dent" : ''}
            >
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1713937438~exp=1713938038~hmac=fb974aa6e18dd7dd93e96222618332ed9458af7b5e7d6831e9bbf94eb68f3e22";
                }}
                src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${option?.profile_image}`}
                alt="Option"
              />
              <span>{option.full_name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
