import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: !!localStorage.getItem("token")
}

const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers : {
        setAuthenticated(state, action) {
            state.isAuthenticated = action.payload;
          }
    }
}) 

export const { setAuthenticated } = authSlice.actions;

export default authSlice.reducer;
