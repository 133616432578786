import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function Service({setServicesToParent,
    serData,
    getServicesData}) {
  // const [chipData, setChipData] = useState([]);
  // const [newChipLabel, setNewChipLabel] = useState('');
  // const [selectedChip, setSelectedChip] = useState('');

  // const handleDelete = (chipToDelete) => () => {
  //     setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  // };

  // const handleAddChip = () => {
  //     let chipToAdd = newChipLabel;
  //     if (selectedChip && !chipToAdd) {
  //         chipToAdd = selectedChip;
  //     }
  //     if (chipToAdd !== '') {
  //         const newChip = {
  //             key: chipData.length,
  //             label: chipToAdd,
  //         };
  //         setChipData((chips) => [...chips, newChip]);
  //         setNewChipLabel('');
  //         setSelectedChip('');
  //     }
  // };

  // const handleInputChange = (event) => {
  //     setNewChipLabel(event.target.value);
  // };

  // const handleSelectChange = (event) => {
  //     setSelectedChip(event.target.value);
  // };
  //     const [serData, setSerData] = useState('')
  //   const [val, setVal] = React.useState("");

  //    / const serviceHandler = (val) => {
  // if (serData?.includes(val)) {
  //   return;
  // }
  //     setSerData(val);
  //     setVal(val);
  //   };
  // React.useEffect(() => {
  //     setServicesToParent(chipData);
  // }, [chipData, setServicesToParent]);

  // React.useEffect(() => {
  //     setServicesToParent(serData);
  //     const data = serData.map((el, index) => ({
  //         key: index,
  //         label: el,
  //     }));
  //     setChipData(data);
  //     setNewChipLabel('');
  // }, [serData, setServicesToParent]);

  // const [chipData, setChipData] = React.useState([]);
  // { key: 0, label: 'Tooth cleaning' },
  //     { key: 1, label: 'Root Canal Therapy' },
  //     { key: 2, label: 'Implants' },
  //     { key: 3, label: 'Composite Bonding' },
  //     { key: 4, label: 'Fissure Sealants' },
  //     { key: 5, label: 'Surgical Extractions' },

  // const [newChipLabel, setNewChipLabel] = React.useState("");

  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) =>
  //     chips.filter((chip) => chip.key !== chipToDelete.key)
  //   );
  // };

  // const handleAddChip = (e) => {
  //   if (!chipData.find((chip) => chip.label === newChipLabel)) {
  //     if (newChipLabel.trim() !== "") {
  //       const newChip = {
  //         key: chipData.length,
  //         label: newChipLabel,
  //       };

  //       setChipData((chips) => [...chips, newChip]);
  //       setNewChipLabel("");
  //     }
  //   }
  // };

  // const handleChange = (event) => {
  //   setNewChipLabel(event.target.value);
  // };

  // React.useEffect(() => {
  //   const serviceData = chipData?.map((val) => val?.label);
  //   setServicesToParent(serviceData);
  // }, [chipData]);

  // React.useEffect(() => {
  //   const newLabel = serData;

  //   if (newLabel && !chipData.find((chip) => chip.label === newLabel)) {
  //     const newData = {
  //       key: chipData.length,
  //       label: newLabel,
  //     };
  //     setChipData((chips) => [...chips, newData]);
  //   }
  //   setNewChipLabel("");
  // }, [serData]);

  // React.useEffect(() => {
  //   // const newLabel = serData;

  //   for (let index = 0; index < getServicesData.length; index++) {
  //     const newLabel = getServicesData[index];
  //     if (newLabel && !chipData.find((chip) => chip.label === newLabel)) {
  //       const newData = {
  //         key: chipData.length + index,
  //         label: newLabel,
  //       };
  //       console.log("hsdfshdfhsdf");

  //       setChipData((chips) => [...chips, newData]);
  //       }
  //     }

  //   // setNewChipLabel("");
  // }, [getServicesData]);
  const [chipData, setChipData] = React.useState([]);
  // { key: 0, label: 'Tooth cleaning' },
  //     { key: 1, label: 'Root Canal Therapy' },
  //     { key: 2, label: 'Implants' },
  //     { key: 3, label: 'Composite Bonding' },
  //     { key: 4, label: 'Fissure Sealants' },
  //     { key: 5, label: 'Surgical Extractions' },

  const [newChipLabel, setNewChipLabel] = React.useState("");

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleAddChip = (e) => {
    if (!chipData.find((chip) => chip.label === newChipLabel)) {
      if (newChipLabel.trim() !== "") {
        const newChip = {
          key: chipData.length,
          label: newChipLabel,
        };

        setChipData((chips) => [...chips, newChip]);
        setNewChipLabel("");
      }
    }
  };

  const handleChange = (event) => {
    setNewChipLabel(event.target.value);
  };

  React.useEffect(() => {
    const serviceData = chipData?.map((val) => val?.label);
    console.log('serviceData', serviceData)
    setServicesToParent(serviceData);
  }, [chipData]);

  React.useEffect(() => {
    const newLabel = serData;

    if (newLabel && !chipData.find((chip) => chip.label === newLabel)) {
      const newData = {
        key: chipData.length,
        label: newLabel,
      };
      setChipData((chips) => [...chips, newData]);
    }
    setNewChipLabel("");
  }, [serData]);

  React.useEffect(() => {
    // const newLabel = serData;

    for (let index = 0; index < getServicesData.length; index++) {
      const newLabel = getServicesData[index];
      if (newLabel && !chipData.find((chip) => chip.label === newLabel)) {
        const newData = {
          key: chipData.length + index,
          label: newLabel,
        };
        console.log("hsdfshdfhsdf");
        
      
        setChipData((chips) => [...chips, newData]);
        }
      }
    
    // setNewChipLabel("");
  }, [getServicesData]);

  return (
    <div className="add_Service">
      <h5 className="fw-bold mb-4">Add Profile</h5>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 1,
        }}
        component="div"
      >
        <div className="abc service_data d-flex flex-wrap gap-2">
          {chipData.map((data) => {
                        let icon;

                        if (data.label === 'React') {
                            icon = <TagFacesIcon />;
                        }

                        return (
                            <Chip
                                key={data.key}
                                icon={icon}
                                label={data.label}
                                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                            />
                        );
                    })}
        </div>
      </Paper>

      <div className="add_service mt-3">
        <div className="d-flex">
          <TextField
              label="Add Services"
              value={newChipLabel}
              onChange={handleChange}
              variant="outlined"
              placeholder="Add Services"
              className="w-100"
            />
          <button className="theme_btn rounded-2 ms-2"
             
              onClick={handleAddChip}
         
            >
              Add
            </button>
        </div>
      </div>
    </div>
  );
}
