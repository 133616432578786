import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import branchimg from "../../Assets/provider-reg-1.jpg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSelector, useDispatch } from "react-redux";

import { fetchClinicBranches } from "../../redux/slices/getting_clinic_branches/index";

import {
  twoStepVerificationSetting,
  successNotification,
  errorNotification,
} from "../../utils/httpServices/httpService";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getUnseenMessagesByClinicId,
  changePassword,
} from "../../utils/httpServices/httpService";

import {
  matchingValidator,
  passwordValidator,
  emptyStringValidator,
  emailValidator,
  phoneValidator,
  pincodeValidator,
} from "../../utils/validators/validatorServices";

const customContentStyle = {
  width: "45%",
  height: "500px",
  maxWidth: "none",
};

export default function NavigationBar() {
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  // Dialog
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpenDialog = () => {
  //     setOpen(true);
  // };
  // const handleCloseDialog = () => {
  //     setOpen(false);
  // };

  const [showDialog, setShowDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openSecurityModal = () => {
    setShowDialog(true);
  };

  const closeSecurityModal = () => {
    setShowDialog(false);
  };

  const openChangePassword = () => {
    closeSecurityModal();
    setShowModal(true);
  };

  const CloseChangePassword = () => {
    setShowModal(false);
    openSecurityModal();
  };

  const state = useSelector((state) => state);

  const dispatcher = useDispatch();
  console.log("state in sidebar", state);

  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    if (state.getClinicBrances.data === null) {
      console.log("date is empty in state", state);
      dispatcher(fetchClinicBranches());
      console.log("after dispatcher function", state);
    }
    if (
      state?.getClinicBrances?.data !== null &&
      state?.getClinicBrances?.data?.data.length > 0
    ) {
      setBranchList(state?.getClinicBrances?.data?.data);
      console.log("branch list", branchList);
      getUnseenMessages();
    }
  }, [state?.getClinicBrances]);

  async function chnageSettingTwoFactor(status) {
    console.log("status", status);
    try {
      const resp = await twoStepVerificationSetting();
      console.log("two factor status", resp);
      if (resp?.code === 200) {
        successNotification("Status Changed Successfully");
        dispatcher(fetchClinicBranches());
      }
    } catch (err) {
      // alert(err);
    }
  }

  const navigate = useNavigate();

  const [selectedBranch_id, setSelectedBranch_id] = useState(null);

  function selectedBranch(id) {
    setSelectedBranch_id(id);
  }

  function navigating_Profile() {
    // console.log("branch id",selectedBranch_id)
    // if(selectedBranch_id !== null){
    //     navigate(`/provider-profile/${selectedBranch_id}`, { replace: true });
    // }
    // else{
    errorNotification("Select Branch First");
    // }
  }

  const [unseenMessages, setUnseenMessages] = useState(null);

  async function getUnseenMessages() {
    const clinicId = JSON.parse(localStorage.getItem("user_details"));
    if (clinicId !== undefined && clinicId !== null) {
      try {
        const resp = await getUnseenMessagesByClinicId(clinicId._id);
        console.log("unseen messages response", resp);
        if (resp?.code === 200) {
          setUnseenMessages(resp?.data);
        }
      } catch (err) {
        // alert(err);
      }
    }
  }

  /////////////////////////////// Change Password START ///////////////////////////

  const [fireEyeOld_Password, setfireEyeOld_Password] = useState("password");
  const [fireEyeNew_password, setfireEyeNew_password] = useState("password");
  const [fireEyeConfirm_password, setfireEyeConfirm_password] =
    useState("password");

  const [password, setPassword] = useState({
    old_password: "",
    confirm_Password: "",
    new_password: "",
  });

  const [passwordValidation, setPasswordValidation] = useState({
    old_password: false,
    confirm_Password: false,
    new_password: false,
  });

  const settingPasswordValues = (event) => {
    setPassword({
      ...password,
      [event.target.name]: event.target.value,
    });

    console.log("updated values", password);
  };

  function ChangePasswordValidatorCheck(name) {
    switch (name) {
      case "old_password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          old_password: emptyStringValidator(password[name]),
        }));
        break;
      case "confirm_Password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          confirm_Password: matchingValidator(
            password[name],
            password.new_password
          ),
        }));
        break;
      case "new_password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          new_password: passwordValidator(password[name]),
        }));
        break;
      default:
    }
  }

  function finalValidationCheck() {
    let tempValidator = passwordValidation;
    let validatorKeys = Object.keys(passwordValidation);
    validatorKeys.forEach((ele) => {
      if (ele === "old_password")
        tempValidator[ele] = emptyStringValidator(password[ele]);
      else if (ele === "confirm_Password")
        tempValidator[ele] = matchingValidator(
          password[ele],
          password.new_password
        );
      else tempValidator[ele] = passwordValidator(password[ele]);
    });
    setPasswordValidation(() => ({
      ...tempValidator,
    }));
  }

  async function saveChangePassword() {
    finalValidationCheck();

    const validatorStatus = Object.values(passwordValidation);
    if (!validatorStatus.some((ele) => ele === true)) {
      const data = {
        old_password: password.old_password,
        new_password: password.new_password,
      };
      console.log("data", data);
      try {
        const resp = await changePassword(data);
        console.log(" login response data=> ", resp);
        if (resp?.code === 200) {
          successNotification("Password Changed Successfully");
          console.log("resp for login", resp.data);
          setPassword({
            old_password: "",
            confirm_Password: "",
            new_password: "",
          });
          setPasswordValidation({
            old_password: false,
            confirm_Password: false,
            new_password: false,
          });
          CloseChangePassword();
        }
      } catch (err) {
        console.log("login error data", err);
      }
    } else {
      console.log("validation failed");
    }
  }

  //////////////////////////////Change password END ////////////////////////////////

  return (
    <>
      <div className="navigationBarWrapper">
        <ul className="">
          <li className="">
            <Link className="" to="/dashboard">
              <i className="fa fa-home "></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="">
            <Link className="" to="/appointment-request">
              <i className="fa fa-user "></i>
              <span>Appointments</span>
            </Link>
          </li>
          <li className="position-rela">
            <Link className="" to="/provider-chat-box">
              <i class="fa-solid fa-message"></i>
              <span>Messages</span>
              {unseenMessages > 0 ? (
                <span className="notif">{unseenMessages}</span>
              ) : (
                <></>
              )}
            </Link>
          </li>

          <li className="">
            <Link className="" to="/Provider-Calender">
              <i className="fa fa-calendar "></i>
              <span>Calendar</span>
            </Link>
          </li>

          <li className="">
            <Link className="" to="/providers">
              <i className="fa fa-user "></i>
              <span>Dentists</span>
            </Link>
          </li>
          <li className="">
            <Link className="" to="/billings">
              <i class="fa-solid fa-money-bill-wave"></i>
              <span>Billings</span>
            </Link>
          </li>
          {/* <li className="">
                        <Link className='' to="/provider-profile"><i className='fa fa-id-card '></i><span className='text-center'>Security and Settings</span></Link>
                    </li> */}
          <li className="">
            <Link className="" onClick={openSecurityModal}>
              <i className="fa fa-id-card "></i>
              <span className="text-center">Security and Settings</span>
            </Link>
          </li>
          {/* <li className="">
                        <Link className='' to="/provider-home"><i className='fa fa-gear '></i><span>Settings</span></Link>
                    </li> */}
        </ul>
      </div>

      {/* ---Dialog--- */}
      <Dialog
        open={showDialog}
        onClose={closeSecurityModal}
        // open={open}
        // onClose={handleCloseDialog}
        aria-describedby="alert-dialog"
        PaperProps={{
          style: {
            width: "90%", // Adjust width for smaller screens
            maxWidth: "400px", // Set maximum width
          },
        }}
      >
        <DialogContent className="d-flex flex-column justify-content-center align-items-center p-2">
          <DialogContentText id="alert-dialog">
            <Typography variant="h5" className="text-center" component="h4">
              Settings
            </Typography>
          </DialogContentText>
          {/* <p className="timer">
                        Time left <b>02:00 mins</b>
                    </p> */}
          <div style={{ cursor: "pointer" }} className="settings-cards-wrap ">
            <div className="settings-cards" onClick={openChangePassword}>
              <i class="fa-solid fa-unlock"></i>
              <p>Change Password</p>
            </div>
            {/* <Button
              type="button"
              style={{
                "background-color": "transparent",
                color: "black",
                display: "block",
              }}
              onClick={() =>
                selectedBranch_id === null
                  ? errorNotification("Select Branch First")
                  : navigate(`/provider-profile/${selectedBranch_id}`)
              }
              className="settings-cards"
            >
              <i class="fa fa-pencil"></i>
              <p>Edit Branch</p>
            </Button> */}
            {/* <div className="settings-cards">
                            <label className="switch">
                                <input type="checkbox" id='notifi-switch' />
                                <span className="slider"></span>
                            </label>
                            <label htmlFor="notifi-switch" className='notifi-label'>Two F.A.</label>
                        </div> */}
          </div>
          <div className="notifications-switch">
            <label htmlFor="notifi-switch" className="notifi-label">
              Two Factor Authentication
            </label>
            <label className="switch">
              <input
                type="checkbox"
                checked={
                  state?.getClinicBrances?.data?.data[0]?.two_step_verification
                }
                id="notifi-switch"
                onChange={(e) => chnageSettingTwoFactor(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>

          <div className="selectBranch-edit">
            {/* <h5>Select Branch</h5> */}
            <div className="branch-items-wrap">
              {/* <Link to="/provider-profile"> */}
              {/* <div className="branch-item">
                                    <img src={branchimg} alt="" className='branchimg' />
                                    <p className="branch-name">Brand Name</p>
                                </div> */}
              {branchList.length > 0 ? (
                branchList.map((ele, i) => (
                  <div
                    className="branch-item"
                    // style={
                    //   selectedBranch_id !== null &&
                    //   selectedBranch_id === ele?._id
                    //     ? { "border-color": "var(--color-pink)" }
                    //     : {}
                    // }
                  >
                    <div>
                      <img
                        className="branchimg"
                        src={profile_image_base_url + ele.profile_image}
                        onError={(e) => (e.target.src = branchimg)}
                        alt="branch_img"
                      />
                      <span>
                        {" "}
                        {ele.full_name && ele.full_name !== ""
                          ? ele.full_name
                          : ""}
                      </span>
                    </div>
                    <div>
                      <i
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/provider-profile/${ele._id}`);
                        }}
                        style={{ cursor: "pointer" }}
                        class="fa fa-pencil"
                      ></i>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {/* </Link> */}
              {/* <Link to="/provider-profile">
                                <div className="branch-item">
                                    <img src={branchimg} alt="" className='branchimg' />
                                    <p className="branch-name">Brand Name</p>
                                </div>
                            </Link>
                            <Link to="/provider-profile">
                                <div className="branch-item">
                                    <img src={branchimg} alt="" className='branchimg' />
                                    <p className="branch-name">Brand Name</p>
                                </div>
                            </Link>
                            <Link to="/provider-profile">
                                <div className="branch-item">
                                    <img src={branchimg} alt="" className='branchimg' />
                                    <p className="branch-name">Brand Name</p>
                                </div>
                            </Link> */}
            </div>
          </div>

          {/* <div className="modal-btns">
                        <Button variant='outlined' onClick={handleCloseDialog}>Resend</Button>
                        <Button variant='outlined' onClick={handleCloseDialog} autoFocus>
                            Continue
                        </Button>
                    </div> */}
        </DialogContent>
        {/* <DialogActions className='modal-foot'>
                </DialogActions> */}
      </Dialog>

      <Modal
        show={showModal}
        // onHide={!showModal}
        className="change-password"
      >
        <Modal.Header closeButton onClick={CloseChangePassword}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="card">
                      <div className="card-body"> */}
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="mb-3 position-relative">
                <label className="mb-2">Old Password</label>
                <input
                  type={fireEyeOld_Password}
                  className="form-control"
                  name="old_password"
                  value={password.old_password}
                  onChange={settingPasswordValues}
                  onBlur={(e) => ChangePasswordValidatorCheck(e.target.name)}
                />
                <div className="fireEyePosition">
                  {fireEyeOld_Password === "password" ? (
                    <button onClick={() => setfireEyeOld_Password("text")}>
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button onClick={() => setfireEyeOld_Password("password")}>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {passwordValidation.old_password ? (
                  <div className="validation_message">*Password required</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 position-relative">
                <label className="mb-2">New Password</label>
                <input
                  type={fireEyeNew_password}
                  className="form-control"
                  value={password.new_password}
                  name="new_password"
                  onChange={settingPasswordValues}
                  onBlur={(e) => ChangePasswordValidatorCheck(e.target.name)}
                />
                <div className="fireEyePosition">
                  {fireEyeNew_password === "password" ? (
                    <button onClick={() => setfireEyeNew_password("text")}>
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button onClick={() => setfireEyeNew_password("password")}>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {passwordValidation.new_password ? (
                  <div className="validation_message">
                    *Password must be 8 or greater in length
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="mb-3 position-relative">
                <label className="mb-2">Confirm Password</label>
                <input
                  type={fireEyeConfirm_password}
                  className="form-control"
                  name="confirm_Password"
                  value={password.confirm_Password}
                  onChange={settingPasswordValues}
                  onBlur={(e) => ChangePasswordValidatorCheck(e.target.name)}
                />
                <div className="fireEyePosition">
                  {fireEyeConfirm_password === "password" ? (
                    <button onClick={() => setfireEyeConfirm_password("text")}>
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button
                      onClick={() => setfireEyeConfirm_password("password")}
                    >
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {passwordValidation.confirm_Password ? (
                  <div className="validation_message">
                    *Password does not match
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="submit-section">
                              <button type="submit" className="theme_btn" onClick={submit}>Save Changes</button>
                            </div> */}
            </div>
          </div>
          {/* </div>
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="theme_btn"
            onClick={CloseChangePassword}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="theme_btn"
            onClick={() => saveChangePassword()}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
