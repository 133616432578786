import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import providerImg from "../../Assets/user1 1.png";
import providerImg from "../../Assets/noUserImage.png";
import branch from "../../Assets/provider-reg-1.jpg";
import { useNavigate } from 'react-router-dom';

import {
  getBranchListing,
  successNotification,
  getDentistListingService,
  makeHttpRequest,
} from "../../utils/httpServices/httpService";
import { useDispatch, useSelector } from "react-redux";

import { fetchClinicBranches } from "../../redux/slices/getting_clinic_branches/index";

import { updateClinicBranchIndex } from "../../redux/slices/selected_clinic_Branch/index";
import ProviderHomepage from "../../pages/ProviderHomepage/ProviderHomepage";
import AddStaff from "../AddStaff/AddStaff";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  Typography,
} from "@mui/material";
import { setAuthenticated } from "../../redux/slices/auth";

const customContentStyle = {
  width: "30%",
  height: "200px",
  maxWidth: "none",
};

export default function Sidebar({ initialState, triggerParentUpdate  }) {

  const navigate = useNavigate();

  const state = useSelector((state) => {
    return state
  })
  console.log("🚀 ~ Sidebar ~ state:", state)

  const [branchList, setBranchList] = useState([]);
  console.log("🚀 ~ Sidebar ~ branchList:", branchList)
  const [activeIndex, setActiveIndex] = useState(state?.selectedBranchIndex || 0);

  const [clinicId , setClinicId] = useState(null)

  const [staffDetails , setStaffDetails] = useState(null)
  console.log("🚀 ~ Sidebar ~ staffDetails:", staffDetails)

  const [ userRole, setUserRole ] = useState()

  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;


  const dispatcher = useDispatch();
  console.log("state in sidebar", state);

  useEffect(()=> {

    const clinic  = branchList?.find(b => b.branch_type === "main")
    console.log("🚀 ~ useEffect ~ clinic:", clinic,branchList)
    // setClinicId(clinic?.clinic_id)
    setClinicId(clinic?._id)

  },[branchList])

  useEffect(() => {
    if (state.getClinicBrances.data === null) {
      console.log("date is empty in state", state);
      dispatcher(fetchClinicBranches());
      console.log("after dispatcher function", state);
    }
    if (
      state?.getClinicBrances?.data !== null &&
      state?.getClinicBrances?.data?.data.length > 0
    ) {
      setBranchList(state?.getClinicBrances?.data?.data);
      console.log("branch list", branchList);
      getProviderListById(state?.getClinicBrances?.data?.data[0]?._id);
    }
  }, [state.getClinicBrances]);

  const settingBranch = (i, id) => {
    localStorage.setItem("selected_Branch", i);
    console.log("selcted indexxxxx", i);
    getProviderListById(id);
    dispatcher(updateClinicBranchIndex(i));

    triggerParentUpdate(i);
    setActiveIndex(i);
  };

  const [providerList, setProviderList] = useState([]);

  async function getProviderListById(id) {
    if (id !== undefined) {
      let obj = {
        name: "",
        branch_id: id,
        sort: -1,
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
      };
      try {
        const resp = await getDentistListingService(obj);
        console.log("resp for dentist list", resp);
        if (resp?.code === 200) {
          setProviderList(resp.data);
        }
      } catch (err) {
        console.log("dentist list error", err);
      }
    } else return;
  }

  // State to track active button index

  // --view-staff-profile--

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // / --Edit-staff-profile--

  useEffect(()=>{
    if(clinicId){
      getStaffDetails(clinicId)
    }

  },[clinicId])

  useEffect(()=>{

    const role = JSON.parse(localStorage.getItem("user_details")).user_type
 

    setUserRole(role)

  },[])

const getStaffDetails = async(clinicId)=>{
  try {
    const resp = await makeHttpRequest("get",`users/getStaff/${clinicId}`)
    if(resp?.data?.[0]){

      setStaffDetails(resp?.data?.[0])
    }else{
      setStaffDetails(null)
    }
    
  } catch (error) {
    console.log(error)
  }
}

//delete staff
const [showDeleteModal ,setShowDeleteModal] = useState(false)

const handleShowDeleteModal = ()=> setShowDeleteModal(true)

const handleCloseDeleteModal = ()=> setShowDeleteModal(false)


const handleStaffDelete =async ()=>{
try {
  const resp = await makeHttpRequest("delete",`users/deleteStaff/${staffDetails?._id}`)
  handleCloseDeleteModal()
  successNotification("Staff Deleted Successfully")
  getStaffDetails(clinicId)
} catch (error) {
  console.log(error)
}
}

const dispatch = useDispatch()

function logout() {
  localStorage.clear();
  dispatch(setAuthenticated(false))

  navigate("/");
}

  return (
    <>
      <div className="sidebarWrapper w-100">
        <div className="bgBlack"></div>
        <ul className="">
          <li className="liHead">
            <Link className="" to="">
              <i className="fa fa-home "></i>
              <span>Locations</span>
            </Link>
            <ul className="listing_item dentist_listing">
              {branchList.length > 0 ? (
                branchList.map((ele, i) => (
                  <li>
                    <button
                      onClick={() => settingBranch(i, ele?._id)}
                      className={activeIndex === i ? "active" : ""}
                      // className={selectedBranch === ele?._id ? "active" : ""}
                    >
                      <img
                        src={profile_image_base_url + ele.profile_image}
                        onError={(e) => (e.target.src = branch)}
                        alt="branch_img"
                      />
                      {ele.practice_name && ele.practice_name !== ""
                        ? ele.practice_name
                        : ""}
                    </button>
                  </li>
                ))
              ) : (
                <></>
              )}
            </ul>
          </li>
          <hr />
          <li className="liHead">
            <Link className="" to="">
              <i className="fa fa-user "></i>
              <span>Dentists</span>
            </Link>
            <ul className="dentist_listing">
              {/* <li><Link className='' to=""><img src={providerImg} alt="provider_img" />Provider 1</Link></li>
                            <li><Link className='' to=""><img src={providerImg} alt="provider_img" />Provider 2</Link></li>
                            <li><Link className='' to=""><img src={providerImg} alt="provider_img" />Provider 3</Link></li> */}
              {providerList.length !== 0 ? (
                providerList.map((ele) => (
                  <li>
                    <Link className="d-flex align-items-center" to="">
                      <img
                        src={
                          ele?.profile_image && ele?.profile_image !== ""
                            ? profile_image_base_url + ele?.profile_image
                            : providerImg
                        }
                        alt="provider_img"
                      />
                     {ele?.full_name ? ele?.full_name : ""}
                    </Link>
                  </li>
                ))
              ) : (
                <></>
              )}
            </ul>
          </li>
          {
            userRole !== "staff" 
            &&
            <li className="liHead">
            <Link className="">
              <i className="fa fa-user "></i>
              <span>Staff</span>
            </Link>

            <ul className="mb-2">
              <li className="st_list">
                <img src={staffDetails?.profile_image || providerImg} alt="staff" />
                {
                  staffDetails === null ?
                <button onClick={handleShow} className=" theme-outline-sm">
                  Add
                </button>
                :

                <div className="position-relative ">
                  <p className="mb-1 fw-bold">{staffDetails?.full_name}</p>
                  <span className="">
                    <i  onClick ={handleShowDeleteModal} class="fa-solid fa-trash-can"></i>
                    <i onClick={handleShow} class="fa-solid fa-pencil"></i>
                  </span>
                </div>
                }
              </li>
            </ul>
            {
              show &&

            <AddStaff show={show} handleClose={handleClose} 
              clinic_id={clinicId}
              staffDetails ={staffDetails}
              getStaffDetails={getStaffDetails}
            />
            }
           </li>

          }
          
        </ul>
        <div className="mt-4 ms-2 d-md-none d-block" >
            <button onClick={logout}  className="theme-outline-sm">Logout</button>
        </div>
      </div>

      <Dialog
          open={showDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-describedby="alert-dialog"
          PaperProps={{
            style: customContentStyle, // Apply custom styles using PaperProps
          }}
        >
          <DialogContent className="d-flex justify-content-center align-items-center">
            <DialogContentText id="alert-dialog">
              <Typography variant="h5" className="text-center" component="h4">
                Are you sure you want to Delete this Staff.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDeleteModal}>
              No
            </Button>
            <Button variant="outlined" onClick={() => handleStaffDelete()}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
