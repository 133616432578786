import React from "react";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import Sidebar from "../../../components/Sidebar/Sidebar";

export default function DoctorProfile() {
  return (
    <>
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar />
        </div>
        <div className="fullWidhtWrap">
          <ProviderNav />
          <div className="doctorWrap">
            <section>
              <div className="container">
                <NavigationBar />
                <div className="profDltWrap">
                  <h3>Doctor Profile</h3>
                  <Button className="dltIc">
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="doctorCard">
                      <img src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" alt="Doctor Profile Image" />
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Name of Doctor
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="Dr. Pankaj Tripathi"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Designation
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="Dentist"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Experience
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="5 Years"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            Joining Date
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="23/05/2017"
                          />
                        </div>
                      </div>
                      <div className="col-6 mb-4">
                        <div className="form-group">
                          <label for="" class="form-label">
                            No. of Confirmed Appointments
                          </label>
                          <input
                            type="text"
                            class="form-control input_field"
                            id=""
                            value="200"
                          />
                        </div>
                      </div>
                      <Button class="theme_btn w-50 m-auto">Save</Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ProviderFooter />
        </div>
      </div>
    </>
  );
}
