import React from 'react'
import pcare01 from "../../Assets/pcare01.jpg"
import pcare02 from "../../Assets/pcare02.jpg"
import pcare03 from "../../Assets/pcare03.jpg"
import avatar from "../../Assets/avtar_img.jpeg"
import profile01 from "../../Assets/profile01.jpg"
import user01 from "../../Assets/user1.jpg"
import user02 from "../../Assets/user2.jpg"
import user03 from "../../Assets/user3.jpg"

import "./ViewBlog.scss"
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Nav/Navbar'

export default function ViewBlog() {
    return (
        <>
            <Navbar />
            <div className='page-wrap'>

                <div className="Blog-container">
                    <div className="container">
                        <div className="col-md-7 col-sm-8 col-xs-12">
                            <div className="page-description">
                                <h4 className="page-title">Latest Blog</h4>
                                <h1 className="page-text">Explore the latest insights and articles from our skilled dental professionals.</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blogs view-blogs my-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="blog">
                                    <div className="blog-image">
                                        <Link to=""><img className="img-fluid" src={pcare01} alt="Post " /></Link>
                                    </div>
                                    <h4 className="blog-title">
                                        <Link to="" >Doccure – Making your clinic painless visit?</Link></h4>
                                    <div className="blog-info clearfix">
                                        <div className="post-left">
                                            <ul>
                                                <li>
                                                    <div className="post-author">
                                                        <Link  ><img src={avatar} alt="Post Author" /> <span>Dr. Ruby Perrin</span></Link>
                                                    </div>
                                                </li>
                                                <li><i className="far fa-clock"></i> 4 Dec 2023</li>
                                                <li><i className="far fa-comments"></i> 12 Comments</li>
                                                <li><i className="fa fa-tags"></i> Health Tips</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <p className='mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit laboris ullamco laborisut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        <p className='mb-4'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est facilis quisquam vitae incidunt eum ratione voluptatem eligendi. Unde ullam voluptas consequatur amet veritatis id nostrum eum aperiam pariatur, inventore delectus, itaque facere nemo necessitatibus deleniti, omnis cum qui minima et incidunt tempore est? Magni dolore est fugit perspiciatis tempora voluptate architecto amet a molestias incidunt at harum, reiciendis sint aliquid? Corporis nulla non quod beatae porro libero praesentium, quae repellendus autem commodi? Cumque, voluptas facere exercitationem facilis, dolorem illum hic doloremque veritatis temporibus, ut delectus aliquid soluta iusto similique magni odit eos alias corporis voluptates? Soluta dolor obcaecati autem nobis!</p>
                                        <p className=''>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem autem error rerum voluptatem! Excepturi aliquam earum facere? Sint facere, quaerat dolor facilis nisi amet laudantium consectetur rem quia corrupti quibusdam et similique omnis ad eos ab maxime nam ea sunt deleniti? Laudantium possimus, pariatur sint sunt corporis necessitatibus voluptates cum, quam omnis odio velit! Voluptatibus omnis dignissimos delectus hic nobis alias nesciunt ex id sint sunt quam et vero deserunt error veniam, aut numquam quisquam, consequuntur distinctio temporibus repudiandae a?</p>
                                    </div>
                                </div>

                                <div className="card blog-share clearfix">
                                    <div className="card-header">
                                        <h4 className="card-title">Share the post</h4>
                                    </div>
                                    <div className="card-body">
                                        <ul className="social-share">
                                            <li><Link title="Facebook"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link title="Twitter"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link title="Linkedin"><i className="fab fa-linkedin"></i></Link></li>
                                            <li><Link title="Google Plus"><i className="fab fa-google-plus"></i></Link></li>
                                            <li><Link title="Youtube"><i className="fab fa-youtube"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="card author-widget clearfix">
                                    <div className="card-header">
                                        <h4 className="card-title">About Author</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="about-author">
                                            <div className="about-author-img">
                                                <div className="author-img-wrap">
                                                    <Link ><img className="img-fluid rounded-circle" alt="Darren Elder" src={profile01} /></Link>
                                                </div>
                                            </div>
                                            <div className="author-details">
                                                <a href="doctor-profile.html" className="blog-author-name">Dr. Darren Elder</a>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card blog-comments clearfix">
                                    <div className="card-header">
                                        <h4 className="card-title">Comments (12)</h4>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="comments-list">
                                            <li>
                                                <div className="comment">
                                                    <div className="comment-author">
                                                        <img className="avatar" alt="Michelle Fairfax" src={user01} />
                                                    </div>
                                                    <div className="comment-block">
                                                        <span className="comment-by">
                                                            <span className="blog-author-name">Michelle Fairfax</span>
                                                        </span>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                        <p className="blog-date">Dec 6, 2023</p>
                                                        <Link className="comment-btn" > <i className="fas fa-reply"></i> Reply </Link>
                                                    </div>
                                                </div>
                                                <ul className="comments-list reply">
                                                    <li>
                                                        <div className="comment">
                                                            <div className="comment-author">
                                                                <img className="avatar" alt="Gina Moore" src={user03} />
                                                            </div>
                                                            <div className="comment-block">
                                                                <span className="comment-by">
                                                                    <span className="blog-author-name">Gina Moore</span>
                                                                </span>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae.</p>
                                                                <p className="blog-date">Dec 6, 2023</p>
                                                                <Link className="comment-btn" href="#"> <i className="fas fa-reply"></i> Reply </Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="comment">
                                                            <div className="comment-author">
                                                                <img className="avatar" alt="Carl Kelly" src={user02} />
                                                            </div>
                                                            <div className="comment-block">
                                                                <span className="comment-by">
                                                                    <span className="blog-author-name">Carl Kelly</span>
                                                                </span>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae.</p>
                                                                <p className="blog-date">December 7, 2023</p>
                                                                <Link className="comment-btn" href="#"><i className="fas fa-reply"></i> Reply </Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="comment">
                                                    <div className="comment-author">
                                                        <img className="avatar" alt="Elsie Gilley" src={user02} />
                                                    </div>
                                                    <div className="comment-block">
                                                        <span className="comment-by">
                                                            <span className="blog-author-name">Elsie Gilley</span>
                                                        </span>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                        <p className="blog-date">December 11, 2023</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="comment">
                                                    <div className="comment-author">
                                                        <img className="avatar" alt="Joan Gardner" src={user03} />
                                                    </div>
                                                    <div className="comment-block">
                                                        <span className="comment-by">
                                                            <span className="blog-author-name">Joan Gardner</span>
                                                        </span>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                        <p className="blog-date">December 13, 2023</p>
                                                        <Link className="comment-btn" href="#"> <i className="fas fa-reply"></i> Reply </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="card new-comment clearfix">
                                    <div class="card-header">
                                        <h4 class="card-title">Leave Comment</h4>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <div class="mb-3">
                                                <label class="mb-2">Name <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" />
                                            </div>
                                            <div class="mb-3">
                                                <label class="mb-2">Your Email Address <span class="text-danger">*</span></label>
                                                <input type="email" class="form-control" />
                                            </div>
                                            <div class="mb-3">
                                                <label class="mb-2">Comments</label>
                                                <textarea rows="4" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea>
                                            </div>
                                            <div class="submit-section">
                                                <button class="theme_btn submit-btn" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
                                <div className="theiaStickySidebar" >
                                    <div className="card search-widget">
                                        <div className="card-body">
                                            <form className="search-form">
                                                <div className="input-group">
                                                    <input type="text" placeholder="Search..." className="form-control" />
                                                    <button type="submit" className=" theme_btn"><i className="fa fa-search"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card post-widget">
                                        <div className="card-header">
                                            <h4 className="card-title">Latest Posts</h4>
                                        </div>
                                        <div className="card-body">
                                            <ul className="latest-posts">
                                                <li>
                                                    <div className="post-thumb">
                                                        <Link to="">
                                                            <img className="img-fluid" src={pcare01} alt="blog" />
                                                        </Link>
                                                    </div>
                                                    <div className="post-info">
                                                        <h6>  <Link to="" >Doccure – Making your clinic painless visit?</Link> </h6>
                                                        <p>4 Dec 2023</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="post-thumb">
                                                        <Link to=""> <img className="img-fluid" src={pcare02} alt="blog" />  </Link>
                                                    </div>
                                                    <div className="post-info">
                                                        <h6>
                                                            <Link >What are the benefits of Online Doctor Booking?</Link>
                                                        </h6>
                                                        <p>3 Dec 2023</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="post-thumb">
                                                        <Link to=""> <img className="img-fluid" src={pcare03} alt="blog" />  </Link>
                                                    </div>
                                                    <div className="post-info">
                                                        <h6> <Link to="">Benefits of consulting with an Online Doctor</Link> </h6>
                                                        <p>3 Dec 2023</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="post-thumb">
                                                        <Link to="">
                                                            <img className="img-fluid" src={pcare01} alt="blog" />
                                                        </Link>
                                                    </div>
                                                    <div className="post-info">
                                                        <h6>
                                                            <Link to="" >5 Great reasons to use an Online Doctor</Link>
                                                        </h6>
                                                        <p>2 Dec 2023</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="post-thumb">
                                                        <Link to="">
                                                            <img className="img-fluid" src={pcare02} alt="blog" />
                                                        </Link>
                                                    </div>
                                                    <div className="post-info">
                                                        <h6>
                                                            <Link to="">Online Doctor Appointment Scheduling</Link>
                                                        </h6>
                                                        <p>1 Dec 2023</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card category-widget">
                                        <div className="card-header">
                                            <h4 className="card-title">Blog Categories</h4>
                                        </div>
                                        <div className="card-body">
                                            <ul className="categories">
                                                <li><Link >Cardiology <span>(62)</span></Link></li>
                                                <li><Link >Health Care <span>(27)</span></Link></li>
                                                <li><Link >Nutritions <span>(41)</span></Link></li>
                                                <li><Link >Health Tips <span>(16)</span></Link></li>
                                                <li><Link >Medical Research <span>(55)</span></Link></li>
                                                <li><Link >Health Treatment <span>(07)</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card tags-widget">
                                        <div className="card-header">
                                            <h4 className="card-title">Tags</h4>
                                        </div>
                                        <div className="card-body">
                                            <ul className="tags">
                                                <li><Link className="tag">Children</Link></li>
                                                <li><Link className="tag">Disease</Link></li>
                                                <li><Link className="tag">Appointment</Link></li>
                                                <li><Link className="tag">Booking</Link></li>
                                                <li><Link className="tag">Kids</Link></li>
                                                <li><Link className="tag">Health</Link></li>
                                                <li><Link className="tag">Family</Link></li>
                                                <li><Link className="tag">Tips</Link></li>
                                                <li><Link className="tag">Shedule</Link></li>
                                                <li><Link className="tag">Treatment</Link></li>
                                                <li><Link className="tag">Dr</Link></li>
                                                <li><Link className="tag">Clinic</Link></li>
                                                <li><Link className="tag">Online</Link></li>
                                                <li><Link className="tag">Health Care</Link></li>
                                                <li><Link className="tag">Consulting</Link></li>
                                                <li><Link className="tag">Doctors</Link></li>
                                                <li><Link className="tag">Neurology</Link></li>
                                                <li><Link className="tag">Dentists</Link></li>
                                                <li><Link className="tag">Specialist</Link></li>
                                                <li><Link className="tag">Doccure</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </>

    )
}
