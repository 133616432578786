import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children, account_type }) => {
  const isAuthenticated = localStorage.getItem("token");
  const detail = localStorage.getItem("user_details");
  if (detail) {
    const userDetails = JSON.parse(detail);
    if(userDetails?.user_type === "staff"){
      userDetails.user_type = "clinic"
    }
    console.log("userdetails after parse", userDetails, "type", userDetails.user_type, "account_type", account_type)
    if (isAuthenticated && account_type === userDetails.user_type) {
      return children
    }
    else {
      return <Navigate to="/" />
    }
  }
  else {
    return <Navigate to="/" />
  }
}