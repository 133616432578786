import React, { useEffect, useState } from "react";
import "./RecentSearch.scss";
import { Link } from "react-router-dom";
// import companyLogo from "../../Assets/Logos/logo1.png";
import companyLogo from "../../Assets/logodenist.png";
import seachicon from "../../Assets/search-plus.svg";
import prevIcon from "../../Assets/previous-match.svg";
import { useNavigate } from "react-router-dom";

export default function RecentSearch() {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState("");
  const [address, setAddress] = useState("");

  const listHandler = () => {
    let account_type = null;
    const token = localStorage.getItem("token");
    const v = localStorage.getItem("user_details");
    console.log(v);
    const user_data = JSON.parse(v);
    console.log(user_data?.user_type);
    console.log("token", token);

    if (user_data !== null) {
      const parsedData = user_data;
      account_type = parsedData?.user_type;
    }

    if (token === null) {
      navigate("/dentist-lists");
      console.log("User not authenticated. Redirecting to /dentist-lists");
    } else if (token !== null && account_type === "user") {
      navigate("/dentist-list");
      console.log("User authenticated as a user. Redirecting to /dentist-list");
    }
  };

  useEffect(() => {
    let date = localStorage.getItem("currentDate");
    setCurrentDate(JSON.parse(date));

    let address1 = localStorage.getItem("address");
    setAddress(JSON.parse(address1));
  });

  const handleClick = () => {
    localStorage.removeItem("retainedData");
    localStorage.removeItem("data");
    localStorage.removeItem("seraching_details");
    localStorage.removeItem("address");
    localStorage.removeItem("currentDate");
    localStorage.removeItem("clinic-lists");
    // localStorage.removeItem("country")
    // Condition to determine the destination URL
    const destination = "/questionnair";

    // Condition to determine if a query parameter needs to be added
    const queryParam = "?questionNum=0";

    // Construct the final URL
    const url = `${destination}${queryParam}`;

    // Navigate to the final URL
    navigate(url);
  };

  return (
    <div className="recent-search-cntner ">
      <nav
        id="defaultNavbar"
        className="navbar search-header shadow-sm  navbar-light bg-light fixed-top"
      >
        <div className="container ">
          <Link className="navbar-brand text-black" to="/">
            <img
              src={companyLogo}
              alt=""
              width="30"
              height="24"
              class="d-inline-block align-text-top"
            />
          </Link>
        </div>
      </nav>
      <div className="page-wrap">
        <div className="container">
          <section className="recentSearch_box w-100 ">
            <div class="row g-4 justify-content-center">
              <div class="col-sm-4" onClick={listHandler}>
                <div class="card mb-0 shadow-lg">
                  <img className="searchicon" src={prevIcon} alt="search" />
                  <div class="card-body">
                    <h5 class="card-title text-center text-bold mb-2">
                      Return to my previous match{" "}
                    </h5>
                    <p class="card-text text-center">
                      View the dentists we recommended in {address} on{" "}
                      {currentDate}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4" onClick={handleClick}>
                <div class="card mb-0 shadow-lg">
                  <img className="searchicon" src={seachicon} alt="search" />
                  <div class="card-body">
                    <h5 class="card-title text-center text-bold mb-2">
                      Start a new search{" "}
                    </h5>
                    <p class="card-text text-center">
                      Answer a new set of questions and get matched with the
                      best dentists near you
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
