import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  editDentistProfile,
  successNotification,
  timeSlotRemoveCheck,
} from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";
import ChildModal from "../ChildComp/ChildModal";
import { convertDateToTimeString, daysOfWeek } from "../../utils/common/common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 5,
};

// function ChildModal({
//   open1,
//   setOpen1,
//   slotDay,
//   slots,
//   setAddSlots_addProvider,
//   handleClose1,
//   id,
// }) {
//   console.log("day", slotDay, "slots prop", slots);

//   const [start_time_addProvider, setStart_time_addProvider] =
//     React.useState("");
//   const [end_time_addProvider, setEnd_time_addProvider] = React.useState("");

//   const [allSlot, setAllSlot] = React.useState([]);

//   useEffect(() => {
//     console.log('slots', slots)
//     let data = structuredClone(slots);
//     setAllSlot(data);
//   }, [open1]);

//   let selected_slot = allSlot.filter((ele) => slotDay === ele.day);
//   let remaining_slot = allSlot.filter((ele) => slotDay !== ele.day);

//   console.log("slected_slot", selected_slot, remaining_slot);

//   const addingTimeSlot = () => {
//     console.log(
//       "adding Time slot works",
//       start_time_addProvider,
//       end_time_addProvider
//     );

//     selected_slot[0].slot.push({
//       start: start_time_addProvider,
//       end: end_time_addProvider,
//     });

//     console.log("slected time slot array", selected_slot);

//     remaining_slot.push({
//       day: slotDay,
//       slot: selected_slot[0].slot,
//     });

//     console.log("new slots", remaining_slot);

//     setAllSlot(remaining_slot);

//     setStart_time_addProvider("");
//     setEnd_time_addProvider("");

//     console.log("all slots", allSlot);
//   };

//   async function slotChecking(i) {
//     let data1 = structuredClone(slots);
//     console.log(data1)

//     let daySlot = data1.filter((ele) => slotDay === ele.day);

//     if(!daySlot[0]?.slot[i]?._id){
//       removeTimeSlot(i);
//       return;
//     }

//     console.log("day slot to remove check", i, daySlot);

//     const startTime = daySlot[0]?.slot[i]?.start;
//     const endTime = daySlot[0]?.slot[i]?.end;

//     const [starhour, startminute] = startTime?.split(":");
//     const [endHour, endMinute] = endTime?.split(":");
//     let time = 0;
//     starhour === "00" ? (time = 24) : (time = starhour);
//     console.log(starhour);

//     if (time > endHour) {
//       if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
//         toast.error(
//           "Invalid practice hours. Start time must be before end time."
//         );
//         return;
//       } else if (time >= 13 && time <= 24 && endHour >= 13 && endHour <= 24) {
//         toast.error(
//           "Invalid practice hours. Start time must be before end time."
//         );
//         return;
//       }
//     } else if (time === endHour && startminute >= endMinute) {
//       toast.error(
//         "Invalid practice hours. Start time must be before end time."
//       );
//       return;
//     }

//     const startInputTime = new Date("2023-01-01");
//     const endInputTime = new Date("2023-01-01");

//     startInputTime.setHours(starhour, startminute, 0, 0); // Set the hours and minutes
//     endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

//     let start = startInputTime.toISOString();
//     let end = endInputTime.toISOString();
//     let data = {
//       dentist_id: id,
//       day: slotDay,
//       start: start,
//       end: end,
//     };
//     console.log("checking slot delete payload", data);
//     try {
//       const resp = await timeSlotRemoveCheck(data);
//       console.log("time slot checking", resp);
//       if (resp?.code === 200) {
//         removeTimeSlot(i);
//       }
//     } catch (err) {
//       // alert(err);
//     }
//   }

//   function removeTimeSlot(i) {
//     console.log("index", i);

//     if (selected_slot[0].slot.length !== 0) {
//       selected_slot[0]?.slot?.splice(i, 1);
//       remaining_slot.push({
//         day: slotDay,
//         slot: selected_slot[0].slot,
//       });
//       console.log("new slots after remove", remaining_slot);
//       setAllSlot(remaining_slot);

//       console.log("all slots", allSlot);
//     } else {
//       setAllSlot([]);
//     }
//   }

//   function savingSlotsFunction() {

//     console.log("savingSlots firedddip !!!", allSlot);

//     setAddSlots_addProvider(allSlot);
//     setOpen1(false);
//   }
//   console.log('allSlot', allSlot)

//   return (
//     <React.Fragment>
//       <Modal
//         open={open1}
//         onClose={handleClose1}
//         aria-labelledby="child-modal-title"
//         aria-describedby="child-modal-description"
//       >
//         <Box sx={{ ...style, width: 400 }}>
//           <div className="time-slot">
//               <div class="hours-info">
//                 <div class="row hours-cont">
//                   <div class="col-12 col-md-10">
//                     <div class="row">
//                       <div class="col-12 col-md-6">
//                         <div class="mb-3">
//                           <label class="mb-2">Start Time</label>
//                           <input
//                             type="time"
//                             className="time_input"
//                             name=""
//                             id=""
//                             value={start_time_addProvider}
//                             onChange={(e) =>
//                               setStart_time_addProvider(e.target.value)
//                             }
//                           />
//                           {/* <select class="form-select form-control">
//                             <option>-</option>
//                             <option selected="">12.00 am</option>
//                             <option>12.30 am</option>
//                             <option>1.00 am</option>
//                             <option>1.30 am</option>
//                           </select> */}
//                         </div>
//                       </div>
//                       <div class="col-12 col-md-6">
//                         <div class="mb-3">
//                           <label class="mb-2">End Time</label>
//                           {/* <select class="form-select form-control">
//                             <option>-</option>
//                             <option>12.00 am</option>
//                             <option selected="">12.30 am</option>
//                             <option>1.00 am</option>
//                             <option>1.30 am</option>
//                           </select> */}
//                           <input
//                             type="time"
//                             className="time_input"
//                             name=""
//                             id=""
//                             value={end_time_addProvider}
//                             onChange={(e) =>
//                               setEnd_time_addProvider(e.target.value)
//                             }
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="add-more mb-3">
//                     <Link class="add-hours text-muted" onClick={addingTimeSlot}>
//                       <i class="fa fa-plus-circle"></i> Add
//                     </Link>
//                   </div>
//                 </div>
//                 <div class="row hours-cont">
//                   <div class="col-12 col-md-10">
//                     {allSlot
//                       .filter((ele) => slotDay === ele.day)[0]
//                       ?.slot?.map((ele2, i) => (
//                         <div class="row">
//                           <div class="col-12 col-md-6">
//                             <div class="mb-3">
//                               <label class="mb-2">Start Time</label>
//                               <input
//                                 type="time"
//                                 className="time_input"
//                                 name="start"
//                                 id="start"
//                                 value={ele2.start}
//                                 onChange={(e) => {
//                                   ele2.start = e.target.value;
//                                   setAllSlot([...allSlot]);
//                                 }}

//                                 disabled={ele2?._id ? true : false}
//                               />
//                             </div>
//                           </div>
//                           <div class="col-12 col-md-6">
//                             <div class="mb-3">
//                               <label class="mb-2">End Time</label>
//                               <input
//                                 type="time"
//                                 className="time_input"
//                                 name="end_time"
//                                 id="end_time"
//                                 value={ele2.end}
//                                 onChange={(e) => {
//                                   ele2.end = e.target.value;
//                                   setAllSlot([...allSlot]);
//                                 }}

//                                 disabled={ele2?._id ? true : false}
//                               />
//                             </div>
//                           </div>
//                           <div class="col-md-2">
//                             <div class="mt-2">
//                               <Link
//                                 class="btn btn-danger"
//                                 onClick={() => slotChecking(i)}
//                               >
//                                 <i class="fa-regular fa-trash-can"></i>
//                               </Link>
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                   </div>
//                 </div>
//               </div>
//               <div class="submit-section text-center">
//                 <button
//                   className="theme_btn rounded"
//                   onClick={() => savingSlotsFunction()}

//                 >
//                   Save
//                 </button>
//               </div>
//           </div>
//         </Box>
//       </Modal>
//     </React.Fragment>
//   );
// }

export default function EditHours({
  open7,
  handleClose7,
  editProfile_id,
  selectedProviderSlots,
  clinicWorkingHoursSlots,
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  console.log("editProfile_id", editProfile_id);
  const [allSlots_addProvider, setAddSlots_addProvider] = useState([
    { day: "Sunday", slot: [] },
    { day: "Monday", slot: [] },
    { day: "Tuesday", slot: [] },
    { day: "Wednesday", slot: [] },
    { day: "Thursday", slot: [] },
    { day: "Friday", slot: [] },
    { day: "Saturday", slot: [] },
  ]);

  const [slotDay, setSlotDay] = React.useState("Monday");

  const settingDay = (day) => {
    setSlotDay(day);
  };

  const [dentistSlots, setDentistSlots] = useState([]);
  console.log("🚀 ~ dentistSlots:", dentistSlots);

  useEffect(() => {
    console.log("selectedProviderSlots", selectedProviderSlots);
    setDentistSlots(selectedProviderSlots);
    // let providerSlots = structuredClone(selectedProviderSlots);
    // if (providerSlots.length > 0) {
    //   let clean_data = [];

    //   providerSlots.map((ele) => {
    //     if (ele.slot.length === 0)
    //       clean_data.push({ day: ele?.day, slot: ele.slot });
    //     else {
    //       let slotdata = ele.slot;
    //       let clean_slots = [];
    //       slotdata.map((ele2) => {
    //         ele2.start = new Date(ele2?.start)?.toLocaleString().split(", ")[1];
    //         // ele2.start.split("T")[1]?.split(":")[0] +
    //         // ":" +
    //         // ele2.start.split("T")[1]?.split(":")[1];
    //         ele2.end = new Date(ele2?.end)?.toLocaleString().split(", ")[1];
    //         // ele2.end.split("T")[1]?.split(":")[0] +
    //         // ":" +
    //         // ele2.end.split("T")[1]?.split(":")[1];
    //         console.log("ele for slot time", ele2);
    //         clean_slots.push(ele2);
    //       });
    //       clean_data.push({ day: ele.day, slot: clean_slots });
    //     }
    //   });
    //   console.log("clean data", clean_data);
    //   setAddSlots_addProvider(clean_data);}
    
  }, [editProfile_id]);

 

  async function slotChecking(i) {
    console.log("2222222222", selectedProviderSlots);
    let data1 = structuredClone(selectedProviderSlots);

    let daySlot = data1.filter((ele) => slotDay === ele.day);

    if (!daySlot[0]?.slot[i]?._id) {
      removingSlot(i);
      return;
    }


    const startTime = daySlot[0]?.slot[i]?.start;
    const endTime = daySlot[0]?.slot[i]?.end;

    const [starhour, startminute] = startTime?.split(":");
    const [endHour, endMinute] = endTime?.split(":");
    let time = 0;
    starhour === "00" ? (time = 24) : (time = starhour);
    console.log(starhour);

    if (time > endHour) {
      if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
        toast.error(
          "Invalid practice hours. Start time must be before end time."
        );
        return;
      } else if (time >= 13 && time <= 24 && endHour >= 13 && endHour <= 24) {
        toast.error(
          "Invalid practice hours. Start time must be before end time."
        );
        return;
      }
    } else if (time === endHour && startminute >= endMinute) {
      toast.error(
        "Invalid practice hours. Start time must be before end time."
      );
      return;
    }

    const startInputTime = new Date("2023-01-01");
    const endInputTime = new Date("2023-01-01");

    startInputTime.setHours(starhour, startminute, 0, 0); // Set the hours and minutes
    endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

    let start = startInputTime.toISOString();
    let end = endInputTime.toISOString();

    // let daySlot = selectedProviderSlots.filter((ele) => slotDay === ele.day);


    let data = {
      dentist_id: editProfile_id,
      day: slotDay,
      start: start,
      end: end,
    };
    console.log("checking slot delete payload", data);
    try {
      const resp = await timeSlotRemoveCheck(data);
      console.log("time slot checking", resp);
      if (resp?.code === 200) {
        removingSlot(i);
      }
    } catch (err) {
      // alert(err);
    }
  }

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon1 = (start, end) => {
    console.log(start, end);

    let t1 = "";
    let t2 = "";

    const st = checkAMorPM(start);
    if (st === "AM") {
      const hour = start.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(start.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${start.split(":")[1]} PM`;
    }

    const en = checkAMorPM(end);
    if (en === "AM") {
      const hour = end.split(":");
      t2 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(end.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t2 = `${h}:${end.split(":")[1]} PM`;
    }

    const formatTime = { formatTime: `${t1}-${t2}` };
    console.log("formatTime", formatTime);
    return formatTime;
  };

  function removingSlot(i) {
    let slots = allSlots_addProvider?.filter((ele) => ele.day === slotDay)[0]
      ?.slot;
    let remain = allSlots_addProvider?.filter((ele) => ele.day !== slotDay)[0]
      ?.slot;
    slots.splice(i, 1);
    remain.push({
      day: slotDay,
      slot: slots,
    });
    console.log("new slots after remove form 3", remain);
    setAddSlots_addProvider(remain);
  }

  function disabledWeekDay(day) {
    let working_days = structuredClone(clinicWorkingHoursSlots);

    if (working_days.filter((ele) => ele.day === day).length === 0) return true;
    else return false;
  }

  //  --child-modal--

  const handleSave1 = () => {
    // Add your save logic here
    // For now, let's just close the modal
    handleClose1();
  };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  async function editProviderProfile(e, id) {
    e.preventDefault();
    // let slots = structuredClone(allSlots_addProvider);

    // let data1 = structuredClone(allSlots_addProvider)

    // let daySlot = data1.filter((ele) => slotDay === ele.day);

    // console.log("day slot to remove check", i, daySlot);
    // let check = false;

    // slots.forEach((ele) => {
    //   ele.slot.forEach((ele2) => {
    //     if (ele2.start !== "" && ele2.end !== "") {
    //       //   ele2.start = "2023-12-06T" + ele2.start + ":00.000Z";
    //       // if (ele2.end !== "") ele2.end = "2023-12-06T" + ele2.end + ":00.000Z";

    //       const startTime = ele2.start;
    //       const endTime = ele2.end;

    //       const [starhour, startminute] = startTime.split(":");
    //       const [endHour, endMinute] = endTime.split(":");
    //       let time = 0;
    //       starhour === "00" ? (time = 24) : (time = starhour);
    //       console.log(starhour);

    //       if (time > endHour) {
    //         if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
    //           toast.error(
    //             "Invalid practice hours. Start time must be before end time."
    //           );
    //           check = true;
    //           return;
    //         } else if (
    //           time >= 13 &&
    //           time <= 24 &&
    //           endHour >= 13 &&
    //           endHour <= 24
    //         ) {
    //           toast.error(
    //             "Invalid practice hours. Start time must be before end time."
    //           );
    //           check = true;
    //           return;
    //         }
    //       } else if (time === endHour && startminute >= endMinute) {
    //         toast.error(
    //           "Invalid practice hours. Start time must be before end time."
    //         );
    //         check = true;
    //         return;
    //       }

    //       const startInputTime = new Date("2023-01-01");
    //       const endInputTime = new Date("2023-01-01");

    //       startInputTime.setHours(starhour, startminute, 0, 0); // Set the hours and minutes
    //       endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

    //       ele2.start = startInputTime.toISOString();
    //       ele2.end = endInputTime.toISOString();
    //     } else {
    //       check = true;
    //       return;
    //     }
    //   });

    //   if (check === true) return;
    // });

    // if (check) {
    //   toast.error("Start and end time for a slot is required.");
    //   return;
    // }

    
    const data = {
      slots: [...dentistSlots],
    };

    try {
      const resp = await editDentistProfile(data, id);
      console.log(" provider profile response", resp);
      if (resp?.code === 200) {
        successNotification("Hours Editted Successfully");
        handleSave1();
        handleCloseModal();
      }
    } catch (err) {
      // alert(err);
    }
  }

  function getClinicTimeByDay() {
    const clinicSlot = clinicWorkingHoursSlots.find(
      (slot) => slot.day === slotDay
    );

    if (clinicSlot) {
      const startTime = new Date(clinicSlot.start_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        // hour12: true,
      });
      const endTime = new Date(clinicSlot.end_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        // hour12: true,
      });

      return `Clinic Time : ${startTime} - ${endTime}`;
    } else {
      return "Clinic is not open this day.";
    }
  }

  function handleCloseModal() {
    handleClose7();
    setSlotDay("Monday");
  }

  const toggleSlot = (dayIndex, slotIndex) => {

    setDentistSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[dayIndex].slot[slotIndex] = {
        ...updatedSlots[dayIndex].slot[slotIndex],
        enable: !updatedSlots[dayIndex].slot[slotIndex].enable,
      };
      return updatedSlots;
    });
  };

  return (
    <div>
      {/* <Button onClick={handleOpen7}>Open modal</Button> */}
      <Modal
        open={open7}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-container"
      >
        <Box className="modal-content edit_hoursmodal ">
          <span className="cross_mdl" onClick={handleCloseModal}>
            <i class="fa-solid fa-xmark"></i>
          </span>
          <div className="card border-0 schedule-widget mb-0">
            <div className="schedule-header">
              <div className="schedule-nav">
                <ul className="nav nav-tabs nav-justified gap-2" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#slot_Monday"
                      aria-selected="true"
                      role="tab"
                      onClick={() => settingDay("Monday")}
                    >
                      Monday
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Tuesday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Tuesday")}
                    >
                      Tuesday
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Wednesday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Wednesday")}
                    >
                      Wednesday
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Thursday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Thursday")}
                    >
                      Thursday
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Friday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Friday")}
                    >
                      Friday
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Saturday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Saturday")}
                    >
                      Saturday
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#slot_Sunday"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                      onClick={() => settingDay("Sunday")}
                    >
                      Sunday
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content schedule-cont">
              {daysOfWeek.map((day, dayIndex) => {
                const obj = dentistSlots.find((slot) => slot.day === day);
                const objIndex = dentistSlots.findIndex((slot) => slot.day === day);

                return (
                  <div
                    className={`slotsOverflow tab-pane fade show ${
                      day === "Monday" ? "active" : ""
                    }`}
                    role="tabpanel"
                    id={`slot_${day}`}
                  >
                    <div className="slotTimeContainer">
                      {obj ? (
                        obj.slot.map((slot, slotIndex) => (
                          <div className="time-slots" key={slotIndex}>
                            <input
                              type="checkbox"
                              checked={slot.enable}
                              id={`time-slot-${dayIndex}-${slotIndex}`}
                            />
                            <label
                              htmlFor={`time-slot-${dayIndex}-${slotIndex}`}
                              onClick={() => toggleSlot(objIndex, slotIndex)}
                            >
                              {convertDateToTimeString(slot)}
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>No slots available for {day}.</p>
                      )}

                      {/* <div className="time-slots">
                      <input type="checkbox" id="slot-time-2" />
                      <label htmlFor="slot-time-2">9:00 AM to 10:00 AM</label>
                    </div> */}
                    </div>
                  </div>
                );
              })}

              {/* <div
                id="slot_monday"
                className="tab-pane fade show active"
                role="tabpanel"
              > */}
              {/* <h4 className="card-title d-flex justify-content-between align-items-center">
                  <span className="timeTitle shadow">Time Slots</span>
                  <button
                    onClick={() => handleOpen1("Monday")}
                    className="theme-outline-btn"
                    disabled={disabledWeekDay("Monday")}
                  >
                    <i className="fa me-2 fa-plus-circle" />
                    Edit Slot
                  </button>
                </h4> */}

              {/* <div className="doc-times">
                  <div className="doc-slot-list" style={{ display: "none" }}>
                    8:00 pm - 11:30 pm
                    <Link className="delete_schedule">
                      <i className="fa fa-times" />
                    </Link>
                  </div>
                  <div className="doc-slot-list">
                    11:30 pm - 1:30 pm
                    <Link className="delete_schedule">
                      <i className="fa fa-times" />
                    </Link>
                  </div>
                  <div className="doc-slot-list">
                    3:00 pm - 5:00 pm
                    <Link className="delete_schedule">
                      <i className="fa fa-times" />
                    </Link>
                  </div>
                  <div className="doc-slot-list">
                    6:00 pm - 11:00 pm
                    <Link className="delete_schedule">
                      <i className="fa fa-times" />
                    </Link>
                  </div>
                </div> */}
              {/* <div className="doc-times">
                  {allSlots_addProvider
                    .filter((ele) => ele.day === slotDay)[0]
                    ?.slot.map((ele, i) => (
                      <div className="doc-slot-list">
                        {ele.start} - {ele.end} 
                         {localCon1(ele.start, ele.end)?.formatTime}
                        {ele.start} - {ele.end}

                        <Link
                          className="delete_schedule"
                          onClick={() => slotChecking(i)}
                        >
                          <i className="fa fa-times" />
                        </Link>
                      </div>
                    ))}
                  {allSlots_addProvider.filter((ele) => ele.day === slotDay)[0]
                    ?.slot.length === 0 ? (
                    <p className="text-muted mb-0">Not Available</p>
                  ) : (
                    <></>
                  )}
                  <br />
                   {disabledWeekDay("Monday") ? (
                    "Clinic does not work this day"
                  ) : (
                    <></>
                  )}
                </div> */}

              {/* --Anwar-select-time-slot--02-may-- */}

              {/* </div> */}

              {/* <p className="ps-2">
                <strong>{getClinicTimeByDay()}</strong>
              </p> */}
            </div>
            {open1 && (
              <ChildModal
                open1={open1}
                handleClose1={handleClose1}
                slotDay={slotDay}
                setOpen1={setOpen1}
                slots={allSlots_addProvider}
                setAddSlots_addProvider={setAddSlots_addProvider}
                // id={editProfile_id}
              />
            )}
            <div className="text-center mt-3 ">
              <button
                onClick={(e) => editProviderProfile(e, editProfile_id)}
                className="theme_btn rounded"
              >
                Save
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
