import React,{useState, useEffect} from 'react'
import key from "../../Assets/key.png"
import "./ResetPassword.scss"
import { Link } from 'react-router-dom';

import { matchingValidator, passwordValidator, emptyStringValidator, emailValidator, phoneValidator, pincodeValidator } from '../../utils/validators/validatorServices';
import {successNotification, errorNotification, resetForgotPasswordService} from '../../utils/httpServices/httpService';

import {useParams} from "react-router-dom";

import { useNavigate } from 'react-router-dom';

export default function ResetPassword() {

    const [verification,setVerification] = useState('');

    const navigate = useNavigate();

    let params = useParams();

    function routeParams(){
        console.log("verification id", params.data)
        setVerification(params.data);
         }

    const [fireEyeNew_password, setfireEyeNew_password] = useState('password');
    const [fireEyeConfirm_password, setfireEyeConfirm_password] = useState('password');

    const [password, setPassword] = useState({
        confirm_Password: '',
        new_password: ''
      });
    
      const [passwordValidation, setPasswordValidation] = useState({
        confirm_Password: false,
        new_password: false
      });

      const settingPasswordValues = (event) => {
        setPassword({
          ...password,
          [event.target.name]: event.target.value
        })
    
        console.log("updated values", password)
      }
    
      function ChangePasswordValidatorCheck(name) {
        switch (name) {
          case "confirm_Password":
            setPasswordValidation(() => ({
              ...passwordValidation,
              "confirm_Password": matchingValidator(password[name], password.new_password)
            }))
            break;
          case "new_password":
            setPasswordValidation(() => ({
              ...passwordValidation,
              "new_password": passwordValidator(password[name])
            }))
            break;
          default:
        }
      }
    
      function finalValidationCheck() {
        let tempValidator = passwordValidation;
        let validatorKeys = Object.keys(passwordValidation);
        validatorKeys.forEach((ele) => {
          if (ele === 'confirm_Password')
            tempValidator[ele] = matchingValidator(password[ele], password.new_password)
          else
            tempValidator[ele] = passwordValidator(password[ele])
        })
        setPasswordValidation(() => ({
          ...tempValidator,
        }));
      }
    
      async function submit() {
        finalValidationCheck();
    
        const validatorStatus = Object.values(passwordValidation);
        if (!validatorStatus.some((ele) => ele === true)) {
          const data = {
            "verification" : verification,
            "password" : password.new_password,
          }
          console.log("data", data);
          try {
            const resp = await resetForgotPasswordService(data);
            console.log(" login response data ", resp);
              navigate("/login");
              successNotification("Password Changed Successfully");
              console.log("resp for login", resp.data)
              
          } catch (err) {
            console.log("login error data", err)
          }
        } else {
          console.log("validation failed");
        }
      }

      useEffect(() => {
        routeParams()
      })
    

    return (

        <div class="page_wrap">
            <div class="container">
                <div class="login reset_password">
                    <div class="row">
                        <div class="col-xl-12 p-0">
                            <div class="login__forget_details">
                                <div className='icon mb-3'>
                                    <img src={key} className='aboutIc' alt="icon" />
                                </div>
                                <h4 class="login__heading-text">Set New Password</h4>
                                <p class="login__text-desc">Your new password must be different to previously used passwords.</p>
                                <div class="mb-3 position-relative text-start">
                                    <label for="exampleFormControlInput1" class="form-label">Password</label>
                                    <input type={fireEyeNew_password} className="form-control" value={password.new_password} name='new_password' onChange={settingPasswordValues} onBlur={(e) => ChangePasswordValidatorCheck(e.target.name)} />
                                    <div className="fireEyePosition_set">
                                {fireEyeNew_password === 'password' ? (<button onClick={() => setfireEyeNew_password('text')}><i class="fa fa-eye-slash" aria-hidden="true"></i></button>) :
                                  (<button onClick={() => setfireEyeNew_password('password')}><i class="fa fa-eye"  aria-hidden="true"></i></button>)}
                              </div>
                              {passwordValidation.new_password ? (<div className="validation_message">*Password must be 8 or greater in length</div>) :
                                (<></>)}
                                </div>
                                
                                <div class="mb-3 position-relative text-start">
                                    <label for="exampleFormControlInput1" class="form-label">Confirm Password</label>
                                    <input type={fireEyeConfirm_password} className="form-control" name='confirm_Password' value={password.confirm_Password} onChange={settingPasswordValues} onBlur={(e) => ChangePasswordValidatorCheck(e.target.name)}  /> 
                                    <div className="fireEyePosition_set">
                                {fireEyeConfirm_password === 'password' ? (<button onClick={() => setfireEyeConfirm_password('text')}><i class="fa fa-eye-slash"  aria-hidden="true"></i></button>) :
                                  (<button onClick={() => setfireEyeConfirm_password('password')}><i class="fa fa-eye" aria-hidden="true"></i></button>)}
                              </div>
                              {passwordValidation.confirm_Password ? (<div className="validation_message">*Password does not match</div>) :
                                (<></>)}       
                                </div>
                               
                                <button class="theme_btn rounded-2 w-100" onClick={submit}>Reset Password</button>
                                <div className='text-center mt-3'>
                                    <Link className='text-muted' to="/login"><i class="fa-solid fa-arrow-left"></i> Back to login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
