import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import "./UserProfile.scss";
import avatar from "../../Assets/avtar_img.jpeg";
import { Modal } from 'bootstrap';

import pdfImage from "../../Assets/pdf.png";
// import profile01 from "../../Assets/profile01.jpg"
// import profile02 from "../../Assets/profile02.jpg"
// import profile03 from "../../Assets/profile03.jpg"
// import profile04 from "../../Assets/profile04.jpg"

// import image01 from "../../Assets/client-01.jpg"
// import image02 from "../../Assets/client-02.jpg"
import Footer from "../../components/Footer/Footer";
import UserHeader from "../../components/UserHeader/UserHeader";

import Dropzone, { useDropzone } from "react-dropzone";

import { useNavigate } from "react-router-dom";

import {
  getProfilesDetail,
  successNotification,
  changePassword,
  errorNotification,
  imageUpload,
  editUserProfile,
  addMedicalRecord,
  getMedicalRecordsList,
  editMedicalRecordService,
  deleteMedicalRecord,
  editMedicalRecordById,
  getFavoutiteClinicList,
  favouriteStatusChanger,
  getNotifications,
  getNextAppointmentData,
  getAppointmentHistoryData,
  getSingleAppointmentData,
  seenNotifications,
  unSeenNotifications,
  downloadImage,
  uploadReceipt,
  unseenMessagesCount,
  getSuggestedSlotsGlobal,
  slotBooking,
  makeHttpRequest,
  slotBookingForReshedule,
} from "../../utils/httpServices/httpService";

import {
  matchingValidator,
  passwordValidator,
  emptyStringValidator,
  emailValidator,
  phoneValidator,
  pincodeValidator,
} from "../../utils/validators/validatorServices";

import moment from "moment";

import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Avatar } from "antd";
import ProfilePic from "../../components/ProfilePic/ProfilePic";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../../redux/slices/auth";
import { getUserId, getisoDateForDateObj } from "../../utils/common/common";

export default function UserProfile() {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [favouriteData, setFavouriteData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [nextAppointment, setNextAppointment] = useState({});
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [viewAppointment, setViewAppointment] = useState(false);

  const [notificationCount, setNotificationCount] = useState();
  const [receiptData, setReceiptData] = useState([]);
  const [receipt, setReceipt] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [messageTotalCount, setMessageTotalCount] = useState("");
  const [appointId, setAppointId] = useState("");

  const [suggestedSlots, setSuggestedSlots] = useState({});

  const [selectedSlot, setSelectedSlot] = useState({});

  const [selectedAppointment, setSelectedAppointment] = useState({});

  const [newDenstist, setNewDentist] = useState(null);

  const [showSuggestedSlots, setShowSuggestedSlots] = useState(false);

  const [userDetails, setUserDetails] = useState({
    email: "",
    first_name: "",
    full_name: "",
    last_name: "",
    status: "",
    user_type: "",
    _id: "",

    profile_image: "",
    dob: "",
    blood_group: "",
    phone_number: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
  });
  const [tempEmailAndStatus, setTempEmailAndStatus] = useState({
    temp_email: null,
    temp_email_status: null,
  });
  const [fireEyeOld_Password, setfireEyeOld_Password] = useState("password");
  const [fireEyeNew_password, setfireEyeNew_password] = useState("password");
  const [fireEyeConfirm_password, setfireEyeConfirm_password] =
    useState("password");

  const [password, setPassword] = useState({
    old_password: "",
    confirm_Password: "",
    new_password: "",
  });

  const [passwordValidation, setPasswordValidation] = useState({
    old_password: false,
    confirm_Password: false,
    new_password: false,
  });
  const [mediacalList, setMedicalList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [chips, setChips] = useState([]);
  const [activeTab, setActiveTab] = useState("tab6");
  const [selectedFiles_editMedical, setSelectedFiles_editMedical] = useState(
    []
  );

  const [receiptModalShow, setReceiptModalShow] = useState(false);

  const [numberOfPages, setNumberOfPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const pageSize = 5;

  const modalRef = useRef(null);

  function nextPage() {
    let value = offset + 1;
    setOffset(value);
  }

  function previousPage() {
    let value = offset - 1;
    setOffset(value);
  }

  function changingPage(i) {
    setOffset(i);
  }

  useEffect(() => {
    if (activeTab === "tab1") {
      getData();
    } else if (activeTab === "tab6") {
      getAppointmentData();
    } else if (activeTab === "tab2") {
      getNotificationData();
    } else if (activeTab === "tab3") {
      getReceipt();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "tab6") {
      getAppointmentData();
    }
  }, [offset]);

  useEffect(() => {
    getMessageNumber();
  }, []);

  useEffect(() => {
    getUserData();
    routeParams();
  }, [params.tab]);

  useEffect(() => {
    if (appointId !== "") {
      viewParticularAppointment(appointId);
    }
  }, [appointId]);

  useEffect(() => {
    queryHandler();
  }, [location.search]);

  const onlyNumberKey = (evt) => {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  };

  // console.log('viewAppointment',viewAppointment)
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;
  const medical_records_doc_url = process.env.REACT_APP_MEDICAL_DOCS;

  // console.log("base url", process.env.REACT_APP_USER_PROFILE_IMAGE)

  let currentDate = moment().format("MMMM Do YYYY");
  // console.log(currentDate);

  function routeParams() {
    if (params.tab === "tab1") {
      setActiveTab("tab6");
    } else {
      setActiveTab(params.tab);
    }
  }

  function queryHandler() {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams?.size > 0) {
      let appointmentId = queryParams.get("appointmentId");
      // setAppointId(appointmentId)
      viewParticularAppointment(appointmentId);
      setActiveTab("tab6");
    }
  }

  function birthdate(dob) {
    if (dob !== "" && dob !== null && dob != undefined) {
      // const today = moment(dob);
      // setdateOfBirthAge({
      //   dob: moment(dob).format('Do MMMM YYYY'),
      //   age: moment().diff(moment(today, dob), 'years')
      // })
      const dobMoment = moment(dob);

      // Format the DOB without the time portion
      const formattedDOB = dobMoment.format("Do MMMM YYYY");

      // Calculate age based on the difference between today and the DOB
      const age = moment().diff(dobMoment, "years");

      // Assuming you have a state variable named setdateOfBirthAge to update DOB and age
      setdateOfBirthAge({
        dob: formattedDOB,
        age: age,
      });
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const dispatch = useDispatch();

  function logout() {
    localStorage.clear();
    dispatch(setAuthenticated(false));
    navigate("/");
  }

  async function getUserData() {
    try {
      const resp = await getProfilesDetail();
      if (resp?.code === 200) {
        setUserDetails({
          email: resp.data.email,
          first_name: resp.data.first_name,
          full_name: resp.data.full_name,
          last_name: resp.data.last_name,
          status: resp.data.status,
          user_type: resp.data.user_type,
          _id: resp.data._id,

          profile_image: resp.data.profile_image ? resp.data.profile_image : "",
          dob: resp.dob ? resp.data.dob : "",
          blood_group: resp.data.blood_group ? resp.data.blood_group : "",
          phone_number: resp.data.phone_number ? resp.data.phone_number : "",
          address: resp.data.address ? resp.data.address : "",
          city: resp.data.city ? resp.data.city : "",
          state: resp.data.state ? resp.data.state : "",
          zip_code: resp.data.zip_code ? resp.data.zip_code : "",
          country: resp.data.country ? resp.data.country : "",
        });

        setupdateUserProfile({
          first_name: resp.data.first_name,
          last_name: resp.data.last_name,
          email: resp.data.email,

          status: resp.data.status,
          user_type: resp.data.user_type,
          _id: resp.data._id,
          profile_image: resp.data.profile_image ? resp.data.profile_image : "",
          dob: resp.data.dob ? resp.data.dob : "",
          blood_group: resp.data.blood_group ? resp.data.blood_group : "",
          phone_number: resp.data.phone_number ? resp.data.phone_number : "",
          address: resp.data.address ? resp.data.address : "",
          city: resp.data.city ? resp.data.city : "",
          state: resp.data.state ? resp.data.state : "",
          zip_code: resp.data.zip_code ? resp.data.zip_code : "",
          country: resp.data.country ? resp.data.country : "",
        });
        birthdate(resp.data.dob);
        if (resp.data.temp_email !== null) {
          setTempEmailAndStatus({
            temp_email: resp.data.temp_email,
            temp_email_status: resp.data.temp_email_status,
          });
        }
        console.log("user details after patching", userDetails);
      }
    } catch (err) {
      console.log(err);
      // alert(err)
    }
  }

  ///////////////////    Profile Setting page (STARTS) ///////////////////////

  const [dateOfBirthAge, setdateOfBirthAge] = useState({
    dob: "",
    age: "",
  });


  const [updateUserProfile, setupdateUserProfile] = useState({
    profile_image: "",
    first_name: "",
    last_name: "",
    dob: "",
    blood_group: "",
    phone_number: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    email: "",
    country: "",
  });

  const [updateUserProfileValidation, setupdateUserProfileValidation] =
    useState({
      email: false,
      first_name: false,
      last_name: false,
      phone_number: false,
      zip_code: false,
    });

  const [latestAppointment, setLatestAppointment] = useState(null)

  const settingUserProfileValues = (event) => {
    setupdateUserProfile({
      ...updateUserProfile,
      [event.target.name]: event.target.value,
    });

  };

  function updateUserProfileValidatorCheck(name) {
    switch (name) {
      case "first_name":
        setupdateUserProfileValidation(() => ({
          ...updateUserProfileValidation,
          first_name: emptyStringValidator(updateUserProfile[name]),
        }));
        break;
      case "last_name":
        setupdateUserProfileValidation(() => ({
          ...updateUserProfileValidation,
          last_name: emptyStringValidator(updateUserProfile[name]),
        }));
        break;
      case "email":
        setupdateUserProfileValidation(() => ({
          ...updateUserProfileValidation,
          email: emailValidator(updateUserProfile[name]),
        }));
        break;

      case "phone_number":
        setupdateUserProfileValidation(() => ({
          ...updateUserProfileValidation,
          phone_number: phoneValidator(updateUserProfile[name]),
        }));
        break;

      case "zip_code":
        setupdateUserProfileValidation(() => ({
          ...updateUserProfileValidation,
          zip_code: pincodeValidator(updateUserProfile[name]),
        }));
        break;

      default:
    }
  }

  const profilePicture = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type.split("/").includes("gif") ||
        file.type.split("/").includes("jpg") ||
        file.type.split("/").includes("jpeg") ||
        file.type.split("/").includes("png")
      ) {
        if (file.size <= 2000000) {
          const data = new FormData();
          data.append("path", "userProfile");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload image response", resp);
            if (resp?.code === 200) {
              setupdateUserProfile({
                ...updateUserProfile,
                profile_image: resp.path,
              });
              successNotification("Image uplaoded Successfully");
              console.log("user profile data", updateUserProfile);
            }
          } catch (error) {
            console.log("upload profile image error", error);
          }
        } else {
          errorNotification("Image size is greater than 2MB");
        }
      } else {
        errorNotification(
          "Please upload image in supported format (PNG,JPG,GIF)"
        );
      }
    }
  };

  function finalValidationCheck_foruser() {
    console.log(
      "user check function",
      updateUserProfile,
      updateUserProfileValidation
    );
    let tempValidator = updateUserProfileValidation;
    let validatorKeys = Object.keys(updateUserProfileValidation);
    console.log("user keys", validatorKeys, tempValidator);
    validatorKeys.forEach((ele) => {
      if (ele === "first_name" || ele === "last_name")
        tempValidator[ele] = emptyStringValidator(updateUserProfile[ele]);
      else if (ele === "email")
        tempValidator[ele] = emailValidator(updateUserProfile[ele]);
      else if (ele === "phone_number")
        tempValidator[ele] =
          updateUserProfile[ele] !== ""
            ? phoneValidator(updateUserProfile[ele])
            : false;
      else
        tempValidator[ele] =
          updateUserProfile[ele] !== ""
            ? pincodeValidator(updateUserProfile[ele])
            : false;
    });
    setupdateUserProfileValidation(() => ({
      ...tempValidator,
    }));
  }

  async function saveUpdateProfile() {
    finalValidationCheck_foruser();

    const validatorStatus = Object.values(updateUserProfileValidation);
    if (!validatorStatus.some((ele) => ele === true)) {
      const data = {
        ...updateUserProfile,
      };
      console.log("data", data);
      try {
        const resp = await editUserProfile(data);
        console.log(" user profile update", resp);
        if (resp?.code === 200) {
          successNotification("Profile Updated Successfully");
          getUserData();
        }
      } catch (err) {
        // alert(err);
      }
    } else {
      console.log("validation failed", updateUserProfileValidation);
    }
  }

  ///////////////////    Profile Setting page (ENDS) ///////////////////////

  ///////////////////    Change password (STARTS) ///////////////////////

  const settingPasswordValues = (event) => {
    setPassword({
      ...password,
      [event.target.name]: event.target.value,
    });

    console.log("updated values", password);
  };

  function ChangePasswordValidatorCheck(name) {
    switch (name) {
      case "old_password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          old_password: emptyStringValidator(password[name]),
        }));
        break;
      case "confirm_Password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          confirm_Password: matchingValidator(
            password[name],
            password.new_password
          ),
        }));
        break;
      case "new_password":
        setPasswordValidation(() => ({
          ...passwordValidation,
          new_password: passwordValidator(password[name]),
        }));
        break;
      default:
    }
  }

  function finalValidationCheck() {
    let tempValidator = passwordValidation;
    let validatorKeys = Object.keys(passwordValidation);
    validatorKeys.forEach((ele) => {
      if (ele === "old_password")
        tempValidator[ele] = emptyStringValidator(password[ele]);
      else if (ele === "confirm_Password")
        tempValidator[ele] = matchingValidator(
          password[ele],
          password.new_password
        );
      else tempValidator[ele] = passwordValidator(password[ele]);
    });
    setPasswordValidation(() => ({
      ...tempValidator,
    }));
  }

  async function submit() {
    finalValidationCheck();

    const validatorStatus = Object.values(passwordValidation);
    if (!validatorStatus.some((ele) => ele === true)) {
      const data = {
        old_password: password.old_password,
        new_password: password.new_password,
      };
      try {
        const resp = await changePassword(data);
        if (resp?.code === 200) {
          successNotification("Password Changed Successfully");
          console.log("resp for login", resp.data);
          setPassword({
            old_password: "",
            confirm_Password: "",
            new_password: "",
          });
          setPasswordValidation({
            old_password: false,
            confirm_Password: false,
            new_password: false,
          });
        }
      } catch (err) {
        console.log("login error data", err);
      }
    } else {
      console.log("validation failed");
    }
  }

  ///////////////////    Change password page (ENDS) ///////////////////////

  ///////////////////// Add medical records (START) ////////////////////

  const [medicalRecord, setmedicalRecord] = useState({
    title_name: "",
    patient_name: "",
    hospital_name: "",
    documents: [],
    symptoms: "",
    date: "",
  });

  const [medicalRecordValidation, setMedicalRecordValidation] = useState({
    title_name: false,
    patient_name: false,
    symptoms: false,
    hospital_name: false,
  });

  const settingmedicalRecordValues = (event) => {
    setmedicalRecord({
      ...medicalRecord,
      [event.target.name]: event.target.value,
    });

  };

  function updateMedicalRecordCheck(name) {
    switch (name) {
      case "title_name":
        setMedicalRecordValidation(() => ({
          ...medicalRecordValidation,
          title_name: emptyStringValidator(medicalRecord[name]),
        }));
        break;
      case "patient_name":
        setMedicalRecordValidation(() => ({
          ...medicalRecordValidation,
          patient_name: emptyStringValidator(medicalRecord[name]),
        }));
        console.log("medical record validators", medicalRecordValidation);
        break;
      case "hospital_name":
        setMedicalRecordValidation(() => ({
          ...medicalRecordValidation,
          hospital_name: emptyStringValidator(medicalRecord[name]),
        }));
        console.log("medical record validators", medicalRecordValidation);
        break;

      case "symptoms":
        setMedicalRecordValidation(() => ({
          ...medicalRecordValidation,
          symptoms: emptyStringValidator(medicalRecord[name]),
        }));
        console.log("medical records validators", medicalRecordValidation);
        break;

      default:
    }
  }

  async function uploadreceipt(event) {
    const file = event[event.length - 1];
    const fileType = file.type.split("/")[1];
    const isPDF = fileType === "pdf";
    const isImage = ["jpeg", "png", "gif", "bmp", "webp"].includes(fileType);


    if (isPDF || isImage) {
      if (file.size <= 2000000) {
        const data = new FormData();
        data.append("path", "receipt");
        data.append("media", file);
        try {
          const resp = await imageUpload(data);
          if (resp?.code === 200) {
            setReceipt(resp?.path);
            // let doc = medicalRecord.documents;
            // doc.push(resp.path);
            // console.log("doc", doc, "medical records", medicalRecord)
            // setmedicalRecord({
            //   ...medicalRecord,
            //   documents: doc
            // })

            // successNotification("Receipt uplaoded Successfully");
            // console.log("user profile data", medicalRecord, "event", event)
            setSelectedFiles(event);
          }
        } catch (error) {
          console.log("upload profile image error", error);
          return setSelectedFiles(event.slice(0, event.length - 1));
        }
      } else {
        errorNotification("File size is greater than 2MB");
      }
    } else {
      errorNotification("Only pdf and Image file is supported.");
    }
  }

  const medicalRecordDocs = async (event) => {
    console.log("uploaded add medical data", event);
    const file = event[event.length - 1];

    // if (!(file.type.split('/').includes('csv'))) {
    //   if (file.size <= 2000000) {
    //     const data = new FormData;
    //     data.append("path", "medicalRecords");
    //     data.append("media", file);
    //     try {
    //       const resp = await imageUpload(data);
    //       console.log("medical doc response", resp);
    //       if (resp?.code === 200) {
    //         let doc = medicalRecord.documents;
    //         doc.push(resp.path);
    //         console.log("doc", doc, "medical records", medicalRecord)
    //         setmedicalRecord({
    //           ...medicalRecord,
    //           documents: doc
    //         })
    //         successNotification("Image uplaoded Successfully");
    //         console.log("user profile data", medicalRecord, "event", event)
    setSelectedFiles(event);
    //         }
    //       }
    //       catch (error) {
    //         console.log("upload profile image error", error)
    //         return setSelectedFiles(event.slice(0, event.length - 1));
    //       }
    //     }
    //     else {
    //       errorNotification("Image size is greater than 2MB")
    //     }
    //   }
    //   else {
    //     errorNotification("Csv files are not supported")
    //   }
  };

  function finalValidationCheck_medicalRecords() {
    console.log(
      "medical check function",
      medicalRecord,
      medicalRecordValidation
    );
    let tempValidator = medicalRecordValidation;
    let validatorKeys = Object.keys(medicalRecordValidation);
    console.log("user keys for medical", validatorKeys, tempValidator);
    validatorKeys.forEach((ele) => {
      tempValidator[ele] = emptyStringValidator(medicalRecord[ele]);
    });
    setMedicalRecordValidation(() => ({
      ...tempValidator,
    }));
  }

  async function saveMedicalRecord() {
    finalValidationCheck_medicalRecords();

    const validatorStatus = Object.values(medicalRecordValidation);
    if (!validatorStatus.some((ele) => ele === true)) {
      const data = {
        ...medicalRecord,
      };
      console.log("medical record data", data);
      try {
        const resp = await addMedicalRecord(data);
        console.log(" medical record added ", resp);
        if (resp?.code === 200) {
          successNotification("Medical record added Successfully");
          getAllMedicalRecordsListFunc();

          setmedicalRecord({
            title_name: "",
            patient_name: "",
            hospital_name: "",
            documents: [],
            symptoms: "",
            date: "",
          });

          setMedicalRecordValidation({
            title_name: false,
            patient_name: false,
            symptoms: false,
            hospital_name: false,
          });

          setSelectedFiles([]);

          document.getElementById("closeIt_addMedical").click();
        }
      } catch (err) {
        // alert(err);
      }
    } else {
      console.log(
        "validation failed for medical record",
        medicalRecordValidation
      );
    }
  }

  ////////////////////  Add medical records (ENDS) ///////////////////////]

  ////////////////////  Get medical records (STARTS) ///////////////////////

  async function deleteMedicalRecordFromList(id) {
    console.log("id to delete", id);
    try {
      const resp = await deleteMedicalRecord(id);
      console.log("delete medical record list ", resp);
      if (resp?.code === 200) {
        successNotification("Record Deleted Successfully");
        getAllMedicalRecordsListFunc();
      }
    } catch (err) {
      // alert(err);
    }
  }

  async function getAllMedicalRecordsListFunc() {
    let obj = {
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
    };
    try {
      const resp = await getMedicalRecordsList(obj);
      console.log(" get medical record list ", resp);
      if (resp?.code === 200) {
        let list = [];
        setMedicalList(resp.data);
        resp.data.map((ele, i) =>
          list.push(
            <tr>
              <td>{i + 1}</td>
              <td>{ele.patient_name ? ele.patient_name : "--"}</td>
              <td>
                {ele.date ? moment(ele.date).format("Do MMMM YYYY") : "--"}
                {/* <span className="d-block text-info">{moment(ele.date).format("hh:mm A")}</span> */}
              </td>
              <td>{ele.symptoms ? ele.symptoms : "--"}</td>
              <td>
                <Link
                  title="Download attachment"
                  className="theme_btn rounded btn-sm px-3 d-inline-block"
                >
                  {" "}
                  <i className="fa fa-download"></i>
                </Link>
              </td>
              <td>Your Self</td>
              <td>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#editMedicalRecordBox"
                  type="submit"
                  onClick={() => {
                    patchEditMedicalRecords(ele._id);
                  }}
                  className="btn btn-sm btn-danger px-3 py-2 me-2 rounded "
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    deleteMedicalRecordFromList(ele._id);
                  }}
                  className="btn btn-sm btn-danger px-3 py-2 rounded "
                >
                  <i className="far fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          )
        );
        setMedicalList(list);
        console.log("medical list for render", mediacalList);
      }
    } catch (err) {
      // alert(err);
    }
  }

  const [editMedicalID, setEditMadicalID] = useState("");

  async function patchEditMedicalRecords(id) {
    console.log("patching edit medical record", id);
    try {
      const resp = await editMedicalRecordById(id);
      console.log("patching value response", resp);
      if (resp?.code === 200) {
        if (resp.data.documents.length > 0) {
          let docs = [];
          resp.data.documents.forEach(async (ele, i) => {
            console.log("running complete url", medical_records_doc_url + ele);

            let response = await fetch(medical_records_doc_url + ele);
            let data = await response.blob();
            let metadata = {
              type: "image/jpeg",
            };
            let file = new File([data], ele, metadata);
            docs.push(file);
            console.log("url to file", file, "doc1111111", docs);
            if (resp.data.documents.length === i + 1) {
              console.log("docs console", docs);
              setSelectedFiles_editMedical(docs);
              console.log(
                "selectedFiles_editMedical",
                selectedFiles_editMedical
              );
            }
          });
        } else {
          setSelectedFiles_editMedical([]);
        }
        setEditMedicalRecord({
          title_name: resp.data.title_name,
          patient_name: resp.data.patient_name,
          hospital_name: resp.data.hospital_name,
          documents: resp.data.documents,
          symptoms: resp.data.symptoms,
          date: resp.data.date,
        });
        setEditMadicalID(id);
        console.log(
          "user profile data",
          editMedicalRecord,
          "id after set",
          editMedicalID
        );
      }
    } catch (error) {
      console.log("upload profile image error", error);
    }
  }

  ////////////////////  Get medical records (ENDS) ///////////////////////

  /////////////////// Edit Medical Records (STARTS) /////////////////////

  const [editMedicalRecord, setEditMedicalRecord] = useState({
    title_name: "",
    patient_name: "",
    hospital_name: "",
    documents: [],
    symptoms: "",
    date: "",
  });

  const [editMedicalRecordValidation, setEditMedicalRecordValidation] =
    useState({
      title_name: false,
      patient_name: false,
      symptoms: false,
      hospital_name: false,
    });

  const settingEditMedicalRecordValues = (event) => {
    setEditMedicalRecord({
      ...editMedicalRecord,
      [event.target.name]: event.target.value,
    });

    console.log("updated values for medical record", editMedicalRecord);
  };

  function updateEditMedicalRecordCheck(name) {
    switch (name) {
      case "title_name":
        setEditMedicalRecordValidation(() => ({
          ...editMedicalRecordValidation,
          title_name: emptyStringValidator(editMedicalRecord[name]),
        }));
        console.log("medical record validators", editMedicalRecordValidation);
        break;
      case "patient_name":
        setEditMedicalRecordValidation(() => ({
          ...editMedicalRecordValidation,
          patient_name: emptyStringValidator(editMedicalRecord[name]),
        }));
        console.log("medical record validators", editMedicalRecordValidation);
        break;
      case "hospital_name":
        setEditMedicalRecordValidation(() => ({
          ...editMedicalRecordValidation,
          hospital_name: emptyStringValidator(editMedicalRecord[name]),
        }));
        console.log("medical record validators", editMedicalRecordValidation);
        break;

      case "symptoms":
        setEditMedicalRecordValidation(() => ({
          ...editMedicalRecordValidation,
          symptoms: emptyStringValidator(editMedicalRecord[name]),
        }));
        console.log("medical records validators", editMedicalRecordValidation);
        break;

      default:
    }
  }

  // const editMedicalRecordDocs = async (event) => {
  //   console.log("uploaded medical data", event)
  //   const file = event[event.length - 1];

  //   // if (!(file.type.split('/').includes('csv'))) {
  //   //   if (file.size <= 2000000) {
  //   //     const data = new FormData;
  //   //     data.append("path", "medicalRecords");
  //   //     data.append("media", file);
  //   //     try {
  //   //       const resp = await imageUpload(data);
  //   //       console.log("medical doc response for edit", resp);
  //   //       if (resp?.code === 200) {
  //   //         let doc = editMedicalRecord.documents;
  //   //         doc.push(resp.path);
  //   //         console.log("doc for edit", doc, "medical records edit", editMedicalRecord)
  //   //         setEditMedicalRecord({
  //   //           ...editMedicalRecord,
  //   //           documents: doc
  //   //         })
  //   //         successNotification("Image uplaoded Successfully");
  //   //         console.log("user profile data", editMedicalRecord, "event for edit", event)
  //   //         setSelectedFiles_editMedical(event);
  //   //       }
  //   //     }
  //   //     catch (error) {
  //   //       console.log("upload profile image error for edit", error)
  //   //       return setSelectedFiles_editMedical(event.slice(0, event.length - 1));
  //   //     }
  //   //   }
  //   //   else {
  //   //     errorNotification("Image size is greater than 2MB")
  //   //   }
  //   // }
  //   // else {
  //   //   errorNotification("Csv files are not supported")
  //   // }
  // }

  // function finalValidationCheck_editMedicalRecords() {
  //   console.log("medical check function", editMedicalRecord, editMedicalRecordValidation)
  //   let tempValidator = editMedicalRecordValidation;
  //   let validatorKeys = Object.keys(editMedicalRecordValidation);
  //   console.log("user keys for medical", validatorKeys, tempValidator)
  //   validatorKeys.forEach((ele) => {
  //     tempValidator[ele] = emptyStringValidator(editMedicalRecord[ele])
  //   })
  //   setEditMedicalRecordValidation(() => ({
  //     ...tempValidator,
  //   }));
  // }

  // async function saveEditMedicalRecord(record_id) {
  //   finalValidationCheck_editMedicalRecords();

  //   const validatorStatus = Object.values(editMedicalRecordValidation);
  //   if (!validatorStatus.some((ele) => ele === true)) {
  //     const data = {
  //       ...editMedicalRecord
  //     }
  //     console.log("medical record data", data, record_id);
  //     try {
  //       const resp = await editMedicalRecordService(data, editMedicalID);
  //       console.log("edit medical record ", resp);
  //       if (resp?.code === 200) {
  //         successNotification("Medical record added Successfully");
  //         getAllMedicalRecordsListFunc();
  //         document.getElementById('closeIt_editMedical').click()
  //       }
  //     } catch (err) {
  //       // alert(err);
  //     }
  //   } else {
  //     console.log("validation failed form edit medical record", editMedicalRecordValidation);
  //   }

  // }

  ////////////////// Edit Medical Records (ENDS) ///////////////////////

  /////////////////////// ADD MEDICAL DROPZONE (START) //////////////////
  // Callback for when files are dropped
  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     console.log("accepted files",acceptedFiles,selectedFiles)
  //     const updatedFiles = selectedFiles.concat(
  //       acceptedFiles.slice(0, 6 - selectedFiles.length)
  //     );
  //     console.log("updated files",updatedFiles)
  //     medicalRecordDocs(updatedFiles);
  //     console.log("slectedfiles after set state ",selectedFiles)
  //   },
  //   [selectedFiles]
  // );

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    const docFilesName = medicalRecord.documents;
    console.log("medical doc files name", docFilesName);
    updatedFiles.splice(index, 1);
    docFilesName.splice(index, 1);
    console.log("data after remove ", updatedFiles, docFilesName);
    setmedicalRecord({
      ...addMedicalRecord,
      documents: docFilesName,
    });
    setSelectedFiles(updatedFiles);
  };

  const {
    getRootProps: getRootProps_addMedical,
    getInputProps: getInputProps_addMedical,
  } = useDropzone({
    onDrop: (acceptedFiles) => {


      const updatedFiles = selectedFiles.concat(
        acceptedFiles.slice(0, 1 - selectedFiles.length)
      );

      uploadreceipt(acceptedFiles);

    },
    content: [selectedFiles],
    accept: "image/*",
    multiple: true,
    maxFiles: 1,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onChange = (newChips) => {
    setChips(newChips);
  };

  /////////////////////// ADD MEDICAL DROPZONE (END) //////////////////

  /////////////////////// EDIT MEDICAL DROPZONE (START) ///////////////

  // Callback for when files are dropped
  // const onDrop__editMedical = useCallback(
  //   (acceptedFiles) => {
  //     console.log("accepted files for editMedical",acceptedFiles,selectedFiles_editMedical)
  //     const updatedFiles = selectedFiles_editMedical.concat(
  //       acceptedFiles.slice(0, 6 - selectedFiles_editMedical.length)
  //     );
  //     console.log("updated files for edit medical docs",updatedFiles)
  //     editMedicalRecordDocs(updatedFiles);
  //     console.log("slectedfiles after set state for edit docs ",selectedFiles_editMedical)
  //   },
  //   [selectedFiles_editMedical]
  // );

  const removeImage_editMedical = (index) => {
    const updatedFiles = [...selectedFiles_editMedical];
    const docFilesName = editMedicalRecord.documents;
    updatedFiles.splice(index, 1);
    docFilesName.splice(index, 1);
    console.log("data after remove for edit doc", updatedFiles, docFilesName);
    setEditMedicalRecord({
      ...editMedicalRecord,
      documents: docFilesName,
    });
    setSelectedFiles_editMedical(updatedFiles);
  };

  // const { getRootProps: getRootProps_editMedical, getInputProps: getInputProps_editMedical } = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     console.log("accepted files for editMedical", acceptedFiles, selectedFiles_editMedical)
  //     const updatedFiles = selectedFiles_editMedical.concat(
  //       acceptedFiles.slice(0, 1)
  //     );
  //     console.log("updated files for edit medical docs", updatedFiles)
  //     editMedicalRecordDocs(updatedFiles);
  //     console.log("slectedfiles after set state for edit docs ", selectedFiles_editMedical)
  //   },
  //   content: [selectedFiles_editMedical],
  //   accept: "image/*",
  //   multiple: true,
  //   maxFiles: 1,
  // });

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const [chips, setChips] = useState([]);

  // const onChange = (newChips) => {
  //   setChips(newChips);
  // };

  /////////////////////// EDIT MEDICAL DROPZONE (START) ///////////////

  // react-dropzone hook configuration
  async function getData() {
    try {
      const resp = await getFavoutiteClinicList();
      setFavouriteData(resp?.data);
      console.log("resp1", resp?.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getNotificationData() {
    try {
      const resp1 = await getNotifications();
      console.log(resp1);
      setNotificationData(resp1?.data);

      await seenNotifications();
      getUnSeenCount();
    } catch (error) { }
  }

  const ratingFun = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <i
        key={index}
        className={`fas fa-star ${index < Math.floor(rating) ? "filled" : ""}`}
      ></i>
    ));
  };

  //Give format to date
  function formatCustomDate(date) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    // const year = date.getFullYear();

    return `${dayOfWeek}${dayOfMonth} ${month}`;
  }

  function formatCustomDate1(date) {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek}${dayOfMonth} ${month} ${year}`;
  }

  function availableDate(availableArray) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const daysOfWeeks = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    };
    let result = `Available on`;
    let presentDayStatus = false;
    let before = 7;
    let after = 7;
    let presentDate = new Date();
    let dateArray = formatCustomDate(presentDate);
    let getPresentDayNumber = presentDate.getDay();
    let presentDay = daysOfWeek[getPresentDayNumber - 1];
    console.log(presentDay);

    for (let index = 0; index < availableArray.length; index++) {
      if (daysOfWeeks[availableArray[index]?.day] === daysOfWeeks[presentDay]) {
        presentDayStatus = true;
        break;
      }
      if (daysOfWeeks[availableArray[index]?.day] < daysOfWeeks[presentDay]) {
        let v = 7 - daysOfWeeks[presentDay];
        let v1 = daysOfWeeks[availableArray[index]?.day];
        let v2 = v + v1;
        if (before > v2) {
          before = v2;
        }
      }
      if (daysOfWeeks[availableArray[index]?.day] > daysOfWeeks[presentDay]) {
        let v =
          daysOfWeeks[availableArray[index]?.day] - daysOfWeeks[presentDay];

        if (after < v) {
          after = v;
        }
      }
    }

    if (presentDayStatus) {
      return `${result} ${dateArray}`;
    }

    if (after != 7) {
      console.log(after);
      presentDate.setDate(presentDate.getDate() + after);
      console.log(presentDate);
      return `${result} ${formatCustomDate(presentDate)}`;
    } else if (before != 7) {
      presentDate.setDate(presentDate.getDate() + before);
      return `${result} ${formatCustomDate(presentDate)}`;
    } else {
      return "Not available";
    }
  }

  async function favouriteStatusHandler(branchId) {
    console.log(branchId);
    try {
      const body = {
        branch_id: branchId,
      };
      const resp = await favouriteStatusChanger(body);
      getData();
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  }

  const checkAMorPM = (time) => {
    // Assuming the time is in HH:mm:ss format
    const hour = parseInt(time.split(":")[0], 10);

    if (hour < 12) {
      return "AM";
    } else {
      return "PM";
    }
  };

  const localCon1 = (date) => {
    let v = new Date(date)
      ?.toLocaleString("en-US", { hour12: false })
      .split(", ")[1];
    let t1 = "";

    const st = checkAMorPM(v);
    if (st === "AM") {
      const hour = v.split(":");
      t1 = `${hour[0]}:${hour[1]} AM`;
    } else {
      const hour = parseInt(v.split(":")[0], 10);
      const h = hour > 12 ? hour - 12 : hour;
      t1 = `${h}:${v.split(":")[1]} PM`;
    }



    const formatTime = { formatTime: `${t1}` };

    return formatTime;
  };

  function getDate(date) {
    let res = localCon1(date);
    return res?.formatTime;
  }

  async function getAppointmentData() {
    try {
      const resp = await getNextAppointmentData();
      console.log(resp?.data);
      if (resp?.data && resp?.data !== null) {
        setNextAppointment(resp?.data);
        // setViewAppointment(true);
      } else {
        // setViewAppointment(false);
      }
      const params = {
        limit: pageSize,
        offset: offset * pageSize,
      };

      const resp1 = await getAppointmentHistoryData(params);
      setAppointmentHistory(resp1?.data);
      paginationConfig(resp1?.count);
      console.log(resp1);
    } catch (error) { }
  }

  // console.log(nextAppointment)
  function dateChanger(date) {
    const d = new Date(date);
    let res = formatCustomDate1(d);
    console.log(res, "first");
    return res;
  }

  const makeDateString = (dateString) => {
    const date = new Date(dateString);

    // Get the day of the week, month, and day
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate();

    // Format the date with a leading zero if the day is a single digit
    const formattedDay = day < 10 ? `0${day}` : day;

    // Get the year
    const year = date.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${dayOfWeek}, ${formattedDay} ${month} ${year}`;

    return formattedDate;
  };

  async function getUnSeenCount() {
    try {
      const resp = await unSeenNotifications();
      setNotificationCount(resp?.data);
      // console.log(resp);
    } catch (error) {
      console.log(error);
    }
  }

  async function getReceipt() {
    try {
      const params = {
        screen: "receipt",
      };
      const resp = await getAppointmentHistoryData(params);
      console.log(resp);
      setReceiptData(resp?.data);
    } catch (error) { }
  }

  async function getMessageNumber() {
    try {
      const resp = await unseenMessagesCount();
      console.log("setMessageTotalCount", resp);
      setMessageTotalCount(resp?.data);
    } catch (error) { }
  }

  function viewHandler() {
    //     if(viewAppointment === false)return;
    // console.log(viewAppointment)
    console.log(nextAppointment, "nextAppointmenttttttt");
    if (Object.keys(nextAppointment).length === 0) {
      toast.warning("No appointments found in the history");
      return;
    }
    setViewAppointment(!viewAppointment);
  }

  async function viewParticularAppointment(id) {
    console.log(id);

    // getSuggestedSlots(id);
    try {
      const resp = await getSingleAppointmentData(id);
      setNextAppointment(resp?.data);
      // viewnoAppoint()
      getLatestAppointmentDetail(resp?.data)
      setViewAppointment(true);

      console.log(resp?.data);
    } catch (error) { }
  }

  const getLatestAppointmentDetail = async (currentAppointment) => {
    const branchId = currentAppointment?.branch_id;

    if (!branchId) {
      console.log("user id not available");
      return;
    }

    try {
      const resp = await makeHttpRequest(
        "get",
        `users/getLatestAppointmentForUser/${branchId}`
      );
      if (resp?.data?._id === currentAppointment?._id) {
        getSuggestedSlots(resp?.data?._id);
      }
      setLatestAppointment(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  function viewAppoint() {
    setViewAppointment(true);
  }

  const handleDownload = async (image) => {
    console.log(image);
    if (image) {
      const url = image;

      try {
        const response = await downloadImage(url);
        const contentType = response.type;

        console.log("Content-Type:", contentType);
        const blob = new Blob([response]);

        // Create a virtual anchor element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "User_receipt.pdf"; // Set the desired filename

        // Trigger a click on the anchor element
        document.body.appendChild(link);
        link.click();

        // Clean up the virtual anchor element
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  async function saveReceipt() {
    try {
      const body = {
        appointment_id: appointmentId,
        receipt: receipt,
      };
      const resp = await uploadReceipt(body);

      toast.success("Your receipt has been uploaded.");
      handleHideModal()
      getReceipt()
    } catch (error) {
      console.log(error);
    }
  }

  function viewHandler1(type, id) {
    if (type === "appointment") {
      setActiveTab("tab6");
      setAppointId(id);
    }
  }

  //get suggested slots

  const getSuggestedSlots = async (appoint_Id) => {
    const params = {
      appointment_id: appoint_Id,
    };
    try {
      const resp = await getSuggestedSlotsGlobal(params);
      if (resp?.data?.data) {
        setShowSuggestedSlots(true);
        setSelectedAppointment(resp?.data?.data?.appointment_id);
        if (resp?.data?.data?.dentist_id) {
          setNewDentist(resp?.data?.data?.dentist_id);
        }
        if (resp?.data?.data?.suggested_slots?.length > 0) {
          extractSlots(resp?.data?.data?.suggested_slots);
        }
      } else {
        setShowSuggestedSlots(false);
        setSelectedAppointment({});
        setNewDentist(null);
        setSuggestedSlots({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  function extractSlots(slotData) {
    const slotsByDate = {};

    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    slotData.forEach((slot) => {
      // Extracting date from start time
      const date = new Date(slot.start).toLocaleDateString("en-GB", options);

      // Extracting time from start and end times
      const startTime = new Date(slot.start).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const endTime = new Date(slot.end).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      // Constructing slot string
      const slotString = `${startTime} - ${endTime}`;

      // Adding slot to slotsByDate
      if (!slotsByDate[date]) {
        slotsByDate[date] = [slotString];
      } else {
        slotsByDate[date].push(slotString);
      }
    });

    setSuggestedSlots(slotsByDate);
  }

  const handleSlotSelection = (date, selectedSlot) => {
    let t = convertTimeFun(date, selectedSlot);

    const slot = {
      start_time: t?.start,
      end_time: t?.end,
    };

    setSelectedSlot(slot);
  };

  function convertTimeFun(date, selectedSlot) {
    // Parse the date string to get day and month
    const [day, month, year] = date.split(" ");

    // Parse the start and end times from the selectedSlot string
    const [startTime, endTime] = selectedSlot.split(" - ");

    // Create start and end date objects
    const startDate = new Date(`${year} ${month} ${day} ${startTime}`);
    const endDate = new Date(`${year} ${month} ${day} ${endTime}`);

    // Convert dates to UTC format
    const startUTC = startDate.toISOString();
    const endUTC = endDate.toISOString();

    // Return object with start and end times
    return { start: startUTC, end: endUTC };
  }

  const bookSlotFun = async () => {
    if (!selectedSlot?.start_time || !selectedSlot?.end_time) {
      toast.error("Select any slot");
      return;
    }
    const localStartDate = new Date(selectedSlot?.start_time);
    const weekdayNumber = localStartDate.getDay();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dayOfWeek = weekdays[weekdayNumber];

    // const DateStringUtC = new Date(
    //   localStartDate.setHours(0, 0, 0, 0)
    // ).toISOString();

    const DateStringUtC = getisoDateForDateObj(selectedSlot?.start_time)

    const startDate = localStartDate
      .toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    console.log(
      "changs for new payload :",
      selectedSlot,
      new Date(selectedSlot?.start_time)
    );

    const body = {
      branch_id: selectedAppointment?.branch_id,
      dentist_id: newDenstist || "",
      start_time: selectedSlot?.start_time,
      end_time: selectedSlot?.end_time,
      reason: "dental issue",
      services: selectedAppointment?.service,
      user_id: getUserId(),
      day: dayOfWeek,
      date: startDate,
      start: DateStringUtC,
    };

    try {
      const resp = await slotBookingForReshedule(body);
      getAppointmentData();
      setViewAppointment(false)
      toast.success("Slot Booked Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  function paginationConfig(totalCount) {
    let count = totalCount === undefined ? 0 : totalCount;
    let numberOfPages = Math.ceil(count / pageSize);
    let arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i);
    }
    setNumberOfPages(arr);
  }

  const handleShowModal = () => {
    const modalElement = modalRef.current;
    const modal = new Modal(modalElement);
    modal.show();
  };


  const handleHideModal = () => {
    const modalElement = modalRef.current;
    const modal = Modal.getInstance(modalElement);
    modal.hide();
  };

  return (
    <>
      <UserHeader data={userDetails?.profile_image} />

      <div className="page-wrap">
        <div className="patient">
          <div className="container">
            <div className="row g-4">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <div className="profile-sidebar">
                  <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                      <Link to="" className="booking-doc-img">
                        {userDetails?.profile_image ? (
                          <img
                            src={
                              userDetails?.profile_image
                                ? profile_image_base_url +
                                userDetails?.profile_image
                                : process.env.REACT_APP_DEFAULT_IMAGE_URL
                            }
                            className="img-fluid rounded-circle "
                            alt="User Patient"
                          />
                        ) : (
                          <ProfilePic
                            name={userDetails?.full_name}
                            fontSize={45}
                          />
                        )}
                      </Link>
                      <div className="profile-det-info">
                        <h6>{userDetails?.full_name}</h6>
                        <div className="patient-details">
                          {dateOfBirthAge.dob &&
                            dateOfBirthAge.age !== undefined && (
                              <h6>
                                {" "}
                                <i className="fas fa-birthday-cake" />{" "}
                                {
                                  dateOfBirthAge.age === 0
                                    ? dateOfBirthAge.dob
                                    : `${dateOfBirthAge.dob}, ${dateOfBirthAge.age
                                    } ${dateOfBirthAge.age === 1
                                      ? "year"
                                      : "years"
                                    }`
                                }{" "}
                              </h6>
                            )}
                          {userDetails?.state && userDetails?.country && (
                            <h6 className="mb-0">
                              <i className="fas fa-map-marker-alt" />{" "}
                              {userDetails?.state}, {userDetails?.country}
                            </h6>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-widget">
                    <nav className="dashboard-menu">
                      <ul>
                        {/* <li>
                          <Link
                            className={`custome-tab  ${
                              activeTab === "tab1" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab1")}
                          >
                            <i className="fas fa-bookmark" />
                            <span>Favourites</span>
                          </Link>
                        </li> */}

                        <li>
                          <Link
                            className={`custome-tab ${activeTab === "tab6" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("tab6")}
                          >
                            <i className="fas fa-lock" />
                            <span>My Appointments</span>
                          </Link>
                        </li>

                        <li>
                          <Link className="custome-tab" to="/chat-box">
                            <i className="fas fa-comments" />
                            <span>Message</span>
                            <small className="unread-msg">
                              {messageTotalCount === 0
                                ? null
                                : messageTotalCount}
                            </small>
                          </Link>
                        </li>

                        <li>
                          <Link
                            className={`custome-tab ${activeTab === "tab2" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("tab2")}
                          >
                            <i className="fa-solid fa-bell"></i>
                            <span>Notifications</span>
                          </Link>
                        </li>

                        <li>
                          <Link
                            className={`custome-tab ${activeTab === "tab3" ? "active" : ""
                              }`}
                            onClick={() => {
                              handleTabClick("tab3");
                              getAllMedicalRecordsListFunc();
                            }}
                          >
                            <i className="fas fa-clipboard" />
                            <span>Upload receipts</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`custome-tab ${activeTab === "tab4" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("tab4")}
                          >
                            <i className="fas fa-user-cog" />
                            <span>Profile Settings</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`custome-tab ${activeTab === "tab5" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("tab5")}
                          >
                            <i className="fas fa-lock" />
                            <span>Change Password</span>
                          </Link>
                        </li>

                        <li>
                          <button className="custome-tab" onClick={logout}>
                            <i className="fas fa-sign-out-alt" />
                            <span>Logout</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>

              <div className="col-md-7 col-lg-8 col-xl-9">
                <main className="patient__main-page">
                  {/* Content for Tab 1 */}
                  {/* {activeTab === "tab1" && ( */}
                  {false && (
                    <div className="tab-content">
                      <div className="row row-grid">
                        {favouriteData?.map((val) => (
                          <div
                            className="col-md-6 col-lg-4"
                            key={val?.branch_details[0]?._id}
                          >
                            <div className="profile-widget">
                              <div className="doc-img">
                                <Link to="">
                                  <img
                                    className="img-fluid"
                                    alt="User "
                                    src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.branch_details[0]?.clinic_image}`}
                                  />
                                </Link>
                                <Link
                                  to=""
                                  className="fav-btn"
                                  onClick={() =>
                                    favouriteStatusHandler(
                                      val?.branch_details[0]?._id
                                    )
                                  }
                                >
                                  {val?.favourite_status ? (
                                    <i className="far fa-bookmark" />
                                  ) : (
                                    <i class="fa-solid fa-bookmark" />
                                  )}
                                </Link>
                              </div>
                              <div className="pro-content">
                                <h5 className="title">
                                  <a href="doctor-profile.html">
                                    {val?.branch_details[0]?.practice_name}
                                  </a>
                                  <i className="fas fa-check-circle verified" />
                                </h5>
                                <p className="speciality">
                                  {val?.branch_details[0]?.bio}
                                </p>
                                <div className="rating">
                                  {ratingFun(val?.branch_details[0]?.rating)}
                                  {/* <i className="fas fa-star " />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" /> */}
                                  <span className="d-inline-block average-rating">
                                    ({val?.branch_details[0]?.total_count})
                                  </span>
                                </div>
                                <ul className="available-info">
                                  <li>
                                    <i className="fas fa-map-marker-alt" />{" "}
                                    {val?.branch_details[0]?.address}
                                  </li>
                                  <li>
                                    <i className="far fa-clock" />{" "}
                                    {availableDate(
                                      val?.branch_details[0]?.working_hours
                                    )}
                                  </li>
                                </ul>
                                <div className="d-flex justify-content-between align-items-center">
                                  <Link
                                    to={`/appointments/${val?.branch_id}`}
                                    className="theme-outline-btn rounded py-2 px-3"
                                  >
                                    View Profile
                                  </Link>

                                  <Link
                                    to={`/appointments/${val?.branch_id}`}
                                    className="theme_btn rounded py-2 px-3"
                                  >
                                    Book Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* <div className="col-md-6 col-lg-4">
                        <div className="profile-widget">
                          <div className="doc-img">
                            <Link to="">
                              <img className="img-fluid" alt="User " src={profile02}
                              />
                            </Link>
                            <Link to="" className="fav-btn">
                              <i className="far fa-bookmark" />
                            </Link>
                          </div>
                          <div className="pro-content">
                            <h5 className="title">
                              <Link to="">Dr. Darren Elder</Link>
                              <i className="fas fa-check-circle verified" />
                            </h5>
                            <p className="speciality">
                              BDS, MDS - Oral &amp; Maxillofacial Surgery
                            </p>
                            <div className="rating">
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star" />
                              <span className="d-inline-block average-rating">(35)</span>
                            </div>
                            <ul className="available-info">
                              <li>
                                <i className="fas fa-map-marker-alt" /> Newyork, USA
                              </li>
                              <li>
                                <i className="far fa-clock" /> Available on Fri, 22 Mar
                              </li>

                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/view-dentist-profile" className="theme-outline-btn rounded py-2 px-3" >
                                View Profile
                              </Link>

                              <Link to="/appointment" className="theme_btn rounded py-2 px-3">
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="profile-widget">
                          <div className="doc-img">
                            <Link to="">
                              <img className="img-fluid" alt="User" src={profile03}
                              />
                            </Link>
                            <Link to="" className="fav-btn">
                              <i className="far fa-bookmark" />
                            </Link>
                          </div>
                          <div className="pro-content">
                            <h5 className="title">
                              <a href="doctor-profile.html">Dr. Deborah Angel</a>
                              <i className="fas fa-check-circle verified" />
                            </h5>
                            <p className="speciality">
                              MBBS, MD - General Medicine, DNB - Cardiology
                            </p>
                            <div className="rating">
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star" />
                              <span className="d-inline-block average-rating">(27)</span>
                            </div>
                            <ul className="available-info">
                              <li>
                                <i className="fas fa-map-marker-alt" /> Georgia, USA
                              </li>
                              <li>
                                <i className="far fa-clock" /> Available on Fri, 22 Mar
                              </li>

                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/view-dentist-profile" className="theme-outline-btn rounded py-2 px-3" >
                                View Profile
                              </Link>

                              <Link to="/appointment" className="theme_btn rounded py-2 px-3">
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="profile-widget">
                          <div className="doc-img">
                            <Link to="">
                              <img
                                className="img-fluid"
                                alt="User "
                                src={profile04}
                              />
                            </Link>
                            <Link to="" className="fav-btn">
                              <i className="far fa-bookmark" />
                            </Link>
                          </div>
                          <div className="pro-content">
                            <h5 className="title">
                              <a href="doctor-profile.html">Dr. Sofia Brient</a>
                              <i className="fas fa-check-circle verified" />
                            </h5>
                            <p className="speciality">MBBS, MS - General Surgery, MCh - Urology</p>
                            <div className="rating">
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star" />
                              <span className="d-inline-block average-rating">(4)</span>
                            </div>
                            <ul className="available-info">
                              <li>
                                <i className="fas fa-map-marker-alt" /> Louisiana, USA
                              </li>
                              <li>
                                <i className="far fa-clock" /> Available on Fri, 22 Mar
                              </li>

                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/view-dentist-profile" className="theme-outline-btn rounded py-2 px-3" >
                                View Profile
                              </Link>
                              <Link to="/appointment" className="theme_btn rounded py-2 px-3">
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="profile-widget">
                          <div className="doc-img">
                            <Link to="">
                              <img
                                className="img-fluid"
                                alt="User"
                                src={profile01}
                              />
                            </Link>
                            <Link to="" className="fav-btn">
                              <i className="far fa-bookmark" />
                            </Link>
                          </div>
                          <div className="pro-content">
                            <h5 className="title">
                              <a href="doctor-profile.html">Dr. Marvin Campbell</a>
                              <i className="fas fa-check-circle verified" />
                            </h5>
                            <p className="speciality">
                              MBBS, MD - Ophthalmology, DNB - Ophthalmology
                            </p>
                            <div className="rating">
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star" />
                              <span className="d-inline-block average-rating">(66)</span>
                            </div>
                            <ul className="available-info">
                              <li>
                                <i className="fas fa-map-marker-alt" /> Michigan, USA
                              </li>
                              <li>
                                <i className="far fa-clock" /> Available on Fri, 22 Mar
                              </li>
                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/view-dentist-profile" className="theme-outline-btn rounded py-2 px-3" >
                                View Profile
                              </Link>
                              <Link to="/appointment" className="theme_btn rounded py-2 px-3">
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="profile-widget">
                          <div className="doc-img">
                            <Link to="">
                              <img
                                className="img-fluid"
                                alt="User"
                                src={profile02}
                              />
                            </Link>
                            <Link to="" className="fav-btn">
                              <i className="far fa-bookmark" />
                            </Link>
                          </div>
                          <div className="pro-content">
                            <h5 className="title">
                              <a href="doctor-profile.html">Dr. Katharine Berthold</a>
                              <i className="fas fa-check-circle verified" />
                            </h5>
                            <p className="speciality">
                              MS - Orthopaedics, MBBS, M.Ch - Orthopaedics
                            </p>
                            <div className="rating">
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star filled" />
                              <i className="fas fa-star" />
                              <span className="d-inline-block average-rating">(52)</span>
                            </div>
                            <ul className="available-info">
                              <li>
                                <i className="fas fa-map-marker-alt" /> Texas, USA
                              </li>
                              <li>
                                <i className="far fa-clock" /> Available on Fri, 22 Mar
                              </li>
                            </ul>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/view-dentist-profile" className="theme-outline-btn rounded py-2 px-3" >
                                View Profile
                              </Link>

                              <Link to="/appointment" className="theme_btn rounded py-2 px-3">
                                Book Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  )}
                  {/* Content for Tab 2 */}
                  {activeTab === "tab2" && (
                    <div className="tab-content">
                      <div className="card notification">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <h4 className="notification__text">Notifications</h4>
                          {/* <div className="form-check form-switch">
                          <label className="form-check-label" for="flexSwitchCheckChecked">Disable Notification</label>
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                        </div> */}
                        </div>
                        <div className="card-body">
                          <ul className="notification__list">
                            {notificationData?.map((val, i) => (
                              <li className="notification__list-item" key={i}>
                                <Link
                                  to=""
                                  onClick={() =>
                                    viewHandler1(val?.type, val?._id)
                                  }
                                >
                                  <div className="notify-block d-flex">
                                    <span className="avatar">
                                      {val?.sender_details?.profile_image ? (
                                        <img
                                          className="avatar-img"
                                          alt=""
                                          src={`${process.env.REACT_APP_USER_PROFILE_IMAGE}${val?.sender_details?.profile_image}`}
                                        />
                                      ) : (
                                        <img
                                          className="avatar-img"
                                          alt=""
                                          src={
                                            process.env
                                              .REACT_APP_DEFAULT_IMAGE_URL
                                          }
                                        />
                                      )}
                                    </span>
                                    <div className="media-body">
                                      <h6>
                                        {val?.title}{" "}
                                        <span className="notification-time">
                                          {getDate(val?.createdAt)}
                                        </span>
                                      </h6>
                                      <p className="noti-details">
                                        {val?.body}{" "}
                                        <span className="noti-title">
                                          {val?.sender_details?.full_name}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                            {/* <li className='notification__list-item'>
                            <Link to="">
                              <div className="notify-block d-flex">
                                <span className="avatar">
                                  <img className="avatar-img" alt="Ruby perin" src={image01} />
                                </span>
                                <div className="media-body">
                                  <h6>
                                    Travis Tremble{" "}
                                    <span className="notification-time">18.30 PM</span>
                                  </h6>
                                  <p className="noti-details">
                                    Sent a amount of $210 for his Appointment{" "}
                                    <span className="noti-title">Dr.Ruby perin </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li className='notification__list-item'>
                            <Link to="">
                              <div className="notify-block d-flex">
                                <span className="avatar">
                                  <img className="avatar-img" alt="Ruby perin" src={image02} />
                                </span>
                                <div className="media-body">
                                  <h6>
                                    Travis Tremble{" "}
                                    <span className="notification-time">18.30 PM</span>
                                  </h6>
                                  <p className="noti-details">
                                    Sent a amount of $210 for his Appointment{" "}
                                    <span className="noti-title">Dr.Ruby perin </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li className='notification__list-item'>
                            <Link to="">
                              <div className="notify-block d-flex">
                                <span className="avatar">
                                  <img className="avatar-img" alt="Ruby perin" src={image01} />
                                </span>
                                <div className="media-body">
                                  <h6>
                                    Travis Tremble{" "}
                                    <span className="notification-time">18.30 PM</span>
                                  </h6>
                                  <p className="noti-details">
                                    Sent a amount of $210 for his Appointment{" "}
                                    <span className="noti-title">Dr.Ruby perin </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Content for tab 6 */}
                  {(activeTab === "tab6" || activeTab === "tab1") && (
                    <div className="tab-content">
                      <div className="card  appointment">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h4 className="card-title">Appointment </h4>
                          <a className="btn btn-primary" onClick={viewHandler}>
                            <i className="fa-solid fa-clock-rotate-left"></i>
                          </a>
                        </div>
                        {viewAppointment && (
                          <div className="card-body">
                            <div class="card shadow border-0 appointment-card">
                              <div className="appointment-card-inn flex-column">
                                <div className="doctor-dtl">
                                  <p className="hdng-small mb-4">
                                    <b>Clinic Detail</b>
                                  </p>
                                  <div className="d-flex align-items-start flex-wrap gap-4">
                                    <img
                                      className="doc-img"
                                      alt="Doctor Image"
                                      src={`${
                                        // nextAppointment?.dentist_details
                                        //   ?.profile_image
                                        //   ? process.env
                                        //       .REACT_APP_USER_PROFILE_IMAGE +
                                        //     nextAppointment?.dentist_details
                                        //       ?.profile_image
                                        nextAppointment?.branch_details
                                          ?.profile_image
                                          ? process.env
                                            .REACT_APP_USER_PROFILE_IMAGE +
                                          nextAppointment?.branch_details
                                            ?.profile_image
                                          : process.env
                                            .REACT_APP_DEFAULT_IMAGE_URL
                                        }`}
                                    />
                                    <div className="">
                                      <p className="mb-1">
                                        <strong>Clinic: </strong>{" "}
                                        {
                                          nextAppointment?.branch_details
                                            ?.practice_name
                                        }
                                      </p>
                                      <p className="mb-1">
                                        <strong>Dentist: </strong>{" "}
                                        {nextAppointment?.dentist_details
                                          ? nextAppointment?.dentist_details
                                            ?.full_name
                                          : "Not specified"}
                                      </p>

                                      {/* <h6>
                                        {
                                          nextAppointment?.dentist_details
                                            ?.dentist_details
                                        }
                                      </h6> */}
                                      <p className="mb-1">
                                        <strong>Location: </strong>
                                        {
                                          nextAppointment?.branch_details
                                            ?.address
                                        }
                                      </p>
                                      <p className="">
                                        <Link
                                          to={`/appointments/${nextAppointment?.branch_id}/profile`}
                                          className="btn btn-sm rounded bg_primary "
                                        >
                                          View Clinic
                                        </Link>
                                      </p>
                                      <p>
                                        {
                                          nextAppointment?.dentist_details
                                            ?.designation
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="doctor-dtl">
                                  <p className="hdng-small mb-4">
                                    <b>Patient Detail</b>
                                  </p>
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      className="doc-img"
                                      alt="Doctor Image"
                                      src={`${nextAppointment?.user_details
                                        ?.profile_image
                                        ? process.env
                                          .REACT_APP_USER_PROFILE_IMAGE +
                                        nextAppointment?.user_details
                                          ?.profile_image
                                        : process.env
                                          .REACT_APP_DEFAULT_IMAGE_URL
                                        }`}
                                    />
                                    <div className="">
                                      <h6>
                                        {
                                          nextAppointment?.user_details
                                            ?.full_name
                                        }
                                      </h6>
                                      {/* <p>Dental</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="appointment-dtl">
                                <div className="">
                                  <p className="hdng-small mb-1">
                                    <b>Appointment Date</b>
                                  </p>
                                  <p className="mb-1">
                                    {makeDateString(
                                      nextAppointment?.start_time
                                    )}
                                  </p>
                                </div>
                                <div className="">
                                  <p className="hdng-small mb-1">
                                    <b>Booking Date</b>
                                  </p>
                                  <p>
                                    {makeDateString(nextAppointment?.createdAt)}
                                  </p>
                                </div>
                                {/* <div className="">
                              <p className='hdng-small mb-1'>
                                <b>
                                  Amount
                                </b>
                              </p>
                              <p> $160</p>
                            </div> */}
                                {/* <div className="">
                              <p className='hdng-small mb-1'>
                                <b>
                                  Follow Up
                                </b>
                              </p>
                              <p>16 Nov 2023</p>
                            </div> */}
                                <div className="">
                                  <p className="hdng-small mb-1">
                                    <b>Status</b>
                                  </p>
                                  <p>
                                    <span
                                      class={`badge rounded-pill ${nextAppointment?.status ===
                                        "accepted" ||
                                        nextAppointment?.status === "completed"
                                        ? "bg-success"
                                        : nextAppointment?.status ===
                                          "rejected" ||
                                          nextAppointment?.status ===
                                          "canceled"
                                          ? "bg-danger" :
                                          nextAppointment?.status ===
                                            "rescheduled"
                                            ? "bg-info"
                                            : "bg-warning"
                                        }`}
                                    >
                                      {nextAppointment?.status === "accepted"
                                        ? "confirm"
                                        : nextAppointment?.status ===
                                          "completed"
                                          ? "completed"
                                          : nextAppointment?.status === "rejected"
                                            ? "rejected"
                                            : nextAppointment?.status ===
                                              "canceled"

                                              ? "cancelled"
                                              : nextAppointment?.status ===
                                                "rescheduled" ? "rescheduled"
                                                : "pending"}
                                    </span>
                                  </p>
                                </div>
                               
                              </div>
                          {
                            (nextAppointment?.status === "rejected" && nextAppointment?.cancel_reason) &&
                             <div className="doctor-dtl mb-3">
                                  Cancel Reason: <p>{nextAppointment?.cancel_reason}</p>
                                </div>
                          }
                              

                              {(showSuggestedSlots && latestAppointment?.status === 'rescheduled') && (
                                <div class="card canceCard border-0 m-4">
                                  <div class="row g-0">
                                    {newDenstist && (
                                      <div class="col-md-12">
                                        <img
                                          className="doc_imag"
                                          src={
                                            newDenstist?.profile_image
                                              ? profile_image_base_url +
                                              newDenstist?.profile_image
                                              : avatar
                                          }
                                          alt="..."
                                        />
                                        <h5 class="card-title mt-2">
                                          {newDenstist?.full_name}
                                        </h5>
                                      </div>
                                    )}

                                    <div class="col-md-12">
                                      <div class="card-body p-0">
                                        <p class="card-tilte text-bold">
                                          {" "}
                                          Available Slots
                                        </p>
                                        {Object.entries(suggestedSlots)?.map(
                                          ([date, slots]) => (
                                            <div class="app-time mb-2">
                                              <div>
                                                <p class="card-tilte">{date}</p>
                                                <div class="app-check">
                                                  {slots.map((slot, index) => (
                                                    <div
                                                      className="reschedule-slots"
                                                      key={index}
                                                    >
                                                      <input
                                                        type="radio"
                                                        class="option-input radio"
                                                        // name={`slot-${date}`}
                                                        name={`slot-oss`}
                                                        onChange={() =>
                                                          handleSlotSelection(
                                                            date,
                                                            slot
                                                          )
                                                        }
                                                      />
                                                      <div class="app-border">
                                                        <label class="app-label">
                                                          {slot}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}

                                        {/* <div class="app-time mb-2">
                                        <div>
                                          <p class="card-tilte">
                                            {" "}
                                            16 March 2024
                                          </p>
                                          <div class="app-check">
                                            <input
                                              type="radio"
                                              class="option-input radio"
                                              name="example"
                                            />
                                            <div class="app-border">
                                              <label class="app-label ">
                                                9:00 AM
                                              </label>
                                            </div>

                                            <input
                                              type="radio"
                                              class="option-input radio"
                                              name="example"
                                            />
                                            <div class="app-border">
                                              <label class="app-label ">
                                                9:00 AM
                                              </label>
                                            </div>
                                            <input
                                              type="radio"
                                              class="option-input radio"
                                              name="example"
                                            />
                                            <div class="app-border">
                                              <label class="app-label ">
                                                9:00 AM
                                              </label>
                                            </div>
                                            <input
                                              type="radio"
                                              class="option-input radio"
                                              name="example"
                                            />
                                            <div class="app-border">
                                              <label class="app-label ">
                                                9:00 AM
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                        <div className="d-flex gap-2">
                                          <button
                                            className="theme_btn  mt-4"
                                            onClick={() =>
                                              navigate(
                                                `/appointments/${selectedAppointment?.branch_id}`
                                              )
                                            }
                                          >
                                            Choose another time
                                          </button>
                                          <button
                                            className="theme_btn  mt-4"
                                            onClick={bookSlotFun}
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      {viewAppointment === false && (
                        <div className="card appointment">
                          <div className="card-header d-flex align-items-center justify-content-between">
                            <h4 className="card-title">History </h4>
                            {/* <a className='btn btn-primary' ><i class="fa-solid fa-clock-rotate-left"></i></a> */}
                          </div>
                          <div className="card-body">
                            <div class="table-responsive">
                              <table class="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th>Doctor</th>
                                    <th>Appointment Date</th>
                                    <th>Booking Date</th>
                                    {/* <th>Amount</th> */}
                                    {/* <th>Follow Up</th> */}
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {appointmentHistory?.map((val, i) => (
                                    <tr key={i}>
                                      <td>
                                        <h2 class="table-avatar">
                                          <img
                                            class="avatar rounded-circle"
                                            src={
                                              val?.dentist_details
                                                ?.profile_image
                                                ? process.env
                                                  .REACT_APP_USER_PROFILE_IMAGE +
                                                val?.dentist_details
                                                  ?.profile_image
                                                : val?.branch_details
                                                  ?.profile_image
                                                  ? process.env
                                                    .REACT_APP_USER_PROFILE_IMAGE +
                                                  val?.branch_details
                                                    ?.profile_image
                                                  : process.env
                                                    .REACT_APP_DEFAULT_IMAGE_URL
                                            }
                                            alt="User "
                                          />
                                          <p className="mb-0">
                                            {val?.dentist_details?.full_name}
                                            <span>
                                              {
                                                val?.dentist_details
                                                  ?.designation
                                              }
                                            </span>
                                          </p>
                                        </h2>
                                      </td>
                                      <td>
                                        {makeDateString(val?.start_time)}
                                        <span class="d-block text-info">
                                          {getDate(val?.start_time)}
                                        </span>
                                      </td>
                                      <td>{makeDateString(val?.createdAt)}</td>
                                      {/* <td>$160</td> */}
                                      {/* <td>16 Nov 2023</td> */}
                                      <td>
                                        <span
                                          class={`badge rounded-pill ${val?.status === "accepted" ||
                                            val?.status === "completed"
                                            ? "bg-success" :
                                            val?.status === "rescheduled"
                                              ?
                                              "bg-info"

                                              : val?.status === "rejected" ||
                                                val?.status === "canceled"
                                                ? "bg-danger"
                                                : "bg-warning"
                                            }`}
                                        >
                                          {val?.status === "accepted"
                                            ? "Confirm"
                                            : val?.status === "completed"
                                              ? "Completed"
                                              : val?.status === "rejected"
                                                ? "Rejected"
                                                : val?.status === "canceled"
                                                  ? "Cancelled"
                                                  : val?.status === "rescheduled"
                                                    ? "Rescheduled"
                                                    : "Pending"}
                                        </span>
                                      </td>
                                      <td>
                                        <div class="table-action">
                                          {/* <Link class="btn btn-sm btn-primary">
                                      <i class="fas fa-print"></i> Print
                                    </Link> */}
                                          <Link
                                            class="btn btn-sm btn-info text-white ms-2"
                                            onClick={() =>
                                              viewParticularAppointment(
                                                val?._id
                                              )
                                            }
                                          >
                                            <i class="far fa-eye"></i> View
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className="card-footer pt-2">
                                <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
                                  <ul className="page-number m-0 p-0 list-unstyled d-flex gap-2">
                                    {/* <li className="text-center theme_btn px-3 py-2 text-white rounded">1</li>
                                                        <li className="text-center bg-soft-primary text-dark rounded">2</li>
                                                        <li className="text-center text-dark rounded">...</li>
                                                        <li className="text-center bg-soft-primary text-dark rounded">6</li> */}
                                    {numberOfPages.map((ele, i) => (
                                      <li
                                        className={
                                          offset === i
                                            ? "text-center theme_btn px-3 py-2 text-white rounded"
                                            : "text-center bg-soft-primary text-dark rounded"
                                        }
                                        onClick={() => changingPage(i)}
                                      >
                                        {i + 1}
                                      </li>
                                    ))}
                                  </ul>

                                  <div>
                                    {offset !== 0 ? (
                                      <button
                                        onClick={() => previousPage()}
                                        className="theme-outline-btn rounded me-3"
                                        type="button"
                                      >
                                        <span className="btn-inner">
                                          <span className="text d-inline-block align-middle">
                                            Previous
                                          </span>
                                          {/* <span className="icon d-inline-block align-middle ms-1 ps-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={8}
                                              height={8}
                                              viewBox="0 0 8 8"
                                              fill="none"
                                            >
                                              <path
                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span> */}
                                        </span>
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                    {offset < numberOfPages.length - 1 ? (
                                      <button
                                        onClick={() => nextPage()}
                                        className="theme_btn rounded me-3"
                                        type="button"
                                      >
                                        <span className="btn-inner">
                                          <span className="text d-inline-block align-middle">
                                            Next
                                          </span>
                                          {/* <span className="icon d-inline-block align-middle ms-1 ps-2">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={8}
                                              height={8}
                                              viewBox="0 0 8 8"
                                              fill="none"
                                            >
                                              <path
                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span> */}
                                        </span>
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className='card-footer pe-5'>
                        <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-end">
                            <li class="page-item disabled">
                              <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                              <a class="page-link" href="#">Next</a>
                            </li>
                          </ul>
                        </nav>
                      </div> */}
                        </div>
                      )}
                    </div>
                  )}

                  {/* Content for Tab 3 */}
                  {activeTab === "tab3" && (
                    <div className="tab-content">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <h4 className="card-title">Upload Receipts</h4>
                          {/* <button type="button" class="theme_btn " data-bs-toggle="modal" data-bs-target="#addMedicalRecordBox">
                          Add Medical Record
                        </button> */}
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  {/* <th>#</th> */}
                                  <th>Dentist Name</th>
                                  <th>Appointment Date</th>
                                  {/* <th>Amount</th> */}
                                  {/* <th>Attachment</th> */}
                                  <th>Receipts</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {mediacalList.length !== 0 ? mediacalList : <></>} */}
                                {receiptData?.map((val) => (
                                  <tr>
                                    <td>
                                      <h2 class="table-avatar">
                                        <img
                                          class="avatar rounded-circle"
                                          src={
                                            val?.dentist_details
                                              ?.profile_image
                                              ? process.env
                                                .REACT_APP_USER_PROFILE_IMAGE +
                                              val?.dentist_details
                                                ?.profile_image
                                              : process.env
                                                .REACT_APP_DEFAULT_IMAGE_URL
                                          }
                                          alt="User "
                                        />
                                        <a href="doctor-profile.html">
                                          {val?.dentist_details?.full_name || "Not Specified"}
                                          <span>
                                            {val?.dentist_details?.designation}
                                          </span>
                                        </a>
                                      </h2>
                                    </td>
                                    <td>
                                      {dateChanger(val?.start_time)}
                                      <span class="d-block text-info">
                                        {getDate(val?.start_time)}
                                      </span>
                                    </td>
                                    {/* <td>3 Nov 2023 <span className="d-block text-info">11.00 AM</span></td> */}
                                    {/* <td>$450</td> */}
                                    <td>
                                      <Link
                                        title="Download attachment"
                                        className="theme_btn rounded btn-sm px-3 d-inline-block"
                                        onClick={() =>
                                          handleDownload(
                                            val?.receipt_uploaded_by_user
                                          )
                                        }
                                      >
                                        {" "}
                                        <i className="fa fa-download"></i>
                                      </Link>
                                    </td>
                                    {/* <td>Your Self</td> */}
                                    <td>
                                      {/* <Link className="btn btn-sm btn-danger px-3 py-2 me-2 rounded ">
                                    <i class="fa-regular fa-pen-to-square"></i>
                                  </Link>
                                  <Link className="btn btn-sm btn-danger px-3 py-2 rounded ">
                                    <i className="far fa-trash-alt"></i>
                                  </Link> */}
                                      {val?.receipt_uploaded_by_user ? (
                                        <button className="btn btn-sm btn-success px-3 py-2 me-2 rounded" disabled>
                                          Uploaded
                                        </button>
                                      ) : (
                                        <Link
                                          className="btn btn-sm btn-danger px-3 py-2 me-2 rounded"
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#addMedicalRecordBox"
                                          onClick={() => {
                                            setAppointmentId(val?._id);
                                            setReceiptModalShow(true);
                                            handleShowModal()
                                          }}
                                        >
                                          Upload Receipt
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                {/* <tr>
                                <td>
                                  <h2 class="table-avatar">
                                    <a href="doctor-profile.html" class="avatar avatar-sm me-2">
                                      <img class="avatar-img rounded-circle" src={profile01} alt="User " />
                                    </a>
                                    <a href="doctor-profile.html">Dr. Ruby Perrin <span>Dental</span></a>
                                  </h2>
                                </td>
                                <td>14 Nov 2023 <span class="d-block text-info">10.00 AM</span></td>
                                <td>$450</td>
                                <td>
                                  <Link title="Download attachment" className="theme_btn rounded btn-sm px-3 d-inline-block"> <i className="fa fa-download"></i></Link>
                                </td>
                                <td>
                                  <Link className="btn btn-sm btn-danger px-3 py-2 me-2 rounded ">
                                    Upload Receipt
                                  </Link>
                                </td>
                              </tr> */}
                              </tbody>
                            </table>
                            {/* {mediacalList.length === 0 ? (<div className="validation_message" style={{ 'text-align': 'center', 'font-weight': 'bold' }}>*No medical record found </div>) : <></>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Content for Tab 4 */}
                  {activeTab === "tab4" && (
                    <div className="tab-content">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <div className="mb-3">
                                <div className="change-avatar">
                                  <div className="profile-img">
                                    <img
                                      src={
                                        profile_image_base_url +
                                        updateUserProfile.profile_image
                                      }
                                      onError={(e) => (e.target.src = avatar)}
                                      alt="User"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="upload-img">
                                    <div className="theme_btn">
                                      <label for="upload">
                                        <i className="fa fa-upload" /> Upload
                                        Photo
                                      </label>
                                      <input
                                        type="file"
                                        id="upload"
                                        onChange={profilePicture}
                                        className="upload"
                                      />
                                    </div>
                                    <small className="form-text text-muted">
                                    Allowed file types: JPG, GIF, or PNG. Max size: 2 MB.
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  name="first_name"
                                  value={updateUserProfile.first_name}
                                  onChange={settingUserProfileValues}
                                  onBlur={(e) =>
                                    updateUserProfileValidatorCheck(
                                      e.target.name
                                    )
                                  }
                                />
                                {updateUserProfileValidation.first_name ? (
                                  <div className="validation_message">
                                    *First name required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  name="last_name"
                                  value={updateUserProfile.last_name}
                                  onChange={settingUserProfileValues}
                                  onBlur={(e) =>
                                    updateUserProfileValidatorCheck(
                                      e.target.name
                                    )
                                  }
                                />
                              </div>
                              {updateUserProfileValidation.last_name ? (
                                <div className="validation_message">
                                  *Last name required
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">Date of Birth</label>
                                <div className="cal-icon">
                                  <input
                                    type="date"
                                    className="form-control datetimepicker"
                                    name="dob"
                                    placeholder="MM/DD/YYYY"
                                    value={moment(updateUserProfile.dob).format(
                                      "YYYY-MM-DD"
                                    )}
                                    onChange={settingUserProfileValues}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-6">
                            <div className="mb-3">
                              <label className="mb-2">Blood Group</label>
                              <select name='blood_group' value={updateUserProfile.blood_group}
                                onChange={settingUserProfileValues}
                                className="form-select form-control">
                                <option disabled selected value=""> -- select an option -- </option>
                                <option value="A-">A-</option>
                                <option value="A+">A+</option>
                                <option value="B">B-</option>
                                <option value="B+">B+</option>
                                <option value="AB-">AB-</option>
                                <option value="AB+">AB+</option>
                                <option value="O-">O-</option>
                                <option value="O+">O+</option>
                              </select>
                            </div>
                          </div> */}
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Your Email"
                                  name="email"
                                  value={updateUserProfile.email}
                                  onChange={settingUserProfileValues}
                                />
                                {updateUserProfileValidation.email ? (
                                  <div className="validation_message">
                                    *Valid email address required{" "}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {tempEmailAndStatus.temp_email !== null ? (
                                  <div className="validation_message">
                                    {" "}
                                    <span style={{ color: "green" }}>
                                      new email:
                                    </span>{" "}
                                    {tempEmailAndStatus.temp_email}{" "}
                                    <span style={{ color: "green" }}>
                                      status:{" "}
                                    </span>{" "}
                                    {tempEmailAndStatus.temp_email_status}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">Mobile</label>
                                <input
                                  type="number"
                                  placeholder="Enter Phone Number"
                                  className="form-control"
                                  name="phone_number"
                                  value={updateUserProfile.phone_number}
                                  onChange={settingUserProfileValues}
                                  onBlur={(e) =>
                                    updateUserProfileValidatorCheck(
                                      e.target.name
                                    )
                                  }
                                />
                                {updateUserProfileValidation.phone_number ? (
                                  <div className="validation_message">
                                    *Valid 10 digit phone number required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="mb-3">
                                <label className="mb-2">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Address"
                                  name="address"
                                  value={updateUserProfile.address}
                                  onChange={settingUserProfileValues}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter City"
                                  name="city"
                                  value={updateUserProfile.city}
                                  onChange={settingUserProfileValues}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">State/Province</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter State"
                                  name="state"
                                  value={updateUserProfile.state}
                                  onChange={settingUserProfileValues}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">
                                  Zip Code/Postal Code
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Zip Code"
                                  name="zip_code"
                                  value={updateUserProfile.zip_code}
                                  onChange={settingUserProfileValues}
                                  onBlur={(e) =>
                                    updateUserProfileValidatorCheck(
                                      e.target.name
                                    )
                                  }
                                />
                              </div>
                              {updateUserProfileValidation.zip_code ? (
                                <div className="validation_message">
                                  *Valid 6 digit Zip code required{" "}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <label className="mb-2">Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Country"
                                  name="country"
                                  value={updateUserProfile.country}
                                  onChange={settingUserProfileValues}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="submit-section">
                            <button
                              type="submit"
                              onClick={saveUpdateProfile}
                              className="theme_btn"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Content for Tab 5 */}
                  {activeTab === "tab5" && (
                    <div className="tab-content">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 col-lg-6">
                              <div className="mb-3 position-relative">
                                <label className="mb-2">Old Password</label>
                                <input
                                  type={fireEyeOld_Password}
                                  className="form-control"
                                  name="old_password"
                                  value={password.old_password}
                                  onChange={settingPasswordValues}
                                  onBlur={(e) =>
                                    ChangePasswordValidatorCheck(e.target.name)
                                  }
                                />
                                <div className="fireEyePosition">
                                  {fireEyeOld_Password === "password" ? (
                                    <button
                                      onClick={() =>
                                        setfireEyeOld_Password("text")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye-slash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        setfireEyeOld_Password("password")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                {passwordValidation.old_password ? (
                                  <div className="validation_message">
                                    *Password required
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="mb-3 position-relative">
                                <label className="mb-2">New Password</label>
                                <input
                                  type={fireEyeNew_password}
                                  className="form-control"
                                  value={password.new_password}
                                  name="new_password"
                                  onChange={settingPasswordValues}
                                  onBlur={(e) =>
                                    ChangePasswordValidatorCheck(e.target.name)
                                  }
                                />
                                <div className="fireEyePosition">
                                  {fireEyeNew_password === "password" ? (
                                    <button
                                      onClick={() =>
                                        setfireEyeNew_password("text")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye-slash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        setfireEyeNew_password("password")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                {passwordValidation.new_password ? (
                                  <div className="validation_message">
                                    *Password must be 8 or greater in length
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="mb-3 position-relative">
                                <label className="mb-2">Confirm Password</label>
                                <input
                                  type={fireEyeConfirm_password}
                                  className="form-control"
                                  name="confirm_Password"
                                  value={password.confirm_Password}
                                  onChange={settingPasswordValues}
                                  onBlur={(e) =>
                                    ChangePasswordValidatorCheck(e.target.name)
                                  }
                                />
                                <div className="fireEyePosition">
                                  {fireEyeConfirm_password === "password" ? (
                                    <button
                                      onClick={() =>
                                        setfireEyeConfirm_password("text")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye-slash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        setfireEyeConfirm_password("password")
                                      }
                                    >
                                      <i
                                        class="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                {passwordValidation.confirm_Password ? (
                                  <div className="validation_message">
                                    *Password does not match
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="submit-section">
                                <button
                                  type="submit"
                                  className="theme_btn"
                                  onClick={submit}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/* --Modal--Content--Add */}

      {true && (
        <div
          class="modal custome-modal fade"
          id="addMedicalRecordBox"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="false"
          ref={modalRef}
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Receipt</h5>
                <button
                  type="button"
                  id="closeIt_addMedical"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {/* <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Title Name</label>
                    <input type="text" class="form-control" placeholder="Enter Title Name"
                      name='title_name'
                      value={medicalRecord.title_name}
                      onChange={settingmedicalRecordValues}
                      onBlur={(e) => updateMedicalRecordCheck(e.target.name)} />
                    {medicalRecordValidation.title_name ? (<div className="validation_message">*Title name is required </div>) :
                      (<></>)}
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Patient</label>
                    <input type="text" class="form-control" placeholder="Enter Patient Name"
                      name='patient_name'
                      value={medicalRecord.patient_name}
                      onChange={settingmedicalRecordValues}
                      onBlur={(e) => updateMedicalRecordCheck(e.target.name)} />
                    {medicalRecordValidation.patient_name ? (<div className="validation_message">*Patient name is required </div>) :
                      (<></>)}
                  </div>
                </div>
              </div> */}
                {/* <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="mb-2">Hospital Name</label>
                    <input type="text" class="form-control" placeholder="Enter name here.."
                      name='hospital_name'
                      value={medicalRecord.hospital_name}
                      onChange={settingmedicalRecordValues}
                      onBlur={(e) => updateMedicalRecordCheck(e.target.name)} />
                    {medicalRecordValidation.hospital_name ? (<div className="validation_message">*Hospital name is required field</div>) :
                      (<></>)}
                  </div>
                </div>
              </div> */}
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="mb-2">Upload</label>
                      {/* <div class="upload-medical-records">
                      <input class="form-control" type="file" name="user_file" id="user_files_mr" />
                        <div class="upload-content dropzone">
                          <div>
                            <div class="upload-icon mb-2"><img src="assets/img/upload-icon.png" alt="upload-icon" /></div>
                            <h5>Drag &amp; Drop</h5>
                            <h6>or <span class="text-danger">Browse</span></h6>
                          </div>
                        </div>
                    </div> */}
                      <Dropzone
                        onDrop={(acceptedFiles) => uploadreceipt(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {/* <p className='mb-0'>Drag 'n' drop some files here, or click to select files</p> */}
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="card-body shadow ">
                        <div class="imgUploaderContainer">
                          <div
                            {...getRootProps_addMedical()}
                            className="dropzone"
                          >
                            <input {...getInputProps_addMedical()} />
                            <p>Drag & drop images here, or click to select</p>
                          </div>
                          {selectedFiles.length > 0 &&

                            (
                              <div className="image-preview">
                                {selectedFiles.map((file, index) => {
                                  const isImage = file.type.startsWith("image/");
                                  return (
                                    <div key={index} className="image-item m-auto">
                                      <button
                                        type="button"
                                        onClick={() => removeImage(index)}
                                      >
                                        x
                                      </button>
                                      <img
                                        src={isImage ? URL.createObjectURL(file) : pdfImage}
                                        alt={`Preview ${index + 1}`}
                                        style={{
                                          maxWidth: "100px",
                                          maxHeight: "100px",
                                        }}
                                      />
                                      <p>{file.name}</p>

                                    </div>
                                  )

                                }

                                )}
                              </div>
                            )

                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="mb-2">Symptoms</label>
                    <input type="text" data-role="tagsinput" class="input-tags form-control"
                      name='symptoms'
                      value={medicalRecord.symptoms}
                      onChange={settingmedicalRecordValues}
                      onBlur={(e) => updateMedicalRecordCheck(e.target.name)} />
                    {medicalRecordValidation.symptoms ? (<div className="validation_message">*Symptoms is required field</div>) :
                      (<></>)}
                  </div>
                </div>
              </div> */}
                {/* <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Date</label>
                    <div class="mb-3">
                      <input type="date" className="form-control"
                        name='date'
                        placeholder="MM/DD/YYYY"
                        value={moment(medicalRecord.date).format('YYYY-MM-DD')}
                        onChange={settingmedicalRecordValues} />
                    </div>
                  </div>
                </div>
              </div> */}
                <div class="text-center mt-4">
                  <div class="submit-section text-center">
                    <button
                      type="submit"
                      onClick={saveReceipt}
                      id="medical_btn"
                      class="theme_btn rounded py-2 px-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* --Modal--Content--Edit */}

      {/* <div class="modal custome-modal fade" id="editMedicalRecordBox" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Medical Records</h5>
              <button type="button" id="closeIt_editMedical" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Title Name</label>
                    <input type="text" class="form-control" placeholder="Enter Title Name"
                      name='title_name'
                      value={editMedicalRecord.title_name}
                      onChange={settingEditMedicalRecordValues}
                      onBlur={(e) => updateEditMedicalRecordCheck(e.target.name)} />
                    {editMedicalRecordValidation.title_name ? (<div className="validation_message">*Title name is required </div>) :
                      (<></>)}
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Patient</label>
                    <input type="text" class="form-control" placeholder="Enter Patient Name"
                      name='patient_name'
                      value={editMedicalRecord.patient_name}
                      onChange={settingEditMedicalRecordValues}
                      onBlur={(e) => updateEditMedicalRecordCheck(e.target.name)} />
                    {editMedicalRecordValidation.patient_name ? (<div className="validation_message">*Patient name is required </div>) :
                      (<></>)}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="mb-2">Hospital Name</label>
                    <input type="text" class="form-control" placeholder="Enter name here.."
                      name='hospital_name'
                      value={editMedicalRecord.hospital_name}
                      onChange={settingEditMedicalRecordValues}
                      onBlur={(e) => updateEditMedicalRecordCheck(e.target.name)} />
                    {editMedicalRecordValidation.hospital_name ? (<div className="validation_message">*Hospital name is required field</div>) :
                      (<></>)}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="mb-2">Upload</label>
                   

                    <div className="card-body">
                      <div class="imgUploaderContainer">
                        <div {...getRootProps_editMedical()} className="dropzone">
                          <input {...getInputProps_editMedical()} />
                          <p>
                            Drag & drop images here, or click to select up to 6
                          </p>
                        </div>
                        {selectedFiles_editMedical.length > 0 && (
                          <div className="image-preview">
                            <div className="image-list">
                              {selectedFiles_editMedical.map((file, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Preview ${index + 1}`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "150px",
                                    }}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeImage_editMedical(index)}
                                  >
                                    x
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label class="mb-2">Symptoms</label>
                    <input type="text" data-role="tagsinput" class="input-tags form-control"
                      name='symptoms'
                      value={editMedicalRecord.symptoms}
                      onChange={settingEditMedicalRecordValues}
                      onBlur={(e) => updateEditMedicalRecordCheck(e.target.name)} />
                    {editMedicalRecordValidation.symptoms ? (<div className="validation_message">*Symptoms is required field</div>) :
                      (<></>)}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <label class="mb-2">Date</label>
                    <div class="mb-3">
                      <input type="date" className="form-control"
                        name='date'
                        placeholder="MM/DD/YYYY"
                        value={moment(editMedicalRecord.date).format('YYYY-MM-DD')}
                        onChange={settingEditMedicalRecordValues} />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-4">
                <div class="submit-section text-center">
                  <button type="submit" onClick={saveEditMedicalRecord} id="medical_btn" class="btn btn-primary submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
