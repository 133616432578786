import React, { useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { addRatingAndFeedback } from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";



const RateClinicModal = ({ show, handleClose, providerList,branch_id,
  updateParentComp,
  refetch }) => {
  const [rating, setRating] = useState(1);
  const [feedback, setFeedBack] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");

  const handleSelectChange = (e) => {
    setSelectedProvider(e.target.value);
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  console.log(selectedProvider,"select provider")
  const handleSubmitRate = async (e) => {
    e.preventDefault();
    if(selectedProvider === "" ){
        toast.error("Please select the Dentist ")
        return;
    }else if(rating ===""){
        toast.error("Please provide the rating")
        return;
    }
    let payload = {
      rating: rating,
      reviews: feedback,
      dentist_id:selectedProvider,
      branch_id:branch_id
    };
    try {
      const response = await addRatingAndFeedback(payload);
      if(response.status ===200){
        toast.success("Your feedback has been successfully submitted")
        if(updateParentComp){
          updateParentComp();
        }
      }
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Select onChange={handleSelectChange} value={selectedProvider}>
            <option>Select Provider</option>
            {providerList.map((item) => (
              <option key={item._id} value={item._id}>
                {item.full_name}
              </option>
            ))}
          </Form.Select>
          <div className="rate_provider py-2">
            <div className="rating-submit-container">
              <div className="stars">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    style={{
                      color: star <= rating ? "#ff8fb0" : "gray",
                      cursor: "pointer",
                      fontSize: "25px",
                    }}
                    onClick={() => handleRatingChange(star)}
                  >
                    ★
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="feedback_provider">
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Write Something Here"
              value={feedback}
              onChange={(e) => setFeedBack(e.target.value)}
            >
              <Form.Control as="textarea" placeholder="Leave a feedback here" />
            </FloatingLabel>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary"  onClick={handleClose}>
          Close
        </Button>
        <Button className="theme_btn" onClick={handleSubmitRate}>
          Submit
        </Button> */}
         <button onClick={handleClose} className="btn btn-secondary">Close</button>
         <button onClick={handleSubmitRate} className="theme_btn py-2 rounded-2">Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default RateClinicModal;
