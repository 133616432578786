
const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
const passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
const passwordLength = 8;
const phoneLength = 10;
const pincodeLength = 6;

export function emailValidator(email){
  return emailRegx.test(email) ? false : true;
}

export function passwordValidator(password){
    return password.length >= passwordLength ? false : true;
}

export function emptyStringValidator(string){
    return string?.trim()?.length !== 0 ? false : true;
}

export function matchingValidator(string1, string2){
  return string1 === string2 ? false : true;
}

export function phoneValidator(phone){
  return phone.length === phoneLength ? false : true;
}

export function pincodeValidator(pincode){
  return pincode.length === pincodeLength ? false : true;
}



export * from './validatorServices.js';