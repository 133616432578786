import React from "react";
import "./DentistCard.scss";

import { Link, useNavigate } from "react-router-dom";

import teeth from "../../Assets/teeth.png";

// ---DIalogue--
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePickerComponent from "../DatePicker/DatePickerComponent";

export default function DentistListiing(props) {
  const navigate = useNavigate();
  let account_type = null;
  const token = localStorage.getItem("token");
  const user_data = localStorage.getItem("user_details");
  if (user_data !== null) {
    const parsedData = JSON.parse(user_data);
    account_type = parsedData.user_type;
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filledStars = Array.from({ length: 5 }, (_, index) => (
    <i
      key={index}
      className={`fas fa-star ${
        index < Math.floor(props.rating) ? "filled" : ""
      }`}
    ></i>
  ));

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/appointments/${props.id}`);
        }}
        className="card shadow-sm"
      >
        <div className="card-body">
          <div className="row ">
            <div className="col-md-4 mb-4 mb-md-0">
              <Link to="" className="">
                <img className="clinic_image" src={props.profile} alt="User " />
              </Link>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="card__doc-info-cont">
                    <h4 className="card__doc-name">
                      {/* <a href="doctor-profile.html"> */}
                      {props?.name}
                      {/* </a> */}
                    </h4>
                    <p className="card__doc-speciality">
                      <i className="fas fa-map-marker-alt"></i> {props?.address}
                      {
                        props.distance &&
                      <span className="distance">{props.distance} Miles</span>
                      }
                    </p>
                    {/* <h5 className="card__doc-department"><img src={teeth} className="img-fluid" alt="Speciality" />Dentist</h5> */}
                    <div className="card__rating">
                      {filledStars}
                      <span className="d-inline-block average-rating">
                        ({props?.total_count})
                      </span>
                    </div>
                    <div className="card__clinic-details">
                      {/* <p className="card__doc-location"><i className="fas fa-map-marker-alt"></i>{props.location} <span className='distance'>{props.distance}</span></p> */}
                      <ul className="card__clinic-gallery">
                        {props?.documentImages?.map((val,index) => (
                        <li key={index}>
                            <a
                              href="assets/img/features/feature-01.jpg"
                              data-fancybox="gallery"
                            >
                              <img
                                src={`${process.env.REACT_APP_CLINIC_DOCUMENTS}${val}`}
                                alt="Feature"
                              />
                            </a>
                          </li>
                        ))}
                        {/* <li>
                                            <a href="assets/img/features/feature-02.jpg" data-fancybox="gallery">
                                                <img src={profile03} alt="Feature" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="assets/img/features/feature-03.jpg" data-fancybox="gallery">
                                                <img src={profile01} alt="Feature" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="assets/img/features/feature-04.jpg" data-fancybox="gallery">
                                                <img src={profile04} alt="Feature" />
                                            </a>
                                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card__doc-info-right">
                    <div className="card__clini-infos">
                      <ul>
                        {/* <li><i className="far fa-thumbs-up"></i>{props.likes}</li> */}
                        <li>
                          <i className="far fa-comment"></i>
                          {props?.feedback}
                        </li>
                        {/* <li><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="login" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 01520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 01270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 010 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path></svg>{props.network}</li> */}
                        <li>
                          <i className="fa-solid fa-arrow-trend-up"></i>{" "}
                          {props?.booking}
                        </li>
                      </ul>
                    </div>
                    <div className="card__clinic-booking">
                      <Link
                        className="view-pro-btn theme-outline-btn rounded-1"
                        to={`/appointments/${props.id}`}
                      >
                        View Profile
                      </Link>
                      {/* <Link onClick={handleClickOpen} className="apt-btn theme_btn rounded-1" >Book Appointment</Link> */}

                      <div>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          PaperProps={{
                            style: {
                              width: "400px", // Set the width of the dialog box
                              height: "600px", // Set the height of the dialog box
                              // You can also set other styles like padding, margin, etc. if needed
                            },
                          }}
                        >
                          <DialogTitle
                            className="mb-0 text-center"
                            id="alert-dialog-title"
                          >
                            {"Select Booking Date"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <DatePickerComponent />
                            </DialogContentText>

                            <div className="bookTime">
                              <div className="booktiming">
                                <Link to="" className="timing">
                                  <span>9:00</span>
                                  <span>AM</span>
                                </Link>
                                <Link to="" className="timing active">
                                  <span>10:00</span>
                                  <span>AM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>11:00</span>
                                  <span>AM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>12:00</span>
                                  <span>AM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>2:00</span>
                                  <span>PM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>4:00</span>
                                  <span>PM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>5:00</span>
                                  <span>PM</span>
                                </Link>
                                <Link to="" className="timing">
                                  <span>6:00</span>
                                  <span>PM</span>
                                </Link>
                              </div>
                            </div>
                          </DialogContent>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-12'>
                                    <div className="card__clinic-services d-flex flex-wrap">
                                        {props?.services?.map((val) => (<span>{val}</span>))}
                                    </div>
                                </div> */}

                {/* <span> Whitening</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
