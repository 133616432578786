import React, { useState } from 'react'
import chatUser01 from "../../Assets/noUserImage.png"
import chatUser02 from "../../Assets/chatUser3.jpg"
import moment from 'moment';



export default function Tables({handleClickOpen,data,offset,limit}) {
console.log("🚀 ~ Tables ~ data:", data)

    const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;


    return (
        <div className='table-responsive'>

            <table className="table border-end border-start align-middle rounded">
                <thead className="table-dark">
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Names</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date And Time</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length ? data.map((ele,i) => (<tr>
                        <th scope="row">{i+1+(limit*offset)}</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={ele?.user_details?.profile_image && 
                                        ele?.user_details?.profile_image !== "" ? profile_image_base_url+ ele?.user_details?.profile_image : chatUser01}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="name mb-0">{ele?.user_details?.full_name ? ele?.user_details?.full_name : "" }</h6>
                            </div>
                        </td>
                        <td>{ele?.user_details?.email ? ele?.user_details?.email : ""} </td>
                        <td>{ele?.start_time !== "" && ele?.start_time !== null ? moment(new Date(ele?.start_time)).format('DD-MM-YYYY, hh:mm A') : '--'}</td>
                        <td>{ele?.reason ? ele?.reason : ""} </td>
                        <td>New Patient</td>
                        <td>
                            <button
                                type="button"
                                onClick={() => handleClickOpen(ele)}
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>)) : (<div style={{color:"red",textAlign:'center'}}>*No data to be found</div>)}
                    {/* <tr>
                        <th scope="row">2</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser02}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Jerome Bell</h6>
                            </div>
                        </td>
                        <td>jerome@gmail.com</td>
                        <td>28 may at 12:00 PM</td>
                        <td>Tooth Cleaning</td>
                        <td>New Patient</td>
                        <td>
                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser01}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Darrell Steward</h6>
                            </div>
                        </td>
                        <td>darrell@gmail.com</td>
                        <td>30 May at 04:00 PM</td>
                        <td>Skin Whitening</td>
                        <td>Old Patient</td>
                        <td>
                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser02}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Esther Howard</h6>
                            </div>
                        </td>
                        <td>esther@gmail.com</td>
                        <td>06 June at 10:00 AM</td>
                        <td>Eye Checkup</td>
                        <td>Old Patient</td>
                        <td>

                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser01}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Floyd Miles</h6>
                            </div>
                        </td>
                        <td>floyd@gmail.com</td>
                        <td>06 June at 11:00 AM</td>
                        <td>Regular</td>
                        <td>Old Patient</td>
                        <td>
                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser02}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Guy Hawkins</h6>
                            </div>
                        </td>
                        <td>guy@gmail.com</td>
                        <td>08 June at 10:30 AM</td>
                        <td>Fever</td>
                        <td>New Patient</td>
                        <td>
                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={chatUser02}
                                    className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                    alt="icon"
                                />
                                <h6 className="mb-0">Amine Steward</h6>
                            </div>
                        </td>
                        <td>amine@gmail.com</td>
                        <td>13 June at 01:00 PM</td>
                        <td>Malaria</td>
                        <td>Old Patient</td>
                        <td>

                            <button
                                type="button"
                                className="badge bg-primary rounded-0 px-3 py-2 border-0 me-2"
                            >
                                View
                            </button>

                        </td>
                    </tr> */}
                </tbody>
            </table>

        </div>
    )
}
