import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import clinicimg from "../../Assets/clinicImgs.jpg";
import { imageUpload } from "../../utils/httpServices/httpService";

function CropModal({
  show,
  uploadedImage,
  handleCloseDialog,
  handleUploadNew,
  imageType,
  setClinicProfile
}) {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  console.log("🚀 ~ crop:", crop);

  const aspect_ration = 1;
  const min_dimension = 200;

  const createCroppedImage = () => {
    if (crop?.width === 0 || crop?.height === 0 || crop?.width === undefined ) {
      console.log("no crop required");
      handleCloseDialog();
      return;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const originalImage = new Image();
    if (uploadedImage) {
      originalImage.src = uploadedImage;
    } else {
      originalImage.src = clinicimg;
    }
    originalImage.crossOrigin = "anonymous";
    

    originalImage.onload = () => {
        const cssWidth = 400;
        const cssHeight = 350;
    
        const scaleX = originalImage.width / cssWidth;
        const scaleY = originalImage.height / cssHeight;
    
        // Calculate cropping dimensions
        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;
        const cropWidth = crop.width * scaleX;
        const cropHeight = crop.height * scaleY;
    
    
        canvas.width = cropWidth;
        canvas.height = cropHeight;

        
      ctx.drawImage(
        originalImage,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      canvas.toBlob((blob) => {
        console.log("🚀 ~ canvas.toBlob ~ canvas:", canvas)
        if (!blob) {
          console.error("Failed to convert canvas to blob");
          return;
        }
  
        // Upload the blob
        uploadCropedImage(blob);
    });
   

    };
  };

  const uploadCropedImage = async (cropeImage)=>{
    const data = new FormData();
    data.append("path", "userProfile");
    data.append("media", cropeImage);
    try {
      const resp = await imageUpload(data);
      console.log("upload image response", resp);
     
        setClinicProfile((prev)=> ({
            ...prev ,[imageType] : resp.path
        }))
        handleCloseDialog()
          
    } catch (error) {
      console.log("upload profile image error", error);
    }
  }


  const onImageLoad = (e)=>{
    const {width, height} = e.currentTarget;
    const crop = makeAspectCrop({
      unit: 'px',
      width: min_dimension
    },
    aspect_ration,
    width,
    height
    )

    setCrop(crop)
  }


  return (
    <div>
      <Modal className="cropp_image"  show={show} onHide={handleCloseDialog} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {/* <div className="cropImg"> */}
            <ReactCrop crop={crop} 
            circularCrop
            keepSelection
            aspect={aspect_ration}
            minWidth={min_dimension}
            onChange={(c) => setCrop(c)}>
              <img src={uploadedImage || clinicimg} className="cropImg" 

                onLoad={onImageLoad}
              />
            </ReactCrop>
          {/* </div> */}
        </Modal.Body>
        <input
          style={{ display: "none" }}
          type="file"
          id="uploadLogo"
          onChange={(e) => handleUploadNew(e, imageType)}
          className="hideInput"
        />
        <Modal.Footer>
          <label htmlFor="uploadLogo" className="uploadImage">
            Upload New Image
          </label>
          <Button variant="primary" onClick={createCroppedImage}>
            Crop Image
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CropModal;
