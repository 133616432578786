import React, { useState, useEffect } from "react";
import "./Billings.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import { DatePicker, Space } from "antd";
import Checkbox from "@mui/material/Checkbox";
import {
  getallpayments,
  dentistlistforbilling,
  getappointmentlist,
  getinvoicelist,
  download,
  getsessionId,
  successNotification,
  errorNotification,
  sendrequest,
  imageUpload,
  downloadInvoiceReceipt,
  downloadBillingReceipt,
  downloadedBillingReceipt,
} from "../../../utils/httpServices/httpService";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { capitalizeFirstChar } from "../../../utils/common/common";
import dayjs from 'dayjs';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Billings() {
  // --handletabs--
  const selectedvalue = [];

  const [postContent, setPostContent] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const state = useSelector((state) => state.getClinicBrances);
  const [branch_id, setBranch_id] = useState(null);
  const [initialState, setPageState] = useState(
    localStorage.getItem("selected_Branch")
  );
  const [screenName, setScreenName] = useState("requests");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  /* ---dialog for request Not to be Charged-- */
  const [billinglist, setbilling] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen3 = () => setOpen(true);
  const handleClose3 = () => setOpen(false);
  const [paymentlist, setpayment] = useState([]);
  const [invoicelist, setInvoice] = useState([]);

  const [invoivecount, setinvcount] = useState();
  //   --sidebar-for-Requested-status
  const [isOpen, setIsOpen] = useState(false);
  const [divWidth, setDivWidth] = useState(0);

  const [amount, setAmount] = useState(false);
  const [prof_path, setPth] = useState(false);

  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');

  const [isTodayBilling, setIsTodayBilling] = useState(false);
  const [billingFilterDate, setBillingFilterDate] = useState([startOfMonth, endOfMonth]);
  const { RangePicker } = DatePicker;



  const [isTodayInvoice, setIsTodayInvoice] = useState(false);
  const [invoiceFilterDate, setInvoiceFilterDate] = useState([startOfMonth, endOfMonth]);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
    setDivWidth(25);

    console.log(setIsOpen);
  };

  const handleClose = () => {
    setDivWidth(0);
  };

  const providerProfilePicture = async (event) => {
    console.log("upload image data", event);
    const file = event.target.files[0];
    if (file) {
      if (file) {
        if (file.size <= 2000000) {
          const data = new FormData();
          data.append("path", "documents");
          data.append("media", file);
          try {
            const resp = await imageUpload(data);
            console.log("upload provider profile image response", resp);
            if (resp?.code === 200) {
              //successNotification("Image uplaoded Successfully");
              setPth(resp.path);
              // console.log("provider profile profile data", addProviderProfile)
            }
          } catch (error) {
            console.log("upload document image error", error);
          }
        } else {
          errorNotification("Document size is greater than 2MB");
        }
      } else {
        errorNotification("Please upload file in supported format (docs,pdf)");
      }
    }
  };

  async function sendreq() {
    console.log(selectedvalue, values);
    let obj = {
      appointment_ids: values,
      // 'year' : '2023',
      // 'month' : e.target.value,
      comment: postContent,
      document: prof_path,
    };
    const resp3 = await sendrequest(obj);
    console.log("resp for dentist list", resp3);
    if (resp3?.code === 200) {
      successNotification("Request sent Successfully");
      setOpen(false);
    }
  }

  useEffect(() => {
    console.log(state, initialState);
    if (state?.data?.data) {
      setBranch_id(state?.data?.data[initialState]._id);
    }
    console.log(branch_id);
    getpayments();
  }, [branch_id, initialState, state]);

  useEffect(() => { });

  async function getpayments() {
    if (branch_id) {
      console.log(branch_id);
      try {
        const resp = await getallpayments(branch_id);
        console.log("resp for dentist list", resp);
        if (resp?.code === 200) {
          setAmount(resp.data);
        }

        const resp1 = await dentistlistforbilling(branch_id);
        console.log("resp for dentist list", resp1);
        if (resp1?.code === 200) {
          setbilling(resp1.data);
        }

        // let obj = {
        //   branch_id: branch_id,
        //   // 'year' : '2023',
        //   // 'month' : e.target.value,
        //   limit: pageSize,
        //   offset: offset,
        // };
        // const resp3 = await getinvoicelist(obj);
        // console.log("resp for dentist list", resp3);
        // if (resp3?.code === 200) {
        //   setInvoice(resp3.data);
        //   setinvcount(resp3.allInvoice);
        //   paginationWorking(resp3.data, resp3.count);
        // }
      } catch (err) {
        console.log("dentist list error", err);
      }
    } else return;
  }

  // --sidebar for confirmed status

  const [isOpen1, setIsOpen1] = useState(false);
  const [divWidth1, setDivWidth1] = useState(0);

  //const [selectedvalue, setvalue] = useState([]);

  const handleClickOpen1 = () => {
    setIsOpen1(!isOpen);
    setDivWidth1(25);

    console.log(setIsOpen);
  };

  const handleClose1 = () => {
    setDivWidth1(0);
  };
  const updatePageState = (index) => {
    setPageState(index);
    localStorage.setItem("selected_Branch", index);
  };
  const [status, setStatus] = useState(null);

  const [numberOfPages, setNumberOfPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  function nextPage() {
    let value = offset + 1;
    setOffset(value);
  }

  function previousPage() {
    let value = offset - 1;
    setOffset(value);
  }

  function changingPage(i) {
    setOffset(i);
  }

  const [totalCount, setTotalCount] = useState(0);

  function paginationWorking(list, total) {
    setTotalCount(total);
    console.log("pagination function", list, total);
    let count = total === undefined ? 0 : total;
    let numberOfPages = Math.ceil(count / pageSize);
    let arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i);
    }
    console.log("arr", arr);
    setNumberOfPages(arr);
  }

  /////////////////////////////////    BILLING PAGINATION //////////////////

  const [numberOfPages_bill, setNumberOfPages_bill] = useState([]);
  const [offset_bill, setOffset_bill] = useState(0);
  const [pageSize_bill, setPageSize_bill] = useState(5);

  function nextPage_bill() {
    let value = offset_bill + 1;
    setOffset_bill(value);
  }

  function previousPage_bill() {
    let value = offset_bill - 1;
    setOffset(value);
  }

  function changingPage_bill(i) {
    setOffset_bill(i);
  }

  const [totalCount_bill, setTotalCount_bill] = useState(0);

  function paginationWorking_bill(list, total) {
    setTotalCount_bill(total);
    console.log("pagination function", list, total);
    let count = total === undefined ? 0 : total;
    let numberOfPages = Math.ceil(count / pageSize);
    let arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i);
    }
    console.log("arr", arr);
    setNumberOfPages_bill(arr);
  }

  useEffect(() => {
    if (branch_id) {

      getInvoices()
    }
  }, [isTodayInvoice, invoiceFilterDate, branch_id])

  async function getInvoices() {
    //  console.log(e)

    let start_date = "";
    let end_date = "";

    if (isTodayInvoice) {
      start_date = getDateFromCurrent(0).date;
      end_date = getDateFromCurrent(1).date;
    } else if (invoiceFilterDate?.length > 0) {
      let getDate = filterByDate(invoiceFilterDate);
      start_date = getDate?.start_time;
      end_date = getDate?.end_time;
    }

    let obj = {
      branch_id: branch_id,
      start_date: start_date,
      end_date: end_date,

      limit: pageSize,
      offset: offset,
    };
    const resp3 = await getinvoicelist(obj);
    console.log("resp for dentist list", resp3);
    if (resp3?.code === 200) {
      setInvoice(resp3.data);
      setinvcount(resp3.allInvoice);
      paginationWorking(resp3.data, resp3.count);

    }
  }
  async function handleClick(event) {
    console.log(event.target.value);
    let obj = {
      invoice_id: event.target.value,
    };
    const resp3 = await getsessionId(obj);

    console.log("resp for dentist list", resp3);
    if (resp3?.status === 200) {
      window.open(resp3.data.url, "_blank", "noreferrer");
      // console.log(resp3)
    }
  }

  useEffect(() => {
    if (branch_id) {
      changeStatus(status || "All dentist");
    }
  }, [branch_id, isTodayBilling, billingFilterDate]);

  async function changeStatus(value) {
    //  console.log(e)
    setStatus(value);

    let start_date = "";
    let end_date = "";

    if (isTodayBilling) {
      start_date = getDateFromCurrent(0).date;
      end_date = getDateFromCurrent(1).date;
    } else if (billingFilterDate?.length > 0) {
      let getDate = filterByDate(billingFilterDate);
      start_date = getDate?.start_time;
      end_date = getDate?.end_time;
    }

    let obj = {
      branch_id: branch_id,
      dentist_id: value === "All dentist" ? "" : value,
      start_date: start_date,
      end_date: end_date,
      limit: pageSize_bill,
      offset: offset_bill,
      sort: -1,
    };
    const resp1 = await getappointmentlist(obj);
    console.log("resp for dentist list", resp1);
    if (resp1?.code === 200) {
      setpayment(resp1.data);
      paginationWorking_bill(resp1.data, resp1.count);
    }
  }
  const sampleArr = [];
  const [values, setvalues] = useState([]);

  function onUserRowSelect(event) {
    const value = event.target.value;
    console.log("🚀 ~ onUserRowSelect ~ value:", value);

    setvalues((prevSelected) => {
      if (prevSelected.includes(value)) {
        console.log("includes");
        return prevSelected.filter((item) => item !== value);
      } else {
        console.log("excludes");
        return [...prevSelected, value];
      }
    });
  }

  const downloadReceipt = async (chargedId) => {
    try {
      const resp = await downloadInvoiceReceipt(chargedId);
      console.log(resp);
      const url = resp?.data;
      await download(url);
    } catch (error) { }
  };

  const downloadReceipts = async (id) => {
    try {
      let body = {
        invoice_id: id,
      };
      const resp = await downloadBillingReceipt(body);
      console.log(resp);

      const response = await downloadedBillingReceipt(resp?.data);
      const blob = new Blob([response]);

      // Create a virtual anchor element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Invoice receipt.pdf"; // Set the desired filename

      // Trigger a click on the anchor element
      document.body.appendChild(link);
      link.click();

      // Clean up the virtual anchor element
      document.body.removeChild(link);
    } catch (error) { }
  };

  const onBillingFilterDateChange = (selectedDates) => {
    setIsTodayBilling(false);
    if (selectedDates && selectedDates.length > 0) {
      setBillingFilterDate(selectedDates);
    } else {
      setBillingFilterDate(null);
    }
  };

  const onInvoiceFilterDateChange = (selectedDates) => {
    setIsTodayInvoice(false);
    if (selectedDates && selectedDates.length > 0) {
      setInvoiceFilterDate(selectedDates);
    } else {
      setInvoiceFilterDate(null);
    }
  };

  function filterByDate(FilterDate) {
    if (FilterDate && FilterDate?.length === 2) {
      const firstDate = new Date(FilterDate[0].$d);
      const secondDate = new Date(FilterDate[1].$d);

      const endDate = new Date(secondDate);
      endDate.setDate(secondDate.getDate() + 1);

      const startTime = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        firstDate.getDate(),
        0,
        0,
        0,
        0
      );
      const endTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        0,
        0,
        0,
        0
      );

      return {
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
      };
    }

    return null;
  }

  function getDateFromCurrent(day) {
    let todayEndtime = new Date(); // Current date and time
    let clonedEndtime = new Date(todayEndtime); // Create a copy of current date and time
    let added_date = clonedEndtime.getDate() + day;
    clonedEndtime.setDate(added_date); // Modify the cloned date

    clonedEndtime.setHours(0, 0, 0, 0); // Set time to 00:00:00

    return { date: clonedEndtime.toISOString() };
  }

  return (
    <>
      <ProviderNav />
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar
            initialState={initialState}
            triggerParentUpdate={updatePageState}
          />
        </div>
        <div className="fullWidhtWrap" id="fullWidthWrap">
          <div className="container">
            <section className="doctorWrap">
              <NavigationBar />
              <div className="content-inner pb-0 px-0 " id="billing-dtls">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="d-flex align-items-center gap-3 flex-sm-row flex-column">
                      <button
                        className={`${activeTab === "tab1" ? "theme_btn active rounded" : ""
                          }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        <svg
                          fill="#fff"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M18,1H6A3,3,0,0,0,3,4V22a1,1,0,0,0,1.707.707L6.845,20.57l1.323,1.984A1,1,0,0,0,8.9,23a.986.986,0,0,0,.806-.288L12,20.414l2.293,2.293a1,1,0,0,0,1.539-.153l1.323-1.984,2.138,2.137A1,1,0,0,0,21,22V4A3,3,0,0,0,18,1Zm1,18.586-1.293-1.293a.984.984,0,0,0-.806-.288,1,1,0,0,0-.733.44L14.845,20.43l-2.138-2.137a1,1,0,0,0-1.414,0L9.155,20.43,7.832,18.445a1,1,0,0,0-1.539-.152L5,19.586V4A1,1,0,0,1,6,3H18a1,1,0,0,1,1,1ZM13,11a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h4A1,1,0,0,1,13,11Zm0,4a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h4A1,1,0,0,1,13,15Zm4-4a1,1,0,0,1-1,1H15a1,1,0,0,1,0-2h1A1,1,0,0,1,17,11Zm0,4a1,1,0,0,1-1,1H15a1,1,0,0,1,0-2h1A1,1,0,0,1,17,15Zm0-9a1,1,0,0,1-1,1H8A1,1,0,0,1,8,5h8A1,1,0,0,1,17,6Z" />
                        </svg>{" "}
                        BILLINGS
                      </button>
                      <button
                        className={`${activeTab === "tab2" ? "theme_btn active rounded" : ""
                          }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.55281 1.60553C7.10941 1.32725 7.77344 1 9 1C10.2265 1 10.8906 1.32722 11.4472 1.6055L11.4631 1.61347C11.8987 1.83131 12.2359 1.99991 13 1.99993C14.2371 1.99998 14.9698 1.53871 15.2141 1.35512C15.5944 1.06932 16.0437 1.09342 16.3539 1.2369C16.6681 1.38223 17 1.72899 17 2.24148L17 13H20C21.6562 13 23 14.3415 23 15.999V19C23 19.925 22.7659 20.6852 22.3633 21.2891C21.9649 21.8867 21.4408 22.2726 20.9472 22.5194C20.4575 22.7643 19.9799 22.8817 19.6331 22.9395C19.4249 22.9742 19.2116 23.0004 19 23H5C4.07502 23 3.3148 22.7659 2.71092 22.3633C2.11331 21.9649 1.72739 21.4408 1.48057 20.9472C1.23572 20.4575 1.11827 19.9799 1.06048 19.6332C1.03119 19.4574 1.01616 19.3088 1.0084 19.2002C1.00194 19.1097 1.00003 19.0561 1 19V2.24146C1 1.72899 1.33184 1.38223 1.64606 1.2369C1.95628 1.09341 2.40561 1.06931 2.78589 1.35509C3.03019 1.53868 3.76289 1.99993 5 1.99993C5.76415 1.99993 6.10128 1.83134 6.53688 1.6135L6.55281 1.60553ZM3.00332 19L3 3.68371C3.54018 3.86577 4.20732 3.99993 5 3.99993C6.22656 3.99993 6.89059 3.67269 7.44719 3.39441L7.46312 3.38644C7.89872 3.1686 8.23585 3 9 3C9.76417 3 10.1013 3.16859 10.5369 3.38643L10.5528 3.39439C11.1094 3.67266 11.7734 3.9999 13 3.99993C13.7927 3.99996 14.4598 3.86581 15 3.68373V19C15 19.783 15.1678 20.448 15.4635 21H5C4.42498 21 4.0602 20.8591 3.82033 20.6992C3.57419 20.5351 3.39761 20.3092 3.26943 20.0528C3.13928 19.7925 3.06923 19.5201 3.03327 19.3044C3.01637 19.2029 3.00612 19.1024 3.00332 19ZM19.3044 20.9667C19.5201 20.9308 19.7925 20.8607 20.0528 20.7306C20.3092 20.6024 20.5351 20.4258 20.6992 20.1797C20.8591 19.9398 21 19.575 21 19V15.999C21 15.4474 20.5529 15 20 15H17L17 19C17 19.575 17.1409 19.9398 17.3008 20.1797C17.4649 20.4258 17.6908 20.6024 17.9472 20.7306C18.2075 20.8607 18.4799 20.9308 18.6957 20.9667C18.8012 20.9843 18.8869 20.9927 18.9423 20.9967C19.0629 21.0053 19.1857 20.9865 19.3044 20.9667Z"
                            fill="#fff"
                          />
                          <path
                            d="M5 8C5 7.44772 5.44772 7 6 7H12C12.5523 7 13 7.44772 13 8C13 8.55229 12.5523 9 12 9H6C5.44772 9 5 8.55229 5 8Z"
                            fill="#fff"
                          />
                          <path
                            d="M5 12C5 11.4477 5.44772 11 6 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H6C5.44772 13 5 12.5523 5 12Z"
                            fill="#fff"
                          />
                          <path
                            d="M5 16C5 15.4477 5.44772 15 6 15H12C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17H6C5.44772 17 5 16.5523 5 16Z"
                            fill="#fff"
                          />
                        </svg>{" "}
                        LIST OF INVOICES
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-grid justify-content-sm-end justify-content-center">
                      <button
                        onClick={() => {
                          if (values.length > 0) {
                            handleOpen3();
                          } else {
                            toast.error("Please Select the patients");
                          }
                        }}
                        className="theme-outline-btn rounded ms-auto"
                      >
                        Request not to be charged
                      </button>
                    </div>
                  </div>
                </div>
                {activeTab === "tab1" && (
                  <div className="row">
                    <div className="col-lg-12 ">
                      <div className="card shadow border-0">
                        <div className="card-header d-flex align-items-bottom justify-content-between flex-wrap gap-3">
                          <div>
                            <h4>All Payments</h4>
                            <div className="d-flex gap-2">
                              <p className="amount-data mb-0">
                                Price Per Patient{" "}
                                <span className="btn btn-sm btn-outline-secondary text-primary fw-bold">
                                  {" "}
                                  ${amount?.price_per_appointment}
                                </span>
                              </p>
                              <p className="amount-data mb-0">
                                Total Balance{" "}
                                <span className="btn btn-sm btn-outline-secondary text-danger fw-bold">
                                  {" "}
                                  ${amount?.total_amount}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="filterDate">
                            <div className="row justify-content-end">
                              <div className="col-md-4 col-sm-12">
                                <select
                                  className="form-select "
                                  aria-label="Default select example"
                                  value={status}
                                  onChange={(e) => changeStatus(e.target.value)}
                                >
                                  <option selected value="All dentist">
                                    All Dentist
                                  </option>

                                  {billinglist.length
                                    ? billinglist.map((ele, i) => (
                                      <option value={ele._id}>
                                        {" "}
                                        {ele.full_name}
                                      </option>
                                    ))
                                    : ""}
                                </select>

                              </div>

                              <div class="col-md-7 col-sm-12 text-md-end">
                                <div className="form-group">

                                  <div className="filter-data">
                                    <button
                                      className={`btn btn-sm ${isTodayBilling ? "btn-active" : ""
                                        } me-2`}
                                      onClick={() =>
                                        setIsTodayBilling((prev) => !prev)
                                      }
                                    >
                                      Today
                                    </button>
                                    <Space direction="vertical" size={12}>
                                      <RangePicker
                                        value={billingFilterDate}
                                        onChange={onBillingFilterDateChange}
                                      />
                                    </Space>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                              value={status}
                              onChange={changeStatusmonth}
                            >
                              <option selected>Month</option>
                              <option value="1">January</option>
                              <option value="2">Feburary</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select> */}

                            <div className=""></div>
                          </div>
                        </div>
                        <div className="card-body pt-4">
                          <div className="table-responsive">
                            <table className="table border-end border-start align-middle mb-0 rounded">
                              <thead className="table-dark">
                                <tr>
                                  <th></th>
                                  <th>Patient Name</th>
                                  <th>Dentist Name</th>
                                  <th>Scheduled Date</th>
                                  <th>Appointment ID</th>
                                  <th>Request Date</th>
                                  <th>Appointment Status</th>
                                  <th>Billing Status</th>
                                  {/* <th>billing Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {paymentlist.length
                                  ? paymentlist.map((ele, i) => (
                                    <tr data-item="list">
                                      <th scope="row">
                                        <Checkbox
                                          value={ele._id}
                                          checked={values?.includes(ele._id)}
                                          onClick={onUserRowSelect}
                                          {...label}
                                        />
                                      </th>
                                      <td>{ele?.user_details?.full_name}</td>
                                      <td>{ele.dentist_details?.full_name}</td>
                                      <td>
                                        {" "}
                                        {ele.start_time !== ""
                                          ? moment(ele.start_time).format(
                                            "DD-MM-YYYY"
                                          ) +
                                          " " +
                                          moment(ele.start_time).format(
                                            "hh:mm A"
                                          )
                                          : ""}
                                      </td>
                                      <td>#{ele._id}</td>
                                      <td>
                                        {ele.createdAt !== ""
                                          ? moment(ele.createdAt).format(
                                            "DD-MM-YYYY"
                                          ) +
                                          " " +
                                          moment(ele.createdAt).format(
                                            "hh:mm A"
                                          )
                                          : ""}
                                      </td>
                                      {/* <td>
                                          <div className="edit_status">
                                            <span>Requested</span>
                                            <button
                                              onClick={handleClickOpen}
                                              className="btn btn-sm btn-outline-info"
                                            >
                                              Edit
                                            </button>
                                          </div>
                                        </td> */}
                                      <td>{capitalizeFirstChar(ele.status)}</td>
                                      {/* <td>{ele?.invoice_generated ? capitalizeFirstChar(ele?.invoice?.status) : "Pending"}</td> */}
                                      <td>{ele?.not_to_be_charged ? "Not charged" : ele?.invoice?.status === "payed" ? "Payed" : ele?.invoice?.status === "pending" ? "Unpayed" : "Pending"}</td>

                                    </tr>
                                  ))
                                  : ""}
                              </tbody>
                            </table>
                            {paymentlist.length === 0 ? (
                              <p
                                style={{
                                  color: "red",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                }}
                              >
                                *No data to be found
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="card-footer pt-2">
                          <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
                            <ul className="page-number m-0 p-0 list-unstyled d-flex gap-2">
                              {/* <li className="text-center px-3 py-2 theme_btn text-white rounded">1</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">2</li>
                                                            <li className="text-center text-dark rounded">...</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">6</li> */}
                              {numberOfPages_bill.map((ele, i) => (
                                <li
                                  className={
                                    offset_bill === i
                                      ? "text-center theme_btn px-3 py-2 text-white rounded"
                                      : "text-center bg-soft-primary text-dark rounded"
                                  }
                                  onClick={() => changingPage_bill(i)}
                                >
                                  {i + 1}
                                </li>
                              ))}
                            </ul>
                            {/* <div>
                                                            <button className=" theme-outline-btn rounded me-3" type="button">
                                                                <span className="btn-inner">
                                                                    <span className="text d-inline-block align-middle">Previous</span>
                                                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={8}
                                                                            height={8}
                                                                            viewBox="0 0 8 8"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button className="theme_btn rounded me-3" type="button">
                                                                <span className="btn-inner">
                                                                    <span className="text d-inline-block align-middle">Next</span>
                                                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={8}
                                                                            height={8}
                                                                            viewBox="0 0 8 8"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </div> */}
                          </div>
                          {offset_bill !== 0 ? (
                            <button
                              onClick={() => previousPage_bill()}
                              className="theme-outline-btn rounded me-3"
                              type="button"
                            >
                              <span className="btn-inner">
                                <span className="text d-inline-block align-middle">
                                  Previous
                                </span>
                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={8}
                                    height={8}
                                    viewBox="0 0 8 8"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          ) : (
                            <></>
                          )}
                          {offset_bill < numberOfPages_bill.length - 1 ? (
                            <button
                              onClick={() => nextPage_bill()}
                              className="theme_btn rounded me-3"
                              type="button"
                            >
                              <span className="btn-inner">
                                <span className="text d-inline-block align-middle">
                                  Next
                                </span>
                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={8}
                                    height={8}
                                    viewBox="0 0 8 8"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "tab2" && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card shadow border-0">
                        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                          <div>
                            <h4>All Invoices</h4>
                            <p className="mb-0 fw-bold">
                              More than {invoivecount}+ invoices
                            </p>
                          </div>
                          <div className="d-flex align-items-center ">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                              value={status}
                              onChange={changeStatusmonth}
                            >
                              <option selected>Month</option>
                              <option value="1">January</option>
                              <option value="2">Feburary</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select> */}

                            <div className="form-group">
                              {/* <label className="mb-2" htmlFor="">
                    Filter By Date
                  </label>
                 */}
                              <div className="filter-data">
                                <button
                                  className={`btn btn-sm ${isTodayInvoice ? "btn-active" : ""
                                    } me-2`}
                                  onClick={() =>
                                    setIsTodayInvoice((prev) => !prev)
                                  }
                                >
                                  Today
                                </button>
                                <Space direction="vertical" size={12}>
                                  <RangePicker
                                    value={invoiceFilterDate}
                                    onChange={onInvoiceFilterDateChange}
                                  />
                                </Space>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table border-end border-start align-middle rounded">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">No.</th>
                                  <th scope="col">Appointment to be charged</th>
                                  <th scope="col">Total amount</th>
                                  <th scope="col">Transaction ID</th>
                                  <th scope="col">Action</th>
                                  {/* <th scope="col">Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {invoicelist.length
                                  ? invoicelist.map((ele, i) => (
                                    <tr data-item="list">
                                      <th scope="row">{i + 1}</th>
                                      <td>
                                        {ele?.appointment_to_be_charged}
                                      </td>
                                      <td>
                                        {ele?.total_amount}
                                        {/* {(ele?.appointment_to_be_charged -
                                            ele?.appointment_to_refund) *
                                            ele?.price_per_appointment} */}
                                      </td>

                                      <td>
                                        {ele?.status === "payed" &&
                                          ele?.tansactions
                                          ? ele?.tansactions?.transaction_id
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <Link
                                          to=""
                                          // download="Example-PDF-document"
                                          // target="_blank"
                                          // rel="noreferrer"
                                          className="btn btn-sm btn-success"
                                          onClick={() =>
                                            downloadReceipts(ele?._id)
                                          }
                                        >
                                          Download Invoice{" "}
                                          <svg
                                            fill="#fff"
                                            width={15}
                                            height={15}
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M5.44 7.47h5.26v1.25H5.44zm0 2.36h5.26v1.25H5.44zm0-4.76h5.26v1.25H5.44z" />
                                            <path
                                              d="M11.34 1 9.64.28 8.08 1 6.41.28 4.84 1 2.46 0v16l2.38-1 1.57.69L8.08 15l1.56.69 1.7-.69 2.2 1V0zm.94 13.11-.92-.41
                                                                        -1.69.69-1.57-.72-1.68.69-1.55-.69-1.15.47V1.86l1.15.47 1.55-.69 1.68.69 1.57-.69 1.69.69.92-.41z"
                                            />
                                          </svg>
                                        </Link>
                                        {ele?.total_amount > 0 ? (
                                          <>
                                            {" "}
                                            {ele?.status !== "payed" ? (
                                              <button
                                                id="my-btn"
                                                value={ele._id}
                                                onClick={handleClick}
                                              >
                                                Pay now
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-sm btn-success"
                                                // id="my-btn"
                                                // value={ele._id}
                                                onClick={() =>
                                                  downloadReceipt(
                                                    ele?.tansactions
                                                      ?.charge_id
                                                  )
                                                }
                                              >
                                                Download Payment{" "}
                                                <svg
                                                  fill="#fff"
                                                  width={15}
                                                  height={15}
                                                  viewBox="0 0 16 16"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M5.44 7.47h5.26v1.25H5.44zm0 2.36h5.26v1.25H5.44zm0-4.76h5.26v1.25H5.44z" />
                                                  <path
                                                    d="M11.34 1 9.64.28 8.08 1 6.41.28 4.84 1 2.46 0v16l2.38-1 1.57.69L8.08 15l1.56.69 1.7-.69 2.2 1V0zm.94 13.11-.92-.41
                                                                        -1.69.69-1.57-.72-1.68.69-1.55-.69-1.15.47V1.86l1.15.47 1.55-.69 1.68.69 1.57-.69 1.69.69.92-.41z"
                                                  />
                                                </svg>
                                              </button>
                                            )}
                                          </>
                                        ) : (
                                          " N/A"
                                        )}
                                      </td>

                                      {/* <td>
                                          {" "}
                                          <button
                                            id="my-btn"
                                            value={ele._id}
                                            onClick={handleClick}
                                          >
                                            {" "}
                                            Pay now
                                          </button>
                                        </td> */}
                                    </tr>
                                  ))
                                  : ""}
                              </tbody>
                            </table>
                            {invoicelist.length === 0 ? (
                              <p
                                style={{
                                  color: "red",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                }}
                              >
                                *No data to be found
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="card-footer pt-2">
                          <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
                            <ul className="page-number m-0 p-0 list-unstyled d-flex gap-2">
                              {/* <li className="text-center px-3 py-2 theme_btn text-white rounded">1</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">2</li>
                                                            <li className="text-center text-dark rounded">...</li>
                                                            <li className="text-center bg-soft-primary text-dark rounded">6</li> */}
                              {numberOfPages.map((ele, i) => (
                                <li
                                  className={
                                    offset === i
                                      ? "text-center theme_btn px-3 py-2 text-white rounded"
                                      : "text-center bg-soft-primary text-dark rounded"
                                  }
                                  onClick={() => changingPage(i)}
                                >
                                  {i + 1}
                                </li>
                              ))}
                            </ul>
                            {/* <div>
                                                            <button className=" theme-outline-btn rounded me-3" type="button">
                                                                <span className="btn-inner">
                                                                    <span className="text d-inline-block align-middle">Previous</span>
                                                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={8}
                                                                            height={8}
                                                                            viewBox="0 0 8 8"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button className="theme_btn rounded me-3" type="button">
                                                                <span className="btn-inner">
                                                                    <span className="text d-inline-block align-middle">Next</span>
                                                                    <span className="icon d-inline-block align-middle ms-1 ps-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={8}
                                                                            height={8}
                                                                            viewBox="0 0 8 8"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </div> */}
                          </div>
                          {offset !== 0 ? (
                            <button
                              onClick={() => previousPage()}
                              className="theme-outline-btn rounded me-3"
                              type="button"
                            >
                              <span className="btn-inner">
                                <span className="text d-inline-block align-middle">
                                  Previous
                                </span>
                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={8}
                                    height={8}
                                    viewBox="0 0 8 8"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          ) : (
                            <></>
                          )}
                          {offset < numberOfPages.length - 1 ? (
                            <button
                              onClick={() => nextPage()}
                              className="theme_btn rounded me-3"
                              type="button"
                            >
                              <span className="btn-inner">
                                <span className="text d-inline-block align-middle">
                                  Next
                                </span>
                                <span className="icon d-inline-block align-middle ms-1 ps-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={8}
                                    height={8}
                                    viewBox="0 0 8 8"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="right-sidebar billing shadow"
                style={{
                  width: `${divWidth}rem`,
                  transition: "width 0.6s ease",
                }}
              >
                <div className="right-sidebar-header border-bottom border-1">
                  <article className="right-sidebar-header-details border-0 ">
                    <h4 className="right-sidebar-header-name ">
                      Edit Appointment Status
                    </h4>
                  </article>
                  <span onClick={handleClose}>
                    <i className="fas fa-times" />
                  </span>
                </div>
                <div className="right-sidebar-body p-3">
                  <form>
                    <div
                      className="form-group"
                      data-select2-id="select2-data-11-jt00"
                    >
                      <label className="form-label" htmlFor="payment_id">
                        Appointment Status{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control select2-basic-single select2-hidden-accessible"
                        id="payment_id"
                        data-select2-id="select2-data-payment_id"
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option data-select2-id="select2-data-12-0gj9">
                          Confirmed
                        </option>
                        <option
                          selected="selected"
                          data-select2-id="select2-data-4-q3uh"
                        >
                          Rejected
                        </option>
                      </select>
                    </div>
                    <div className="form-group mt-4">
                      <label className="form-label" htmlFor="payment_id">
                        Mentioned The Reason for Cancellation{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div class="form-floating">
                        <textarea
                          class="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label for="floatingTextarea2">Comments</label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="right-sidebar-footer">
                  <div class="d-grid d-md-flex gap-3 p-3">
                    <button type="submit" class="theme_btn rounded d-block">
                      <span class="btn-inner">
                        <span class="text d-inline-block align-middle">
                          Update
                        </span>
                        <span class="icon d-inline-block align-middle ms-1 ps-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                    <button
                      onClick={handleClose}
                      class="btn btn-secondary d-block"
                      type="button"
                    >
                      <span class="btn-inner">
                        <span class="text d-inline-block align-middle">
                          Close
                        </span>
                        <span class="icon d-inline-block align-middle ms-1 ps-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="right-sidebar biiling shadow"
                style={{
                  width: `${divWidth1}rem`,
                  transition: "width 0.6s ease",
                }}
              >
                <div className="right-sidebar-header border-bottom border-1">
                  <article className="right-sidebar-header-details border-0 ">
                    <h4 className="right-sidebar-header-name ">
                      Edit Appointment Status
                    </h4>
                  </article>
                  <span onClick={handleClose1}>
                    <i className="fas fa-times" />
                  </span>
                </div>
                <div className="right-sidebar-body p-3">
                  <form>
                    <div
                      className="form-group"
                      data-select2-id="select2-data-11-jt00"
                    >
                      <label className="form-label" htmlFor="payment_id">
                        Appointment Status{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control select2-basic-single select2-hidden-accessible"
                        id="payment_id"
                        data-select2-id="select2-data-payment_id"
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option data-select2-id="select2-data-12-0gj9">
                          Showed Up
                        </option>
                        <option
                          selected="selected"
                          data-select2-id="select2-data-4-q3uh"
                        >
                          Canceled
                        </option>
                      </select>
                    </div>
                    <div className="form-group my-4">
                      <label className="form-label" htmlFor="payment_id">
                        Submit alternative date and time slot suggestion for
                        patient <span className="text-danger">*</span>
                      </label>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <DatePicker onChange={onChange} />
                      </Space>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Add Time Slot
                      </label>
                      <div class="hours-info">
                        <div class="row hours-cont">
                          <div class="col-12 ">
                            <div class="row">
                              <div class="col-12 col-md-6">
                                <div class="mb-3">
                                  <label class="mb-2">Start Time</label>
                                  <select class="form-select form-control">
                                    <option>-</option>
                                    <option selected="">12.00 am</option>
                                    <option>12.30 am</option>
                                    <option>1.00 am</option>
                                    <option>1.30 am</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-12 col-md-6">
                                <div class="mb-3">
                                  <label class="mb-2">End Time</label>
                                  <select class="form-select form-control">
                                    <option>-</option>
                                    <option>12.00 am</option>
                                    <option selected="">12.30 am</option>
                                    <option>1.00 am</option>
                                    <option>1.30 am</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="right-sidebar-footer">
                  <div class="d-grid d-md-flex gap-3 p-3">
                    <button type="submit" class="theme_btn rounded d-block">
                      <span class="btn-inner">
                        <span class="text d-inline-block align-middle">
                          Update
                        </span>
                        <span class="icon d-inline-block align-middle ms-1 ps-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                    <button
                      onClick={handleClose1}
                      class="btn btn-secondary d-block"
                      type="button"
                    >
                      <span class="btn-inner">
                        <span class="text d-inline-block align-middle">
                          Close
                        </span>
                        <span class="icon d-inline-block align-middle ms-1 ps-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.32046 4.70834H4.74952V7.25698C4.74952 7.66734 4.41395 8 4 8C3.58605 8 3.25048 7.66734 3.25048 7.25698V4.70834H0.679545C0.293423 4.6687 0 4.34614 0 3.96132C0 3.5765 0.293423 3.25394 0.679545 3.21431H3.24242V0.673653C3.28241 0.290878 3.60778 0 3.99597 0C4.38416 0 4.70954 0.290878 4.74952 0.673653V3.21431H7.32046C7.70658 3.25394 8 3.5765 8 3.96132C8 4.34614 7.70658 4.6687 7.32046 4.70834Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              {/* ---dialog for request Not to be Charged-- */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Reason For Request Not to be Charged
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p className="text-bold">
                      Note<span className="text-danger">*</span>{" "}
                      <span className="text-muted">
                        The Request will be manually evaluated
                      </span>
                    </p>
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label for="floatingTextarea2">Comments</label>
                    </div>
                    <div className="">
                      <div class="my-3">
                        <label for="formFile" class="form-label">
                          Add File (optional)
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          id="formFile"
                          onChange={providerProfilePicture}
                        />
                      </div>
                    </div>
                  </Typography>
                  <div className="d-flex justify-content-end gap-3">
                    <Button variant="outlined" onClick={handleClose3}>
                      Close
                    </Button>
                    <Button variant="outlined" onClick={sendreq}>
                      Send Request
                    </Button>
                  </div>
                </Box>
              </Modal>
            </section>
          </div>
          <ProviderFooter />
        </div>
      </div>
    </>
  );
}
