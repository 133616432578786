import React from 'react'
import "./ProviderFooter.scss"
import logo from "../../Assets/Logos/logo1.png"
import { Link } from 'react-router-dom'

export default function ProviderFooter() {
    return (
        <footer class="footer-section">

            <div class="copyright-area py-3">
                <div class="container">
                    <div class="copyright-text ">
                        <h6 className='text-white mb-0'>Copyright &copy; {(new Date().getFullYear())}, Dentist Finder. All Right Reserved </h6>
                    </div>
                </div>
            </div>
        </footer>
    )
}
