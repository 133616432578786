import React, { useState } from 'react'
import "./ForgotPassword.scss";

import { emailValidator } from '../../utils/validators/validatorServices';
import { forgotPassword, errorNotification, successNotification } from '../../utils/httpServices/httpService';
import { Link, useNavigate } from 'react-router-dom';
import key from "../../Assets/key.png"

export default function ForgotPassword() {

  const development = process.env.REACT_APP_DEVELOPMENT_ENV;

  const [email, setEmail] = useState('');

  const [emailValidators, setEmailValidators] = useState(false);

  const navigate = useNavigate();

  function settingValue(value) {
    setEmail(value);
  }


  async function submit() {
    setEmailValidators(emailValidator(email))
    if (!emailValidator(email)) {
      const data = {
        email,
        "development" : development === "false" ? false : true
      }
      console.log("data", data);
      try {
        const resp = await forgotPassword(data);
        console.log(" login response data=> ", resp);
          successNotification("Email sent successfully");
          setEmail("")
        // navigate("/");
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("validation failed");
    }


  }
  return (
    <div className='page_wrap'>
      <div className="container">
        <div className="login forget_password">
          <div className="login__forget_details">
             <div className='icon mb-3'>
                <img src={key}  className='aboutIc' alt="icon" />
             </div>
            <h4 className='login__heading-text'>Forgot Password?</h4>
            <p className='login__text-desc'>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p>
            <div class="mb-3">
              {/* <label for="exampleFormControlInput1" class="form-label">Email address</label> */}
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="emal@example.com"
                name='email' value={email} onChange={(e) => settingValue(e.target.value)} onBlur={() => setEmailValidators(emailValidator(email))} />
              {emailValidators ? (<div className="validation_message">*Valid email address require </div>) :
                (<></>)}
            </div>
            <button className='theme_btn rounded-2 w-100' onClick={submit}>Send Reset Link</button>
            <div className='text-center mt-3'>
              <Link className='text-muted' to="/login"><i class="fa-solid fa-arrow-left"></i> Back to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
