import * as React from "react";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import Sidebar from "../../../components/Sidebar/Sidebar";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import userImg from '../../../Assets/user1 1.png'
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ReactApexChart from 'react-apexcharts';
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";


// Star Ratings
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a8700' : '#308fe8',
    },
}));

export default function Performance() {
    const series = [{
        name: 'Rating',
        data: [1, 2, 3, 4, 5, 4, 4, 3, 2, 1, 3, 1]
    }];

    const options = {
        chart: {
            height: 250,
            type: 'area',
            background: '#fff',
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"],
        },

        colors: ['#2D9CDB'],
        fill: {
            colors: ['#2D9CDB'],
            gradient: {
                enabled: true,
                shadeIntensity: 0.5,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [0, 80, 100],
            },
        }
    };
    // Area chart end
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="layoutWrap">
            <div className="shadow sideWrap" id="myDIV">
                <Sidebar />
            </div>
            <div className="fullWidhtWrap">
                <ProviderNav />
                <div className="doctorWrap">
                    <section className="hero-container pb-0">
                        <div className="container">
                            <NavigationBar />
                            <BreadCrumb
                                Navitem={"Performance"}
                            />
                            <div className="perfTabsWrap">
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value}>
                                        <Box className="tabHeader">
                                            <TabList onChange={handleChange}>
                                                <Tab label="Overview" value="1" />
                                                <Tab label="Patient Feedback" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel  value="1">
                                            <div className="overViewWrap">
                                                <div className="clinicReport">

                                                </div>
                                                <div className="reviewOverView">
                                                    <div className="row">
                                                        <div className="col-lg-5 col-md-6 col-sm-12">
                                                            <div className="overAll">
                                                                <h5><strong>Overall Rating</strong><span>(230 Ratings)</span></h5>
                                                                <div className="starGroup d-flex align-items-center">
                                                                    <span>4.5</span>
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarHalfIcon />
                                                                </div>
                                                                <div className="starWrap">
                                                                    <div className="star">
                                                                        <span className="title">Location</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={80} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Comfort</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={60} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Staff</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={40} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Facilities</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={20} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Cleanliness</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={10} />
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7 col-md-6 col-sm-12">
                                                            <div className="ratingTrend">
                                                                <ReactApexChart options={options} series={series} type="area" height={350} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <div className="patientReview">
                                                <div className="reviewOverView">
                                                    <div className="row">
                                                        <div className="col-lg-5 col-md-6 col-sm-12">
                                                            <div className="overAll">
                                                                <h5><strong>Overall Rating</strong><span>(230 Ratings)</span></h5>
                                                                <div className="starGroup d-flex align-items-center">
                                                                    <span>4.5</span>
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarIcon />
                                                                    <StarHalfIcon />
                                                                </div>
                                                                <div className="starWrap">
                                                                    <div className="star">
                                                                        <span className="title">Location</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={80} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Comfort</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={60} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Staff</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={40} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Facilities</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={20} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className="star">
                                                                        <span className="title">Cleanliness</span>
                                                                        <Box sx={{ flexGrow: 1 }}>
                                                                            <BorderLinearProgress variant="determinate" value={10} />
                                                                        </Box>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7 col-md-6 col-sm-12">
                                                            <div className="ratingTrend">
                                                                <ReactApexChart options={options} series={series} type="area" height={350} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reviews">
                                                    <div className="fltr d-flex mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="mb-2">Select Doctor</label>
                                                            <select name="" className="form-select" id="">
                                                                <option value="">Dr.Vikram Parihar</option>
                                                                <option value="">Dr.Pawan Tiwari</option>
                                                                <option value="">Dr.Kuleep Mangat</option>
                                                                <option value="">Dr.JL Bassi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="reviewBox">
                                                        <div className="rating">
                                                            <StarIcon className="starIC" />
                                                            <span>5</span>
                                                        </div>
                                                        <div className="reviewText">
                                                            <p>Dr. Vikram Parihar is an exceptional dentist. His professionalism, expertise, and caring approach make dental visits comfortable and stress-free. Highly recommended for dental care.</p>
                                                            <div className="reviewBy">
                                                                <img src={userImg} alt="person" />
                                                                <div className="txtInfo">
                                                                    <h5>Iliana Sahiba</h5>
                                                                    <span>4th April 2023</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reviewBox">
                                                        <div className="rating">
                                                            <StarIcon className="starIC" />
                                                            <span>4.5</span>
                                                        </div>
                                                        <div className="reviewText">
                                                            <p>Dr. Vikram Parihar is an exceptional dentist. His professionalism, expertise, and caring approach make dental visits comfortable and stress-free. Highly recommended for dental care.</p>
                                                            <div className="reviewBy">
                                                                <img src={userImg} alt="person" />
                                                                <div className="txtInfo">
                                                                    <h5>Iliana Sahiba</h5>
                                                                    <span>4th April 2023</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reviewBox">
                                                        <div className="rating">
                                                            <StarIcon className="starIC" />
                                                            <span>5</span>
                                                        </div>
                                                        <div className="reviewText">
                                                            <p>Dr. Vikram Parihar is an exceptional dentist. His professionalism, expertise, and caring approach make dental visits comfortable and stress-free. Highly recommended for dental care.</p>
                                                            <div className="reviewBy">
                                                                <img src={userImg} alt="person" />
                                                                <div className="txtInfo">
                                                                    <h5>Iliana Sahiba</h5>
                                                                    <span>4th April 2023</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </div>
                        </div>
                    </section>
                </div>
                <ProviderFooter />
            </div>
        </div>
    );
}
