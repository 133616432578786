

import React from 'react';
import './Loader.scss';

const AbsoluteLoader = ({ loading = false }) => {
    return (
        loading &&
        <div className="absoluteLoader">
            <div className="spinner"></div>
        </div>

    );
};

export default AbsoluteLoader;
