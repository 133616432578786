import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, ListItem, Paper, TextField } from "@mui/material";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import "./EditServices.scss";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import {
  editDentistProfile,
  getDentistProfileByIdService,
  successNotification,
} from "../../utils/httpServices/httpService";
import Services from "../Services/Services";
import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function EditServices({
  open5,
  handleClose5,
  editProfile_id,
  servicesList,
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // --chips--
  const [getServicesData, setGetServicesData] = useState([]);
  // const [servicesList, setServicesList] = useState([])
  const [services1, setServices] = React.useState([]);

  const [serData, setSerData] = useState("");
  const [val, setVal] = React.useState("");

  // console.log('services',services)
  const getServices = (services) => {
    setServices(services);
  };

  const serviceHandler = (e) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    console.log("gdvjhadvashjhasdahdfh", val);
    setSerData(e.target.value);
    setVal(e.target.value);
  };

  const profile_step2 = useFormik({
    initialValues: {
      services: [],
    },
    onSubmit: (values) => {
      console.log("service form", values);
      editProviderProfile(values, editProfile_id);
    },
  });

  const handleSave1 = () => {
    // Add your save logic here
    // For now, let's just close the modal
    handleClose5();
  };

  const [chipData, setChipData] = React.useState([]);

  const [newChipLabel, setNewChipLabel] = React.useState("");

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    let data = chipData.filter((chip) => chip.key !== chipToDelete.key);
    console.log("after delete data", data);
    profile_step2.setFieldValue("services", data);
  };

  const handleAddChip = () => {
    if (newChipLabel.trim() !== "") {
      const newChip = {
        key: chipData.length,
        label: newChipLabel.trim(),
      };
      setChipData([...chipData, newChip]);
      profile_step2.setFieldValue("services", [...chipData, newChip]);
      setNewChipLabel("");
    }
  };

  async function getDentistProfileById(id) {
    console.log("inside function id", id);
    try {
      const resp = await getDentistProfileByIdService(id);
      console.log("dentist profile detail by id", resp);
      if (resp?.code === 200) {
        let data = resp?.data?.services.map(
          (ele, i) => (ele = { key: i, label: ele })
        );
        // console.log("data",data)
        // profile_step2.setFieldValue("services",data);
        // setChipData(data);
        // console.log(data, 'dbjksdfjkbfsdjkbfjksdf')
        // setGetServicesData(data)
        resp?.data?.services.map((val) => {
          setGetServicesData((prev) => {
            // Check if getServicesData already contains the label
            if (!prev.includes(val)) {
              // If not present, add it to the array
              return [...prev, val];
            } else {
              // If already present, return the previous state
              return prev;
            }
          });
        });
      }
    } catch (error) {
      console.log("upload profile image error", error);
    }
  }

  React.useEffect(() => {
    console.log("user effect trigger");
    if (editProfile_id !== null) getDentistProfileById(editProfile_id);
  }, [editProfile_id]);

  async function editProviderProfile(services, id) {
    // console.log("services data",services.services)
    // let services_values = [];
    // services.services.map((ele) => {
    //     services_values.push(ele.label)
    // })
    const data = {
      services: services1,
    };
    console.log("profile object", data);
    try {
      const resp = await editDentistProfile(data, id);
      console.log(" provider profile response", resp);
      if (resp?.code === 200) {
        successNotification("Profile Editted Successfully");
        handleSave1();
      }
    } catch (err) {
      // alert(err);
    }
  }

  return (
    <div>
      <Modal
        open={open5}
        onClose={handleClose5}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-container"
      >
        <Box className="edit_Service-list modal-content">
          <span className="cross_mdl">
            <i class="fa-solid fa-xmark"></i>
          </span>

          <div>
            <div className="service_input">
              <Services
                setServicesToParent={getServices}
                serData={serData}
                getServicesData={getServicesData}
                key={editProfile_id}
              />
            </div>

            <div className="select_cntner">
              <Select
                onChange={serviceHandler}
                value={val}
                variant="outlined"
                displayEmpty
                style={{ marginTop: "10px", width: "100%" }}
              >
                <MenuItem value="" disabled>
                  Select Services
                </MenuItem>
                {servicesList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 2,
                                m: 0,
                            }}
                            component="ul"
                        >
                            {chipData.map((data) => {
                                let icon;

                                if (data.label === 'React') {
                                    icon = <TagFacesIcon />;
                                }

                                return (
                                    <ListItem key={data.key}>
                                        <Chip

                                            icon={icon}
                                            label={data.label}
                                            onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </Paper>
                        <div className='mt-3 d-flex justify-content-center align-items-center'>
                            <TextField
                                label="Add New Service"
                                variant="outlined"
                                value={newChipLabel}
                                onChange={(e) => setNewChipLabel(e.target.value)}
                                sx={{ marginRight: 2 }}
                            />
                            <button className='btn btn-secondary px-2 rounded py-2' onClick={handleAddChip}>
                                Add
                            </button>
                        </div> */}
          </div>

          <div className="mt-3 d-flex justify-content-end align-items-end">
            <button
              className="theme_btn rounded-2"
              onClick={() => profile_step2.handleSubmit()}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
