import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import chatUser01 from "../../../Assets/chatUser2.jpg";
import doctor01 from "../../../Assets/profile01.jpg";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import NavigationBar from "../../../components/NavigationCont/NaviagtionBar";
import ProviderFooter from "../../../components/ProviderFooter/ProviderFooter";
import ProviderNav from "../../../components/ProviderNav/ProviderNav";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./Dashboard.scss";

import StarIcon from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { DatePicker, Space } from "antd";
import userImg from "../../../Assets/user1 1.png";
import dayjs from 'dayjs';
// import { DatePicker, Space } from "antd";

import { useSelector } from "react-redux";

import {
  getDashboardTopCard_pendingAppointment,
  getDashboardTopCard_totalAppointment,
  getDashboardTopCard_totalDues,
  getDashboardTopCard_totalPatients,
  getDashboard_CenterCharData,
  getDashboard_UpcomingAppointments,
  getDashboard_appointmentRequest,
  getDashboard_approveOrRejectAppontment,
  getDashboard_availableDentist,
  getDashboard_averageRatingForGraph,
  getDashboard_patientsFeedback,
  getDashboard_rating,
  getDentistListingService,
  successNotification,
} from "../../../utils/httpServices/httpService";

import moment from "moment/moment";
import { ResheduleApp } from "../../../components/ResheduleApp/ResheduleApp";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import {

  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a8700" : "#308fe8",
  },
}));

function generateDayWiseTimeSeries(startTime, count, range) {
  const timeSeriesData = [];
  for (let i = 0; i < count; i++) {
    timeSeriesData.push({
      x: new Date(startTime + i * 24 * 60 * 60 * 1000).toISOString(), // Convert timestamp to ISO string
      y: Math.floor(Math.random() * (range.max - range.min + 1)) + range.min,
    });
  }
  return timeSeriesData;
}

export default function Dashboard() {
  const profile_image_base_url = process.env.REACT_APP_USER_PROFILE_IMAGE;

  const state = useSelector((state) => state);
  const [branch_id, setBranch_id] = useState(null);
  const [initialState, setPageState] = useState(
    localStorage.getItem("selected_Branch")
  );

  useEffect(() => {
    if (state.getClinicBrances?.data?.data) {
      setBranch_id(state.getClinicBrances?.data?.data[initialState]._id);
      console.log("dashboard branch id ", branch_id);
    }
  }, [branch_id, initialState, state]);

  const updatePageState = (index) => {
    setPageState(index);
    localStorage.setItem("selected_Branch", index);
  };

  const { RangePicker } = DatePicker;

  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');

  const [dateVal, setDateVal] = useState([startOfMonth, endOfMonth]);
  const [buttonVal, setButtonVal] = useState("");

  const [openCancelModal , setOpenCancelModal] = useState(false)

  const handleCloseCancelModal = ()=>{
    setOpenCancelModal(false)
  }

  const handleOpenCancelModal = ()=>{
    setCancelReason('')
    setOpenCancelModal(true)
    
  }

  const [cancelReason , setCancelReason ] = useState('')

  const [cancelAppointmentId , setCancelAppointmentId] =useState(null)


  const dateHandler = (value) => {
    setDateVal(value);
    setButtonVal("");
    console.log(value);
  };

  const buttonHandler = () => {
    setDateVal([]);
    setButtonVal("today");
  };

  function filterByDate() {
    if (dateVal && dateVal.length === 2) {
      const firstDate = dateVal[0].$d;
      const secondDate = dateVal[1].$d;
      let start = new Date(firstDate);
      let end = new Date(secondDate);

      let added_start_date = start.getDate();
      let added_end_date = end.getDate() + 1;
      start.setDate(added_start_date); // Modify the cloned date
      end.setDate(added_end_date); // Modify the cloned date

      start.setHours(0, 0, 0, 0); // Set time to 00:00:00
      end.setHours(0, 0, 0, 0); // Set time to 00:00:00

      // console.log("new time to ISO string: ", clonedEndtime.toISOString());

      return { start_time: start.toISOString(), end_time: end.toISOString() };
    }
  }
  /////////////////////////////////////////////////////////////////////////////////

  const [topCard_totalAppointment, setTopCard_totalAppointment] = useState("");
  const [topCard_totalPatients, setTopCard_totalPatients] = useState("");
  const [topCard_pendingAppointment, setTopCard_pendingAppointment] =
    useState("");
  const [topCard_totalDues, setTopCard_totalDues] = useState("");

  useEffect(() => {
    if (branch_id !== undefined && branch_id !== null) {
      topCard_totalAppointmentFunc(branch_id);
      topCard_totalPatientsFunc(branch_id);
      topCard_pendingAppointmentFunc(branch_id);
      topCard_totalDuesFunc(branch_id);
    }
  }, [branch_id]);

  async function topCard_totalAppointmentFunc(id) {
    if (id !== undefined && id !== "") {
      try {
        const resp = await getDashboardTopCard_totalAppointment(id);
        console.log("top card total appointment", resp);
        if (resp?.code === 200) {
          setTopCard_totalAppointment(resp.data);
        }
      } catch (err) {
        console.log("top card total appointment", err);
      }
    }
  }

  async function topCard_totalPatientsFunc(id) {
    if (id !== undefined && id !== "") {
      try {
        const resp = await getDashboardTopCard_totalPatients(id);
        console.log("top card toalPatients", resp);
        if (resp?.code === 200) {
          setTopCard_totalPatients(resp.data);
        }
      } catch (err) {
        console.log("top card toalPatients", err);
      }
    }
  }

  async function topCard_pendingAppointmentFunc(id) {
    if (id !== undefined && id !== "") {
      try {
        const resp = await getDashboardTopCard_pendingAppointment(id);
        console.log("top card pending appointments", resp);
        if (resp?.code === 200) {
          setTopCard_pendingAppointment(resp.data);
        }
      } catch (err) {
        console.log("top card pending appointments", err);
      }
    }
  }

  async function topCard_totalDuesFunc(id) {
    if (id !== undefined && id !== "") {
      try {
        const resp = await getDashboardTopCard_totalDues(id);
        console.log("top card total dues", resp);
        if (resp?.code === 200) {
          setTopCard_totalDues(resp.data);
        }
      } catch (err) {
        console.log("top card total dues", err);
      }
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////

  // function timeConversionForFilter(day){
  //     const startInputTime = new Date();
  //     let todayEndtime = new Date();
  //     const endInputTime = new Date(todayEndtime.setDate(todayEndtime.getDate() + day));

  //     startInputTime.setHours(0, 0, 0, 0);
  //     endInputTime.setHours(0, 0, 0, 0);

  //     console.log("new time", startInputTime,endInputTime)
  //     console.log("new time to ISO string " , startInputTime.toISOString(), endInputTime.toISOString())

  //     return {start_date:startInputTime.toISOString() ,end_date:endInputTime.toISOString()}
  // }
  function timeConversionForFilter(day) {
    let todayEndtime = new Date(); // Current date and time
    let clonedEndtime = new Date(todayEndtime); // Create a copy of current date and time
    let added_date = clonedEndtime.getDate() + day;
    clonedEndtime.setDate(added_date); // Modify the cloned date

    clonedEndtime.setHours(0, 0, 0, 0); // Set time to 00:00:00

    console.log("new time to ISO string: ", clonedEndtime.toISOString());

    return { end_time: clonedEndtime.toISOString() };

    // const startInputTime = new Date();
    // let todayEndtime = new Date();
    // const endInputTime = new Date(todayEndtime.setDate(todayEndtime.getDate() + day));

    // startInputTime.setHours(0, 0, 0, 0);
    // endInputTime.setHours(0, 0, 0, 0);

    // console.log("new time", startInputTime,endInputTime)
    // console.log("new time to ISO string " , startInputTime.toISOString(), endInputTime.toISOString())

    // return {start_time:startInputTime.toISOString() ,end_time:endInputTime.toISOString()}
  }

  //////////////////////// UPCOMING APPOINTMENTS ////////////////////////////////

  const [upcomingAppointment_date, setUpcomingAppointments_date] = useState({
    start_date: timeConversionForFilter(1).start_date,
    end_date: timeConversionForFilter(0).end_date,
  });

  const [upcommingAppointment_data, setUpCommingAppointment_data] = useState(
    []
  );

  const [filterName, setFilterName] = useState("today");

  function filterFunction(event) {
    console.log("event", event.target.value);
    const e = event.target.value;
    if (e === "today") {
      setFilterName("today");
      setUpcomingAppointments_date({
        start_date: timeConversionForFilter(1).start_date,
        end_date: timeConversionForFilter(0).end_date,
      });
    } else if (e === "last1week") {
      setFilterName("last1week");
      setUpcomingAppointments_date({
        start_date: timeConversionForFilter(-7).start_date,
        end_date: timeConversionForFilter(-7).end_date,
      });
    } else {
      setFilterName("yesterday");
      setUpcomingAppointments_date({
        start_date: timeConversionForFilter(-1).start_date,
        end_date: timeConversionForFilter(-1).end_date,
      });
    }
  }

  useEffect(() => {
    getDashboard_UpcomingAppointmentsFunc(branch_id);
  }, [dateVal, buttonVal, branch_id]);

  async function getDashboard_UpcomingAppointmentsFunc(id) {
    if (id !== undefined && id !== null) {
      let start_date = "";
      let end_date = "";

      if (buttonVal !== "") {
        start_date = timeConversionForFilter(0).end_time;
        end_date = timeConversionForFilter(1).end_time;
      } else if (dateVal != []) {
        let getDate = filterByDate();
        start_date = getDate?.start_time;
        end_date = getDate?.end_time;
      }
      let obj = {
        branch_id: id,
        start_date: start_date,
        end_date: end_date,
      };
      try {
        const resp = await getDashboard_UpcomingAppointments(obj);
        console.log("upcomming appointment data", resp);
        if (resp?.code === 200) {
          setUpCommingAppointment_data(resp.data);
        }
      } catch (err) {
        console.log("upcomming appointment data error", err);
      }
    }
  }

  //////////////////////////// END UPCOMMING APPOINTMENTS //////////////////////////////

  //////////////////////////// REQUEST APPOINTMENTS //////////////////////////////

  const [providerList, setProviderList] = useState([]);
  const [selectedProvider_id, setSelectedProvider] = useState(null);
  async function getProviderListById(id) {
    if (id !== undefined && id !== null) {
      let obj = {
        name: "",
        branch_id: id,
        sort: -1,
        limit: 3,
        offset: 0,
      };
      try {
        const resp = await getDentistListingService(obj);
        console.log("resp for dentist list", resp);
        if (resp?.code === 200) {
          setProviderList(resp.data);
          if (resp.data.length > 0) setSelectedProvider("");
          setReview_dentist_id(resp?.data[0]?._id);
        }
      } catch (err) {
        console.log("dentist list error", err);
      }
    } else return;
  }

  const [resquestedAppointmentList, setRequestedAppointmentList] = useState([]);

  useEffect(() => {
    getProviderListById(branch_id);
    getDashboard_ratingFun(branch_id);
    getDashboard_averageRatingForGraphFun(branch_id);
  }, [branch_id]);

  

  useEffect(() => {
    getDashboard_appointmentRequestFunc(selectedProvider_id);
  }, [selectedProvider_id, branch_id]);

  function selectedProvider(event) {
    const e = event.target.value;
    setSelectedProvider(e);
  }

  async function getDashboard_appointmentRequestFunc(id) {
    if (id !== undefined && id !== null) {
      let obj = {
        branch_id: branch_id,
        dentist_id: id,
        limit: 5,
        offset: 0,
        sort: -1,
      };
      try {
        const resp = await getDashboard_appointmentRequest(obj);
        console.log("upcomming appointment Request", resp);
        if (resp?.code === 200) {
          setRequestedAppointmentList(resp.data);
          successNotification(resp?.message);
        }
      } catch (err) {
        console.log("upcomming appointment Request error", err);
      }
    }
  }

  async function approveOrRejectAppointmentFunc(id, status) {
    let obj = {
      appointment_id: id,
      status: status,
    };

    if(status === 'rejected'){
      obj.cancel_reason = cancelReason
    }

    try {
      const resp = await getDashboard_approveOrRejectAppontment(obj);
      console.log("approve and reject", resp);
      if(status === "accepted"){
        toast.success("Appointment accepted successfully")
      }else if(status === "rejected"){
        toast.success("Appointment rejected successfully")
      }
      
      if (resp?.code === 200) {
        getDashboard_appointmentRequestFunc(selectedProvider_id);
      }
     handleCloseCancelModal()
    } catch (err) {
      console.log("approve reject error", err);
    }
  }

  /////////////////////// RATING ///////////////////////////

  const [rating, setRating] = useState(null);

  async function getDashboard_ratingFun(id) {
    if (id !== undefined && id !== null) {
      try {
        const resp = await getDashboard_rating(id);
        console.log("dashboard rating", resp);
        if (resp?.code === 200) {
          setRating(resp.data);
        }
      } catch (err) {
        console.log("dashboard rating error", err);
      }
    }
  }

  const [averageRatingGraph, setAverageRatingGraph] = useState([]);

  async function getDashboard_averageRatingForGraphFun(id) {
    if (id !== undefined && id !== null) {
      try {
        const resp = await getDashboard_averageRatingForGraph(id);
        console.log("dashboard graph", resp);
        if (resp?.code === 200) {
          setAverageRatingGraph(resp.data);
        }
      } catch (err) {
        console.log("dashboard graph", err);
      }
    }
  }

  const [review_dentist_id, setReview_dentist_id] = useState(null);

  useEffect(() => {
    getDashboard_patientsFeedbackFun(review_dentist_id);
  }, [review_dentist_id]);

  const setDentist_review = (e) => setReview_dentist_id(e.target.value);

  const [feedback_data, setFeedback_data] = useState([]);

  async function getDashboard_patientsFeedbackFun(id) {
    if (id !== undefined && id !== null) {
      let obj = {
        dentist_id: id,
        is_review_given: true,
        limit: 5,
        offset: 0,
        sort: -1,
      };
      try {
        const resp = await getDashboard_patientsFeedback(obj);
        console.log("dashboard feedback", resp);
        if (resp?.code === 200) {
          setFeedback_data(resp.data);
        }
      } catch (err) {
        console.log("dashboard feedback", err);
      }
    }
  }

  const [available_data, setAvailble_data] = useState([]);

  useEffect(() => {
    getDashboard_availableDentistFunc(branch_id);
    getDashboard_CenterCharDataFunc(branch_id);
  }, [branch_id]);

  async function getDashboard_availableDentistFunc(id) {
    if (id !== undefined && id !== null) {
      try {
        const resp = await getDashboard_availableDentist(id);
        console.log("dashboard available dentist", resp);
        if (resp?.code === 200) {
          setAvailble_data(resp.data);
        }
      } catch (err) {
        console.log("dashboard available dentist", err);
      }
    }
  }

  ///////////////// END RATING

  // line-charts-

  ////////////////////// CENTER CHART DATA START //////////////////////////////

  async function getDashboard_CenterCharDataFunc(id) {
    if (id !== undefined && id !== null) {
      try {
        const resp = await getDashboard_CenterCharData(id);
        console.log("dashboard center CHART DATA", resp);
        if (resp?.code === 200) {
          console.log(
            "dashboard center CHART DATA sorted",
            resp.data.sort((a, b) => a.month - b.month)
          );
          // setAvailble_data(resp.data);
          if (resp.data.length > 0) {
            let sorted_data = resp.data.sort((a, b) => a.month - b.month);
            let visited = [];
            let total_count = [];
            let Date_data = [];
            let today_date = new Date();

            sorted_data.map((ele) => {
              visited.push(ele?.visited_count);
              total_count.push(ele?.total_count);
              let set_date = moment(
                today_date.setFullYear(ele?.year, ele?.month - 1, 1)
              ).toISOString();
              Date_data.push(set_date);
            });
              console.log("🚀 ~ sorted_data.map ~ visited:", visited, total_count)
            setChartData({
              series: [
                {
                  name: "Visited",
                  data: visited,
                },
                {
                  name: "Total Count",
                  data: total_count,
                },
              ],
              options: {
                chart: {
                  height: 350,
                  type: "area",
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  type: "datetime",
                  categories: Date_data,
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              },
            });
          }
        }
      } catch (err) {
        console.log("dashboard center chart data", err);
      }
    }
  }

  ////////////////////// CENTER CHART DATA ENDS //////////////////////////////

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Visited",
        data: [],
      },
      {
        name: "Total Count",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [new Date().getFullYear()],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  // --Dashboard -Header--

  // --Performance---

  const series = [
    {
      name: "Rating",
      data: averageRatingGraph,
    },
  ];

  const options = {
    chart: {
      height: 250,
      type: "area",
      background: "#fff",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    colors: ["#2D9CDB"],
    fill: {
      colors: ["#2D9CDB"],
      gradient: {
        enabled: true,
        shadeIntensity: 0.5,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 80, 100],
      },
    },
  };
  // Area chart end
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showModal, setShowModal] = useState(false);
  
  const [ apppointmentDetails ,setAppoinmentDetails] = useState({})

  const handleOpenModal = (apppointmentDetail) => {
    setShowModal(true);
    setAppoinmentDetails(apppointmentDetail)
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleConfirmReshedule = ()=>{
   
    getDashboard_appointmentRequestFunc(selectedProvider_id);
  }

  return (
    <>
      <ProviderNav />
      <div className="layoutWrap">
        <div className="shadow sideWrap" id="myDIV">
          <Sidebar
            initialState={initialState}
            triggerParentUpdate={updatePageState}
          
          />
        </div>
        <div className="fullWidhtWrap">
          <div className="doctorWrap">
            <section className="pb-2">
              <div className="container">
                <NavigationBar />
                <BreadCrumb Navitem={"Dashboard"} />
                <div className="page-content">
                  <div className="row">
                    <div className="col col-12 col-md-6 col-xl-3">
                      <div className="card animated fadeInUp delay-02s shadow border-0">
                        <div className="card-body p-0">
                          <div className="p-2 d-flex justify-content-between ">
                            <div class="card-icon bg-color-primary rounded ">
                              <i class="fa-solid text-white fs-4 fa-clipboard-user"></i>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                              <h6 class="float-end">Appointments</h6>
                              <h5 class="text-bold float-end mb-0">
                                {" "}
                                {topCard_totalAppointment !== ""
                                  ? topCard_totalAppointment
                                  : "--"}
                              </h5>
                            </div>
                          </div>

                          {/* <ReactApexChart options={chartData1.options}
                                                        series={chartData1.series}
                                                        type="area"
                                                        height={90} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-6 col-xl-3">
                      <div className="card animated fadeInUp delay-02s shadow border-0">
                        <div className="card-body p-0">
                          <div className="p-2 d-flex justify-content-between ">
                            <div class="card-icon bg-light-pink rounded ">
                              <i class="fa-solid text-white fs-4 fa-hospital-user"></i>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                              <h6 class="float-end">Total Patients</h6>
                              <h6 className="fw-bold text-green">
                                {topCard_totalPatients !== ""
                                  ? topCard_totalPatients + " till today"
                                  : "--"}{" "}
                                <span className="fs-6"></span>
                              </h6>
                            </div>
                          </div>

                          {/* <ReactApexChart options={chartData2.options}
                                                        series={chartData2.series}
                                                        type="area"
                                                        height={90} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-6 col-xl-3">
                      <div className="card animated fadeInUp delay-02s shadow border-0">
                        <div className="card-body p-0">
                          <div className="p-2 d-flex justify-content-between ">
                            <div class="card-icon bg-black rounded ">
                              <i class="fa-solid text-white fs-4 fa-address-card"></i>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                              <h6 class="float-end">Pending Appointments</h6>
                              <h5 class="text-bold float-end mb-0">
                                {" "}
                                {topCard_pendingAppointment !== ""
                                  ? topCard_pendingAppointment
                                  : "--"}
                              </h5>
                            </div>
                          </div>

                          {/* <ReactApexChart options={chartData3.options}
                                                        series={chartData3.series}
                                                        type="area"
                                                        height={90} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col col-12 col-md-6 col-xl-3">
                      <div className="card animated fadeInUp delay-02s shadow border-0">
                        <div className="card-body p-0">
                          <div className="p-2 d-flex justify-content-between ">
                            <div class="card-icon bg-green rounded ">
                              <i class="fa-solid text-white fs-4 fa-money-bill-wave"></i>
                            </div>
                            <div class="d-flex flex-column align-items-end">
                              <h6 class="float-end">Pending Dues</h6>
                              <h5 class="text-bold float-end mb-0">
                                {" "}
                                {topCard_totalDues !== ""
                                  ? topCard_totalDues
                                  : "--"}
                              </h5>
                            </div>
                          </div>
                          {/* 
                                                    <ReactApexChart options={chartData4.options}
                                                        series={chartData4.series}
                                                        type="area"
                                                        height={90} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-content charts-section">
                  <div className="row g-md-0 g-4">
                    <div className="col-md-8 col-lg-8 col-xl-7 col-sm-12 ">
                      <div className="line_Chart ">
                        <ReactApexChart
                          options={chartData.options}
                          series={chartData.series}
                          type="area"
                          height={350}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-xl-5 col-sm-12 ">
                      <div className="upcoming_apptmnt">
                        <div className="card border-0 shadow-sm">
                          <div className="card-header d-flex align-content-center justify-content-between flex-wrap gap-3">
                            <div className="">
                              <h4 className="header_text fw-semibold mb-2">
                                Upcoming Appointments
                              </h4>
                              <p className="fw-normal fw-light mb-0">
                                {" "}
                                {Array.isArray(upcommingAppointment_data)
                                  ? upcommingAppointment_data.length > 1
                                    ? `${upcommingAppointment_data.length} Appointments`
                                    : `${upcommingAppointment_data.length} Appointment`
                                  : "--"}
                              </p>
                            </div>
                            <div className="select_day">
                              {/* <select class="form-select" onChange={(e) => filterFunction(e)} aria-label="Default select example">
                                                            <option selected value='today'>Today</option>
                                                            <option value="yesterday">Yesterday</option>
                                                            <option value="last1week">Last One Week</option>
                                                            </select> */}
                              <div className="filter-data">
                                <button
                                  className={`btn btn-sm ${
                                    buttonVal === "today" ? "btn-active" : ""
                                  } me-2`}
                                  onClick={buttonHandler}
                                >
                                  Today
                                </button>
                                <Space direction="vertical" size={12}>
                                  <RangePicker
                                    value={dateVal}
                                    onChange={dateHandler}
                                  />
                                  {/* <RangePicker showTime />
                    <RangePicker picker="week" />
                    <RangePicker picker="month" />
                    <RangePicker picker="quarter" />
                    <RangePicker
                      picker="year"
                      id={{
                        start: "startInput",
                        end: "endInput",
                      }}
                      onFocus={(_, info) => {
                        console.log("Focus:", info.range);
                      }}
                      onBlur={(_, info) => {
                        console.log("Blur:", info.range);
                      }}
                    /> */}
                                </Space>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            {upcommingAppointment_data.length !== 0 ? (
                              upcommingAppointment_data.map((ele) => (
                                <div className="d-flex align-items-center mb-4 px-3 py-3 px-lg-4 bg-light ">
                                  <h6 className="fw-semibold mb-0 text-black f-none pe-3">
                                    {" "}
                                    {ele?.start_time &&
                                    ele?.start_time !== null &&
                                    ele?.start_time !== ""
                                      ? moment(
                                          new Date(ele?.start_time)
                                        ).format("hh:mm A")
                                      : "--"}
                                  </h6>
                                  <div className="border-start ps-3 flex-grow-1">
                                    <h5>
                                      {ele?.user_details?.full_name
                                        ? ele?.user_details?.full_name
                                        : ""}
                                    </h5>
                                    <h6 className="mb-0">
                                      Reason{" "}
                                      <span className="fw-normal text-primary">
                                        {ele?.reason && ele?.reason !== ""
                                          ? ele?.reason
                                          : "--"}
                                      </span>
                                    </h6>
                                  </div>
                                  <div className="">
                                    <i class="fa-solid fa-chevron-right"></i>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                            {upcommingAppointment_data.length === 0 ? (
                              <div
                                style={{ color: "red", "text-aling": "center" }}
                              >
                                *No data to be found
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* <div className='d-flex align-items-center mb-4 px-3 py-3 px-lg-4 bg-light '>
                                                            <h6 className='fw-semibold mb-0 text-black f-none pe-3'>10:00 AM</h6>
                                                            <div className='border-start ps-3 flex-grow-1'>
                                                                <h5>Michael Rodriguez</h5>
                                                                <h6 className='mb-0'>Reson <span className='fw-normal text-primary'>teeth Whitening</span></h6>
                                                            </div>
                                                            <div className=''>
                                                                <i class="fa-solid fa-chevron-right"></i>
                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-content mb-0">
                  <div className="row">
                    <div className="col-md-8 col-lg-7 col-xl-7 col-sm-6 col-12">
                      <div className="card shadow-sm border-0">
                        <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                          <h4 className="header_text mb-0">
                            Appointment Request
                          </h4>
                          <div className="d-flex align-items-center gap-4">
                            <select
                              className="form-select"
                              onChange={(e) => selectedProvider(e)}
                              aria-label="Default select example"
                            >
                              <option value="">Select a dentist</option>{" "}
                              {/* Adding the default option */}
                              {providerList.length !== 0 ? (
                                providerList.map((ele, i) => (
                                  <option key={ele._id} value={ele._id}>
                                    {ele.full_name ? ele.full_name : ""}
                                  </option>
                                ))
                              ) : (
                                <></>
                              )}
                            </select>

                            {/* <div class="dropdown">
                                                            <button class="btn  btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                View
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                <li><button class="dropdown-item" type="button">View All</button></li>
                                                                <li><button class="dropdown-item" type="button">Edit</button></li>
                                                                <li><button class="dropdown-item" type="button">Download</button></li>
                                                                <li><button class="dropdown-item" type="button">Print</button></li>
                                                            </ul>
                                                        </div> */}
                          </div>
                        </div>
                        <div className="pt-0 card-body mb-3">
                          <div className="table-responsive ">
                            <table
                              className="table py-3 mb-0"
                              role="grid"
                              data-toggle="appointment_admin"
                            >
                              <thead className="bg-soft-primary">
                                <tr className="text-dark">
                                  <th className="bg-white border-bottom">
                                    No.
                                  </th>
                                  <th className="bg-white border-bottom">
                                    Names
                                  </th>
                                  <th className="bg-white border-bottom">
                                    Date
                                  </th>
                                  <th className="bg-white border-bottom">
                                    Reason
                                  </th>
                                  <th className="bg-white border-bottom">
                                    Type
                                  </th>
                                  <th className="bg-white border-bottom">
                                    Staus
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {resquestedAppointmentList.length > 0 ? (
                                  resquestedAppointmentList.map((ele, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>
                                        <div className="d-flex align-items-center gap-3">
                                          <img
                                            src={
                                              ele?.user_details
                                                ?.profile_image &&
                                              ele?.user_details
                                                ?.profile_image !== ""
                                                ? profile_image_base_url +
                                                  ele?.user_details
                                                    ?.profile_image
                                                : chatUser01
                                            }
                                            className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                            alt="icon"
                                          />
                                          <h6 className="mb-0">
                                            {ele?.user_details?.full_name
                                              ? ele?.user_details?.full_name
                                              : ""}
                                          </h6>
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        {ele?.start_time !== "" &&
                                        ele?.start_time !== null
                                          ? new Date(
                                              ele?.start_time
                                            ).toLocaleString(undefined, {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })
                                          : "--"}
                                      </td>
                                      <td>{ele?.reason ? ele?.reason : ""}</td>
                                      <td>New Patient</td>
                                      {ele?.status === "pending" ? (
                                        <td>
                                        
                                          <button
                                            className="badge bg-success fw-500 px-3 py-2 border-0 me-2"
                                            onClick={() =>
                                              approveOrRejectAppointmentFunc(
                                                ele?._id,
                                                "accepted"
                                              )
                                            }
                                          >
                                            Accept
                                          </button>
                                          {/* <button
                                            className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
                                            onClick={() =>
                                              approveOrRejectAppointmentFunc(
                                                ele?._id,
                                                "rejected"
                                              )
                                            }
                                          >
                                            Cancel
                                          </button> */}
                                          {/* <button
                                            className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
                                            onClick={()=>handleOpenModal(ele)}
                                          >
                                            Cancel
                                          </button> */}

                                          <button
                                            className="badge bg-danger fw-500 px-3 py-2 border-0 me-2"
                                            onClick={()=>{
                                              setCancelAppointmentId(ele?._id)
                                              handleOpenCancelModal()
                                            }
                                              }
                                          >
                                            Cancel
                                          </button>
                                        </td>
                                      ) : (
                                        <td>{ele?.status}</td>
                                      )}
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td style={{ color: "red" }}>
                                      {" "}
                                      *No data to be Found{" "}
                                    </td>
                                  </tr>
                                )}
                                {/* <tr><td>2</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <img
                                                                                src={chatUser02}
                                                                                className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                                                                alt="icon"
                                                                            />
                                                                            <h6 className="mb-0">Jerome Bell</h6>
                                                                        </div>
                                                                    </td>
                                                                    <td>16 June, 2023</td>
                                                                    <td>Tooth Cleaning</td>
                                                                    <td>Old Patient</td>
                                                                    <td>
                                                                        <button className="badge bg-success fw-500 px-3 py-2 border-0 me-2">
                                                                            Accept
                                                                        </button>
                                                                        <button className="badge bg-danger fw-500 px-3 py-2 border-0 me-2">
                                                                            Cancel
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-0">
                                                                    <td className="border-0">3</td>
                                                                    <td className="border-0">
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <img
                                                                                src={chatUser01}
                                                                                className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                                                                alt="icon"
                                                                            />
                                                                            <h6 className="mb-0">Darrell Steward</h6>
                                                                        </div>
                                                                    </td>
                                                                    <td className="border-0">18 May, 2023</td>
                                                                    <td className="border-0">Skin Whitening</td>
                                                                    <td className="border-0">Old Patient</td>
                                                                    <td className='border-0'>
                                                                        <button className="badge bg-success fw-500 px-3 py-2 border-0 me-2">
                                                                            Accept
                                                                        </button>
                                                                        <button className="badge bg-danger fw-500 px-3 py-2 border-0 me-2">
                                                                            Cancel
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr className="border-0">
                                                                    <td className="border-0">3</td>
                                                                    <td className="border-0">
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <img
                                                                                src={chatUser02}
                                                                                className="img-fluid flex-shrink-0 icon-40 object-fit-cover"
                                                                                alt="icon"
                                                                            />
                                                                            <h6 className="mb-0 fs-6">Darrell Steward</h6>
                                                                        </div>
                                                                    </td>
                                                                    <td className="border-0">18 May, 2023</td>
                                                                    <td className="border-0">Skin Whitening</td>
                                                                    <td className="border-0">Old Patient</td>
                                                                    <td className='border-0'>
                                                                        <button className="badge bg-success fw-500 px-3 py-2 border-0 me-2">
                                                                            Accept
                                                                        </button>
                                                                        <button className="badge bg-danger fw-500 px-3 py-2 border-0 me-2">
                                                                            Cancel
                                                                        </button>
                                                                    </td>
                                                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-5 col-xl-5 col-sm-6 col-12">
                      <div className="avaialble_doctors">
                        <div className="card border-0 shadow-sm">
                          <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                            <div className="">
                              <h4 className="header_text fw-semibold mb-2">
                                Available Doctors
                              </h4>
                            </div>
                            {/* <div class="dropdown">
                                                            <button class="btn  btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                View All
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                <li><button class="dropdown-item" type="button">View</button></li>
                                                                <li><button class="dropdown-item" type="button">Appointment</button></li>
                                                            </ul>
                                                        </div> */}
                          </div>
                          <div className="card-body">
                            {available_data.length > 0 ? (
                              available_data.map((ele) => (
                                <div className="d-flex align-items-center mb-4 px-2 py-2 px-lg-3 bg-light ">
                                  <div className="img-cntainer">
                                    <img
                                      class="img-fluid"
                                      src={
                                        ele?.profile_image &&
                                        ele?.profile_image !== ""
                                          ? profile_image_base_url +
                                            ele?.profile_image
                                          : doctor01
                                      }
                                    />
                                  </div>
                                  <div class="ms-4 flex-grow-1">
                                    <h5>
                                      {ele?.full_name ? ele?.full_name : "--"}
                                    </h5>
                                    <p class="mb-0">{ele?.designation}</p>
                                  </div>
                                  <div>
                                    <h6 class="text-primary mb-0">
                                      {ele?.patient_count &&
                                      ele?.patient_count !== ""
                                        ? ele?.patient_count + " patients"
                                        : "0 patients"}
                                    </h6>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                            {/* <div className='d-flex align-items-center px-3 py-3 px-lg-4 bg-light '>
                                                            <div className='img-cntainer'>
                                                                <img class="img-fluid" src={doctor02} alt="Dr-profile" loading="lazy" />
                                                            </div>
                                                            <div class="ms-4 flex-grow-1">
                                                                <h5>Darrell Steward</h5>
                                                                <p class="mb-0">Dental Specialist</p>
                                                            </div>
                                                            <div>
                                                                <h6 class="text-primary mb-0">100 patients</h6>
                                                            </div>

                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="p-0">
              <div className="container">
                <BreadCrumb Navitem={"Performance"} />
                <div className="perfTabsWrap">
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box className="tabHeader">
                        <TabList onChange={handleChange}>
                          <Tab label="Overview" value="1" />
                          <Tab label="Patient Feedback" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <div className="overViewWrap">
                          <div className="clinicReport"></div>
                          <div className="reviewOverView">
                            <div className="row">
                              <div className="col-lg-5 col-md-6 col-sm-12">
                                <div className="overAll">
                                  <h5>
                                    <strong>Overall Rating</strong>
                                    <span>
                                      (
                                      {rating?.total_count
                                        ? rating?.total_count
                                        : "0 "}{" "}
                                      Ratings)
                                    </span>
                                  </h5>
                                  <div className="starGroup d-flex align-items-center">
                                    <span>
                                      {rating?.average_rating
                                        ? rating?.average_rating
                                        : "--"}
                                    </span>
                                    {[1, 2, 3, 4, 5].map((ele, i) => {
                                      if (
                                        ele <=
                                        Math.floor(rating?.average_rating)
                                      )
                                        return <StarIcon />;
                                      if (
                                        ele > rating?.average_rating &&
                                        ele - 1 < rating?.average_rating
                                      )
                                        return <StarHalfIcon />;
                                      else return <StarBorder />;
                                    })}
                                    {/* <StarIcon />
                                                                        <StarIcon />
                                                                        <StarIcon />
                                                                        <StarIcon />
                                                                        <StarHalfIcon /> */}
                                  </div>
                                  {/* <div className="starWrap">
                                                                        <div className="star">
                                                                            <span className="title">Location</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={80} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Comfort</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={60} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Staff</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={40} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Facilities</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={20} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Cleanliness</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={10} />
                                                                            </Box>
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>
                              <div className="col-lg-7 col-md-6 col-sm-12">
                                <div className="ratingTrend">
                                  <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="area"
                                    height={350}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value="2">
                        <div className="patientReview">
                          {/* <div className="reviewOverView">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-6 col-sm-12">
                                                                <div className="overAll">
                                                                    <h5><strong>Overall Rating</strong><span>(230 Ratings)</span></h5>
                                                                    <div className="starGroup d-flex align-items-center">
                                                                        <span>4.5</span>
                                                                        <StarIcon />
                                                                        <StarIcon />
                                                                        <StarIcon />
                                                                        <StarIcon />
                                                                        <StarHalfIcon />
                                                                    </div>
                                                                    <div className="starWrap">
                                                                        <div className="star">
                                                                            <span className="title">Location</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={80} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Comfort</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={60} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Staff</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={40} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Facilities</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={20} />
                                                                            </Box>
                                                                        </div>
                                                                        <div className="star">
                                                                            <span className="title">Cleanliness</span>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <BorderLinearProgress variant="determinate" value={10} />
                                                                            </Box>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-6 col-sm-12">
                                                                <div className="ratingTrend">
                                                                    <ReactApexChart options={options} series={series} type="area" height={350} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div className="reviews">
                            <div className="fltr d-flex mb-3">
                              <div className="form-group">
                                <label htmlFor="" className="mb-2">
                                  Select Doctor
                                </label>
                                <select
                                  name=""
                                  value={review_dentist_id}
                                  onChange={(e) => setDentist_review(e)}
                                  className="form-select"
                                  id=""
                                >
                                  {providerList.length !== 0 ? (
                                    providerList.map((ele, i) => (
                                      <option
                                        selected={i === 0}
                                        value={ele?._id}
                                      >
                                        {ele?.full_name ? ele?.full_name : ""}
                                      </option>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </select>
                              </div>
                            </div>
                            {feedback_data.length > 0 ? (
                              feedback_data.map((ele) => (
                                <div className="reviewBox">
                                  <div className="rating">
                                    <StarIcon className="starIC" />
                                    <span>
                                      {ele?.rating &&
                                      ele?.rating !== "" &&
                                      ele?.is_review_given
                                        ? ele?.rating
                                        : "--"}
                                    </span>
                                  </div>
                                  <div className="reviewText">
                                    <p>
                                      {ele?.reviews &&
                                      ele?.reviews !== "" &&
                                      ele?.is_review_given
                                        ? ele?.reviews
                                        : "--"}
                                    </p>
                                    <div className="reviewBy">
                                      <img src={userImg} alt="person" />
                                      <div className="txtInfo">
                                        <h5>
                                          {ele?.reviews &&
                                          ele?.reviews !== "" &&
                                          ele?.is_review_given
                                            ? ele?.user?.full_name
                                            : "--"}
                                        </h5>
                                        <span>
                                          {" "}
                                          {ele?.updatedAt &&
                                          ele?.updatedAt !== "" &&
                                          ele?.is_review_given
                                            ? moment(
                                                ele?.user?.updatedAt
                                              ).format("do MMMM YYYY")
                                            : "--"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                            {feedback_data.length === 0 ? (
                              <p
                                style={{
                                  color: "red",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                }}
                              >
                                *No data to be found
                              </p>
                            ) : (
                              <></>
                            )}
                            {/* <div className="reviewBox">
                                                            <div className="rating">
                                                                <StarIcon className="starIC" />
                                                                <span>4.5</span>
                                                            </div>
                                                            <div className="reviewText">
                                                                <p>Dr. Vikram Parihar is an exceptional dentist. His professionalism, expertise, and caring approach make dental visits comfortable and stress-free. Highly recommended for dental care.</p>
                                                                <div className="reviewBy">
                                                                    <img src={userImg} alt="person" />
                                                                    <div className="txtInfo">
                                                                        <h5>Iliana Sahiba</h5>
                                                                        <span>4th April 2023</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reviewBox">
                                                            <div className="rating">
                                                                <StarIcon className="starIC" />
                                                                <span>5</span>
                                                            </div>
                                                            <div className="reviewText">
                                                                <p>Dr. Vikram Parihar is an exceptional dentist. His professionalism, expertise, and caring approach make dental visits comfortable and stress-free. Highly recommended for dental care.</p>
                                                                <div className="reviewBy">
                                                                    <img src={userImg} alt="person" />
                                                                    <div className="txtInfo">
                                                                        <h5>Iliana Sahiba</h5>
                                                                        <span>4th April 2023</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </section>
            {
              showModal &&
            <ResheduleApp show={showModal} handleClose={handleCloseModal} appointmentDetail ={apppointmentDetails}

               handleConfirmInParent = {handleConfirmReshedule}
            />
            }
          </div>
          <ProviderFooter />
        </div>
      </div>


      <Modal
                open={openCancelModal}
                onClose={handleCloseCancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Reason For Cancel The Request
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* <p className="text-bold">
                      Note<span className="text-danger">*</span>{" "}
                      <span className="text-muted">
                        The Request will be manually evaluated
                      </span>
                    </p> */}
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label for="floatingTextarea2">Reason</label>
                    </div>
                    {/* <div className="">
                      <div class="my-3">
                        <label for="formFile" class="form-label">
                          Add File (optional)
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          id="formFile"
                          // onChange={providerProfilePicture}
                        />
                      </div>
                    </div> */}
                  </Typography>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    <Button variant="outlined" onClick={handleCloseCancelModal}>
                      Close
                    </Button>
                    <Button variant="outlined" onClick={()=>approveOrRejectAppointmentFunc(cancelAppointmentId,"rejected",)}>
                      Confirm
                    </Button>
                  </div>
                </Box>
              </Modal>

    </>
  );
}
