import React from 'react'

const ProfilePic = ({ name ,fontSize }) => {

    const firstLetter = name.charAt(0).toUpperCase();
  
    return (
      <div className="profile-pic-container" style={{fontSize:fontSize}}>
        <span className="profile-pic-letter">{firstLetter}</span>
      </div>
    );
  };
  

export default ProfilePic