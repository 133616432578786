import { useMemo, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Steps } from 'antd';
import "./AppointmentStatus.scss"
import moment from 'moment';
import { getDashboard_approveOrRejectAppontment } from '../../utils/httpServices/httpService';
import { toast } from 'react-toastify';

function AppointmentStatus({ show, handleShow, handleClose, name,appointmentDetail,handleChangeTime, updateparentState, ...props}) {


    const stepPosition = useMemo(() => {
        switch (appointmentDetail?.status) {
          case "pending":
            return 1;
          case "accepted":
            return 2;
          case "completed":
            return 3;
          default:
            return 0; 
        }
      }, [appointmentDetail]);


      async function approveOrRejectAppointmentFunc(status) {
        let obj = {
          appointment_id: appointmentDetail?._id,
          status: status,
        };
        try {
          const resp = await getDashboard_approveOrRejectAppontment(obj);
          if(status === "accepted"){
            toast.success("Appointment accepted successfully")
          }else if(status === "rejected"){
            toast.success("Appointment rejected successfully")
          }
          updateparentState()
        } catch (err) {
        //   throw new Error(err)
        console.log(err)
        }
      }


    return (
        <>

            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                {
                    appointmentDetail?.status === "rejected" ?
                    (<Steps
                        size="small"
                        current={1}
                        status="error"
                        items={[
                            {
                                title: 'Request',
                            },
                            {
                                title: 'Rejected',
                            },
                            
                        ]}
                    />)
                    :

                   appointmentDetail?.previous_slot 
                   ?

                    (<Steps
                        size="small"
                        current={stepPosition}
                        items={[
                            {
                                title: 'Request',
                            },
                            {
                                title: 'Rescheduled',
                            },
                            {
                                title: 'Completed',
                            },
                        ]}
                    />)
                    :
                    (<Steps
                        size="small"
                        current={stepPosition}
                        items={[
                            {
                                title: 'Request',
                            },
                            {
                                title: 'Scheduled',
                            },
                            {
                                title: 'Completed',
                            },
                        ]}
                    />)
                    
                }
                    
                    <div className="card scheduleAppointment text-start mb-4">
                        <h5 className='title'>Schedule Appointment</h5>
                        <div className="card-body">
                            <h6 className="card-title">Appointment Reason</h6>
                            <p className="card-text">{appointmentDetail?.reason}</p>
                            {
                                appointmentDetail?.previous_slot ?

                                <>
                               <h6 className="card-title">Original Time</h6>
                                <p className="card-text">{ moment(new Date(appointmentDetail?.previous_slot?.start_time)).format('DD-MM-YYYY, hh:mm A') }</p>
                                <h6 className="card-title">Rescheduled Time</h6>
                                <p className="card-text">{ moment(new Date(appointmentDetail?.start_time)).format('DD-MM-YYYY, hh:mm A') }</p>
                                </> 
                                :
                                <>
                                <h6 className="card-title">Requested Time</h6>
                                <p className="card-text">{ moment(new Date(appointmentDetail?.start_time)).format('DD-MM-YYYY, hh:mm A') }</p>
                                </>
                            }
                            
                            <h6 className="card-title">Provider</h6>
                            <p className="card-text">{ appointmentDetail?.booked_on_dentist ? appointmentDetail?.dentist_details?.full_name : "Not Specified" }</p>
                           
                            {/* <div className="form-group mb-4">
                                <label htmlFor="" className='formlabel'>Provider</label>
                                <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option selected>Dr Dalwinder Saran</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div> */}
                     
                     {
                        appointmentDetail?.status === 'pending' &&
                        <div className="btn-group w-100" role="group" aria-label="Basic mixed styles example">
                                <button type="button" className="btn btn-pink" onClick={()=>approveOrRejectAppointmentFunc('accepted')}>Confirm</button>
                                <button type="button" className="btn btn-warning" onClick={handleChangeTime}>Change Time</button>
                                <button type="button" className="btn btn-danger" onClick={()=>approveOrRejectAppointmentFunc('rejected')}>Cancel</button>
                            </div>
                     }
                          


                        </div>
                    </div>
                    <div className="card card-custom">
                        <div className="card-body">
                            <h5 className="card-title text-center">User Details</h5>
                            <p className="card-text">
                                <i class="fa-solid fa-user"></i> <strong>Name:</strong> {appointmentDetail?.user_details?.full_name}
                            </p>
                            <p className="card-text">
                                <i class="fa-solid fa-envelope"></i> <strong>Email:</strong> {appointmentDetail?.user_details?.email}
                            </p>
                            {/* <p className="card-text">
                                <i class="fa-solid fa-phone"></i> <strong>Phone:</strong> (123) 456-7890
                            </p>
                            <p className="card-text">
                                <i class="fa-solid fa-calendar"></i> <strong>Date of Birth:</strong> 01/01/1980
                            </p> */}
                        </div>
                    </div>


                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default AppointmentStatus;