import React from 'react'
import { useState } from 'react';
import "./BookConfirmed.scss"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BookConfirmed({ show, handleContinue, handleClose , appointmentDetails }) {

    const {questionnairDetails} = appointmentDetails;



    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal className='bookingConfimed' show={show} onHide={handleClose}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
                <Modal.Header closeButton>
                    <div className='header_txt'>
                        <i class="fa-solid fa-circle-check"></i>
                        <p className='m-1'><strong>Request Sent! Thanks {appointmentDetails?.userDetails?.full_name}.</strong></p>
                        <p className=''><strong>Look out for an email and text for next steps.</strong></p>

                        <ol type="1" className='listing-text'>
                            <li>Don't go to your appointment until it's confirmed.</li>
                            <li>The practice will reach out to confirm your appointment.</li>
                            <li>Remember, go to your appointment to get your offer!</li>
                        </ol>

                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="requestContainer">
                        {/* <h4>Request Details</h4> */}
                        <h5>Appointment Details</h5>
                        <div className='apptmtDetails'>
                            <div className='apptmentItme'>
                                <p>Date</p>
                                <span>{appointmentDetails?.appointmentDate}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>Time</p>
                                <span>{appointmentDetails?.appointmentTime}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>Provider</p>
                                <span>{appointmentDetails?.dentistDetails?.full_name || "Not Specified"}</span>
                            </div>
                        </div>
                        <h5>Survey Information</h5>
                        <div className='apptmtDetails'>
                            <div className='apptmentItme'>
                                <p>What is the main purpose of your visit?</p>
                                <span>{questionnairDetails?.main_purpose || "N/A"}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>What I'm looking for in a dentist is...</p>
                                <span>{questionnairDetails?.looking_in_dentist  || "N/A"}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>When was the last time you saw a dentist?</p>
                                <span>{questionnairDetails?.last_time  || "N/A"}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>What time works best for your visit?</p>
                                <span>{questionnairDetails?.best_visit_time  || "N/A"}</span>
                            </div>
                            <div className='apptmentItme'>
                                <p>Do you have dental insurance?</p>
                                <span>{questionnairDetails?.have_insurance || "N/A"}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                    {/* <button className='btn '></button> */}
                    <button className='theme_btn ' onClick={handleContinue} >continue</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BookConfirmed


