import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";

import { useNavigate } from "react-router-dom";
import {
  loginService,
  successNotification,
  login_OtpVerify,
  resend_loginOtp,
  socialSignUpService,
} from "../../utils/httpServices/httpService";
import { emailValidator } from "../../utils/validators/validatorServices";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchClinicBranches } from "../../redux/slices/getting_clinic_branches/index";
import OtpInput from "react-otp-input";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const customContentStyle = {
  width: "30%",
  height: "300px",
  maxWidth: "none",
};

export default function Login() {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const [fireEyePassword, setfireEyePassword] = useState("password");

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [loginFormValidator, setLoginFormValidator] = useState({
    email: false,
  });

  const settingValues = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
    });
  };

  function settingValidators(name) {
    switch (name) {
      case "email":
        setLoginFormValidator(() => ({
          email: emailValidator(loginForm[name]),
        }));
        console.log("add", loginFormValidator);
        break;
      default:
    }
  }

  function finalValidationCheck() {
    let tempValidator = loginFormValidator;
    let validatorKeys = Object.keys(loginFormValidator);
    validatorKeys.forEach((ele) => {
      if (ele === "email") tempValidator[ele] = emailValidator(loginForm[ele]);
    });
    setLoginFormValidator(() => ({
      ...tempValidator,
    }));
  }

  async function submit() {
    finalValidationCheck();

    const validatorStatus = Object.values(loginFormValidator);
    if (!validatorStatus.some((ele) => ele === true)) {
      const data = {
        ...loginForm,
      };
      console.log("data", data);
      try {
        const resp = await loginService(data);
        console.log(" login response data=> ", resp);
        if (resp?.code === 200 && !resp?.two_step_verification) {
          localStorage.setItem("user_details", JSON.stringify(resp.data.user));
          localStorage.setItem("token", resp.data.token);
          successNotification("Login Successfully");
          console.log("resp for login", resp.data);
          if (resp?.data?.user?.user_type === "user") {
            navigate("/");
          } else {
            navigate("/dashboard");
            console.log("fetching branches ....");
            dispatcher(fetchClinicBranches());
            localStorage.setItem("selected_Branch", 0);
          }
        } else {
          successNotification(resp?.message);
          setOpen(true);
        }
      } catch (err) {
        console.log("login error data", err);
      }
    } else {
      console.log("validation failed");
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function verifyOtp() {
    try {
      console.log("otp", otp);
      let obj = {
        email: loginForm.email,
        otp: otp,
      };

      console.log("obj", obj);

      const resp = await login_OtpVerify(obj);
      console.log("verify otp", resp);
      if (resp?.code === 200) {
        localStorage.setItem("user_details", JSON.stringify(resp.data.user));
        localStorage.setItem("token", resp.data.token);
        successNotification("Login Successfully");
        console.log("resp for login", resp.data);
        if (resp.data.user.user_type === "user") {
          navigate("/user-profile/tab1");
        } else {
          navigate("/dashboard");
          console.log("fetching branches ....");
          dispatcher(fetchClinicBranches());
          localStorage.setItem("selected_Branch", 0);
        }
      }
    } catch (err) {
      console.log("login error data", err);
    }
  }

  async function resend_verifyOtp() {
    try {
      console.log("otp", otp);
      let obj = {
        email: loginForm.email,
      };
      console.log("obj", obj);

      const resp = await resend_loginOtp(obj);
      console.log("verify otp", resp);
      if (resp?.code === 200) {
        successNotification("Otp Resend sucessfully");
        setOtp("");
      }
    } catch (err) {
      console.log("login error data", err);
    }
  }
  const signUpHandler = async (response) => {
    // Access user information from the response object
    const result = jwtDecode(response?.credential);
    // console.log(a);
    const body = {
      email: result?.email,
      first_name: result?.given_name,
      last_name: result?.family_name,
      full_name: result?.name,
      social_type: "google",
      social_id: result?.sub,
    };

    const resp = await socialSignUpService(body);
    console.log(resp,"=================================response================================");
    localStorage.setItem("token", resp?.data?.token);
    let v = resp?.data?.user;
    localStorage.setItem("user_details", JSON.stringify(v));
    if (resp?.data?.user?.user_type === "user") {
        navigate("/");
      } else {
        navigate("/dashboard");
        console.log("fetching branches ....");
        dispatcher(fetchClinicBranches());
        localStorage.setItem("selected_Branch", 0);
      }
    // navigate("/");
  };

  useEffect(()=>{

   const isAuthenticated = localStorage.getItem("token");
   const detail = localStorage.getItem("user_details");
   if (detail) {
    const userDetails = JSON.parse(detail);
    if(userDetails?.user_type === "user"){
      navigate("/");
    }else{
      navigate("/dashboard");
    }
  }

  },[])

  return (
    <div className="pt-3 position-relative overflow-x-hidden">
      <div className="container-fluid">
        <div className="login">
          

          <div className="row">
            <div className="col-xl-12 p-0">
              <div className="login__text-form">
                <h4 className="login__title">Login to Dentist Finder</h4>
                <div className="login__google_auth">
                  {/* <button className='btn-white w-100'> */}
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" role="img" class="icon ">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="#4285F4"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" fill="#FBBC05"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path>
                                        </svg> */}
                  {/* Sign in with Google
                                    </button> */}
                  <div className="socialLoginWrap">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        //   console.log(credentialResponse);
                        signUpHandler(credentialResponse);
                      }}
                    />
                  </div>
                  <hr class="login__divider sign-in"></hr>
                  <div className="login__auth-form">
                    <div className="mb-4">
                      <div class="mb-3">
                        <label for="exampleInputEmail1 " class="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          name="email"
                          value={loginForm.email}
                          onChange={settingValues}
                          onBlur={(e) => settingValidators(e.target.name)}
                          aria-describedby="emailHelp"
                        />
                        {loginFormValidator.email ? (
                          <div className="validation_message">
                            *Enter valid email address
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div class="mb-4  position-relative">
                        <label for="password" class="form-label d-flex">
                          Password{" "}
                          <Link
                            className="d-block ms-auto"
                            to="/ForgotPassword"
                          >
                            Forgot Password?
                          </Link>
                        </label>
                        <input
                          type={fireEyePassword}
                          class="form-control"
                          name="password"
                          id="password"
                          value={loginForm.password}
                          onChange={settingValues}
                          aria-describedby="password"
                        />
                        <div className="fireEyePosition_set">
                          {fireEyePassword === "password" ? (
                            <button onClick={() => setfireEyePassword("text")}>
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <button
                              onClick={() => setfireEyePassword("password")}
                            >
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="w-100 theme_btn rounded-2"
                          onClick={submit}
                        >
                          Login
                        </button>
                      </div>
                    </div>

                    {/* <p class="auth-link text-center">
                                            <Link class="underline" onClick={handleClickOpenDialog} >Open OTP Modal</Link>
                                        </p> */}
                    <p class="auth-link text-center">
                      Don't have an account?{" "}
                      <Link class="underline" to="/SignUp">
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="blobs1">
                  <svg
                    width="216"
                    height="216"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FF6BB5"
                      d="M68,-38.7C81.5,-16,81.1,15.3,67.5,39C53.8,62.7,26.9,78.8,0.7,78.5C-25.6,78.1,-51.1,61.2,-65.2,37.2C-79.3,13.3,-81.9,-17.8,-69.1,-40.1C-56.3,-62.4,-28.2,-75.9,-0.4,-75.7C27.3,-75.4,54.6,-61.3,68,-38.7Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
                <div className="blobs2">
                  <svg
                    width="216"
                    height="214"
                    viewBox="0 0 200 200"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FF6BB5"
                      d="M68,-38.7C81.5,-16,81.1,15.3,67.5,39C53.8,62.7,26.9,78.8,0.7,78.5C-25.6,78.1,-51.1,61.2,-65.2,37.2C-79.3,13.3,-81.9,-17.8,-69.1,-40.1C-56.3,-62.4,-28.2,-75.9,-0.4,-75.7C27.3,-75.4,54.6,-61.3,68,-38.7Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---OTP- */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog"
        PaperProps={{
          style: customContentStyle, // Apply custom styles using PaperProps
        }}
      >
        <DialogContent className="d-flex flex-column justify-content-center align-items-center">
          <DialogContentText id="alert-dialog">
            <Typography variant="h5" className="text-center" component="h4">
              Enter OTP
            </Typography>
          </DialogContentText>
          <div className="otp-wrap">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <p className="timer">
            {/* Time left <b>02:00 mins</b> */}
            <b></b>
          </p>
          <div className="modal-btns">
            <Button variant="outlined" onClick={resend_verifyOtp}>
              Resend
            </Button>
            <Button variant="outlined" onClick={verifyOtp} autoFocus>
              Continue
            </Button>
          </div>
        </DialogContent>
        {/* <DialogActions className='modal-foot'>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
