import React, { useState, useEffect, useCallback } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Nav/Navbar";

// images-

import icon1 from "../../Assets/usericon.png";
import icon2 from "../../Assets/hospital-user.png";
import icon3 from "../../Assets/file.png";

import {
  supportPageService,
  faqQuestions,
} from "../../utils/httpServices/httpService";
import ContactUs from "./components/ContactUs";

export default function Support() {
  const support_base_url = process.env.REACT_APP_CMS;

  const [support, setSupport] = useState({
    first_section: "",
    second_section: "",
    card_section: [],
    faq_section: "",
  });

  const [supportQuestions, setSupportQuestions] = useState([]);

  const [background_image_data, set_background_image_data] = useState(
    "./Assets/home_1_1_3.jpg"
  );

  async function getSupport() {
    try {
      const resp = await supportPageService();
      console.log("support page data ", resp);
      if (resp?.code === 200) {
        console.log("support page resp", resp.data);
        setSupport({
          first_section: resp.data.first_section,
          second_section: resp.data.second_section,
          card_section: resp.data.card_section,
          faq_section: resp.data.faq_section,
        });
        // if(resp.data.first_section.background_image && resp.data.first_section.background_image !== null && resp.data.first_section.background_image !== "")
        set_background_image_data(
          support_base_url + resp.data.first_section.background_image
        );
      }
    } catch (err) {
      console.log(err);
      // alert(err)
    }
  }

  useEffect(() => {
    getSupport();
    getFaqQuestionsAnswers();
  }, []);

  async function getFaqQuestionsAnswers() {
    try {
      let obj = {
        sort: 1,
        type: "support",
      };
      const resp = await faqQuestions(obj);
      console.log("faq page data ", resp);
      // if (resp?.code === 200) {
      console.log("faq page questions", resp.item);
      setSupportQuestions(resp.item);
      // }
    } catch (err) {
      console.log(err);
      // alert(err)
    }
  }

  return (
    <>
      <Navbar />
      <div className="page-wrap">
        {/* ---Hero--Section--- */}
        <section
          className="support_container"
          style={{ "background-image": `url(${background_image_data})` }}
        >
          <div className="container">
            <div className="support">
              <div className="support__left-content">
                <h4 className="support__sub-title">What We Do</h4>
                <h1 className="support__title">
                  {support.first_section.title
                    ? support.first_section.title
                    : ""}
                </h1>
                <p className="support__description">
                  {support.first_section.description
                    ? support.first_section.description
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* ---Hero--Section---End */}

        {/* ---Support___Help---- */}

        <section className="help-container">
          <div className="container">
            <div className="help">
              <div className="help__heading_text">
                <h2 className="help__main_title">
                  {support.second_section.title
                    ? support.second_section.title
                    : ""}
                </h2>
                <p className="help__subtitle">
                  {support.second_section.description
                    ? support.second_section.description
                    : ""}
                </p>
              </div>

              <div className="help__boxes">
                <div className="row justify-content-center ">
                  {support.card_section && support.card_section.length > 0 ? (
                    support.card_section.map((ele) => (
                      <div className="col-xl-4 col-md-4 col-sm-6 col-12">
                        <div className="help__box">
                          <div class="help__circle-border">
                            <div class="help__circle">
                              <img
                                src={support_base_url + ele?.icon}
                                onError={(e) => (e.target.src = icon1)}
                                className="cardIcon"
                                alt="user "
                              />
                            </div>
                          </div>
                          <h4 className="help__title">
                            {ele.title ? ele.title : ""}
                          </h4>
                          <p className="help__description">
                            {ele.description ? ele.description : ""}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq_section pt-0 pt-xs-0">
          <div className="container">
            <div className="faq">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-12">
                  <h2 className="faq__title">
                    {support.faq_section.title ? support.faq_section.title : ""}
                  </h2>
                  <p className="faq__descripion">
                    {support.faq_section.description
                      ? support.faq_section.description
                      : ""}
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="faq__accordion">
                    <div class="accordion" id="accordionExample">
                      {supportQuestions && supportQuestions.length > 0 ? (
                        supportQuestions.map((ele, i) => (
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapse" + i}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {i + 1}. {ele.question ? ele.question : ""}
                              </button>
                            </h2>
                            <div
                              id={"collapse" + i}
                              class="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <p>{ele.answer ? ele.answer : ""}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      {/* <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Accordion Item #2
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi numquam ab, repellendus voluptas porro adipisci sit sint quae. Eos, velit.</p>

                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Accordion Item #3
                          </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi numquam ab, repellendus voluptas porro adipisci sit sint quae. Eos, velit.</p>

                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact_us-Form">
          <ContactUs />
        </section>

        <Footer />
      </div>
    </>
  );
}
