import React, { useEffect, useState } from 'react';
import "./Footer.scss"
import logo from "../../Assets/Logos/logo1.png"
import { Link } from 'react-router-dom'
import { getcontctdetails } from '../../utils/httpServices/httpService';
import ContactForm from '../ContactForm/ContactForm';

export default function Footer() {
  const [contact, setContact] = useState([]);

  const [showContactUs , setShowContactUs] = useState(false)

  const handleShowContact = ()=>{
    setShowContactUs(true)
  }

  const handleCloseContactUs = ()=>{
    setShowContactUs(false)

  }


   useEffect(() => {
   getProviderListById();
     },[]);

async function getProviderListById() {
 
    try {
        const resp = await getcontctdetails();
        console.log("resp for dentist list", resp);
        if (resp?.code === 200) {
            setContact(resp.data);
        }
    } catch (err) {
        console.log("dentist list error", err)
    }
}
 async function handleClick(event){
 console.log(event.target.value)
window.open(contact.facebook, "_blank", " https://www.facebook.com/dentistfinderai/");
 }   
 
 async function handleClickinsta(event){
 console.log(event.target.value)
window.open(contact.instagram, "_blank", "noreferrer");
 }   

 async function handleClickyou(event){
 console.log(event.target.value)
window.open(contact.youtube, "_blank", "noreferrer");
 }   

 const handleContactUsSubmited =  ()=>{
   console.log("form submitted")
  }

  const redirectTOBlogPage = ()=>{
  window.location.href =  'https://blog.dentistfinder.ai/'
}

    return (
      <footer class="footer-section">
        <div class="container">
          <div class="footer-content pt-5 ">
            <div class="row">
              <div class="col-xl-4 col-lg-4 mb-50">
                <div class="footer-widget">
                  <div class="footer-logo mb-2">
                    <a href="index.html">
                      <img src={logo} class="img-fluid" alt="logo" />
                    </a>
                  </div>
                  <div class="footer-text">
                    <p>
                      Searching for a trusted dentist? Look no further! We
                      understand the importance of oral health, and that's why
                      we've created Dentist Finder – your go-to platform for
                      finding the perfect dental care professional.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div class="footer-widget">
                  <ul className="mb-0">
                    <li>
                      <Link to="/find-dentist">For Dentists</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/support">Support</Link>
                    </li>
                    <li>
                      <Link onClick={handleShowContact}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/termsofuse">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/privacy-&-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link onClick={()=>redirectTOBlogPage()} >Blog</Link>
                    </li>
                    {/* <li><Link to="">Contact</Link></li> */}
                  </ul>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div class="footer-widget">
                  <div class="footer-text mb-25">
                    <div className="">
                      <span className="icon">
                        <i class="fa-solid fa-phone"></i>
                      </span>
                      <span className="footer_txt ms-3">{contact.phone}</span>
                    </div>
                  </div>
                  <div class="footer-text mb-25">
                    <div className="">
                      <span className="icon">
                        <i class="fa-solid fa-envelope"></i>
                      </span>
                      <span className="footer_txt ms-3 ">{contact.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-area position-relative">
          <div class="container">
            <div class="row">
              <div className="hrztal_line"></div>

              <div className="col-xl-12 col-lg-12 d-flex justify-content-sm-end justify-content-center">
                <div class="footer-social-icon ">
                  {/*                               
                                <a href='https://www.facebook.com/dentistfinderai/' value={contact.facebook} ><i class="fa-brands fa-facebook "></i></a>
                                <a href='https://www.instagram.com/dentistfinder.ai' value={contact.instagram} ><i class="fa-brands fa-instagram "></i></a>
                                <a href='https://www.tiktok.com/@dentistfinder.ai' value={contact.youtube} ><i class="fa-brands fa-tiktok"></i></a>
                                <a href='https://twitter.com/DentistFinderai' value={contact.youtube}><i class="fa-brands fa-x-twitter"></i></a>
                                <a href='https://www.youtube.com/@dentistfinderai' value={contact.youtube} ><i class="fa-brands fa-youtube"></i></a>
                                <a href='https://twitter.com/DentistFinderai' value={contact.youtube}><i class="fa-brands fa-x-twitter"></i></a>
                                <a href='https://www.tiktok.com/@dentistfinder.ai' value={contact.youtube} ><i class="fa-brands fa-tiktok"></i></a> */}

                  <a
                    href={contact.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-facebook "></i>
                  </a>
                  <a
                    href={contact.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-instagram "></i>
                  </a>
                  <a
                    href={contact.tik_tok}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-tiktok"></i>
                  </a>
                  <a
                    href={contact.x}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                  <a
                    href={contact.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-youtube"></i>
                  </a>
             
               
                </div>
              </div>

              <div class="col-xl-12 col-lg-12 text-right">
                <div class="copyright-text mt-4">
                  <p>
                    Copyright &copy; {new Date().getFullYear()}, Dentist Finder.
                    All Rights Reserved{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm
          show={showContactUs}
          handleClose={handleCloseContactUs}
          handleSubmit={handleContactUsSubmited}
          isDisableText={true}
        />
      </footer>
    );
}
