import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmPopUp({ show, handleClose, handleSubmit }) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To provide feedback or rating, you must be logged in.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmPopUp;
