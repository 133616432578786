import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { submitContactUs } from '../utils/httpServices/httpService';
import { toast } from 'react-toastify';

const useContactUsForm = () => {



    const validationSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
          // phone_number: Yup.string().required("Phone Number is required"),

          // title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
      });
    
      const handleSubmitForm =async (values)=> {
        console.log(values)
        try {
          const resp = await submitContactUs(values)
          toast.success("Successfully submitted")
          formik.resetForm();
        } catch (error) {
          console.log(error)
        }
      }
    
      const formik = useFormik({
        initialValues: {
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          description: "",
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmitForm,
      });
    
      return {
        formik,
        
      };
}

export default useContactUsForm