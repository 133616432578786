import React from 'react';
import { Link } from 'react-router-dom';
import profile01 from '../../Assets/profile01.jpg';
import profile02 from '../../Assets/profile02.jpg';
import profile03 from '../../Assets/profile03.jpg';
import profile04 from '../../Assets/profile04.jpg';
import teeth from '../../Assets/teeth.png';
import user01 from '../../Assets/user1.jpg';
import user02 from '../../Assets/user2.jpg';
import user03 from '../../Assets/user3.jpg';
import Footer from '../../components/Footer/Footer';
import "./DentistProfile.scss";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';



const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}




export default function DentistProfile() {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    return (
        <div className='page-wrap'>
            <section className='profile-container'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile">
                                <div class="card border-0 shadow">
                                    <div class="card-body">
                                        <div class="card__doctor-widget">
                                            <div class="card__doc-info-left">
                                                <div class="card__doctor-img">
                                                    <Link to="">
                                                        <img src={profile01} class="img-fluid rounded-2" alt="User " />
                                                    </Link>
                                                </div>
                                                <div class="card__doc-info-cont">
                                                    <h4 class="card__doc-name"><Link href="doctor-profile.html">Dr. Ruby Perrin</Link></h4>
                                                    <p class="card__doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                    <h5 class="card__doc-department"><img src={teeth} class="img-fluid" alt="Speciality" />Dentist</h5>
                                                    <div class="card__rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="d-inline-block average-rating">(25)</span>
                                                    </div>
                                                    <div class="card__clinic-details">
                                                        <p class="card__doc-location"><i class="fas fa-map-marker-alt"></i> Georgia, USA<span className='distance'> 4.9ml</span></p>
                                                        <ul class="card__clinic-gallery">
                                                            <li>
                                                                <a href="assets/img/features/feature-01.jpg" data-fancybox="gallery">
                                                                    <img src={profile02} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="assets/img/features/feature-02.jpg" data-fancybox="gallery">
                                                                    <img src={profile03} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="assets/img/features/feature-03.jpg" data-fancybox="gallery">
                                                                    <img src={profile01} alt="Feature" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="assets/img/features/feature-04.jpg" data-fancybox="gallery">
                                                                    <img src={profile04} alt="Feature" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="card__clinic-services">
                                                        <span>Dental Fillings</span>
                                                        <span> Whitening</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card__doc-info-right">
                                                <div class="card__clini-infos">
                                                    <ul>
                                                        <li><i class="far fa-thumbs-up"></i> 96%</li>
                                                        <li><i class="far fa-comment"></i> 25 feedback</li>
                                                        <li><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="login" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 01520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 01270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 010 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z"></path></svg> in Network</li>
                                                        <li><i class="fa-solid fa-arrow-trend-up"></i> Booked by 22 people in the last 30 days</li>
                                                    </ul>
                                                </div>
                                                <div class="card__clinic-booking">

                                                    <Link class="apt-btn theme_btn rounded-1" to="/appointment">Book Appointment</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="about-profile">
                            <Box sx={{ bgcolor: 'background.paper', }}>
                                <AppBar position="static">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"

                                    >
                                        <Tab label="Overview" {...a11yProps(0)} />
                                        <Tab label="Locations" {...a11yProps(1)} />
                                        <Tab label="Reviews" {...a11yProps(2)} />
                                        <Tab label="Business Hours" {...a11yProps(3)} />
                                        <Tab label="Photos" {...a11yProps(4)} />
                                    </Tabs>
                                </AppBar>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-9">
                                                <div className="widget about-widget">
                                                    <h5 className=''>About Me</h5>
                                                    <p className=''>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum eveniet at voluptate quibusdam asperiores non eos velit vel omnis labore? Dignissimos eaque illo mollitia voluptates, dolore nisi quo doloribus error rerum iusto excepturi cumque maiores adipisci quibusdam minus praesentium, delectus minima, maxime illum voluptatibus amet sequi sapiente! Totam, aut est.</p>
                                                </div>
                                                <div className="widget education-widget">
                                                    <h5 className=''>Education</h5>
                                                    <div class="experience-box">
                                                        <ul class="experience-list">
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <Link to="#/" class="name">American Dental Medical University</Link>
                                                                        <div>BDS</div>
                                                                        <span class="time">1998 - 2003</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <Link to="#/" class="name">American Dental Medical University</Link>
                                                                        <div>MDS</div>
                                                                        <span class="time">2003 - 2005</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <div class="widget experience-widget">
                                                    <h5 class="widget-title">Work &amp; Experience</h5>
                                                    <div class="experience-box">
                                                        <ul class="experience-list">
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <Link class="name">Glowing Smiles Family Dental Clinic</Link>
                                                                        <span class="time">2010 - Present (5 years)</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <Link class="name">Comfort Care Dental Clinic</Link>
                                                                        <span class="time">2007 - 2010 (3 years)</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <Link class="name">Dream Smile Dental Practice</Link>
                                                                        <span class="time">2005 - 2007 (2 years)</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="widget awards-widget">
                                                    <h5 class="widget-title">Awards</h5>
                                                    <div class="experience-box">
                                                        <ul class="experience-list">
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <p class="exp-year">July 2023</p>
                                                                        <h5 class="exp-title">Humanitarian Award</h5>
                                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="experience-user">
                                                                    <div class="before-circle"></div>
                                                                </div>
                                                                <div class="experience-content">
                                                                    <div class="timeline-content">
                                                                        <p class="exp-year">March 2011</p>
                                                                        <h5 class="exp-title">Certificate for International Volunteer Service</h5>
                                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="service-list">
                                                    <h4>Services</h4>
                                                    <ul class="clearfix">
                                                        <li>Tooth cleaning </li>
                                                        <li>Root Canal Therapy</li>
                                                        <li>Implants</li>
                                                        <li>Composite Bonding</li>
                                                        <li>Fissure Sealants</li>
                                                        <li>Surgical Extractions</li>
                                                    </ul>
                                                </div>
                                                <div class="service-list">
                                                    <h4>Specializations</h4>
                                                    <ul class="clearfix">
                                                        <li>Children Care</li>
                                                        <li>Dental Care</li>
                                                        <li>Oral and Maxillofacial Surgery </li>
                                                        <li>Orthodontist</li>
                                                        <li>Periodontist</li>
                                                        <li>Prosthodontics</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <div class="location-list">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="clinic-content">
                                                        <h4 class="clinic-name"><Link >Smile Cute Dental Care Center</Link></h4>
                                                        <p class="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                        <div class="rating">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <span class="d-inline-block average-rating">(4)</span>
                                                        </div>
                                                        <div class="clinic-details mb-0">
                                                            <h5 class="clinic-direction"> <i class="fas fa-map-marker-alt"></i> 2286 Sundown Lane, Austin, Texas 78749, USA <br />  <Link to="" >Get Directions</Link></h5>
                                                            <ul>
                                                                <li>
                                                                    <a href="assets/img/features/feature-01.jpg" data-fancybox="gallery2">
                                                                        <img src={profile02} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-02.jpg" data-fancybox="gallery2">
                                                                        <img src={profile01} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-03.jpg" data-fancybox="gallery2">
                                                                        <img src={profile03} alt="Feature" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-04.jpg" data-fancybox="gallery2">
                                                                        <img src={profile04} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <div class="clinic-timing">
                                                        <div>
                                                            <p class="timings-days">
                                                                <span> Mon - Sat </span>
                                                            </p>
                                                            <p class="timings-times">
                                                                <span>10:00 AM - 2:00 PM</span>
                                                                <span>4:00 PM - 9:00 PM</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class="timings-days">
                                                                <span>Sun</span>
                                                            </p>
                                                            <p class="timings-times">
                                                                <span>10:00 AM - 2:00 PM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    {/* <div class="consult-price">
                                                        $250
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="location-list">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="clinic-content">
                                                        <h4 class="clinic-name"><Link >Smile Cute Dental Care Center</Link></h4>
                                                        <p class="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>
                                                        <div class="rating">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <span class="d-inline-block average-rating">(4)</span>
                                                        </div>
                                                        <div class="clinic-details mb-0">
                                                            <h5 class="clinic-direction"> <i class="fas fa-map-marker-alt"></i> 2286 Sundown Lane, Austin, Texas 78749, USA <br />  <Link to="" >Get Directions</Link></h5>
                                                            <ul>
                                                                <li>
                                                                    <a href="assets/img/features/feature-01.jpg" data-fancybox="gallery2">
                                                                        <img src={profile02} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-02.jpg" data-fancybox="gallery2">
                                                                        <img src={profile01} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-03.jpg" data-fancybox="gallery2">
                                                                        <img src={profile03} alt="Feature" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="assets/img/features/feature-04.jpg" data-fancybox="gallery2">
                                                                        <img src={profile04} alt="Feature " />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <div class="clinic-timing">
                                                        <div>
                                                            <p class="timings-days">
                                                                <span> Mon - Sat </span>
                                                            </p>
                                                            <p class="timings-times">
                                                                <span>10:00 AM - 2:00 PM</span>
                                                                <span>4:00 PM - 9:00 PM</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class="timings-days">
                                                                <span>Sun</span>
                                                            </p>
                                                            <p class="timings-times">
                                                                <span>10:00 AM - 2:00 PM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    {/* <div class="consult-price">
                                                        $250
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        <div class="widget review-listing">
                                            <ul class="comments-list">

                                                <li className=''>
                                                    <div class="comment">
                                                        <img class="avatar avatar-sm rounded-circle" alt="User" src={user01} />
                                                        <div class="comment-body">
                                                            <div class="meta-data">
                                                                <span class="comment-author">Richard Wilson</span>
                                                                <span class="comment-date">Reviewed 2 Days ago</span>
                                                                <div class="review-count rating">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </div>
                                                            <p class="recommended"><i class="far fa-thumbs-up"></i> I recommend the doctor</p>
                                                            <p class="comment-content">
                                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                Ut enim ad minim veniam, quis nostrud exercitation.
                                                                Curabitur non nulla sit amet nisl tempus
                                                            </p>
                                                            <div class="comment-reply">
                                                                <Link class="comment-btn" href="#">
                                                                    <i class="fas fa-reply"></i> Reply
                                                                </Link>
                                                                <p class="recommend-btn">
                                                                    <span>Recommend?</span>
                                                                    <Link href="#" class="like-btn">
                                                                        <i class="far fa-thumbs-up"></i> Yes
                                                                    </Link>
                                                                    <Link href="#" class="dislike-btn">
                                                                        <i class="far fa-thumbs-down"></i> No
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ul class="comments-reply">
                                                        <li>
                                                            <div class="comment">
                                                                <img class="avatar avatar-sm rounded-circle" alt="User" src={user02} />
                                                                <div class="comment-body">
                                                                    <div class="meta-data">
                                                                        <span class="comment-author">Charlene Reed</span>
                                                                        <span class="comment-date">Reviewed 3 Days ago</span>
                                                                        <div class="review-count rating">
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star filled"></i>
                                                                            <i class="fas fa-star"></i>
                                                                        </div>
                                                                    </div>
                                                                    <p class="comment-content">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                        Ut enim ad minim veniam.
                                                                        Curabitur non nulla sit amet nisl tempus
                                                                    </p>
                                                                    <div class="comment-reply">
                                                                        <Link class="comment-btn" href="#">
                                                                            <i class="fas fa-reply"></i> Reply
                                                                        </Link>
                                                                        <p class="recommend-btn">
                                                                            <span>Recommend?</span>
                                                                            <Link href="#" class="like-btn">
                                                                                <i class="far fa-thumbs-up"></i> Yes
                                                                            </Link>
                                                                            <Link href="#" class="dislike-btn">
                                                                                <i class="far fa-thumbs-down"></i> No
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                </li>


                                                <li className=' border-top border-1 pt-5'>
                                                    <div class="comment">
                                                        <img class="avatar avatar-sm rounded-circle" alt="User " src={user03} />
                                                        <div class="comment-body">
                                                            <div class="meta-data">
                                                                <span class="comment-author">Travis Trimble</span>
                                                                <span class="comment-date">Reviewed 4 Days ago</span>
                                                                <div class="review-count rating">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </div>
                                                            <p class="comment-content">
                                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                Ut enim ad minim veniam, quis nostrud exercitation.
                                                                Curabitur non nulla sit amet nisl tempus
                                                            </p>
                                                            <div class="comment-reply">
                                                                <Link class="comment-btn" href="#">
                                                                    <i class="fas fa-reply"></i> Reply
                                                                </Link>
                                                                <p class="recommend-btn">
                                                                    <span>Recommend?</span>
                                                                    <Link href="#" class="like-btn">
                                                                        <i class="far fa-thumbs-up"></i> Yes
                                                                    </Link>
                                                                    <Link href="#" class="dislike-btn">
                                                                        <i class="far fa-thumbs-down"></i> No
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                            <div class="all-feedback text-center">
                                                <Link href="#" class="btn btn-primary btn-sm">
                                                    Show all feedback <strong>(167)</strong>
                                                </Link>
                                            </div>


                                        </div>
                                        <div class="write-review">
                                            <h4>Write a review for <strong>Dr. Darren Elder</strong></h4>
                                            <form>
                                                <div class="mb-3">
                                                    <label class="mb-2">Review</label>
                                                    <div class="star-rating">
                                                        <input id="star-5" type="radio" name="rating" value="star-5" />
                                                        <label for="star-5" title="5 stars"><i class="active fa fa-star"></i></label>
                                                        <input id="star-4" type="radio" name="rating" value="star-4" />
                                                        <label for="star-4" title="4 stars"><i class="active fa fa-star"></i></label>
                                                        <input id="star-3" type="radio" name="rating" value="star-3" />
                                                        <label for="star-3" title="3 stars"><i class="active fa fa-star"></i></label>
                                                        <input id="star-2" type="radio" name="rating" value="star-2" />
                                                        <label for="star-2" title="2 stars"> <i class="active fa fa-star"></i> </label>
                                                        <input id="star-1" type="radio" name="rating" value="star-1" />
                                                        <label for="star-1" title="1 star"><i class="active fa fa-star"></i></label>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="mb-2">Title of your review</label>
                                                    <input class="form-control" type="text" placeholder="If you could say it in one sentence, what would you say?" />
                                                </div>
                                                <div class="mb-3">
                                                    <label class="mb-2">Your review</label>
                                                    <textarea id="review_desc" maxlength="100" class="form-control" data-gramm="false" wt-ignore-input="true" data-quillbot-element="6ESyPF2msLezz8iza6dTK"></textarea>
                                                    <div class="d-flex justify-content-between mt-3"><small class="text-muted"><span id="chars">100</span> characters remaining</small></div>
                                                </div>
                                                <hr />
                                                <div class="mb-3">
                                                    <div class="terms-accept">
                                                        <div class="custom-checkbox">
                                                            <input type="checkbox" id="terms_accept" />
                                                            <label for="terms_accept">I have read and accept <a href="#">Terms &amp; Conditions</a></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="submit-section">
                                                    <button type="submit" class="btn btn-primary submit-btn">Add Review</button>
                                                </div>
                                            </form>
                                        </div>

                                    </TabPanel>
                                    <TabPanel value={value} index={3} dir={theme.direction}>
                                        <div class="row">
                                            <div class="col-md-6 offset-md-3">

                                                <div class="widget business-widget">
                                                    <div class="widget-content">
                                                        <div class="listing-hours">
                                                            <div class="listing-day current">
                                                                <div class="day">Today <span>5 Nov 2023</span></div>
                                                                <div class="time-items">
                                                                    <span class="open-status"><span class="badge bg-success">Open Now</span></span>
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Monday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Tuesday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Wednesday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Thursday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Friday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day">
                                                                <div class="day">Saturday</div>
                                                                <div class="time-items">
                                                                    <span class="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div class="listing-day closed">
                                                                <div class="day">Sunday</div>
                                                                <div class="time-items">
                                                                    <span class="time"><span class="badge bg-danger">Closed</span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel value={value} index={4} dir={theme.direction}>
                                        <ImageList sx={{ width: 500, height: 450, margin: 'auto' }} cols={3} rowHeight={164}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </TabPanel>
                                </SwipeableViews>
                            </Box>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
