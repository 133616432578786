import React from 'react'
import pcare01 from "../../Assets/pcare01.jpg"
import pcare02 from "../../Assets/pcare02.jpg"
import pcare03 from "../../Assets/pcare03.jpg"
import avatar from "../../Assets/avtar_img.jpeg"
import "./Blog.scss"
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'

import Navbar from "../../components/Nav/Navbar"



export default function Blog() {
  return (

    <>

      <Navbar />

      <div className='page-wrap'>

        <div className="Blog-container">
          <div className="container">
            <div class="col-md-7 col-sm-8 col-xs-12">
              <div class="page-description">
                <h4 class="page-title">Latest Blog</h4>
                <h1 class="page-text">Explore the latest insights and articles from our skilled dental professionals.</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="blogs my-5">
          <div className="container">
            <div className="row">
              <div class="col-lg-8 col-md-12">
                <div class="blog">
                  <div class="blog-image">
                    <Link to=""><img class="img-fluid" src={pcare01} alt="Post " /></Link>
                  </div>
                  <h4 class="blog-title">
                    <Link to="" >Doccure – Making your clinic painless visit?</Link></h4>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <Link  ><img src={avatar} alt="Post Author" /> <span>Dr. Ruby Perrin</span></Link>
                          </div>
                        </li>
                        <li><i class="far fa-clock"></i> 4 Dec 2023</li>
                        <li><i class="far fa-comments"></i> 12 Comments</li>
                        <li><i class="fa fa-tags"></i> Health Tips</li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit laboris ullamco laborisut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Link to="/view-blog" class="read-more">View Blog</Link>
                  </div>
                </div>


                <div class="blog">
                  <div class="blog-image">
                    <Link to="" ><img class="img-fluid" src={pcare02} alt="blog" /></Link>
                  </div>
                  <h4 class="blog-title"><a href="blog-details.html">What are the benefits of Online Doctor Booking?</a></h4>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <Link to="" ><img src={avatar} alt="Post Author" /> <span>Dr. Darren Elder</span></Link>
                          </div>
                        </li>
                        <li><i class="far fa-clock"></i> 3 Dec 2023</li>
                        <li><i class="far fa-comments"></i> 7 Comments</li>
                        <li><i class="fa fa-tags"></i> Cardiology</li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit laboris ullamco laborisut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Link to="/view-blog" class="read-more">View Blog</Link>
                  </div>
                </div>


                <div class="blog">
                  <div class="blog-image">
                    <Link ><img class="img-fluid" src={pcare03} alt="blog" /></Link>
                  </div>
                  <h4 class="blog-title"><Link >Benefits of consulting with an Online Doctor</Link></h4>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <Link to="/view-blog" ><img src={avatar} alt="Post Author" /> <span>Dr. Deborah Angel</span></Link>
                          </div>
                        </li>
                        <li><i class="far fa-clock"></i> 3 Dec 2023</li>
                        <li><i class="far fa-comments"></i> 2 Comments</li>
                        <li><i class="fa fa-tags"></i> Health Care</li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit laboris ullamco laborisut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Link to="/" class="read-more">View Blog</Link>
                  </div>
                </div>


                <div class="blog">
                  <div class="blog-image">
                    <Link to="/" ><img class="img-fluid" src={pcare02} alt="blog" /></Link>
                  </div>
                  <h4 class="blog-title"><Link >5 Great reasons to use an Online Doctor</Link></h4>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <Link to="/view-blog" ><img src={avatar} alt="Post Author" /> <span>Dr. Sofia Brient</span></Link>
                          </div>
                        </li>
                        <li><i class="far fa-clock"></i> 2 Dec 2023</li>
                        <li><i class="far fa-comments"></i> 41 Comments</li>
                        <li><i class="fa fa-tags"></i> Health Tips</li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit laboris ullamco laborisut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <Link to="" class="read-more">View Blog</Link>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-12">
                    <div class="blog-pagination">
                      <nav>
                        <ul class="pagination justify-content-center">
                          <li class="page-item disabled">
                            <Link class="page-link" to="" tabindex="-1"><i class="fas fa-angle-double-left"></i></Link>
                          </li>
                          <li class="page-item">
                            <Link class="page-link" to="" >1</Link>
                          </li>
                          <li class="page-item active">
                            <Link class="page-link" to="" >2 <span class="visually-hidden">(current)</span></Link>
                          </li>
                          <li class="page-item">
                            <Link class="page-link" to="" >3</Link>
                          </li>
                          <li class="page-item">
                            <Link class="page-link" to="" ><i class="fas fa-angle-double-right"></i></Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
                <div class="theiaStickySidebar" >
                  <div class="card search-widget">
                    <div class="card-body">
                      <form class="search-form">
                        <div class="input-group">
                          <input type="text" placeholder="Search..." class="form-control" />
                          <button type="submit" class=" theme_btn"><i class="fa fa-search"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="card post-widget">
                    <div class="card-header">
                      <h4 class="card-title">Latest Posts</h4>
                    </div>
                    <div class="card-body">
                      <ul class="latest-posts">
                        <li>
                          <div class="post-thumb">
                            <Link to="">
                              <img class="img-fluid" src={pcare01} alt="blog" />
                            </Link>
                          </div>
                          <div class="post-info">
                            <h6>  <Link to="" >Doccure – Making your clinic painless visit?</Link> </h6>
                            <p>4 Dec 2023</p>
                          </div>
                        </li>
                        <li>
                          <div class="post-thumb">
                            <Link to="">
                              <img class="img-fluid" src={pcare02} alt="blog" />
                            </Link>
                          </div>
                          <div class="post-info">
                            <h6>
                              <Link >What are the benefits of Online Doctor Booking?</Link>
                            </h6>
                            <p>3 Dec 2023</p>
                          </div>
                        </li>
                        <li>
                          <div class="post-thumb">
                            <Link to="">
                              <img class="img-fluid" src={pcare03} alt="blog" />
                            </Link>
                          </div>
                          <div class="post-info">
                            <h6>
                              <Link to="">Benefits of consulting with an Online Doctor</Link>
                            </h6>
                            <p>3 Dec 2023</p>
                          </div>
                        </li>
                        <li>
                          <div class="post-thumb">
                            <Link to="">
                              <img class="img-fluid" src={pcare01} alt="blog" />
                            </Link>
                          </div>
                          <div class="post-info">
                            <h6>
                              <Link to="" >5 Great reasons to use an Online Doctor</Link>
                            </h6>
                            <p>2 Dec 2023</p>
                          </div>
                        </li>
                        <li>
                          <div class="post-thumb">
                            <Link to="">
                              <img class="img-fluid" src={pcare02} alt="blog" />
                            </Link>
                          </div>
                          <div class="post-info">
                            <h6>
                              <Link to="">Online Doctor Appointment Scheduling</Link>
                            </h6>
                            <p>1 Dec 2023</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card category-widget">
                    <div class="card-header">
                      <h4 class="card-title">Blog Categories</h4>
                    </div>
                    <div class="card-body">
                      <ul class="categories">
                        <li><Link >Cardiology <span>(62)</span></Link></li>
                        <li><Link >Health Care <span>(27)</span></Link></li>
                        <li><Link >Nutritions <span>(41)</span></Link></li>
                        <li><Link >Health Tips <span>(16)</span></Link></li>
                        <li><Link >Medical Research <span>(55)</span></Link></li>
                        <li><Link >Health Treatment <span>(07)</span></Link></li>
                      </ul>
                    </div>
                  </div>
                  <div class="card tags-widget">
                    <div class="card-header">
                      <h4 class="card-title">Tags</h4>
                    </div>
                    <div class="card-body">
                      <ul class="tags">
                        <li><Link class="tag">Children</Link></li>
                        <li><Link class="tag">Disease</Link></li>
                        <li><Link class="tag">Appointment</Link></li>
                        <li><Link class="tag">Booking</Link></li>
                        <li><Link class="tag">Kids</Link></li>
                        <li><Link class="tag">Health</Link></li>
                        <li><Link class="tag">Family</Link></li>
                        <li><Link class="tag">Tips</Link></li>
                        <li><Link class="tag">Shedule</Link></li>
                        <li><Link class="tag">Treatment</Link></li>
                        <li><Link class="tag">Dr</Link></li>
                        <li><Link class="tag">Clinic</Link></li>
                        <li><Link class="tag">Online</Link></li>
                        <li><Link class="tag">Health Care</Link></li>
                        <li><Link class="tag">Consulting</Link></li>
                        <li><Link class="tag">Doctors</Link></li>
                        <li><Link class="tag">Neurology</Link></li>
                        <li><Link class="tag">Dentists</Link></li>
                        <li><Link class="tag">Specialist</Link></li>
                        <li><Link class="tag">Doccure</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>


    </>

  )
}
