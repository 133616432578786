import React, { useEffect, useState } from 'react';
import aboutIcon from "../../Assets/aboutic.png";
import abouthero from "../../Assets/smiles.jpg";
import Footer from '../../components/Footer/Footer';
import Navbar from "../../components/Nav/Navbar";

import { aboutUsService } from '../../utils/httpServices/httpService';

export default function AboutUs() {

  const [aboutUs,setAboutUs] = useState({
    first_section:"",
    second_section:"",
    card_section:[]
  });

  const about_us_base_url = process.env.REACT_APP_CMS;

  async function getAboutUs() {
    try {
      const resp = await aboutUsService();
      console.log("about us data ", resp);
      if (resp?.code === 200) {
        console.log("resp",resp.data)
        setAboutUs({
          first_section:resp.data.first_section,
          second_section:resp.data.second_section,
          card_section:resp.data.card_section,
        })
      }
    } catch (err) {
      console.log(err)
      // alert(err)
    }
  }

  useEffect(() => {
    getAboutUs();
  }, []);

  return (

    <>
     <Navbar/>

      <div className='about_us page-wrap'>
        <section className="hero-container pb-0">
          <div className="container">
            <div className="about">
              <div className="about__photo ">
                <div class="about__frame">
                  <img src={aboutUs.first_section.side_image ? (about_us_base_url + aboutUs.first_section.side_image) : abouthero} className='' alt='A dentist Doctor' />
                </div>
                <div className='rectangle'></div>
              </div>
              <div className="about__body half">
                <h4 className="about__subtitle">About Us</h4>
                <h1 className="about__title">{aboutUs.first_section.title ? aboutUs.first_section.title : ''}</h1>
                <p className='about__description'>{aboutUs.first_section.description ? aboutUs.first_section.description : ''}</p>
              </div>
            </div>
          </div>
        </section>
        <section className='whychoose-container'>
          <div className="whychoose">
            <div className="container">
              <div className="whychoose__heading_text">
                <h2 className="whychoose__title text-center">{aboutUs.second_section.title ? aboutUs.second_section.title : ''}</h2>
                <p className='whychoose__heading-desc text-center'>{aboutUs.second_section.description ? aboutUs.second_section.description : ''}</p>
              </div>
              <div className='whychoose__boxes'>
                <div className="row justify-content-center g-4">
                  {aboutUs.card_section && aboutUs.card_section.length > 0 ? (aboutUs.card_section.map((ele) => (<div className='col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12'>
                    <div className='whychoose__box '>
                      <div className='whychoose__icon'>
                        {/* <svg xmlns={ele.icon ? (about_us_base_url + ele.icon) : 'http://www.w3.org/2000/svg'} viewBox="0 0 48 48"><title>{ele.icon ? (about_us_base_url + ele.icon) : ''}</title><g id="Emergency_call" data-name="Emergency call"><path d="M34,0H14A5,5,0,0,0,9,5V43a5,5,0,0,0,5,5H34a5,5,0,0,0,5-5V5A5,5,0,0,0,34,0ZM30,2V3a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V2Zm7,41a3,3,0,0,1-3,3H14a3,3,0,0,1-3-3V5a3,3,0,0,1,3-3h2V3a3,3,0,0,0,3,3H29a3,3,0,0,0,3-3V2h2a3,3,0,0,1,3,3Z" /><path d="M33,42H15a1,1,0,0,0,0,2H33A1,1,0,0,0,33,42Z" /><path d="M19,35V27c0-1.33-1.36-1-5-1-1.33,0-1,1.36-1,5,0,1.34,1.56,1,4,1v2H14a1,1,0,0,0,0,2C17.58,36,19,36.35,19,35Zm-4-5V28h2v2Z" /><path d="M22,34a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2H25c0-8.74.83-8-3-8a1,1,0,0,0,0,2h1v6Z" /><path d="M34,34H33c0-8.74.83-8-3-8a1,1,0,0,0,0,2h1v6H30a1,1,0,0,0,0,2h4A1,1,0,0,0,34,34Z" /><path d="M21,19c0,4.68-.76,4,5,4,1.34,0,1-1.56,1-4h3a1,1,0,0,0,1-1c0-5.75.72-5-4-5,0-4.68.76-4-5-4-1.34,0-1,1.56-1,4H18a1,1,0,0,0-1,1C17,19.75,16.28,19,21,19Zm-2-4c4.57,0,4,.61,4-4h2c0,4.57-.61,4,4,4v2c-4.57,0-4-.61-4,4H23c0-4.57.61-4-4-4Z" /></g></svg> */}
                        <img src={ele.icon ? (about_us_base_url + ele.icon) : aboutIcon} className='aboutIc' alt="" />
                      </div>
                      <h4 className='whychoose__sub_heading'>{ele.title ? ele.title : ''}</h4>
                      <p className='whychoose__desc'>{ele.description ? ele.description : ''}</p>
                    </div>
                  </div>))) : <></>}
                  {/* <div className='col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12'>
                    <div className='whychoose__box'>
                      <div className='whychoose__icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>hospital</title><g id="hospital"><path d="M29,28H28V16a1,1,0,0,0-1-1H21V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V28H3a1,1,0,0,0,0,2H29a1,1,0,0,0,0-2ZM26,17V28H21V17ZM15,28H10V22.05h5Zm2,0V21.05a1,1,0,0,0-1-1H9a1,1,0,0,0-1,1v7H6V4H19V28ZM12.5,11a1,1,0,0,1-1-1V9h-1a1,1,0,0,1,0-2h1V6a1,1,0,0,1,2,0V7h1a1,1,0,0,1,0,2h-1v1A1,1,0,0,1,12.5,11ZM12,14a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2h2A1,1,0,0,1,12,14Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h2A1,1,0,0,1,17,14Zm-5,3a1,1,0,0,1-1,1H9a1,1,0,0,1,0-2h2A1,1,0,0,1,12,17Zm5,0a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h2A1,1,0,0,1,17,17Z" /></g></svg>                    </div>
                      <h4 className='whychoose__sub_heading'>Visit Now</h4>
                      <p className='whychoose__desc'>All of the dentist we recommend meet your standards. We visit, interview, and background check every. </p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12'>
                    <div className='whychoose__box'>
                      <div className='whychoose__icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.848" height="24.047" viewBox="0 0 23.848 24.047">
                          <g>
                            <path d="M11.92,24.047A11.918,11.918,0,0,0,23.844,12.456a12.066,12.066,0,0,0-.1-1.944l-1.982.27a9.937,9.937,0,1,1-3.98-6.657L14.9,7h7V0l-2.7,2.7A11.922,11.922,0,1,0,11.92,24.047Z" />
                            <path d="M7.25,14.433l2.3-1.1.1-.058a2.388,2.388,0,0,0,1.313-2.009l.007-.759A2.509,2.509,0,0,0,8.465,8H7.257A2.239,2.239,0,0,0,4.9,10.327V11h2v-.673c0-.237.031-.327.354-.327H8.465a.5.5,0,0,1,.506.5l0,.736s-.05.127-.347.325L6.6,12.538l-.351.16A2.5,2.5,0,0,0,4.9,14.743V17H10.67V15H6.9v-.229A.985.985,0,0,1,7.25,14.433Z" />
                            <polygon points="17.903 17 17.903 9 15.903 9 15.903 12 13.903 12 13.903 8 11.903 8 11.903 14 15.903 14 15.903 17 17.903 17" />
                          </g>
                        </svg>

                      </div>
                      <h4 className='whychoose__sub_heading'>24 Hour Open</h4>
                      <p className='whychoose__desc'>All of the dentist we recommend meet your standards. We visit, interview, and background check every. </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

    </>

  )
}
