import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export function ChildModal({
  open1,
  handleClose1,
  slotDay,
  slots,
  setAddSlots_addProvider,
  setOpen1,
}) {
  console.log("day", slotDay, "slots prop", slots);

  const [start_time_addProvider, setStart_time_addProvider] =
    React.useState("");
  const [end_time_addProvider, setEnd_time_addProvider] = React.useState("");

  const [allSlot, setAllSlot] = React.useState([]);
  console.log("🚀 ~ allSlot:", allSlot);

  useEffect(() => {
    let data = structuredClone(slots);
    setAllSlot(data);
  }, [slots]);

  let selected_slot = allSlot.filter((ele) => slotDay === ele.day);

  let remaining_slot = allSlot.filter((ele) => slotDay !== ele.day);

  const [storeSlotTimes, setStoreSlotsTime] = useState([
    { start: "", end: "" },
  ]);
  console.log("🚀 ~ storeSlotTimes:", storeSlotTimes);

  useEffect(() => {
    let selected_slot = allSlot.filter((ele) => slotDay === ele.day);
    console.log("🚀 ~ useEffect ~ selected_slot:", selected_slot);
    if (selected_slot?.[0]?.slot.length > 0) {
      setStoreSlotsTime(selected_slot?.[0]?.slot);
    }
  }, [allSlot, slotDay]);

  const addingTimeSlot = () => {
    console.log(
      "adding Time slot works",
      start_time_addProvider,
      end_time_addProvider
    );
    selected_slot[0].slot.push({
      start: start_time_addProvider,
      end: end_time_addProvider,
    });
    console.log("slected time slot array", selected_slot);
    remaining_slot.push({
      day: slotDay,
      slot: selected_slot[0].slot,
    });
    console.log("new slots", remaining_slot);
    setAllSlot(remaining_slot);

    setStart_time_addProvider("");
    setEnd_time_addProvider("");

    console.log("all slots", allSlot);
  };

  const removeTimeSlot = (i) => {
    console.log("index", i);
    if (selected_slot[0].slot.length !== 0) {
      selected_slot[0].slot.splice(i, 1);
      remaining_slot.push({
        day: slotDay,
        slot: selected_slot[0].slot,
      });
      console.log("new slots after remove", remaining_slot);
      setAllSlot(remaining_slot);

      console.log("all slots", allSlot);
    } else {
      setAllSlot([]);
    }
  };

  function savingSlotsFunction() {
    const filteredStoreSlotTimes = storeSlotTimes.filter(
      (slot) => slot.start !== "" || slot.end !== ""
    );
    const finalSlotArray = [...allSlot];
    const slotIndex = allSlot.findIndex((obj) => obj.day === slotDay);
    console.log("🚀 ~ savingSlotsFunction ~ slotIndex:", slotIndex);
    if (slotIndex === -1) {
      finalSlotArray.push({ day: slotDay, slot: filteredStoreSlotTimes });
    } else {
      finalSlotArray[slotIndex] = {
        ...finalSlotArray[slotIndex],
        slot: filteredStoreSlotTimes,
      };
    }

    // const finalSlotArray = allSlot.map((obj) => {
    //   if (obj.day === slotDay) {
    //     return { ...obj, slot: filteredStoreSlotTimes };
    //   }
    //   return obj;
    // });
    console.log("🚀 ~ finalSlotArray ~ finalSlotArray:", finalSlotArray);

    setAddSlots_addProvider(finalSlotArray);
    setOpen1(false);
  }

  // const settingTime = (e,time_type) => {
  //     let today_date = moment();
  //     console.log("start",today_date);
  //     let time = "2023-12-06T"+e.target.value+":00.000Z";
  //     console.log("time",time);
  //     setTimeSlot({
  //      ...addtimeSlot,
  //      [time_type]:time
  //    })
  //    console.log("update time",addtimeSlot);
  //  }

  //  function timeConversion(time){
  //     if(time !== ""){
  //      let hours_min = time.split('T')[1].split(':');
  //     //  console.log("hours",hours_min);
  //      return hours_min[0]+':'+hours_min[1]
  //     }
  //     else {
  //       return "";
  //     }
  //     }

  const handleSlottimeChange = (e, index) => {
    const { value, name } = e.target;
    setStoreSlotsTime((prev) => {
      const slotArray = [...prev];

      slotArray[index] = { ...slotArray[index], [name]: value };

      return slotArray;
    });
  };

  const handleSlotAdd = () => {
    setStoreSlotsTime((prev) => {
      const updatedArray = [...prev];
      updatedArray.push({ start: "", end: "" });
      return updatedArray;
    });
  };

  const handleSlotRemove = (index) => {
    setStoreSlotsTime((prev) => {
      const SlotArray = [...prev];

      const filteredArray = SlotArray.filter((_, i) => i !== index);

      return filteredArray;
    });
  };

  return (
    <React.Fragment>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-container"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Box className="modal-content edit_hoursmodal">
          <span className="cross_mdl" onClick={handleClose1}>
            <i class="fa-solid fa-xmark"></i>
          </span>
          <div className="time-slot">
            <div class="hours-info">
              {storeSlotTimes?.map((item, index) => (
                <div class="row hours-cont align-items-center">
                  <div class="col-12 col-md-9">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="mb-2 form-label">Start Time</label>
                          <input
                            type="time"
                            className="time_input form-control"
                            name="start"

                            id=""
                            value={item?.start}
                            onChange={(e) =>
                              // setStart_time_addProvider(e.target.value)
                              handleSlottimeChange(e, index)
                            }
                            //     value={addtimeSlot.start_time !== "" ? timeConversion(addtimeSlot.start_time) : ""}
                            //     onChange={(e) => settingTime(e,'start')
                            // }
                          />
                          {/* <select class="form-select form-control">
                                                          <option>-</option>
                                                          <option selected="">12.00 am</option>
                                                          <option>12.30 am</option>
                                                          <option>1.00 am</option>
                                                          <option>1.30 am</option>
                                                      </select> */}
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="mb-2 form-label">End Time</label>
                          {/* <select class="form-select form-control">
                                                          <option>-</option>
                                                          <option>12.00 am</option>
                                                          <option selected="">12.30 am</option>
                                                          <option>1.00 am</option>
                                                          <option>1.30 am</option>
                                                      </select> */}
                          <input
                            type="time"
                            className="time_input form-control"
                            name="end"
                            id=""
                            value={item?.end}
                            onChange={(e) =>
                              // setEnd_time_addProvider(e.target.value)
                              handleSlottimeChange(e, index)
                            }
                            //    value={addtimeSlot.start_time !== "" ? timeConversion(addtimeSlot.end_time): ""}
                            //    onChange={(e) => settingTime(e,'end')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mt-2">
                      {index === 0 ? (
                        <button
                          class="theme-outline-btn px-3 py-2"
                          type="submit"
                          // onClick={addingTimeSlot}
                          onClick={handleSlotAdd}
                        > Add time slot
                          <i class="ms-2 fa fa-plus-circle"></i>
                        </button>
                      ) : (
                        <button
                          class="btn btn-danger"
                          onClick={() => handleSlotRemove(index)}
                        >
                          <i class="fa-regular fa-trash-can"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {/* <div class="row hours-cont align-items-center">
                  <div class="col-12 col-md-10">
                    {allSlot
                      .filter((ele) => slotDay === ele.day)[0]
                      ?.slot?.map((ele2, i) => (
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="mb-3">
                              <label class="mb-2">Start Time</label>
                              <input
                                type="time"
                                className="time_input"
                                name="start"
                                id="start"
                                value={ele2.start}
                                onChange={(e) => {
                                  ele2.start = e.target.value;
                                  setAllSlot([...allSlot]);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="mb-3">
                              <label class="mb-2">End Time</label>
                              <input
                                type="time"
                                className="time_input"
                                name="end_time"
                                id="end_time"
                                value={ele2.end}
                                onChange={(e) => {
                                  ele2.end = e.target.value;
                                  setAllSlot([...allSlot]);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="mt-2">
                              <button
                                class="btn btn-danger"
                                onClick={() => removeTimeSlot(i)}
                              >
                                <i class="fa-regular fa-trash-can"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div> */}
            </div>
          </div>
          <div class="submit-section text-center">
            <button
              className="theme_btn rounded"
              type="button"
              onClick={() => savingSlotsFunction()}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default ChildModal;
