import React, { useEffect, useState } from "react";
// import { brows } from 'react-router';
import { Link } from "react-router-dom";
import logo from "../../Assets/Logos/company-logo2.png";
import "./Recommendation.scss";
import user01 from "../../Assets/user1.jpg";
import user02 from "../../Assets/user2.jpg";
import user03 from "../../Assets/user3.jpg";

// --slick--slider---

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addQuestionnaireToEmail,
  getHomeContentService,
} from "../../utils/httpServices/httpService";

const testimonials = [
  {
    id: 1,
    name: "John Doe",
    review:
      '"Everyone was great. All the staff were pleasant. They took my son in and worked on his teeth. They kept talking to my son so he doesnt get nervous. My son had a great time. I would definitely recommend them."',
    image: user01, // Add your image file in the public folder and update the path
  },
  {
    id: 2,
    name: "John Doe",
    review:
      '"I\'m so glad I went through Opencare to find a new dentist. Very satisfied! I have been in need of a new dentist for a year and Opencare made the process very simple."',
    image: user02, // Add your image file in the public folder and update the path
  },
  {
    id: 3,
    name: "John Doe",
    review:
      '"It was a really good experience. The place is super neat and clean, the staff is really friendly and professional. I did not have to wait a minute and overall it was an excellent experience. I will definitely come back!!"',
    image: user03, // Add your image file in the public folder and update the path
  },
  {
    id: 4,
    name: "John Doe",
    review:
      '"Literally the best dental visit l have ever had. As someone who has had a challenging dental history and very sensitive teeth, it\'s obvious that everyone at this practice is kind and truly cares."',
    image: user01, // Add your image file in the public folder and update the path
  },
  // Add more testimonials as needed
];

export default function Recommendation() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [testimonials, setTestimonial] = useState([]);

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: true, // Display navigation arrows
    dots: false, // Hide dots navigation
  };

  const addQuestionToEmail = async () => {
    try {
      const cd = localStorage.getItem("seraching_details");
      const clinicData = JSON.parse(cd);
      if (email === "") {
        toast.error("Please add email.");
        return;
      }
      clinicData.email = email;
      const body = clinicData;

      const resp = await addQuestionnaireToEmail(body);
      console.log(resp);
      listHandler();
      // localStorage.removeItem("retainedData")
    } catch (error) {}
  };

  const listHandler = () => {
    let account_type = null;
    const token = localStorage.getItem("token");
    const v = localStorage.getItem("user_details");

    const user_data = JSON.parse(v);


    if (user_data !== null) {
      const parsedData = user_data;
      account_type = parsedData?.user_type;
    }
   
    if (token === null) {
  
      navigate("/dentist-lists");

    } else if (token !== null) {
   
      navigate("/dentist-list");
    }
  };

  async function getTestimonials() {
    try {
      const resp = await getHomeContentService();
      console.log(resp);
      setTestimonial(resp?.data?.patient_testimonials?.testimonials);
    } catch (error) {}
  }

  useEffect(() => {
    getTestimonials();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addQuestionToEmail();
    }
  };

  return (
    <div className="top-search">
      <div className="container">
        <div className="top-search__top-dtl">
          <Link className="logo mb-2" to="/">
            <img src={logo} alt="" />
          </Link>
          <h3 className="top-search__title">
            We found several dentists that match your criteria.
          </h3>
          {/* <button className='theme-outline-btn text-black' onClick={listHandler}>Show Results</button> */}
        </div>
        <div className="top-search__bottom-dtl">
          <h4 className="text-center">
            {/* To ensure the security of your booking, please provide your email
            address. */}
            What is your email?
          </h4>
          <p className="top-search__desc text-center mb-4">
            Easily book your appointment and recieve timely reminders. Your
            privacy will be respected.{" "}
          </p>
          <input
            type="text"
            placeholder="Enter your email"
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div class="d-flex gap-4 justify-content-center align-items-center mt-4 mb-5">
          <button
            class="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={listHandler}
          >
            Skip
          </button>
          <button class="theme_btn" type="button" onClick={addQuestionToEmail}>
            Show Results
          </button>
        </div>
        <div className="testimonial-container pt-5">
          <div className="testimonial">
            <div className="row ">
              <div className="col-md-8 col-sm-8 m-auto">
                <div className="testimonial-section">
                  <Slider {...settings}>
                    {testimonials?.map((testimonial) => (
                      <div key={testimonials?.id} className="testimonial-item">
                        <img
                          src={
                            testimonial?.image
                              ? process.env.REACT_APP_CMS + testimonial?.image
                              : process.env.REACT_APP_DEFAULT_IMAGE_URL
                          }
                          alt={testimonial?.name}
                          className="user-image"
                        />
                        <p className="user-name">{testimonial?.name}</p>
                        <p className="user-review">
                          {testimonial?.description}
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
