import { createSlice } from "@reduxjs/toolkit";
import {useSelector } from 'react-redux';

const selectedBranch = createSlice({
    name:'selected_branch_data',
    initialState:0,
    reducers: {
        updateClinicBranchIndex(state,action){
            console.log("state",state,"and action", action)
            return action.payload
        }
    }
    }
    )

 export default selectedBranch.reducer; 
 export const {updateClinicBranchIndex } = selectedBranch.actions;