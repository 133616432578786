import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import AboutUs from "../pages/AboutUs/AboutUs";
import Blog from "../pages/Blog/Blog";
import Support from "../pages/Support/Support";
import Homepage from "../pages/Home/Homepage";
import FindDentist from "../pages/FindDentist/FindDentist";
import Login from "../Authenticate/Login/Login";
import SignUp from "../Authenticate/SignUp/SignUp";
import ForgotPassword from "../Authenticate/ForgotPassword/ForgotPassword";
import Questionnair from "../pages/Questionnair/Questionnair";
import DentistList from "../pages/Dentist-List/DentistList";
import DentistLists from "../pages/Dentist-Lists/DentistLists";
import Recommendation from "../components/Recommendation/Recommendation";
import ProviderReg from "../Authenticate/ProviderReg/ProviderReg";
import Appointment from "../pages/Appointment/Appointment";
import Appointments from "../pages/Appointments/Appointments";
import DentistProfile from "../pages/Dentist-Profile/DentistProfile";
import TermsOfUse from "../pages/Terms-of-Use/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import ProviderHomepage from "../pages/ProviderHomepage/ProviderHomepage";
import ProviderProfile from "../pages/ProviderHomepage/ProviderProfile/ProviderProfile";
import ViewBlog from "../pages/ViewBlog/ViewBlog";
import UserProfile from "../pages/UserProfile/UserProfile";
import ChatBox from "../pages/Chat-box/ChatBox";
import Calender from "../pages/ProviderHomepage/Calender/Calender";
import DoctorList from "../pages/ProviderHomepage/Doctor/DoctorList";
import DoctorProfile from "../pages/ProviderHomepage/Doctor/DoctorProfile";
import ProviderLogin from "../Authenticate/ProviderLogin/ProviderLogin";
import ProviderFooter from "../components/ProviderFooter/ProviderFooter";
import ProviderResetPassword from "../Authenticate/ProviderResetPassword/ProviderResetPassword";
import ProviderForgot from "../Authenticate/ProviderForgot/ProviderForgot";
import Navbar from "../components/Nav/Navbar";
import AppointmentRequest from "../pages/ProviderHomepage/AppointmentRequest/AppointmentRequest";
import Billings from "../pages/ProviderHomepage/Billings/Billings";
import Performance from "../pages/ProviderHomepage/Performance/Performance";
import Dashboard from "../pages/ProviderHomepage/Dashboard/Dashboard";
import ProvdiderChatBox from "../pages/ProviderHomepage/ProviderChat-box/ProvdiderChatBox";

import { PrivateRoute } from "../private-routes";
import ResetPassword from "../Authenticate/ResetPassword/ResetPassword";
import RecentSearch from "../pages/RecentSearch/RecentSearch";

export default function AllRoute() {

    const user_details =  JSON.parse(localStorage.getItem("user_details"))

  return (
    <Routes>
  {/* {  user_details?.user_type === "clinic" ?
  <Route
        path="/"
        element={
          <PrivateRoute account_type={"clinic"}>
            <Dashboard />
          </PrivateRoute>
        }
      ></Route>
  : 
      <Route path="/" element={<Homepage />}></Route>
  } */}
  <Route path="/" element={<Homepage />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/SignUp" element={<SignUp />}></Route>
      <Route path="/about" element={<AboutUs />}></Route>
      <Route path="/blog" element={<Blog />}></Route>
      <Route path="/view-blog" element={<ViewBlog />}></Route>
      <Route path="/find-dentist" element={<FindDentist />}></Route>
      <Route path="/support" element={<Support />}></Route>
      <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
      <Route path="/reset-password/:data" element={<ResetPassword />}></Route>
      <Route path="/questionnair" element={<Questionnair />}></Route>
      <Route path="/recommendation" element={<Recommendation />}></Route>
      <Route path="/recentSearch" element={<RecentSearch />}></Route>
      <Route path="/termsofuse" element={<TermsOfUse />}></Route>
      <Route path="/privacy-&-policy" element={<PrivacyPolicy />}></Route>
      {/* <Route path="/providerLogin" element={<ProviderLogin />}></Route> */}
      {/* <Route path="/providerReg" element={<ProviderReg />}></Route> */}
      {/* <Route path="/providerForgot" element={<ProviderForgot />}></Route> */}
      {/* <Route path="/providerResetPassword" element={<ProviderResetPassword />}></Route> */}
      <Route path="/Navbar" element={<Navbar />}></Route>
      <Route path="/performance" element={<Performance />}></Route>
      <Route path="/dentist-lists" element={<DentistLists />}></Route>
      <Route path="/appointments/:id/:from?" element={<Appointments />}></Route>


      {/* ======= Clinic profile ============= */}

      <Route
        path="/doctor-list"
        element={
          <PrivateRoute account_type={"user"}>
            <DoctorList />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/doctor-profile"
        element={
          <PrivateRoute account_type={"clinic"}>
            <DoctorProfile />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/provider-profile/:id"
        element={
          <PrivateRoute account_type={"clinic"}>
            <ProviderProfile />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/dentistProfile"
        element={
          <DentistProfile />
          // <PrivateRoute account_type={"clinic"}>
           
          // </PrivateRoute>
        }
      ></Route>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute account_type={"clinic"}>
            <Dashboard />
          </PrivateRoute>
        }
      ></Route>
      {/* <Route path="/appointment/:id" element={<PrivateRoute account_type={'user'}><Appointment /></PrivateRoute>}></Route> */}
      <Route
        path="/provider-chat-box"
        element={
          <PrivateRoute account_type={"clinic"}>
            <ProvdiderChatBox />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/appointment-request"
        element={
          <PrivateRoute account_type={"clinic"}>
            <AppointmentRequest />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/billings"
        element={
          <PrivateRoute account_type={"clinic"}>
            <Billings />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/dentist-list"
        element={
          <PrivateRoute account_type={"user"}>
            <DentistList />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/providers"
        element={
          <PrivateRoute account_type={"clinic"}>
            <ProviderHomepage />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/Provider-Calender"
        element={
          <PrivateRoute account_type={"clinic"}>
            <Calender />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/chat-box"
        element={
          <PrivateRoute account_type={"user"}>
            <ChatBox />
          </PrivateRoute>
        }
      ></Route>

      {/* ======= user profile ============= */}

      <Route
        path="/user-profile/:tab"
        element={
          <PrivateRoute account_type={"user"}>
            <UserProfile />{" "}
          </PrivateRoute>
        }
      ></Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
