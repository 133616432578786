import React, { useState, useEffect } from "react";
import "./Questionnair.scss";
import icon1 from "../../Assets/icon1.svg";
import icon2 from "../../Assets/icon2.svg";
import icon3 from "../../Assets/icon3.svg";
import icon4 from "../../Assets/icon4.svg";
import icon5 from "../../Assets/icon5.svg";
import icon7 from "../../Assets/dental-bonding-ic.svg";
import icon6 from "../../Assets/icon6.svg";
import manulife from "../../Assets/manulife.png";
import sunlife from "../../Assets/sunlife.svg";
import canadalife from "../../Assets/canada-life.svg";
import blueCross from "../../Assets/Blue-Cross.svg";
import Desjardians from "../../Assets/logo-dsf-en-US.svg";
import GreenShield from "../../Assets/greenshield.svg";
import checked from "../../Assets/check.svg";
import cross from "../../Assets/cross.svg";
import CDCP from "../../Assets/CDCP logo.png";

import Anthem from "../../Assets/anthem.jpg";
import Humana from "../../Assets/humana.png";
import Ameritas from "../../Assets/ameritas.png";
import Aetna from "../../Assets/aetna.png";
import Cigna from "../../Assets/cigna.png";
import Delta_Dental from "../../Assets/delta_dental.png";
import Guardian from "../../Assets/guardian.png";
import Medicaid from "../../Assets/medicaid.png";
import Metlife from "../../Assets/metlife.png";
import United_Healthcare from "../../Assets/unitedHealthCare.png";

import { useLocation, useNavigate } from "react-router-dom";
import {
  getNearestClinic,
  getNearestClinics,
} from "../../utils/httpServices/httpService";
import { toast } from "react-toastify";

// let question = [{ ques: "What is the main purpose of your visit?", options: [{ ans: "Checkup & Cleaning", icon: icon1 }, { ans: "Specific Procedure", icon: icon2 }, { ans: "Dental Emergency", icon: icon3 }, { ans: "Second Opinion", icon: icon4 }, { ans: "Dental Bonding", icon: icon7 },] },
// {
//     ques: "What specific procedure are you looking to have?", options: [{ ans: "Fillings", icon: "" }, { ans: "Crowns", icon: "" }, { ans: "Root Canal", icon: "" }, { ans: "Wisdom teeth Removal", icon: "", }, { ans: "Teeth Whitening", icon: "" }, { ans: "Dental Bridge", icon: "" },
//     //  { ans: "Dental Bonding", icon: "" },
//     { ans: "Tooth Extraction", icon: "" }, { ans: "Checkup $ Cleaning", icon: "" }]
// },
// { ques: "What I'm looking for in a dentist", options: [{ ans: "Nearly Location", icon: "" }, { ans: "Accept My Insurances", icon: "" }, { ans: "Top Rated", icon: "" }, { ans: "Schedule Flexiblility", icon: "", }, { ans: "Modern Clinic", icon: "" }, { ans: "Highly Experienced", icon: "" }, { ans: "Dental Bonding", icon: "" },] },
// { ques: "When was the last time you saw a dentist", options: [{ ans: "Less Then A Year", icon: "" }, { ans: "1-3 Years", icon: "" }, { ans: "3+ Years", icon: "" }, { ans: "Never Ever One", icon: "", }] },
// { ques: "What time for your visit works best for you", options: [{ ans: "Morning 8am-12pm", icon: "" }, { ans: "Afternoon 12pm-5pm", icon: "" }, { ans: "Evening After 5pm", icon: "" }, { ans: "Weekends Sat-Sun", icon: "", }] },
// { ques: "How soon would you like to receive care", options: [{ ans: "As Soon As Possible", icon: "" }, { ans: "Within A Week", icon: "" }, { ans: "1-2 Week ", icon: "" }, { ans: "More Then 2 Weeks", icon: "", }] },
// { ques: "Do you have dental insurance?", options: [{ ans: "Yes I have insurance", icon: icon5 }, { ans: "No I don't have insurance", icon: icon6 },] },
// { ques: "Who is your dental insurance provider?", options: [{ ans: "Manulife Financial", icon: manulife }, { ans: "Sunlife Financial", icon: sunlife }, { ans: "Canada Life", icon: canadalife }, { ans: "Blue Cross", icon: blueCross }, { ans: "Desjardins", icon: Desjardians }, { ans: "Green Shield ", icon: GreenShield }, { ans: "Other", icon: "" },] },
// { ques: "If we hired you a dentist that matches all of your criteria are you ready to book now?", options: [{ ans: "Yes", icon: checked }, { ans: "No", icon: cross }] },
// { ques: "What's holding you back?", options: [{ ans: "Waiting for insurance benefit to renew", icon: "" }, { ans: "I need to check with my partner first", icon: "" }, { ans: "Don't have the time", icon: "" }, { ans: "Not looking for a dentist right now", icon: "" }, { ans: "Other", icon: "" },] },

// ]

export default function Questionnair() {
  const retainedData = JSON.parse(localStorage.getItem("retainedData"));
  console.log("🚀 ~ Questionnair ~ retainedData:", retainedData);
  const location = useLocation();
  const query_param = new URLSearchParams(location.search);
  const questionNumber = parseInt(query_param.get("questionNum"));

  useEffect(() => {}, [query_param]);
  const [countryName, setCountryName] = useState(
    localStorage.getItem("country")
  );
  console.log("🚀 ~ Questionnair ~ countryName:", countryName)
  // const progressBar = JSON.parse(ses.getItem("progress_bar"));
  // const totalCounts = JSON.parse(sessionStorage.getItem("total_counts"));
  const outOfCanadaInsurances = [
    { ans: "Anthem", icon: Anthem },
    { ans: "Humana", icon: Humana },
    { ans: "Ameritas", icon: Ameritas },
    { ans: "Aetna", icon: Aetna },
    { ans: "Cigna", icon: Cigna },
    { ans: "Delta Dental", icon: Delta_Dental },
    { ans: "Guardian", icon: Guardian },
    { ans: "Medicaid", icon: Medicaid },
    { ans: "Metlife", icon: Metlife },
    { ans: "United Healthcare", icon: United_Healthcare },
    { ans: "Other", icon: "" },
  ];

  const navigate = useNavigate();
  const [questionNum, setQuestionNum] = useState(0);

  useEffect(() => {
    console.log("checlin");
    handleProgressBar();
  }, [questionNum]);
  const survey = [
    {
      ques: "What is the main purpose of your visit?",
      options: [
        { ans: "Checkup & Cleaning", icon: icon1 },
        { ans: "Specific Procedure", icon: icon2 },
        { ans: "Dental Emergency", icon: icon3 },
        { ans: "Second Opinion", icon: icon4 },
        // , { ans: "Dental Bonding", icon: icon7 },
      ],
    },

    {
      ques: "What specific procedure are you looking to have?",
      options: [
        { ans: "Fillings", icon: "" },
        { ans: "Crowns", icon: "" },
        { ans: "Root Canal", icon: "" },
        { ans: "Teeth Whitening", icon: "" },
        { ans: "Dental Bridge", icon: "" },
        // { ans: "Dental Bonding", icon: "" },
        { ans: "Braces", icon: "" },
        { ans: "Wisdom tooth Extraction", icon: "" },
        { ans: "Checkup & Cleanings", icon: "" },
        { ans: "Veneers", icon: "" },
        { ans: "Invisalign", icon: "" },
        { ans: "Dental Implants", icon: "" },
        { ans: "Composite Bonding", icon: "" },
        { ans: "Mouth Guards", icon: "" },
        { ans: "Dentures", icon: "" },
      ],
    },
   
    {
      ques: "What I'm looking for in a dentist is...",
      options: [
        { ans: "Nearby Location", icon: "" },
        { ans: "Accepts My Insurance", icon: "" },
        { ans: "Top Rated", icon: "" },
        { ans: "Schedule Flexibility", icon: "" },
        { ans: "Modern Clinic", icon: "" },
        { ans: "Highly Experienced", icon: "" },
        // { ans: "Dental Bonding", icon: "" },
      ],
    },
    {
      ques: "When was the last time you saw a dentist?",
      options: [
        { ans: "Less Than A Year", icon: "" },
        { ans: "1-3 Years", icon: "" },
        { ans: "3+ Years", icon: "" },
        { ans: "Never Seen One", icon: "" },
      ],
    },
    {
      ques: "What time works best for your visit?",
      options: [
        { ans: "Morning 8am-12pm", icon: "" },
        { ans: "Afternoon 12pm-5pm", icon: "" },
        { ans: "Evening After 5pm", icon: "" },
        { ans: "Weekends Sat-Sun", icon: "" },
      ],
    },
    {
      ques: "How soon would you like to receive care?",
      options: [
        { ans: "As Soon As Possible", icon: "" },
        { ans: "Within A Week", icon: "" },
        { ans: "1-2 Weeks ", icon: "" },
        { ans: "More Than 2 Weeks", icon: "" },
      ],
    },
    {
      ques: "Do you have dental insurance?",
      options: [
        { ans: "Yes I have insurance", icon: icon5 },
        { ans: "No I don't have insurance", icon: icon6 },
      ],
    },
    {
      ques: "Who is your dental insurance provider?",
      options:
        countryName === "Canada"
          ? [
              { ans: "Manulife Financial", icon: manulife },
              { ans: "Sunlife Financial", icon: sunlife },
              { ans: "Canada Life", icon: canadalife },
              { ans: "Blue Cross", icon: blueCross },
              { ans: "Desjardins", icon: Desjardians },
              { ans: "Green Shield ", icon: GreenShield },
              { ans: "CDCP", icon: CDCP },
              { ans: "Other", icon: "" },
            ]
          : outOfCanadaInsurances,
    },
    {
      ques: "If we found you a dentist that matches all of your criteria, are you willing to book an appointment?",
      options: [
        { ans: "Yes", icon: checked },
        { ans: "No", icon: cross },
      ],
    },
    {
      ques: "What's holding you back?",
      options: [
        { ans: "Waiting for insurance benefit to renew", icon: "" },
        { ans: "I need to check with my partner first", icon: "" },
        { ans: "Don't have the time", icon: "" },
        { ans: "Not looking for a dentist right now", icon: "" },
        { ans: "Other", icon: "" },
      ],
    },
  ];
  const [open, setOpen] = useState(false);
  const [insurance, setInsurance] = useState("");

  const [haveInsurance, setHaveInsurance] = useState(false);
  const [width, setWidth] = useState("0%");
  console.log("🚀 ~ Questionnair ~ width:", width);
  const [lat, setLat] = useState(0);
  const [lng, setlng] = useState(0);
  const totalQuestions = 10;
  const [time, setTime] = useState([]);
  const [totalAttemp, setToatalAttempt] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
  });
  const [count, setCount] = useState(retainedData?.count || 0);
  const [single, setSingle] = useState(
    retainedData?.single || {
      0: -1,
      3: -1,
      // 4: -1,
      5: -1,
      6: -1,
      7: -1,
      8: -1,
      9: -1,
      10: -1,
    }
  );
  console.log("🚀 ~ Questionnair ~ single:", single);

  const [multiple, setMultiple] = useState(
    retainedData?.multiple || [
      {
        1: {
          0: -1,
          1: -1,
          2: -1,
          3: -1,
          4: -1,
          5: -1,
          6: -1,
          7: -1,
        },
      },

      {
        2: {
          0: -1,
          1: -1,
          2: -1,
          3: -1,
          4: -1,
          5: -1,
          // 6: -1,
        },
      },
      {
        4: {
          0: -1,
          1: -1,
          2: -1,
          3: -1,
        },
      },
    ]
  );

  console.log("🚀 ~ Questionnair ~ multiple:", multiple);

  const var_name = [
    "main_purpose",
    "services",
    "looking_in_dentist",
    "last_time",
    "best_visit_time",
    "receive_care",
    "have_insurance",
    "insurance",
    "ready_to_book",
    "holding_back",
  ];
  const selectedData =
    JSON.parse(localStorage.getItem("seraching_details")) || {};
  const [data, setData] = useState(
    retainedData?.data || [
      {
        main_purpose: selectedData.main_purpose || "",
      },
      {
        services: selectedData?.services || [],
      },
      {
        looking_in_dentist: selectedData?.looking_in_dentist || [],
      },
      {
        last_time: selectedData?.last_time || "",
      },
      {
        best_visit_time: selectedData?.best_visit_time || [],
      },
      {
        receive_care: selectedData?.receive_care || "",
      },
      {
        have_insurance: selectedData?.haveInsurance || null,
      },
      {
        insurance: selectedData?.insurance || "",
      },
      {
        ready_to_book: selectedData?.ready_to_book || null,
      },
      {
        holding_back: selectedData?.holding_back || "",
      },
      {
        coordinates: selectedData?.coordinates || [],
      },
    ]
  );
  console.log("data------------>", data);

  //sending data to local storage
  useEffect(() => {
    return () => {
      // Define the data to be retained
      const retainedData = {
        single: single,
        multiple: multiple,
        data: data,
        // width: width,
        count: count,
      };
      console.log(retainedData, "unmount");
      // Store the retained data in local storage
      localStorage.setItem("retainedData", JSON.stringify(retainedData));
    };
  });

  function countHandlerForPercentages(questionNumber) {
    if (totalAttemp[questionNumber] === 0) {
      setCount((prev) => prev + 1);
    }

    setToatalAttempt((prevAttempts) => ({
      ...prevAttempts,
      [questionNumber]: 1,
    }));
  }
  // Check if the URLSearchParams API is supported by the browser
  const [query, setQuery] = useState(0);
  if (window.URLSearchParams) {
    // Create a URLSearchParams object with the current URL query parameters
    var queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'myNewUrlQuery' parameter
    var myNewUrlQueryValue = queryParams.get("questionNum");

    // Check if the parameter exists and has a value
    if (myNewUrlQueryValue !== null) {
      if (query !== parseInt(myNewUrlQueryValue)) {
        console.log("myNewUrlQueryValue", myNewUrlQueryValue);
        setQuery(parseInt(myNewUrlQueryValue));
      }

      console.log("Value of myNewUrlQuery parameter:", myNewUrlQueryValue);
    }
  }

  useEffect(() => {
    setQuestionNum(query);
  }, [query]);

  const handleQuestion = (action) => {
    if (action === "next") {
      let newQuestionNum = questionNum;
      if (questionNum === 0) {
        if (data[0].main_purpose === "") {
          toast.error("Please select a filed");
        } else if (
          questionNum === 0 &&
          data[0].main_purpose !== "Specific Procedure"
        ) {
          newQuestionNum = 2;
        } else if (
          questionNum === 0 &&
          data[0].main_purpose == "Specific Procedure"
        ) {
          newQuestionNum = 1;
        }
      } else {
        if (questionNum === 6 || questionNum === 8) {
          // if(questionNum === 6){

          // }
          if (
            questionNum === 8 &&
            data[questionNum][var_name[questionNum]] === "Yes"
          ) {
            submitHandler();
            return;
          }
          if (data[questionNum][var_name[questionNum]] === null) {
            toast.error("Please select a field.");
            return;
          } else {
            if (questionNum === 6 && haveInsurance === false) {
              newQuestionNum += 2;
              // setQuestionNum((questionNum) => questionNum + 2);
              countHandlerForPercentages(questionNum + 2);
            } else if (questionNum < 9) {
              newQuestionNum += 1;
              // setQuestionNum((questionNum) => questionNum + 1);
              countHandlerForPercentages(questionNum + 1);
            }
          }
        } else if (questionNum === 1 || questionNum === 2 || questionNum === 3) {
          if (data[questionNum][var_name[questionNum]].length === 0) {
           
            toast.error("Please select a field.");
            return;
          } else {
            if (questionNum < 9) {
              newQuestionNum += 1;
              // setQuestionNum((questionNum) => questionNum + 1);
              countHandlerForPercentages(questionNum + 1);
            }
          }
        }
        else if (data[questionNum][var_name[questionNum]] === "") {
          toast.error("Please select a field.");
          return;
        } else {
          if (
            questionNum === 7 &&
            data[questionNum][var_name[questionNum]] === "Other"
          ) {
            if (insurance === "") {
              toast.error("Please select a field.");
              return;
            } else {
              newQuestionNum += 1;
              // setQuestionNum((questionNum) => questionNum + 1);
              countHandlerForPercentages(questionNum + 1);
            }
          } else if (questionNum < 9) {
            if (questionNum === 4 && data[4].best_visit_time?.length === 0) {
              toast.error("Please select a filed");
            }
            if (
              questionNum === 4 &&
              // data[0][var_name[0]] === "Dental Emergency"
              data[0].main_purpose === "Dental Emergency"
            ) {
              newQuestionNum += 2;
              console.log("leave this");
              setQuestionNum((questionNum) => questionNum + 2);
              countHandlerForPercentages(questionNum + 2);
            } else {
              console.log(
                data,
                "==========================data========================="
              );
              console.log(data[4].best_visit_time);
              if (data[4]?.best_visit_time?.length > 0) {
                newQuestionNum += 1;
                // setQuestionNum((questionNum) => questionNum + 1);
                countHandlerForPercentages(questionNum + 1);
              }
            }
          }
        }
      }
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=${newQuestionNum}`;
      window.history.pushState({ path: newurl }, "", newurl);
      setQuery(newQuestionNum);
      // setQuestionNum(newQuestionNum);
    } else if (
      action === "prev" &&
      questionNum === 2 &&
      data[0].main_purpose === "Specific Procedure"
    ) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=1`;
      window.history.pushState({ path: newurl }, "", newurl);
      setQuery(1);
    } else if (
      action === "prev" &&
      questionNum === 2 &&
      data[0].main_purpose !== "Specific Procedure"
    ) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=0`;
      window.history.pushState({ path: newurl }, "", newurl);
      setQuery(0);
    } else if (
      action === "prev" &&
      questionNum === 6 &&
      data[0].main_purpose === "Dental Emergency"
    ) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=4`;
      window.history.pushState({ path: newurl }, "", newurl);
      setQuery(4);
    } else if (
      action === "prev" &&
      questionNum === 8 &&
      haveInsurance === false
    ) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=${questionNum - 2}`;
      window.history.pushState({ path: newurl }, "", newurl);
      setQuery(questionNum - 2);
      // setQuestionNum((questionNum) => questionNum - 2);
    } else if (action === "prev" && questionNum > 0) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=${questionNum - 1}`;
      window.history.pushState({ path: newurl }, "", newurl);
      // setQuestionNum((questionNum) => questionNum - 1);
      setQuery(questionNum - 1);
    } else if (action === "prev" && questionNum == 0) {
      navigate("/");
    }
  };


  // const handleQuestion = (action) => {
  //   const incrementQuestionNum = (increment) => {
  //     const newQuestionNum = questionNum + increment;
  //     const newurl =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       window.location.pathname +
  //       `?questionNum=${newQuestionNum}`;
  //     window.history.pushState({ path: newurl }, "", newurl);
  //     setQuery(newQuestionNum);
  //   };
  
  //   const redirectTo = (questionNumValue) => {
  //     const newurl =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       window.location.pathname +
  //       `?questionNum=${questionNumValue}`;
  //     window.history.pushState({ path: newurl }, "", newurl);
  //     setQuery(questionNumValue);
  //   };
  
  //   if (action === "prev") {
  //     if (questionNum === 0) {
  //       navigate("/");
  //     } else if (questionNum === 2) {
  //       redirectTo(data[0].main_purpose === "Specific Procedure" ? 1 : 0);
  //     } else if (questionNum === 6 && data[0].main_purpose === "Dental Emergency") {
  //       redirectTo(4);
  //     } else if (questionNum === 8 && !haveInsurance) {
  //       redirectTo(questionNum - 2);
  //     } else if (questionNum > 0) {
  //       incrementQuestionNum(-1);
  //     }
  //   } else if (action === "next") {
  //     let newQuestionNum = questionNum;
  
  //     if (questionNum === 0) {
  //       if (data[0].main_purpose === "") {
  //         toast.error("Please select a field");
  //         return;
  //       }
  //       newQuestionNum = data[0].main_purpose === "Specific Procedure" ? 1 : 2;
  //     } else {
  //       if (questionNum === 8 && data[questionNum][var_name[questionNum]] === "Yes") {
  //         submitHandler();
  //         return;
  //       }
  
  //       if (!data[questionNum][var_name[questionNum]]) {
  //         toast.error("All fields are required.");
  //         return;
  //       }
  
  //       if (questionNum === 6 && !haveInsurance) {
  //         newQuestionNum += 2;
  //         countHandlerForPercentages(questionNum + 2);
  //       } else if (questionNum < 9) {
  //         newQuestionNum += 1;
  //         countHandlerForPercentages(questionNum + 1);
  //       }
  //     }
  //     incrementQuestionNum(newQuestionNum - questionNum);
  //   }
  // };
  

  const submitHandler = async () => {
    if (data[questionNum][var_name[questionNum]] === "") {
      toast.error("All fields are required.");
      return;
    }
    try {
      let best_time = {
        type: "both",
        start_time1: "",
        end_time1: "",
        start_time2: "",
        end_time2: "",
        end_time3: "",
        days: [],
      };

      const bestVisitTime = data[4]?.best_visit_time;

      bestVisitTime?.map((val) => {
        let result;
        if (val === "Morning 8am-12pm") {
          result = changeTimeInUtc("8", "12");
          best_time.start_time1 = result[0] || best_time.start_time1;
          best_time.end_time1 = result[1] || best_time.end_time1;
        } else if (val === "Afternoon 12pm-5pm") {
          result = changeTimeInUtc("12", "17");
          best_time.start_time2 = result[0] || best_time.start_time2;
          best_time.end_time2 = result[1] || best_time.end_time2;
        } else if (val === "Evening After 5pm") {
          result = changeTimeInUtc("0", "17");
          best_time.end_time3 = result[1] || best_time.end_time3;
        } else if (val === "Weekends Sat-Sun") {
          best_time.days.push("Saturday", "Sunday");
        }
      });

      // let best_time = {
      //   type: "both",
      //   start_time1: "",
      //   end_time1: "",
      //   start_time2: "",
      //   end_time2: "",
      //   end_time3: "",
      //   days: [],
      // };

      // data[4]?.best_visit_time?.map((val) => {
      //   if (val === "Morning 8am-12pm") {
      //     const result = changeTimeInUtc("8", "12");

      //     best_time?.start_time1 = result[0]
      //     best_time?.end_time1 = result[1]
      //   } else if (val === "Afternoon 12pm-5pm") {
      //     const result = changeTimeInUtc("12", "17");

      //     best_time?.start_time2 = result[0]
      //     best_time?.end_time2 = result[1]
      //   } else if (val === "Evening After 5pm") {
      //     const result = changeTimeInUtc("0", "17");

      //     best_time?.end_time3 = result[1]

      //   } else if (val === "Weekends Sat-Sun") {
      //     best_time?.days.push("Saturday");
      //     best_time?.days.push("Sunday");
      //   }
      // });

      // if (data[4].best_visit_time === "Morning 8am-12pm") {
      //   const result = changeTimeInUtc("8", "12");
      //   best_time = {
      //     type: "time",
      //     start_time: result[0],
      //     end_time: result[1],
      //   };
      // } else if (data[4].best_visit_time === "Afternoon 12pm-5pm") {
      //   const result = changeTimeInUtc("12", "17");
      //   best_time = {
      //     type: "time",
      //     start_time: result[0],
      //     end_time: result[1],
      //   };
      // } else if (data[4].best_visit_time === "Evening After 5pm") {
      //   const result = changeTimeInUtc("0", "17");
      //   best_time = {
      //     type: "time",
      //     end_time: result[1],
      //   };
      // } else {
      //   best_time = {
      //     type: "day",
      //     days: ["Saturday", "Sunday"],
      //   };
      // }
      const coordinates = [parseFloat(lng), parseFloat(lat)];

      const ins = insurance != "" ? insurance : data[6].have_insurance;
      let selectedInsurance = insurance != "" ? "others" : data[7].insurance;
      // console.log(ins, selectedInsurance);
      let body = {
        main_purpose: data[0].main_purpose,
        service: data[1]?.services, //data[1].services
        looking_in_dentist: data[2].looking_in_dentist,
        last_time: data[3].last_time,
        best_visit_time: best_time, //best_time
        receive_care: data[5].receive_care,
        have_insurance: haveInsurance, //ins === "Yes I have insurance" ? true : false,
        insurance: selectedInsurance, //ins,//data[7].insurance,
        ready_to_book: data[8].ready_to_book === "Yes" ? true : false,
        holding_back: data[9].holding_back,
        coordinates: coordinates,
      };

      let resp = {};
      const token = localStorage.getItem("token");
      const user_data = localStorage.getItem("user_details");
      if (token != null && user_data != null) {
        resp = await getNearestClinics(body);
      } else {
        resp = await getNearestClinic(body);
      }

      body = {
        ...body,
        best_visit_time: data[4].best_visit_time,
        insurance: insurance || data[7].insurance,
      };



      localStorage.setItem("seraching_details", JSON.stringify(body));
      localStorage.setItem("clinic-lists", JSON.stringify(resp?.data));
      addSearchDateInLocalStorage()
      // console.log(resp?.data);
      navigate("/recommendation");
    } catch (error) {
      console.log(error);
    }
  };

 const addSearchDateInLocalStorage = ()=> {
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();

    const formattedDate = `${day} ${monthNames[monthIndex]}`;
    localStorage.setItem("currentDate", JSON.stringify(formattedDate));
  }

  const handleProgressBar = () => {
    const questionNumToWidth = {
      0: "0%",
      1: "10%",
      2: "20%",
      3: "40%",
      4: "60%",
      5: "70%",
      6: "80%",
      7: "85%",
      8: "90%",
      9: "95%",
      10: "100%",
    };
    setWidth(questionNumToWidth[questionNum] || "0%");
  };

  function specificFieldHandler(ind, value) {
    console.log(value);
    // const newQuestionNum = questionNum;
    if (questionNum === 3) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=${questionNum + 1}`;
      window.history.pushState({ path: newurl }, "", newurl);
      // setQuestionNum((questionNum) => questionNum - 1);
      setQuery(questionNum + 1);
      // setQuestionNum((questionNum) => questionNum + 1);
      countHandlerForPercentages(questionNum + 1);
    } else if (questionNum === 5) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?questionNum=${questionNum + 1}`;
      window.history.pushState({ path: newurl }, "", newurl);
      // setQuestionNum((questionNum) => questionNum - 1);
      setQuery(questionNum + 1);
      countHandlerForPercentages(questionNum + 1);
    } else if (questionNum === 6) {
      if (value?.ans === "Yes I have insurance") {
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?questionNum=${questionNum + 1}`;
        window.history.pushState({ path: newurl }, "", newurl);
        // setQuestionNum((questionNum) => questionNum - 1);
        setQuery(questionNum + 1);
        // setQuestionNum((questionNum) => questionNum + 1);
        countHandlerForPercentages(questionNum + 1);
      } else if (value?.ans === "No I don't have insurance") {
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?questionNum=${questionNum + 2}`;
        window.history.pushState({ path: newurl }, "", newurl);
        // setQuestionNum((questionNum) => questionNum - 1);
        setQuery(questionNum + 2);
        // setQuestionNum((questionNum) => questionNum + 2);
        countHandlerForPercentages(questionNum + 2);
      }
    } else if (questionNum === 8) {
      if (value?.ans === "Yes") {
        submitHandler();
      } else {
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?questionNum=${questionNum + 1}`;
        window.history.pushState({ path: newurl }, "", newurl);
        // setQuestionNum((questionNum) => questionNum - 1);
        setQuery(questionNum + 1);
        // setQuestionNum((questionNum) => questionNum + 1);
        countHandlerForPercentages(questionNum + 1);
      }
    }
  }

  const selectorHandler = (ind, el) => {
    console.log("questionNum", questionNum);

    specificFieldHandler(ind, el);
    if (questionNum === 6 && ind === 1) {
      setHaveInsurance(false);
    } else if (questionNum === 6 && ind !== 1) {
      setHaveInsurance(true);
    }
    if (questionNum === 1) {
      if (multiple[0][1][ind] === ind) {
        setMultiple([
          {
            ...multiple[0],
            1: {
              ...multiple[0][1],
              [ind]: -1,
            },
          },
          multiple[1],
          multiple[2],
        ]);
      } else {
        setMultiple([
          {
            ...multiple[0],
            1: {
              ...multiple[0][1],
              [ind]: ind,
            },
          },
          multiple[1],
          multiple[2],
        ]);
      }
    } else if (questionNum === 2) {
      if (multiple[1][2][ind] === ind) {
        setMultiple([
          multiple[0],
          {
            ...multiple[1],
            2: {
              ...multiple[1][2],
              [ind]: -1,
            },
          },
          multiple[2],
        ]);
      } else {
        setMultiple([
          multiple[0],
          {
            ...multiple[1],
            2: {
              ...multiple[1][2],
              [ind]: ind,
            },
          },
          multiple[2],
        ]);
      }
    } else if (questionNum === 4) {
      if (multiple[2][4][ind] === ind) {
        setMultiple([
          multiple[0],
          multiple[1],
          {
            ...multiple[2],
            4: {
              ...multiple[2][4],
              [ind]: -1,
            },
          },
        ]);
      } else {
        setMultiple([
          multiple[0],
          multiple[1],
          {
            ...multiple[2],
            4: {
              ...multiple[2][4],
              [ind]: ind,
            },
          },
        ]);
      }
    } else {
      console.log(single, "before updating");
      setSingle({
        ...single,
        [questionNum]: ind,
      });
      console.log(single, "after updating");
    }

    if (questionNum === 7 && el?.ans === "Other") {
      setOpen(true);
    } else if (questionNum === 7 && el?.ans !== "Other") {
      setOpen(false);
      setInsurance("");
    }
  };

  console.log({ data });

  // func

  const dataStoreHandler = (Data) => {
    if (questionNum === 1) {
      console.log(data);

      const updatedData = [...data];
      console.log(updatedData[1].services);

      if (updatedData[1].services.includes(Data.ans)) {
        updatedData[1].services = updatedData[1].services.filter(
          (val) => val != Data.ans
        );
      } else {
        updatedData[1].services = [
          ...new Set([...updatedData[1].services, Data.ans]),
        ];
      }

      setData(updatedData);
      localStorage.setItem("data", JSON.stringify(updatedData));
    } else if (questionNum === 2) {
      const updatedData = [...data];

      if (updatedData[2].looking_in_dentist.includes(Data.ans)) {
        updatedData[2].looking_in_dentist =
          updatedData[2].looking_in_dentist.filter((val) => val != Data.ans);
      } else {
        updatedData[2].looking_in_dentist = [
          ...new Set([...updatedData[2].looking_in_dentist, Data.ans]),
        ];
      }
      setData(updatedData);
      localStorage.setItem("data", JSON.stringify(updatedData));
    } else if (questionNum === 4) {
      const updatedData = [...data];

      if (updatedData[4].best_visit_time.includes(Data.ans)) {
        updatedData[4].best_visit_time = updatedData[4].best_visit_time.filter(
          (val) => val != Data.ans
        );
      } else {
        updatedData[4].best_visit_time = [
          ...new Set([...updatedData[4].best_visit_time, Data.ans]),
        ];
      }
      setData(updatedData);
      localStorage.setItem("data", JSON.stringify(updatedData));
    } else {
      const updatedData = [...data];
      const propertyName = var_name[questionNum];
      updatedData[questionNum][propertyName] = Data.ans;
      setData(updatedData);
      localStorage.setItem("data", JSON.stringify(updatedData));
    }
  };

  const changeTimeInUtc = (first, second) => {
    const startInputTime = new Date("2023-01-01"); //We need only time here.
    const endInputTime = new Date("2023-01-01"); //We need only time here.

    startInputTime.setHours(first, 0, 0, 0); // Set the hours and minutes
    endInputTime.setHours(second, 0, 0, 0); // Set the hours and minutes

    // const timeZoneOffset = startInputTime.getTimezoneOffset();

    // // Adjust the start time to UTC by subtracting the offset
    // const startUtcTime = new Date(startInputTime.getTime() - timeZoneOffset * 60000);
    // const endUtcTime = new Date(endInputTime.getTime() - timeZoneOffset * 60000);

    const res = [startInputTime.toISOString(), endInputTime.toISOString()];
    return res;
  };

  useEffect(() => {
    const lat1 = localStorage.getItem("lat");
    const lng1 = localStorage.getItem("lng");
    const country = localStorage.getItem("country");

    console.log(lat1, lng1);
    setCountryName(country);
    setLat(lat1);
    setlng(lng1);
  }, [count]);

  // function multiSelectFun(Data, ind){
  //   if(ind === 1){
  //   Data.map((val)=>{
  //    if(val === "Fillings"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Fillings"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [0]: 0,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);

  //    }else if(val === "Crowns"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Crowns"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [1]: 1,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);

  //    }else if(val === "Root Canal"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Root Canal"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [2]: 2,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }else if(val === "Wisdom teeth Removal"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Wisdom teeth Removal"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [3]: 3,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);

  //    }else if(val === "Teeth Whitening"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Teeth Whitening"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [4]: 4,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }else if(val === "Dental Bridge"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Dental Bridge"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [5]: 5,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }else if(val === "Dental Bonding"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Dental Bonding"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [6]: 6,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }else if(val === "Tooth Extraction"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Tooth Extraction"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [7]: 7,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }else if(val === "Checkup & Cleanings"){
  //     const updatedData = [...data];
  //     console.log(updatedData[1].services);

  //       updatedData[1].services = [
  //         ...new Set([...updatedData[1].services, "Checkup & Cleanings"]),
  //       ]

  //     setData(updatedData);

  //     setMultiple([
  //       {
  //         ...multiple[0],
  //         1: {
  //           ...multiple[0][1],
  //           [8]: 8,
  //         },
  //       },
  //       multiple[1],
  //       multiple[2],
  //     ]);
  //    }
  //   });
  //   setCount((prev)=>prev +1)
  // }

  //   else if(ind === 2){
  //   Data.map((val)=>{

  //     if(val === "Nearby Location"){
  //       const updatedData = [...data];

  //       updatedData[2].looking_in_dentist = [
  //         ...new Set([...updatedData[2].looking_in_dentist, "Nearby Location"]),
  //       ];

  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         {
  //           ...multiple[1],
  //           2: {
  //             ...multiple[1][2],
  //             [0]: 0,
  //           },
  //         },
  //         multiple[2],
  //       ]);

  //      }else if(val === "Accept My Insurances"){
  //       const updatedData = [...data];

  //       updatedData[2].looking_in_dentist = [
  //         ...new Set([...updatedData[2].looking_in_dentist, "Accept My Insurances"]),
  //       ];
  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         {
  //           ...multiple[1],
  //           2: {
  //             ...multiple[1][2],
  //             [1]: 1,
  //           },
  //         },
  //         multiple[2],
  //       ]);
  //      }else if(val === "Top Rated"){
  //       const updatedData = [...data];

  //       updatedData[2].looking_in_dentist = [
  //         ...new Set([...updatedData[2].looking_in_dentist, "Top Rated"]),
  //       ];

  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         {
  //           ...multiple[1],
  //           2: {
  //             ...multiple[1][2],
  //             [2]: 2,
  //           },
  //         },
  //         multiple[2],
  //       ]);

  //      }else if(val === "Schedule Flexiblility"){
  //       const updatedData = [...data];

  //       updatedData[2].looking_in_dentist = [
  //         ...new Set([...updatedData[2].looking_in_dentist, "Schedule Flexiblility"]),
  //       ];
  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         {
  //           ...multiple[1],
  //           2: {
  //             ...multiple[1][2],
  //             [3]: 3,
  //           },
  //         },
  //         multiple[2],
  //       ]);
  //      }else if(val === "Modern Clinic"){
  //       const updatedData = [...data];

  //       updatedData[2].looking_in_dentist = [
  //         ...new Set([...updatedData[2].looking_in_dentist, "Modern Clinic"]),
  //       ];
  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         {
  //           ...multiple[1],
  //           2: {
  //             ...multiple[1][2],
  //             [4]: 4,
  //           },
  //         },
  //         multiple[2],
  //       ]);
  //      }else if(val === "Highly Experienced"){
  //       const updatedData = [...data];

  //         updatedData[2].looking_in_dentist = [
  //           ...new Set([...updatedData[2].looking_in_dentist, "Highly Experienced"]),
  //         ];
  //         setData(updatedData);

  //         setMultiple([
  //           multiple[0],
  //           {
  //             ...multiple[1],
  //             2: {
  //               ...multiple[1][2],
  //               [5]: 5,
  //             },
  //           },
  //           multiple[2],
  //         ]);
  //      }
  //   });
  //   setCount((prev)=>prev +1)}

  //   else if(ind === 4){
  //   Data.map((val)=>{
  //     if(val === "Morning 8am-12pm"){
  //       const updatedData = [...data];

  //       updatedData[4].best_visit_time = [
  //         ...new Set([...updatedData[4].best_visit_time, "Morning 8am-12pm"]),
  //       ];

  //     setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         multiple[1],
  //         {
  //           ...multiple[2],
  //           4: {
  //             ...multiple[2][4],
  //             [0]: 0,
  //           },
  //         },
  //       ]);

  //      }else if(val === "Afternoon 12pm-5pm"){
  //       const updatedData = [...data];

  //       updatedData[4].best_visit_time = [
  //         ...new Set([...updatedData[4].best_visit_time, "Afternoon 12pm-5pm"]),
  //       ];

  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         multiple[1],
  //         {
  //           ...multiple[2],
  //           4: {
  //             ...multiple[2][4],
  //             [1]: 1,
  //           },
  //         },
  //       ]);
  //      }else if(val === "Evening After 5pm"){
  //       const updatedData = [...data];
  //       // console.log(updatedData[1].services);

  //       updatedData[4].best_visit_time = [
  //         ...new Set([...updatedData[4].best_visit_time, "Evening After 5pm"]),
  //       ];

  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         multiple[1],
  //         {
  //           ...multiple[2],
  //           4: {
  //             ...multiple[2][4],
  //             [2]: 2,
  //           },
  //         },
  //       ]);
  //      }else if(val === "Weekends Sat-Sun"){
  //       const updatedData = [...data];
  //       // console.log(updatedData[1].services);

  //       updatedData[4].best_visit_time = [
  //         ...new Set([...updatedData[4].best_visit_time, "Weekends Sat-Sun"]),
  //       ];

  //       setData(updatedData);

  //       setMultiple([
  //         multiple[0],
  //         multiple[1],
  //         {
  //           ...multiple[2],
  //           4: {
  //             ...multiple[2][4],
  //             [3]: 3,
  //           },
  //         },
  //       ]);
  //      }
  //   });
  //   setCount((prev)=>prev +1)}
  // }

  // useEffect(() => {
  //   if (localStorage.getItem("seraching_details") !== null) {
  //     let storedData = JSON.parse(localStorage.getItem("seraching_details"));
  //     console.log("storedData", storedData);

  //     if (storedData?.best_visit_time) {
  //       multiSelectFun(storedData?.best_visit_time, 4);
  //     }
  //     if (storedData?.coordinates) {
  //       setLat(storedData?.coordinates[1]);
  //       setlng(storedData?.coordinates[0]);
  //     }
  //     if (storedData?.have_insurance) {
  //     }
  //     if (storedData?.holding_back) {
  //     }
  //     if (storedData?.insurance) {
  //     }
  //     if (storedData?.last_time) {
  //     }
  //     if (storedData?.looking_in_dentist) {
  //       multiSelectFun(storedData?.looking_in_dentist, 2);

  //     }
  //     if (storedData?.main_purpose) {
  //     }
  //     if (storedData?.ready_to_book) {
  //     }
  //     if (storedData?.receive_care) {
  //     }
  //     if (storedData?.service) {
  //       multiSelectFun(storedData?.service, 1);
  //     }
  //   }
  // }, []);

  return (
    <div className="page-wrap">
      <div className="questionnair-container">
        <div className="container-fluid ">
          <div className="Questionnair">
            <div className="row  h-100">
              <div className="col-xl-8 m-auto">
                <div className="Questionnair__right-side-content">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${width}` }}
                      aria-valuenow={5}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {`${width}`}
                    </div>
                  </div>

                  <div className="Questionnair__step-inner-content">
                    <h2 className="Questionnair__main-title ">
                      {survey[questionNum].ques}
                    </h2>
                    {survey[questionNum]?.ques ===
                    "What's holding you back?" ? (
                      <p className="Questionnair__sub-title fw-bold mb-3 text-center">
                        We may be able to provide you with more information or
                        help out in the future.
                      </p>
                    ) : (
                      ""
                    )}

                    <div
                      class={`Questionnair__step-box-container ${
                        questionNum === 3
                          ? "index_num_three"
                          : questionNum === 4
                          ? "index_num_four"
                          : questionNum === 5
                          ? "index_num_five"
                          : ""
                      }`}
                    >
                      {survey[questionNum].options &&
                        survey[questionNum].options.map((el, i) => (
                          <div key={i * 100} class="Questionnair__step-box">
                            <label
                              className={`Questionnair__step-box-content ${
                                questionNum === 1
                                  ? multiple[questionNum - 1][1][i] === i
                                    ? "clicked"
                                    : ""
                                  : questionNum === 2
                                  ? multiple[questionNum - 1][2][i] === i
                                    ? "clicked"
                                    : ""
                                  : questionNum === 4
                                  ? multiple[questionNum - 2][4][i] === i
                                    ? "clicked"
                                    : ""
                                  : single[questionNum] === i
                                  ? "clicked"
                                  : ""
                              }`}
                              onClick={() => {
                                selectorHandler(i, el);
                                dataStoreHandler(el);
                              }}
                            >
                              <span class="step-box-icon">
                                <img
                                  style={{
                                    display: el.icon === "" ? "none" : "block",
                                    width: i === 7 ? "60px" : "150px", // Change width when i === 8
                                    height: i === 7 ? "60px" : "60px", // Change height when i === 8
                                  }}
                                  src={el.icon}
                                  alt=""
                                />
                              </span>
                              <span class="step-box-text">{el.ans}</span>

                              {/* <span class="service-check-option">
                                <input
                                  style={{
                                    display: el.icon === "" ? "none" : "block",
                                  }}
                                  type="radio"
                                  name="service_name"
                                  key={`service_name_${questionNum}`}
                                  value="Services"
                                  checked={single[questionNum] === i}
                                />
                              </span> */}
                            </label>
                          </div>
                        ))}

                      {open && questionNum === 7 && (
                        <input
                          type="text"
                          placeholder="Enter Your Insurance Provider"
                          value={insurance}
                          onChange={(e) => setInsurance(e.target.value)}
                        />
                      )}
                    </div>
                  </div>

                  {/* <div class="d-grid gap-2 d-md-block ">
                    <button class="btn btn-primary" type="button">Button</button>
                    <button class="btn btn-primary" type="button">Button</button>
                  </div> */}

                  <div class="Questionnair__actions">
                    <ul>
                      <li onClick={() => handleQuestion("prev")}>
                        <button
                          class="Questionnair-btn js-btn-prev"
                          title="prev"
                        >
                          {" "}
                          <i class="fa fa-arrow-left"></i> BACK
                        </button>
                      </li>
                      {questionNum === 9 ? (
                        <li
                          onClick={() => {
                            countHandlerForPercentages(questionNum + 1);
                            submitHandler();
                          }}
                        >
                          <button
                            class="Questionnair-btn js-btn-next"
                            title="NEXT"
                          >
                            NEXT <i class="fa fa-arrow-right"></i>
                          </button>
                        </li>
                      ) : (
                        <li onClick={() => handleQuestion("next")}>
                          <button
                            class="Questionnair-btn js-btn-next"
                            title="NEXT"
                          >
                            NEXT <i class="fa fa-arrow-right"></i>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
